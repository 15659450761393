import React, { Component } from "react";
import { Link } from "react-router-dom";

export class NoDataFound extends Component {
  render() {
    return (
      <div id="app">
        <section className="section">
          <div className="container mt-5">
            <div className="page-error">
              <div className="page-inner">
                <h1></h1>
                <p
                  className="page-description ml-5"
                  style={{ textAlign: "center" }}
                >
                  No Data Found
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NoDataFound;

import { updateSupplier } from "../actions/supplierActions";
import {
  GET_SUPPLIERS,
  ADD_SUPPLIER,
  UPDATE_SUPPLIER,
  DELETE_SUPPLIER,
  SUPPLIER_PAYMENT,
  EDIT_SUPPLIER_DUE
} from "../actions/types";

const initialState = { isLoaded: false, suppliers: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIERS:
      return {
        isLoaded: true,
        suppliers: action.payload
      };
    case ADD_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload]
      };
    case UPDATE_SUPPLIER:
      let index = state.suppliers.findIndex(
        sup => sup._id === action.payload._id
      );
      let updated = [
        ...state.suppliers.slice(0, index),
        action.payload,
        ...state.suppliers.slice(index + 1)
      ];

      return {
        ...state,
        suppliers: updated
      };
    case EDIT_SUPPLIER_DUE:
      let editDueindex = state.suppliers.findIndex(
        sup => sup._id === action.payload._id
      );
      let updatedData = [
        ...state.suppliers.slice(0, editDueindex),
        action.payload,
        ...state.suppliers.slice(editDueindex + 1)
      ];

      return {
        ...state,
        suppliers: updatedData
      };

    case SUPPLIER_PAYMENT:
      let pIndex = state.suppliers.findIndex(
        sup => sup._id === action.payload._id
      );
      let pUpdated = [
        ...state.suppliers.slice(0, pIndex),
        action.payload,
        ...state.suppliers.slice(pIndex + 1)
      ];

      return {
        ...state,
        suppliers: pUpdated
      };

    case DELETE_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.filter(sup => sup._id != action.payload)
      };
    default:
      return state;
  }
};

import axios from "axios";
import toastr from "toastr";
import $ from "jquery";
import { GET_MANAGERS, ADD_MANAGER, UPDATE_MANAGER, DELETE_MANAGER } from "./types";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

export const getManagers = proprietorID => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/manager/list/${proprietorID}`
    );
    dispatch({
      type: GET_MANAGERS,
      payload: data.data
    });
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data.message);
  }
  serverLoading(dispatch, false);
};

export const addManager = managerData => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/manager/add`,
      managerData
    );
    if (data.status == true) {
      $("#exampleModal").modal("hide");
      dispatch({
        type: ADD_MANAGER,
        payload: data.data
      });
      toastr.success(data.msg);
    }
  } catch (error) {
    toastr.error(error?.response?.data.message);
  }
  serverLoading(dispatch, false);
};

export const updateManager = managerData => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.put(
      `${SERVER_URL}/api/manager/edit/${managerData._id}`,
      managerData
    );
    dispatch({
      type: UPDATE_MANAGER,
      payload: data.data
    });
    toastr.success(data.msg);
  } catch (error) {
    toastr.error(error?.response?.data.message);
  }
  serverLoading(dispatch, false);
  $("#permissionModal").modal("hide");
  $("#exampleModal").modal("hide");
};
export const deleteManager = managerId => dispatch => {
  serverLoading(dispatch, true);

  axios
    .delete(`${SERVER_URL}/api/manager/delete/${managerId}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      dispatch({
        type: DELETE_MANAGER,
        payload: managerId
      });
      toastr.success("ম্যানেজার ডিলিট হয়েছে।");
    })
    .catch(error => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      toastr.error("সার্ভার থেকে সাড়া পাওয়া যায়নি");
    });
};

import React, { Component } from "react";

import { Button, Card, Container, Modal } from "react-bootstrap";

import { connect } from "react-redux";
import { proprietorStoreUpdate } from "../../actions/storeActions";

class StoreUpdateModal extends Component {
  state = {
    store: {},
    storesUpdateModalOpen: ""
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      store: this.props.storeFindData,
      storesUpdateModalOpen: this.props.storesUpdateModalOpen
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      store: nextProps.storeFindData,
      storesUpdateModalOpen: nextProps.storesUpdateModalOpen
    });
  }

  handleChange = e => {
    if (e.target.name === "hasEcommerce") {
      this.setState({
        ...this.state,
        store: {
          ...this.state.store,
          HNSettings: {
            ...this.state.store.HNSettings,
            [e.target.name]: e.target.checked
          }
        }
      });
    } else {
      this.setState({
        ...this.state,
        store: { ...this.state.store, [e.target.name]: e.target.value }
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { store } = this.state;

    this.props.proprietorStoreUpdate(store).then(result => {
      if (result) {
        this.setState({
          ...this.state,
          storesUpdateModalOpen: false
        });
      }
    });
  };

  render() {
    const { store } = this.state;
    return (
      <Modal
        show={this.state.storesUpdateModalOpen}
        onHide={this.props.closeModal}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Card>
              <Card.Body>
                <form
                  class="row g-3 needs-validation"
                  novalidate
                  onSubmit={this.handleSubmit}
                >
                  <div class="col-md-6">
                    <label for="validationCustom01" class="form-label">
                      Store Name
                    </label>
                    <input
                      type="text"
                      name="storeName"
                      class="form-control"
                      id="validationCustom01"
                      value={store?.storeName}
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="validationCustom01" class="form-label">
                      Mobile
                    </label>
                    <input
                      type="text"
                      name="contactNumber"
                      class="form-control"
                      id="validationCustom01"
                      value={store?.contactNumber}
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="validationCustom01" class="form-label">
                      Store Unique Name
                    </label>
                    <input
                      type="text"
                      name="storeUserName"
                      class="form-control"
                      id="validationCustom01"
                      value={store?.storeUserName}
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="validationCustom01" class="form-label">
                      Token Header
                    </label>
                    <input
                      type="text"
                      name="tokenHeader"
                      class="form-control"
                      id="validationCustom01"
                      value={store?.tokenHeader}
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="validationCustom01" class="form-label">
                      Token Footer
                    </label>
                    <input
                      type="text"
                      name="tokenFooter"
                      class="form-control"
                      id="validationCustom01"
                      value={store?.tokenFooter}
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <label for="validationCustom01" class="form-label">
                      Note
                    </label>
                    <input
                      type="text"
                      name="remarks"
                      class="form-control"
                      id="validationCustom01"
                      value={store?.remarks}
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="col-12 mb-2">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="hasEcommerce"
                        checked={store?.HNSettings?.hasEcommerce}
                        id="invalidCheck"
                        onChange={this.handleChange}
                      />
                      <label class="form-check-label" for="invalidCheck">
                        HasEcommerce
                      </label>
                    </div>
                  </div>

                  <Button
                    type="button"
                    variant="danger"
                    onClick={this.props.closeModal}
                  >
                    Cancle
                  </Button>
                  <Button type="submit" variant="success" className="ml-2 ">
                    {"Update "}
                  </Button>
                </form>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    server: state.server
  };
};
export default connect(mapStateToProps, { proprietorStoreUpdate })(
  StoreUpdateModal
);

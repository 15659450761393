import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import SideBar from "../../components/admin/sidebar";
import { Header } from "../../components/admin";
import { badge } from "../../utils/badge";

import StoreUpdateModal from "./StoreUpdateModal";
import { proprietorStoreUpdate } from "../../../src/actions/storeActions";
const { SearchBar } = Search;

class Stores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proprietors: props.proprietors || [],
      stores: props.stores,
      storeId: "",
      storePaymentStatus: "",
      storeHaseCommerce: "",
      storeFindData: [],
      storesUpdateModalOpen: false,
      modalTitle: ""
    };
  }

  onChangeHandler = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      proprietors: nextProps.proprietor,
      stores: nextProps.stores
    });
  }

  paymentStatusHandler = e => {
    this.setState({ ...this.state, storePaymentStatus: e.target.value });
  };

  hasEcommerceHandler = e => {
    this.setState({ ...this.state, storeHaseCommerce: e.target.checked });
  };

  // Action Store update handler
  storesUpdateModal = id => {
    const { stores } = this.state;
    const getStoreData = stores.filter(store => store._id === id);

    this.setState({
      storesUpdateModalOpen: true,
      modalTitle: "Edit Store",
      storeFindData: getStoreData,
      storeId: id
    });
  };

  storeUpdateHandler = values => {
    const { hasEcommerce, ...rest } = values;
    this.props.proprietorStoreUpdate({
      _id: this.state.storeId,
      HNSettings: {
        hasEcommerce
      },
      ...rest
    });
  };
  closeModal = () => {
    this.setState({
      ...this.state,
      storesUpdateModalOpen: false
    });
  };
  render() {
    let storesData = [...this.state.stores];

    //stores paymentStatus filter
    if (this.state.storePaymentStatus) {
      storesData = storesData.filter(
        store => store.paymentStatus === this.state.storePaymentStatus
      );
    }

    //stores hasEcommerce filter
    if (this.state.storeHaseCommerce) {
      storesData = storesData.filter(
        store => store.HNSettings.hasEcommerce === this.state.storeHaseCommerce
      );
    }
    // is loading
    const isLoaded = true;

    let columns = [
      {
        dataField: "_id",
        text: "ID"
      },
      {
        dataField: "storeName",
        text: "Store Name",
        sort: true
      },
      {
        dataField: "contactNumber",
        text: "Mobile"
      },
      {
        dataField: "status",
        text: "Status",
        formatter: data => {
          return <div> {badge(data)}</div>;
        }
      },
      {
        dataField: "email",
        text: "Email"
      },
      {
        dataField: "address",
        text: "Address"
      },
      {
        dataField: "_id",
        text: "অপশন",

        formatter: (cell, _row) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown d-inline">
                <a href="/" data-toggle="dropdown" className="px-2">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    type="button"
                    className="dropdown-item has-icon"
                    data-toggle="modal"
                    data-target="#storeDetails"
                    onClick={() => {
                      this.storesUpdateModal(cell);
                    }}
                  >
                    <i className="fas fa-edit" />
                    স্টোর আপডেট করুন
                  </a>
                  {/* <div className="dropdown-divider" /> */}
                </div>
                <div className="dropdown-menu dropdown-menu-right"></div>
              </div>
            </div>
          );
        }
      }
    ];
    return (
      <>
        <Header />
        <SideBar />
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>স্টোর</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 p-0">
                  <div className="card p-3">
                    <div className="card-body px-0">
                      {!isLoaded ? (
                        <div
                          style={{ minHeight: 230 }}
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <div className="fa-3x">
                            <i
                              style={{ fontSize: 40 }}
                              className="fas fa-sync fa-spin"
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className=" clear-fix px-3">
                            <div className="d-flex justify-content-start ">
                              <div>
                                <select
                                  className="form-control"
                                  aria-label="Default select example"
                                  onChange={this.paymentStatusHandler}
                                  value={this.state.storePaymentStatus}
                                >
                                  <option value="">All</option>
                                  <option value="paid">Paid</option>
                                  <option value="unpaid">Unpaid</option>
                                </select>
                              </div>

                              <div class="form-check mt-2 ml-4">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="hasEcommerce"
                                  onChange={this.hasEcommerceHandler}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="hasEcommerce"
                                >
                                  has eCcommerce
                                </label>
                              </div>
                            </div>
                          </div>
                          <div style={{ minHeight: 230 }} className="p-3">
                            <PaginationProvider
                              pagination={paginationFactory({
                                sizePerPageList: [
                                  50,
                                  100,
                                  500,
                                  1000,
                                  2000,
                                  5000
                                ]
                              })}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  bootstrap4
                                  keyField="_id"
                                  data={storesData}
                                  columns={columns}
                                  search
                                >
                                  {props => (
                                    <div>
                                      <div className="px-1">
                                        <SearchBar {...props.searchProps} />
                                      </div>
                                      <hr />
                                      <BootstrapTable
                                        {...props.baseProps}
                                        {...paginationTableProps}
                                        {...paginationProps}
                                        wrapperClasses="table-responsive"
                                      />
                                    </div>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <StoresUpdate storeFindData={this.state.storeFindData[0]} /> */}
          <StoreUpdateModal
            storeFindData={this.state.storeFindData[0]}
            storesUpdateModalOpen={this.state.storesUpdateModalOpen}
            closeModal={this.closeModal}
            modalTitle={this.state.modalTitle}
            storeUpdateHandler={this.storeUpdateHandler}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    proprietor: state.proprietor.proprietors,
    stores: state.proprietor.stores,
    server: state.server
  };
};

export default connect(mapStateToProps, {
  proprietorStoreUpdate
})(Stores);

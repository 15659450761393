import axios from "axios";
import $ from "jquery";
import toastr from "toastr";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

import {
  ADD_SUPPLIER,
  GET_SUPPLIERS,
  DELETE_SUPPLIER,
  UPDATE_SUPPLIER,
  GET_ERRORS,
  SUPPLIER_PAYMENT,
  UPDATE_PURCHASE_INVOICE,
  UPDATE_LENDEN,
  DECREASE_CASH,
  GET_PURCHASE_INVOICES,
  ADD_SUPPLIER_PAYMENTS,
  GET_SUPPLIER_PAYMENT_LEDGER,
  GET_SUPPLIER_LEDGER,
  EDIT_SUPPLIER_DUE,
  ADD_SUPPLIER_QUICK_PAYMENTS
} from "./types";

export const getSuppliers = storeId => dispatch => {
  serverLoading(dispatch, true);
  axios
    .get(`${SERVER_URL}/api/supplier/get/${storeId}`)
    .then(res => {
      serverLoading(dispatch, false);
      dispatch({
        type: GET_SUPPLIERS,
        payload: res.data.data
      });
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

export const addSupplier = supplierData => dispatch => {
  serverLoading(dispatch, true);
  axios
    .post(`${SERVER_URL}/api/supplier/add`, supplierData)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#exampleModal").modal("hide");
        dispatch({
          type: ADD_SUPPLIER,
          payload: data.data
        });
        dispatch({
          type: GET_SUPPLIER_LEDGER,
          payload: data.ledger
        });
        toastr.success("সাপ্লায়ার সেভ সম্পন্ন হয়েছে");
      } else {
        // if any error occured
        if (data.msg) {
          $("#exampleModal").modal("hide");
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

export const updateSupplier = supplier => dispatch => {
  serverLoading(dispatch, true);
  axios
    .put(`${SERVER_URL}/api/supplier/edit/${supplier._id}`, supplier)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#exampleModal").modal("hide");
        dispatch({
          type: UPDATE_SUPPLIER,
          payload: data.data
        });
        toastr.success("সাপ্লায়ার আপডেট সম্পন্ন হয়েছে।");
      } else {
        // if any error occured
        if (data.msg) {
          $("#exampleModal").modal("hide");
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};
export const updateSupplierDue = supplier => dispatch => {
  serverLoading(dispatch, true);
  axios
    .patch(`${SERVER_URL}/api/supplier/editDue/${supplier._id}`, supplier)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#supplierDueEditModal").modal("hide");
        dispatch({
          type: EDIT_SUPPLIER_DUE,
          payload: data.data
        });
        toastr.success("সাপ্লায়ার বকেয়া আপডেট সম্পন্ন হয়েছে।");
      } else {
        // if any error occured
        if (data.msg) {
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      $("#editCustomerDueModal").modal("hide");
      toastr.error(error?.response?.data?.message);
    });
};

export const deleteSupplier = supplierId => dispatch => {
  serverLoading(dispatch, true);
  axios
    .delete(`${SERVER_URL}/api/supplier/delete/${supplierId}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      dispatch({
        type: DELETE_SUPPLIER,
        payload: supplierId
      });
      toastr.success("সাপ্লায়ার ডিলিট সম্পন্ন হয়েছে।");
    })
    .catch(error => {
      serverLoading(dispatch, false);

      $("#deleteModal").modal("hide");
      toastr.error(error?.response?.data?.message);
    });
};

export const supplierPayment = postBody => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/supplier/payment`,
      postBody
    );

    if (data.status == true) {
      dispatch({
        type: SUPPLIER_PAYMENT,
        payload: data.data.supplier
      });

      // Update purchase invoice
      dispatch({
        type: UPDATE_PURCHASE_INVOICE,
        payload: data.data.invoice
      });

      // update lenden
      dispatch({
        type: UPDATE_LENDEN,
        payload: data.data.lenden
      });

      // decrease cache
      dispatch({
        type: DECREASE_CASH,
        payload: data.data.lenden.amount
      });
      //update payments
      dispatch({
        type: ADD_SUPPLIER_PAYMENTS,
        payload: data.data.payment
      });

      toastr.success(data.msg);
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const makePaymentSupplier = (postBody, clearState) => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/payments/supplier?paymentOrder=${postBody.paymentOrder}`,
      postBody
    );

    dispatch({
      type: GET_PURCHASE_INVOICES,
      payload: data.data.payments
    });
    dispatch({
      type: GET_SUPPLIER_PAYMENT_LEDGER,
      payload: data.data.ledger
    });
    dispatch({
      type: UPDATE_SUPPLIER,
      payload: data.data.supplier
    });
    dispatch({
      type: ADD_SUPPLIER_QUICK_PAYMENTS,
      payload: data.data.newPayment
    });
    toastr.success(data.msg);
    $("#supplierQuickPayment").modal("hide");
    if (data.status) {
      clearState();
    }
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

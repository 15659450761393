import React, { Component } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import PdfToPrint from "../OutputComponents/PdfToPrint";
import { Link } from "react-router-dom";
import { formatDate, FormatNumber } from "../../utils/utils";
import { t } from "../../HNLanguage/i18nextSetUp";
import ReactDatePicker from "react-datepicker";
import dateFilter from "../../utils/dateRangeFilter";
import DeleteModal from "../Settings/DeleteModal";
import { deletePurchaseInvoice } from "../../actions/purchaseInvoiceActions";
import CustomComponent from "../../components/common/TableCustomComponent";

const { SearchBar } = Search;

class Purchase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      products: [],
      suppliers: [],
      startDate: localStorage.getItem("startDate")
        ? new Date(localStorage.getItem("startDate"))
        : "",
      endDate: localStorage.getItem("endDate")
        ? new Date(localStorage.getItem("endDate"))
        : "",
      deleteId: "",
      supplierFilter: ""
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      invoices: this.props.purchaseInvoices,
      products: this.props.products,
      suppliers: this.props.suppliers
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      invoices: nextProps.purchaseInvoices,
      products: nextProps.products,
      suppliers: nextProps.suppliers
    });
  }

  showPurchaseReportDeleteModal = id => {
    this.setState({
      ...this.state,
      modalTitle: "Invoice Delete",
      deleteId: id
    });
  };

  deleteInvoiceHandler = () => {
    const singleInvoice = this.state.invoices.find(
      invoice => invoice._id == this.state.deleteId
    );
    let deleteData = {
      proprietorID: singleInvoice.proprietorID,
      storeID: singleInvoice.storeID,
      memo: singleInvoice.memo
    };

    this.props.deletePurchaseInvoice(deleteData);
  };

  render() {
    let { store } = this.props;
    let { invoices, startDate, endDate, suppliers } = this.state;

    // monthly filter date here
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // table column

    let columns = [
      {
        dataField: "memo",
        text: t("memo")
      },
      {
        dataField: "supplierID",
        text: t("supplier"),
        formatExtraData: suppliers,
        formatter: (supplierID, _row, _index, suppliers) => {
          const supplier = suppliers.find(
            supplier => supplier._id == supplierID
          );
          return supplier ? supplier.name : "";
        }
      },
      {
        dataField: "totalPrice",
        text: t("price"),
        formatter: cell => FormatNumber(cell)
      },
      {
        dataField: "totalDiscount",
        text: t("discount"),
        formatter: cell => FormatNumber(cell)
      },

      {
        dataField: "grandTotal",
        text: t("totalPrice"),
        formatter: cell => FormatNumber(cell)
      },
      {
        dataField: "_id",
        text: t("paid"),
        formatter: (cell, row) => {
          return row.grandTotal - row.totalDue;
        }
      },
      {
        dataField: "totalDue",
        text: t("due"),
        formatter: cell => FormatNumber(cell)
      },

      {
        dataField: "note",
        text: t("note")
      },
      {
        dataField: "products",
        text: t("option"),
        formatter: (cell, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown d-inline">
                <a href="#" data-toggle="dropdown" className="px-2">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link
                    to={`/supplier/${row.supplierID}/invoices/${row._id}`}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-print"></i>
                    {t("printInvoice")}
                  </Link>
                  <Link
                    to={`/supplier/${row.supplierID}/chalanInvoices/${row._id}`}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-print"></i>
                    {t("Chalan Invoice")}
                  </Link>

                  <div className="dropdown-divider" />
                  <a
                    href="#"
                    onClick={() => this.showPurchaseReportDeleteModal(row._id)}
                    className="dropdown-item has-icon text-danger"
                    data-toggle="modal"
                    data-target="#deleteModal"
                  >
                    <i className="fas fa-trash" /> {t("delete")}
                  </a>
                </div>
              </div>
            </div>
          );
        }
      }
    ];

    let totalPurchase = 0;
    let totalPaid = 0;
    let totalDue = 0;
    let supplierList = [];
    if (invoices.length > 0) {
      if (startDate && endDate) {
        invoices = dateFilter(
          invoices,
          startDate,
          endDate,
          "purchasedAt",
          true
        );
      }
      suppliers &&
        suppliers.forEach(supp => {
          if (invoices?.find(inv => inv?.supplierID === supp?._id))
            supplierList.push(supp);
        });
      if (this.state.supplierFilter)
        invoices = invoices?.filter(
          item => item?.supplierID === this.state.supplierFilter
        );
      invoices.sort(
        (a, b) =>
          new Date(b.purchasedAt).getTime() - new Date(a.purchasedAt).getTime()
      );

      for (let i = 0; i < invoices.length; i++) {
        totalPurchase = totalPurchase + invoices[i].grandTotal;
      }
    }
    totalDue = invoices.reduce((acc, curr) => {
      return (acc += curr.totalDue);
    }, 0);

    const customData = [
      {
        title: t("totalPurchase"),
        value: FormatNumber(totalPurchase)
      },
      {
        title: t("totalPaid"),
        value: FormatNumber(totalPurchase - totalDue)
      },
      {
        title: t("totalDue"),
        value: FormatNumber(totalDue)
      }
    ];

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>{t("purchaseReport")}</h4>

              <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2"
                    selected={startDate}
                    onChange={date => {
                      this.setState({ ...this.state, startDate: date });
                      localStorage.setItem("startDate", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                  />
                </div>
                <div>-</div>
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2 "
                    selected={endDate}
                    onChange={date => {
                      this.setState({ ...this.state, endDate: date });
                      localStorage.setItem("endDate", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    maxDate={new Date()}
                  />
                </div>
                <button
                  className="btn  btn-info mt-2 "
                  style={{ marginLeft: "-.4rem" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date()
                    });
                    localStorage.setItem("startDate", new Date());
                    localStorage.setItem("endDate", new Date());
                  }}
                >
                  {t("daily")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: firstDay,
                      endDate: new Date()
                    });
                    localStorage.setItem("startDate", firstDay);
                    localStorage.setItem("endDate", new Date());
                  }}
                >
                  {t("monthly")}
                </button>
                <button
                  className="btn btn-warning ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: "",
                      endDate: "",
                      supplierFilter: ""
                    });
                    localStorage.setItem("startDate", "");
                    localStorage.setItem("endDate", "");
                  }}
                >
                  {t("reset")}
                </button>
              </div>
            </div>
            <div className="px-2 bg-white">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="clear-fix py-3">
                    {/* <div className="float-left">
                      <p>
                        <strong>
                          {t("totalPurchase")}: {FormatNumber(totalPurchase)}
                          {t("taka")}
                        </strong>
                      </p>
                    </div> */}

                    <div
                      className="btn-group btn-group-sm float-right mb-2"
                      role="group"
                      aria-label="Basic example"
                    >
                      <div>
                        <ReactToPrint
                          trigger={() => (
                            <button
                              title={t("exportPDF")}
                              type="button"
                              className="btn btn-info"
                            >
                              <i className="fas fa-file-pdf"></i>
                            </button>
                          )}
                          content={() => this.componentRef}
                        />
                      </div>
                      <div style={{ display: "none" }}>
                        <PdfToPrint ref={el => (this.componentRef = el)}>
                          <div style={{ minHeight: 842 }} className="bg-white">
                            <div className="p-3">
                              <p className="text-center">
                                <h3>{store.storeName} </h3>{" "}
                                <h5>({t("purchaseReport")})</h5>
                              </p>
                              <div className="clearfix py-3">
                                <table>
                                  <tr>
                                    <th>{t("totalPrice")}</th>
                                    <td>:</td>
                                    <td>{FormatNumber(totalPurchase)}</td>
                                  </tr>
                                  <tr>
                                    <th>{t("totalPaid")}</th>
                                    <td>:</td>
                                    <td>
                                      {FormatNumber(totalPurchase - totalDue)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{t("totalDue")}</th>
                                    <td>:</td>
                                    <td>{totalDue}</td>
                                  </tr>
                                </table>
                                <span className="float-right">
                                  {new Date().toDateString()}{" "}
                                  {new Date().toLocaleTimeString()}
                                </span>
                              </div>
                            </div>
                            <table className="table table-bordered table-striped">
                              <tr>
                                <th scope="col">{t("memo")} </th>
                                <th scope="col">{t("price")} (Tk)</th>
                                <th scope="col">{t("discount")} (Tk)</th>
                                <th scope="col">{t("totalPrice")} (Tk)</th>
                                <th scope="col">{t("paid")} (Tk)</th>
                              </tr>

                              {invoices.length > 0 &&
                                invoices.map((invoice, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{invoice.memo}</td>
                                      <td>
                                        {FormatNumber(invoice?.totalPrice)}
                                      </td>
                                      <td>
                                        {FormatNumber(invoice?.totalDiscount)}
                                      </td>
                                      <td>
                                        {FormatNumber(invoice?.grandTotal)}
                                      </td>
                                      <td>
                                        {FormatNumber(
                                          invoice?.grandTotal - invoice.totalDue
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </table>
                          </div>
                        </PdfToPrint>
                      </div>
                      {invoices.length > 0 && (
                        <CSVLink
                          filename={"invoices.csv"}
                          data={
                            ([
                              t("memo"),
                              t("price"),
                              t("discount"),
                              t("totalPrice")
                            ],
                            invoices.map(invoice => [
                              invoice.memo,
                              FormatNumber(invoice?.totalPrice),
                              FormatNumber(invoice?.totalDiscount),
                              FormatNumber(invoice?.grandTotal)
                            ]))
                          }
                        >
                          <button
                            title={t("exportCSV")}
                            type="button"
                            className="btn btn-primary mx-2"
                          >
                            <i className="fas fa-file-csv"></i>
                          </button>
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div style={{ minHeight: 230 }} className="py-3">
                    {this.props.requestSend ? (
                      <div className="fa-3x text-center">
                        <i
                          style={{ fontSize: 40 }}
                          className="fas fa-sync fa-spin"
                        ></i>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
                        })}
                      >
                        {({ paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="_id"
                            data={invoices}
                            columns={columns}
                            search
                          >
                            {props => (
                              <div>
                                <div>
                                  <div className="d-flex justify-content-between mt-3">
                                    <div className="w-100">
                                      <CustomComponent data={customData} />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div style={{ width: "200px" }}>
                                        <select
                                          name="supplierFilter"
                                          id="supplierFilter"
                                          className="form-control"
                                          value={this.state.supplierFilter}
                                          onChange={e => {
                                            this.setState({
                                              ...this.state,
                                              supplierFilter: e.target.value
                                            });
                                          }}
                                        >
                                          <option value="">
                                            {t("selectSupplier")}
                                          </option>
                                          {supplierList &&
                                            supplierList.map(item => (
                                              <option
                                                key={item?._id}
                                                value={item?._id}
                                              >
                                                {item?.name}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                      <div
                                        style={{
                                          width: "200px",
                                          marginLeft: "1.5rem",
                                          marginTop: ".5rem"
                                        }}
                                      >
                                        <SearchBar {...props.searchProps} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <BootstrapTable
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                  wrapperClasses="table-responsive"
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <DeleteModal
          agreeHandler={this.deleteInvoiceHandler}
          deleteModalTitle={this.state.modalTitle}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    purchaseInvoices: state.purchaseInvoice.invoices,
    products: state.product.products,
    requestSend: state.server.requestSend,
    suppliers: state.supplier.suppliers
  };
};

export default connect(mapStateToProps, {
  deletePurchaseInvoice
})(Purchase);

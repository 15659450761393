import axios from "axios";
import { SERVER_URL } from "../config/config";
import { GET_PERMISSIONS } from "./types";

export const getPermissions = () => async dispatch => {
  try {
    const { data } = await axios.get(`${SERVER_URL}/api/users/permissions/`);
    dispatch({
      type: GET_PERMISSIONS,
      payload: data.permissions
    });
  } catch (error) {
    console.log(error);
  }
};

import categoryIcon from "./icons/category-icon.png";
import unitIcon from "./icons/unit.png";
import manufacturingIcon from "./icons/manufacturing.png";
import costSectionIcon from "./icons/expense_type.png";
import supplierIcon from "./icons/supplier.png";
import customerIcon from "./icons/customer.png";
import productIcon from "./icons/product.png";
import employeeIcon from "./icons/employee.png";
import sellIcon from "./icons/sell.png";
import expenseIcon from "./icons/expense.png";
import incomeExpenseIcon from "./icons/income.png";
import warranty from "./icons/warranty.png";
import bankIcon from "./icons/bankIcon.png";
import uiIcon from "./icons/ui.webp";
import ecommerceIcon from "./icons/ecommerce.png";
import messageIcon from "./icons/message.png";
import orderIcon from "./icons/order.png";
import { t } from "../../../HNLanguage/i18nextSetUp";

let commonMenuData = {
  menus: [
    {
      header: true,
      name: t("dashboard")
    },
    {
      name: t("dashboard"),
      icon: "fas fa-th-large",
      url: "/",
      role: ["proprietor", "manager", "salesman"]
    },
    {
      header: true,
      name: t("product")
    },
    {
      name: t("product"),
      icon: productIcon,
      url: "/product",
      dropdown: true,
      role: ["proprietor", "manager", "salesman"],
      children: [
        {
          name: t("product"),
          icon: productIcon,
          url: "/product",
          role: ["proprietor", "manager"]
        },
        {
          name: t("category"),
          icon: categoryIcon,
          url: "/category",
          role: ["proprietor", "manager"]
        },
        {
          name: t("unit"),
          icon: unitIcon,
          url: "/unit",
          role: ["proprietor", "manager"]
        },
        {
          name: t("manufacturer"),
          icon: manufacturingIcon,
          url: "/manufacturer",
          role: ["proprietor", "manager"]
        },
        {
          name: t("warranty"),
          icon: warranty,
          url: "/warranty",
          role: ["proprietor", "manager"]
        },
        {
          name: t("stock"),
          icon: productIcon,
          url: "/stock",
          role: ["proprietor", "manager"]
        }
      ]
    },

    {
      name: t("supplier"),
      icon: supplierIcon,
      url: "/supplier",
      role: ["proprietor", "manager"]
    },
    {
      name: t("customer"),
      icon: customerIcon,
      url: "/customer",
      role: ["proprietor", "manager", "salesman"]
    },
    {
      header: true,
      name: t("employee")
    },
    {
      name: t("manager"),
      icon: customerIcon,
      url: "/manager",
      role: ["proprietor"]
    },
    {
      name: t("salesMan"),
      icon: customerIcon,
      url: "/sales-man",
      role: ["proprietor"]
    },
    {
      name: t("employee"),
      icon: employeeIcon,
      url: "/employee",
      role: ["proprietor", "manager"]
    },
    {
      header: true,
      name: t("report")
    },
    {
      name: t("sell"),
      icon: sellIcon,
      url: "/sell",
      dropdown: true,
      role: ["proprietor", "manager", "salesman"],
      children: [
        {
          name: t("allSell"),
          icon: sellIcon,
          url: "/allSell",
          role: ["proprietor", "manager", "salesman"]
        },
        {
          name: t("sell"),
          icon: sellIcon,
          url: "/sell",
          role: ["proprietor", "manager", "salesman"]
        },
        {
          name: t("quickSell"),
          icon: sellIcon,
          url: "/quickSell",
          role: ["proprietor", "manager", "salesman"]
        },
        {
          name: t("soldProduct"),
          icon: sellIcon,
          url: "/sold-product",
          role: ["proprietor", "manager", "salesman"]
        }
      ]
    },
    // {
    //   name: t("purchaseProduct"),
    //   icon: sellIcon,
    //   url: "/purchase-product",
    //   role: ["proprietor", "manager", "salesman"]
    // },

    {
      name: t("purchaseReport"),
      icon: sellIcon,
      url: "/purchase-report",
      role: ["proprietor", "manager", "salesman"]
    },
    {
      name: t("payments"),
      icon: sellIcon,
      url: "/customer-payment",
      dropdown: true,
      role: ["proprietor", "manager", "salesman"],
      children: [
        {
          name: t("customer"),
          icon: sellIcon,
          url: "/customer-payment",
          role: ["proprietor", "manager", "salesman"]
        },
        {
          name: t("supplier"),
          icon: sellIcon,
          url: "/supplier-payment",
          role: ["proprietor", "manager", "salesman"]
        }
      ]
    },

    {
      header: true,
      name: t("account")
    },

    {
      name: t("account"),
      icon: sellIcon,
      url: "/profit",
      role: ["proprietor", "manager", "salesman"],
      dropdown: true,
      children: [
        {
          name: t("profit"),
          icon: sellIcon,
          url: "/profit",
          role: ["proprietor", "manager", "salesman"]
        },
        {
          name: t("incomeExpenditure"),
          icon: incomeExpenseIcon,
          url: "/income-expense",
          role: ["proprietor", "manager", "salesman"]
        },
        {
          name: t("cashbox"),
          icon: "fas fa-box",
          url: "/cash-box",
          role: ["proprietor", "manager"]
        },
        {
          name: t("bankTransaction"),
          icon: bankIcon,
          url: "/bank-transaction",
          role: ["proprietor"]
        }
      ]
    },

    {
      name: t("Installment"),
      icon: expenseIcon,
      url: "/installment",
      role: ["proprietor", "manager", "salesman"]
    },
    {
      header: true,
      name: t("expense")
    },
    {
      name: t("expenditure"),
      icon: expenseIcon,
      url: "/expense",
      role: ["proprietor", "manager", "salesman"],
      dropdown: true,
      children: [
        {
          name: t("costSection"),
          icon: costSectionIcon,
          url: "/costSection",
          role: ["proprietor", "manager"]
        },
        {
          name: t("expenditure"),
          icon: expenseIcon,
          url: "/expense",
          role: ["proprietor", "manager", "salesman"]
        }
      ]
    },

    {
      header: true,
      name: t("ecommerce"),
      hasEcommerce: true
    },
    {
      name: t("ecommerce"),
      icon: ecommerceIcon,
      dropdown: true,
      url: "/ecommerce",
      role: ["proprietor", "manager"],
      children: [
        {
          name: t("go to ecommerce"),
          url: "https://shop.hisabnikash.biz/",
          role: ["proprietor", "manager"],
          anchor: true,
          storeUse: true
        },
        {
          name: t("orders"),
          url: "/ecommerce/orders",
          role: ["proprietor", "manager"]
        },
        {
          name: t("Home Slider"),
          url: "/ecommerce/home-slider",
          role: ["proprietor", "manager"]
        },
        {
          name: t("Site Info"),
          url: "/ecommerce/site-info",
          role: ["proprietor", "manager"]
        }
      ]
    },
    {
      header: true,
      name: t("message")
    },
    {
      name: t("message"),
      icon: messageIcon,
      dropdown: true,
      url: "/message",
      role: ["proprietor", "manager"],
      children: [
        {
          name: t("bulkMessage"),
          url: "/message/bulk",
          role: ["proprietor", "manager"]
        },
        {
          name: t("SMSTemplate"),
          url: "/message/template",
          role: ["proprietor", "manager"]
        },
        {
          name: t("messageLog"),
          url: "/message/log",
          role: ["proprietor", "manager"]
        }
      ]
    },
    {
      header: true,
      name: t("settings")
    },

    // {
    //   name: "UI",
    //   icon: uiIcon,
    //   url: "/ui-settings",
    //   role: ["proprietor"]
    // },

    {
      name: t("settings"),
      icon: sellIcon,
      url: "/settings",
      role: ["proprietor", "manager", "salesman"],
      dropdown: true,
      children: [
        {
          name: "UI",
          icon: uiIcon,
          url: "/ui-settings",
          role: ["proprietor"]
        },
        {
          name: t("invoice"),
          icon: uiIcon,
          url: "/invoice-settings",
          role: ["proprietor"]
        }
      ]
    }
  ]
};

const AdminData = {
  menus: [
    {
      name: "প্রোপ্রিয়েটোর",
      icon: employeeIcon,
      url: "/admin/proprietor",
      role: ["admin"]
    },
    {
      name: "স্টোর",
      icon: employeeIcon,
      url: "/admin/stores",
      role: ["admin"]
    },
    {
      name: "Invoice",
      icon: employeeIcon,
      url: "/admin/invoices",
      role: ["admin"]
    }
  ]
};

export { commonMenuData, AdminData };

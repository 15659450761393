import { GET_MANAGERS, ADD_MANAGER, UPDATE_MANAGER, DELETE_MANAGER } from "../actions/types";

const initialState = { managers: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGERS:
      return {
        ...state,
        managers: action.payload
      };
    case ADD_MANAGER:
      return {
        ...state,
        managers: [...state.managers, action.payload]
      };
    case UPDATE_MANAGER:
      let index = state.managers.findIndex(
        manager => manager._id === action.payload._id
      );
      let updated = [
        ...state.managers.slice(0, index),
        action.payload,
        ...state.managers.slice(index + 1)
      ];
      return {
        ...state,
        managers: updated
      };
    case DELETE_MANAGER:
      return {
        ...state,
        managers: state.managers.filter(sup => sup._id != action.payload)
      };


    // case PAY_SALARY:
    //   let sIndex = state.managers.findIndex(
    //     manager => manager._id === action.payload._id
    //   );
    //   let sUpdated = [
    //     ...state.managers.slice(0, sIndex),
    //     action.payload,
    //     ...state.managers.slice(sIndex + 1)
    //   ];
    //   return {
    //     ...state,
    //     managers: sUpdated
    //   };

    default:
      return state;
  }
};

import React, { Component } from "react";
import Modal from "../../Settings/Modal";
import { t } from "../../../HNLanguage/i18nextSetUp";
import { connect } from "react-redux";
import toastr from "toastr";
import { addSalesMan, updateSalesMan } from "../../../actions/salesmanAction";
import moment from "moment";

class SalesManAddEditModal extends Component {
  addSalesManHandler = e => {
    e.preventDefault();
    const { salesMan, storeID } = this.props;
    const { reference, ...others } = salesMan;
    if (!storeID) {
      return toastr.error(t("selectStore"));
    } else if (!salesMan?.name) {
      return toastr.error(t("enterName"));
    } else if (!salesMan?.mobile) {
      return toastr.error(t("enterMobile"));
    }
    this.props.addSalesMan({
      reference: {
        name: reference?.refName,
        mobile: reference?.refMobile
      },
      ...others,
      storeID: storeID,
      proprietorID: this.props.store.store.proprietorID
    });
  };

  updateSalesManHandler = e => {
    e.preventDefault();
    const { salesMan } = this.props;
    const { reference, ...others } = salesMan;
    this.props.updateSalesMan({
      reference: {
        name: reference.refName,
        mobile: reference.refMobile
      },
      ...others
    });
  };

  render() {
    const {
      isAddSalesMan,
      modalTitle,
      onChangeHandler,
      storeID,
      salesMan,
      store
    } = this.props;
    return (
      <Modal
        onSubmit={
          isAddSalesMan ? this.addSalesManHandler : this.updateSalesManHandler
        }
        modalTitle={modalTitle}
      >
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label>{t("store")}</label>

            <select
              required
              name="storeID"
              onChange={onChangeHandler}
              className="form-control mw-100"
              value={storeID}
            >
              <option value="">{t("store")}...</option>
              {store.stores.map(item => (
                <option key={item._id} value={item._id}>
                  {item.storeName}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label>{t("name")}</label>
            <input
              type="text"
              className="form-control"
              name="name"
              onChange={onChangeHandler}
              value={salesMan?.name || ""}
              required
            />
          </div>

          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label>{t("mobile")}</label>

            <input
              type="text"
              name="mobile"
              className="form-control"
              onChange={onChangeHandler}
              value={salesMan?.mobile || ""}
              required
            />
          </div>

          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label>{t("address")}</label>

            <input
              type="text"
              className="form-control"
              name="address"
              onChange={onChangeHandler}
              value={salesMan?.address || ""}
            />
          </div>
          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label htmlFor="joiningDate">{t("joiningDate")}</label>

            <input
              type="date"
              id="joiningDate"
              className="form-control"
              name="joiningDate"
              onChange={onChangeHandler}
              value={moment(salesMan?.joiningDate).format("YYYY-MM-DD") || ""}
            />
          </div>

          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label>{t("monthlySalary")}</label>

            <input
              type="number"
              className="form-control"
              name="salary"
              min={0}
              onChange={onChangeHandler}
              value={salesMan?.salary || ""}
            />
          </div>

          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label>{t("due")}</label>

            <input
              type="number"
              className="form-control"
              name="due"
              min={0}
              onChange={onChangeHandler}
              value={salesMan?.due || ""}
            />
          </div>

          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label>{t("refName")}</label>

            <input
              type="text"
              className="form-control"
              name="refName"
              onChange={onChangeHandler}
              value={salesMan?.reference?.refName || ""}
            />
          </div>
          <div style={{ width: "20rem", marginBottom: "1rem" }}>
            <label>{t("refMobile")}</label>

            <input
              type="text"
              className="form-control"
              name="refMobile"
              onChange={onChangeHandler}
              value={salesMan?.reference?.refMobile || ""}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store,
    requestSend: state.server.requestSend
  };
};

export default connect(mapStateToProps, {
  addSalesMan,
  updateSalesMan
})(SalesManAddEditModal);

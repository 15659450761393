import React, { Component } from "react";
import toastr from "toastr";
import { connect } from "react-redux";
import { addStore } from "../../actions/storeActions";
import storeTypeData from "./storeTypeData";
import { t } from "../../HNLanguage/i18nextSetUp";

class AddStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storeName: "",
      tokenHeader: "",
      tokenFooter: "",
      contactNumber: "",
      remarks: "",
      storeUnitType: "default",
      storeType: ""
    };

    this.storeNameRef = React.createRef();
    this.contactNumberRef = React.createRef();
    this.tokenHeaderRef = React.createRef();
    this.tokenFooterRef = React.createRef();
    this.remarksRef = React.createRef();
    this.storeUnitTypeRef = React.createRef();
    this.storeType = React.createRef();

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.addStoreHandler = this.addStoreHandler.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  onChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  addStoreHandler = () => {
    let {
      storeName,
      storeUnitType,
      contactNumber,
      tokenHeader,
      tokenFooter,
      storeType,
      remarks
    } = this.state;

    if (!storeName) {
      toastr.error("স্টোরের নাম পূরন করুন");
      this.storeNameRef.current.focus();
    } else if (!contactNumber) {
      toastr.error("কন্টাক্ট নাম্বার পূরন করুন করুন");
      this.storeUnitTypeRef.current.focus();
    } else if (!storeUnitType) {
      toastr.error("স্টোর ইউনিট টাইপ সিলেক্ট করুন");
      this.storeUnitTypeRef.current.focus();
    } else if (!tokenHeader) {
      toastr.error("টোকেন হেডার পূরন করুন");
      this.tokenHeaderRef.current.focus();
    } else if (!tokenFooter) {
      toastr.error("টোকেন ফুটার পূরন করুন");
      this.tokenFooterRef.current.focus();
    } else if (!remarks) {
      toastr.error("নোট পূরন করুন");
      this.remarksRef.current.focus();
    } else if (!storeType) {
      toastr.error("স্টোর টাইপ সিলেক্ট করুন");
      this.storeType.current.focus();
    } else {
      this.props.addStore(this.state);
    }
  };
  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>স্টোর যোগ করুন</h4>
            </div>
            <div className="container row justify-content-center">
              <div className="col-md-6 p-4 bg-white rounded">
                <div className="form-group">
                  <lable>স্টোরের নাম</lable>
                  <input
                    ref={this.storeNameRef}
                    onChange={this.onChangeHandler}
                    type="text"
                    className="form-control"
                    name="storeName"
                    value={this.state.storeName}
                  ></input>
                </div>
                <div className="form-group">
                  <lable>কন্টাক্ট নাম্বার</lable>
                  <input
                    ref={this.contactNumberRef}
                    onChange={this.onChangeHandler}
                    type="text"
                    className="form-control"
                    name="contactNumber"
                    value={this.state.contactNumber}
                  ></input>
                </div>
                {/* <div className="form-group">
                  <lable>স্টোর ইউনিট টাইপ</lable>
                  <select
                    ref={this.storeUnitTypeRef}
                    name="storeUnitType"
                    className="form-control"
                    onChange={this.onChangeHandler}
                    defaultValue={""}
                  >
                    <option value="">স্টোর ইউনিট টাইপ</option>
                    <option value="default">ডেফল্ট</option>
                    <option value="multi">মাল্টি</option>
                  </select>
                </div> */}
                <div className="form-group">
                  <lable>{t("storeType")}</lable>
                  <select
                    ref={this.storeUnitTypeRef}
                    name="storeUnitType"
                    className="form-control"
                    onChange={this.onChangeHandler}
                    defaultValue={""}
                  >
                    <option value="">{t("storeType")}</option>
                    {storeTypeData.map(item => (
                      <option value={item.value}>{item.text}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <lable>টোকেন হেডার</lable>
                  <input
                    ref={this.tokenHeaderRef}
                    type="text"
                    className="form-control"
                    name="tokenHeader"
                    onChange={this.onChangeHandler}
                    value={this.state.tokenHeader}
                  ></input>
                </div>

                <div className="form-group">
                  <lable>টোকেন ফুটার</lable>
                  <input
                    ref={this.tokenFooterRef}
                    type="text"
                    className="form-control"
                    name="tokenFooter"
                    onChange={this.onChangeHandler}
                    value={this.state.tokenFooter}
                  ></input>
                </div>
                <div className="form-group">
                  <lable>নোট</lable>
                  <input
                    ref={this.remarksRef}
                    type="text"
                    className="form-control"
                    name="remarks"
                    onChange={this.onChangeHandler}
                    value={this.state.remarks}
                  ></input>
                </div>
                <div className="form-group">
                  <button
                    onClick={this.addStoreHandler}
                    className="btn btn-success btn-block"
                  >
                    সাবমিট করুন
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default connect(null, { addStore })(AddStore);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import { login } from "../../../actions/authActions";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      mobile: "",
      password: "",
      errors: {},
      showPass: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // this.props.history.push("/payment") // stop showing payment warning
      this.props.history.push("/");

      $(".needs-validation").submit(function (event) {
        let form = $(this);
        if (form[0].checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.addClass("was-validated");
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      // this.props.history.push("/payment") // stop showing payment warning
      this.props.history.push("/");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      mobile: this.state.mobile,
      password: this.state.password
    };

    this.props.login(userData);
  }

  render() {
    const { errors, showPass } = this.state;
    const {
      server: { requestSend }
    } = this.props;

    return (
      <div id="app">
        <section className="section">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="login-brand">
                  <img
                    src="../assets/img/hn.png"
                    alt="logo"
                    width="100"
                    className="shadow-light rounded-circle"
                  />
                </div>

                <div className="card card-danger">
                  <div className="card-header">
                    <h3>হিসাব নিকাশ</h3>
                  </div>

                  <div className="card-body">
                    <form
                      // method="POST"
                      // action="#"
                      noValidate
                      className="needs-validation"
                      onSubmit={this.onSubmit}
                    >
                      <div className="form-group">
                        <label htmlFor="mobile">মোবাইল</label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.mobile)
                          })}
                          id="mobile"
                          type="mobile"
                          name="mobile"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.mobile}
                          onChange={this.onChange}
                        />
                        {errors.mobile && (
                          <div className="invalid-feedback text-right">
                            {errors.mobile}
                          </div>
                        )}
                        {/* <div className="invalid-feedback">
                          Please fill in your mobile
                        </div> */}
                      </div>

                      <div className="form-group">
                        <div className="d-block">
                          <label htmlFor="password" className="control-label">
                            পাসওয়ার্ড
                          </label>
                          {/* <div className="float-right">
                            <Link
                              to={"/auth/forget-password"}
                              className="text-small"
                            >
                              Forgot Password?
                            </Link>
                          </div> */}
                        </div>
                        <div class="input-group mb-2 mr-sm-2 rounded">
                          <input
                            className={classnames("form-control mr-0 pr-0 border-right-0", {
                              "is-invalid": !isEmpty(errors.password)
                            })}
                            id="password"
                            type={showPass ? "text" : "password"}
                            name="password"
                            tabIndex="2"
                            required
                            value={this.state.password}
                            onChange={this.onChange}
                          />
                          <span
                            className="input-group-text ml-0 pl-0 border-left-0 rounded-0"
                            style={{ backgroundColor: "#E8F0FE", cursor: "pointer" }}
                            onClick={() => this.setState({
                              ...this.state,
                              showPass: !this.state.showPass
                            })}
                          >
                            {showPass ? (
                              <i className="fas fa-eye-slash"></i>
                            ) : (
                              <i className="fas fa-eye"></i>
                            )}
                          </span>
                        </div>


                        {errors.password && (
                          <div className="invalid-feedback text-right">
                            {errors.password}
                          </div>
                        )}
                        {/* <div className="invalid-feedback">
                          please fill in your password
                        </div> */}
                      </div>

                      <div className="form-group">
                        <button
                          disabled={requestSend}
                          type="submit"
                          className="btn btn-danger btn-lg btn-block"
                          tabIndex="4"
                        >
                          {requestSend ? "Loading..." : "লগিন"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="mt-5 text-muted text-center">
                  Don't have an account?{" "}
                  <Link to={"/auth/register"}>Create One</Link>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  server: state.server
});

export default connect(mapStateToProps, { login })(Login);

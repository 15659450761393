import {
  GET_STOCKS,
  GET_STOCKS_HISTORY,
  STOCK_BULK_UPDATE,
  STOCK_HISTORY_BULK_UPDATE
} from "../actions/types";

const initialState = {
  stock: [],
  stockHistory: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STOCKS:
      return {
        ...state,
        stock: action.payload
      };
    case GET_STOCKS_HISTORY:
      return {
        ...state,
        stockHistory: action.payload
      };

    case STOCK_BULK_UPDATE:
      const updatedStock = [...state.stock];
      action.payload?.forEach(item => {
        const stockIndex = updatedStock.findIndex(
          stock => stock._id === item._id
        );
        if (stockIndex !== -1) {
          updatedStock[stockIndex] = item;
        }
      });
      return {
        ...state,
        stock: updatedStock
      };
    case STOCK_HISTORY_BULK_UPDATE:
      return {
        ...state,
        stockHistory: [...state.stockHistory, action.payload]
      };
    default:
      return state;
  }
};

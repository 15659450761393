//Exteral Lib Import
import { Form, Formik } from "formik";
import * as yup from "yup";

const AsForm = ({ defaultValues, validationSchema, children, onSubmit }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={defaultValues}
      validationSchema={yup.object().shape(validationSchema)}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      {props => <Form>{children}</Form>}
    </Formik>
  );
};

export default AsForm;

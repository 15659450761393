//Internal Lib Import
import {
  GET_BULK_SMS,
  CREATE_BULK_SMS,
  GET_MESSAGE_LOG
} from "../actions/types";

const initialState = { isLoaded: true, messages: [], messageLogs: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BULK_SMS:
      return {
        isLoaded: false,
        messages: action.payload
      };
    case CREATE_BULK_SMS:
      return {
        ...state,
        messages: [...state.messages]
      };
    case GET_MESSAGE_LOG:
      return {
        ...state,
        isLoaded: false,
        messageLogs: [...state.messageLogs, ...action.payload]
      };
    default:
      return state;
  }
};

import {
  GET_PROPRIETORS,
  UPDATE_PROPRIETOR,
  ADD_PROPRIETOR,
  GET_ADMIN_STORES,
  PROPRIETOR_STORE_UPDATE
} from "../actions/types";

const initialState = { proprietors: [], stores: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROPRIETORS:
      return {
        ...state,
        proprietors: action.payload
      };
    case ADD_PROPRIETOR:
      return {
        ...state,
        proprietors: [...state.proprietors, action.payload]
      };
    case GET_ADMIN_STORES:
      return {
        ...state,
        stores: action.payload
      };
    case UPDATE_PROPRIETOR:
      let index = state.proprietors.findIndex(
        proprietor => proprietor._id === action.payload._id
      );
      let updated = [
        ...state.proprietors.slice(0, index),
        action.payload,
        ...state.proprietors.slice(index + 1)
      ];
      return {
        ...state,
        proprietors: updated
      };
    case PROPRIETOR_STORE_UPDATE:
      const updatedStores = state.stores.map(store => {
        if (store._id === action.payload._id) {
          store = action.payload;
        }
        return store;
      });
      return {
        ...state,
        stores: updatedStores
      };

    default:
      return state;
  }
};

import React, { Component } from "react";
import AsForm from "../../ui/AsForm";
import { Row, Col, Container, Button, Card, Modal } from "react-bootstrap";
import proprietorStoreFormData from "./ProprietorStoreFormData";
import FormInput from "../../ui/FormInput";
import { t } from "../../HNLanguage/i18nextSetUp";
import * as yup from "yup";
import { connect } from "react-redux";
import proprietorStoreUpdateData from "./ProprietorStoreUpdateData";

class ProprietorStoreUpdateModal extends Component {
  state = {
    updateStore: {}
  };
  handleStoreChange = e => {
    const updateStore = this.props.proprietorStore.find(
      item => item._id === e.target.value
    );
    this.setState({
      ...this.state,
      updateStore
    });
  };

  render() {
    const options = this.props.proprietorStore.map(item => {
      return { value: item._id, label: item.storeName };
    });
    let proprietorStoreData = proprietorStoreUpdateData.map(item => {
      if (item.name === "storeId") {
        item.options = options;
      }
      return item;
    });

    const validation = {
      proprietorID: yup.string().required(t("Proprietor is required")),
      storeName: yup.string().required(t("Store name is required")),
      storeType: yup.string().required(t("Store Type is required")),
      contactNumber: yup
        .string()
        .matches(
          "^(?:\\+88|88)?(01[3-9]\\d{8})$",
          t("Please enter valid number")
        )
        .required(t("Mobile number is required"))
    };

    const defaultValues = {
      _id: this.state.updateStore?._id || "",
      storeName: this.state.updateStore?.storeName || "",
      tokenHeader: this.state.updateStore?.tokenHeader || "",
      tokenFooter: this.state.updateStore?.tokenFooter || "",
      contactNumber: this.state.updateStore?.contactNumber || "",
      remarks: this.state.updateStore?.remarks || "",
      hasEcommerce: this.state.updateStore?.hasEcommerce || false,
      logo: ""
    };

    // defaultValues._id = this.state.updateStore?._id;
    // defaultValues.storeName = this.state.updateStore?.storeName;
    // defaultValues.tokenHeader = this.state.updateStore?.tokenHeader;
    // defaultValues.tokenFooter = this.state.updateStore?.tokenFooter;
    // defaultValues.contactNumber = this.state.updateStore?.contactNumber;
    // defaultValues.remarks = this.state.updateStore?.remarks;
    // defaultValues.hasEcommerce = this.state.updateStore?.hasEcommerce;

    return (
      <Modal
        show={this.props.showProprietorStoreEditModal}
        onHide={this.props.closeProprietorStoreModal}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Card>
              <Card.Body>
                <AsForm
                  defaultValues={defaultValues}
                  validationSchema={validation}
                  onSubmit={this.props.updateProprietorStore}
                >
                  <Row>
                    {proprietorStoreData?.map(
                      (formItem, index) =>
                        !formItem.isUpdateHide && (
                          <Col md={formItem.col} className="mb-3" key={index}>
                            <FormInput
                              type={formItem.type}
                              name={formItem.name}
                              placeholder={formItem.placeholder}
                              label={formItem.label}
                              isAlter={formItem.isAlter}
                              size={formItem.size}
                              options={formItem.options}
                              optionLabel={formItem.optionLabel}
                              defaultValue={{
                                label: formItem.role,
                                value: formItem.role
                              }}
                              handleChange={this.handleStoreChange}
                            />
                          </Col>
                        )
                    )}
                  </Row>
                  <Button
                    type="button"
                    variant="danger"
                    onClick={this.props.closeProprietorStoreModal}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="success" className="ml-2">
                    {"Update "}
                  </Button>
                </AsForm>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    server: state.server
  };
};
export default connect(mapStateToProps)(ProprietorStoreUpdateModal);

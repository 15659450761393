import axios from "axios";
import toastr from "toastr";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";
import {
  CREATE_BULK_SMS,
  GET_MESSAGE_LOG,
  GET_STORE,
  PURCHASE_SMS,
  UPDATE_SMS_SETTING
} from "./types";

export const updateSmsSetting = data => async dispatch => {
  serverLoading(dispatch, true);
  try {
    serverLoading(dispatch, true);
    const res = await axios.put(
      `${SERVER_URL}/api/sms/setting/${data.storeID}`,
      data
    );

    dispatch({
      type: GET_STORE,
      payload: res.data.data
    });

    toastr.success("আপডেট সম্পন্ন হয়েছে।");
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const purchaseSms = data => async dispatch => {
  serverLoading(dispatch, true);
  try {
    serverLoading(dispatch, true);
    const res = await axios.post(`${SERVER_URL}/api/sms/purchase`, data);
    dispatch({
      type: PURCHASE_SMS,
      payload: res.data.data
    });
    toastr.success("আড সম্পন্ন হয়েছে।");
    if (res) window.location = "/shunno-it-invoice";
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const getBulkSms = data => async dispatch => {
  serverLoading(dispatch, true);
  try {
    serverLoading(dispatch, true);
    const res = await axios.post(`${SERVER_URL}/api/sms/createBulkSms`, data);
    dispatch({
      type: CREATE_BULK_SMS,
      payload: res.data.data
    });
    toastr.success("এসএমএস দেওয়া সম্পন্ন হয়েছে।");

    return res;
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const createBulkSms = data => async dispatch => {
  serverLoading(dispatch, true);
  try {
    serverLoading(dispatch, true);
    const res = await axios.post(`${SERVER_URL}/api/sms/createBulkSms`, data);
    dispatch({
      type: CREATE_BULK_SMS,
      payload: res.data.data
    });

    if (res?.data?.status) {

      // window.location = "/message/log";
      toastr.success("এসএমএস দেওয়া সম্পন্ন হয়েছে।");
      setTimeout(() =>
        window.location.reload(), 1000
      )
    }
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
    setTimeout(() =>
      window.location = "/message/log", 1000
    )

  }
  serverLoading(dispatch, false);
};

export const getMessageLog = data => async dispatch => {
  serverLoading(dispatch, true);
  try {
    serverLoading(dispatch, true);
    const res = await axios.get(
      `${SERVER_URL}/api/sms/messageLog/${data.storeID}/${data.proprietorID}/500`
    );
    dispatch({
      type: GET_MESSAGE_LOG,
      payload: res.data.data
    });
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

import {
  GET_STORE,
  GET_STORES,
  LOADING,
  ADD_EXPENDITURE_PURPOSE,
  UPDATE_EXPENDITURE_PURPOSE,
  DELETE_EXPENDITURE_PURPOSE,
  ADD_UNIT,
  UPDATE_UNIT,
  DELETE_UNIT,
  ADD_MANUFACTURER,
  UPDATE_MANUFACTURER,
  DELETE_MANUFACTURER,
  ADD_WARRANTY,
  UPDATE_WARRANTY,
  DELETE_WARRANTY,
  INVOICE_LOGO_ADD
} from "../actions/types";

const initialState = {
  store: {},
  stores: [],
  isLoaded: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_STORES:
      return {
        ...state,
        stores: action.payload
      };

    case GET_STORE:
      localStorage.setItem("activeStore", JSON.stringify(action.payload));
      return {
        ...state,
        store: action.payload,
        isLoaded: true
      };

    case ADD_EXPENDITURE_PURPOSE:
      return {
        ...state,
        store: action.payload
      };
    case UPDATE_EXPENDITURE_PURPOSE:
      return {
        ...state,
        store: action.payload
      };

    case DELETE_EXPENDITURE_PURPOSE:
      return {
        ...state,
        store: action.payload
      };

    case ADD_UNIT:
      return {
        ...state,
        store: action.payload
      };

    case UPDATE_UNIT:
      return {
        ...state,
        store: action.payload
      };

    case DELETE_UNIT:
      return {
        ...state,
        store: action.payload
      };

    case ADD_MANUFACTURER:
      return {
        ...state,
        store: action.payload
      };

    case UPDATE_MANUFACTURER:
      return {
        ...state,
        store: action.payload
      };

    case DELETE_MANUFACTURER:
      return {
        ...state,
        store: action.payload
      };

    case ADD_WARRANTY:
      return {
        ...state,
        store: action.payload
      };
    case UPDATE_WARRANTY:
      return {
        ...state,
        store: action.payload
      };
    case DELETE_WARRANTY:
      return {
        ...state,
        store: action.payload
      };
    case INVOICE_LOGO_ADD:
      return {
        ...state,
        store: action.payload
      };

    default:
      return state;
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import PdfToPrint from "../OutputComponents/PdfToPrint";
import { Link } from "react-router-dom";
import { formatDate, FormatNumber } from "../../utils/utils";
import { t } from "../../HNLanguage/i18nextSetUp";
import ReactDatePicker from "react-datepicker";
import dateFilter from "../../utils/dateRangeFilter";

const { SearchBar } = Search;

class PurchaseProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      products: [],
      suppliers: [],
      startDate: new Date(),
      endDate: new Date()
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      invoices: this.props.purchaseInvoices,
      products: this.props.products,
      suppliers: this.props.suppliers
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      invoices: nextProps.purchaseInvoices,
      products: nextProps.products,
      suppliers: nextProps.suppliers
    });
  }

  render() {
    let { store } = this.props;
    let { invoices, startDate, endDate, suppliers, products } = this.state;

    const purchaseProduct = invoices?.reduce((acc, curr) => {
      const totalProduct = [...acc, ...curr.products];

      return totalProduct;
    }, []);

    // monthly filter date here
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // table column
    let columns = [
      {
        dataField: "memo",
        text: t("memo")
      },
      {
        dataField: "supplierID",
        text: t("supplierName"),
        formatExtraData: suppliers,
        formatter: (supplierID, _row, _index, suppliers) => {
          const supplier = suppliers.find(
            supplier => supplier._id == supplierID
          );
          return supplier ? supplier.name : "";
        }
      },
      {
        dataField: "supplierID",
        text: t("model"),
        formatExtraData: products,
        formatter: (row, cell) => {
          return cell.products.map(item => item.productID?.model);
        }
      },
      {
        dataField: "supplierID",
        text: t("productName"),
        formatExtraData: products,
        formatter: (row, cell) => {
          return cell.products.map(item => item.productID?.name);
        }
      },
      {
        dataField: "purchasedAt",
        text: t("date"),
        formatter: cell => formatDate(cell)
      },
      {
        dataField: "products",
        text: t("option"),
        formatter: (_, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown d-inline">
                <a href="#" data-toggle="dropdown" className="px-2">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link
                    to={`/supplier/${row.supplierID}/invoices/${row._id}`}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-print"></i>
                    {t("printInvoice")}
                  </Link>
                  <Link
                    to={`/supplier/${row.supplierID}/chalanInvoices/${row._id}`}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-print"></i>
                    {t("Chalan Invoice")}
                  </Link>
                </div>
              </div>
            </div>
          );
        }
      }
    ];

    let totalPurchase = 0;

    if (invoices.length > 0) {
      if (startDate && endDate) {
        invoices = dateFilter(
          invoices,
          startDate,
          endDate,
          "purchasedAt",
          true
        );
      }

      invoices.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      for (let i = 0; i < invoices.length; i++) {
        totalPurchase = totalPurchase + invoices[i].grandTotal;
      }
    }

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>{t("purchaseProduct")}</h4>

              <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2"
                    selected={startDate}
                    onChange={date =>
                      this.setState({ ...this.state, startDate: date })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                  />
                </div>
                <div>-</div>
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2 "
                    selected={endDate}
                    onChange={date =>
                      this.setState({ ...this.state, endDate: date })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    maxDate={new Date()}
                  />
                </div>
                <button
                  className="btn  btn-info mt-2 "
                  style={{ marginLeft: "-.4rem" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date()
                    });
                  }}
                >
                  {t("daily")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: firstDay,
                      endDate: new Date()
                    });
                  }}
                >
                  {t("monthly")}
                </button>
              </div>
            </div>
            <div className="px-2 bg-white">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="clear-fix py-3">
                    <div className="float-left">
                      <p>
                        <strong>
                          {t("totalPurchase")}: {FormatNumber(totalPurchase)}
                          {t("taka")}
                        </strong>
                      </p>
                    </div>
                    <div
                      className="btn-group btn-group-sm float-right mb-2"
                      role="group"
                      aria-label="Basic example"
                    >
                      <ReactToPrint
                        trigger={() => (
                          <button
                            title={t("exportPDF")}
                            type="button"
                            className="btn btn-info"
                          >
                            <i className="fas fa-file-pdf"></i>
                          </button>
                        )}
                        content={() => this.componentRef}
                      />
                      <div style={{ display: "none" }}>
                        <PdfToPrint ref={el => (this.componentRef = el)}>
                          <div style={{ minHeight: 842 }} className="bg-white">
                            <div className="p-3">
                              <h3 className="text-center">{store.storeName}</h3>
                              <div className="clearfix py-3">
                                <h5 className="float-left">{t("supplier")}</h5>
                                <span className="float-right">
                                  {new Date().toDateString()}{" "}
                                  {new Date().toLocaleTimeString()}
                                </span>
                              </div>
                            </div>
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">{t("memo")}</th>
                                  <th scope="col">{t("price")}</th>
                                  <th scope="col">{t("discount")}</th>
                                  <th scope="col">{t("totalPrice")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoices.length > 0 &&
                                  invoices.map((invoice, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{invoice.memo}</td>
                                        <td>
                                          {FormatNumber(invoice?.totalPrice)}
                                        </td>
                                        <td>
                                          {FormatNumber(invoice?.totalDiscount)}
                                        </td>
                                        <td>
                                          {FormatNumber(invoice?.grandTotal)}
                                          {t("tk")}.
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </PdfToPrint>
                      </div>
                      {invoices.length > 0 && (
                        <CSVLink
                          filename={"invoices.csv"}
                          data={
                            ([
                              t("memo"),
                              t("price"),
                              t("discount"),
                              t("totalPrice")
                            ],
                              invoices.map(invoice => [
                                invoice.memo,
                                FormatNumber(invoice?.totalPrice),
                                FormatNumber(invoice?.totalDiscount),
                                FormatNumber(invoice?.grandTotal)
                              ]))
                          }
                        >
                          <button
                            title={t("exportCSV")}
                            type="button"
                            className="btn btn-primary mx-2"
                          >
                            <i className="fas fa-file-csv"></i>
                          </button>
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div style={{ minHeight: 230 }} className="py-3">
                    {this.props.requestSend ? (
                      <div className="fa-3x text-center">
                        <i
                          style={{ fontSize: 40 }}
                          className="fas fa-sync fa-spin"
                        ></i>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
                        })}
                      >
                        {({ paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="_id"
                            data={invoices}
                            columns={columns}
                            search
                          >
                            {props => (
                              <div>
                                <SearchBar {...props.searchProps} />
                                <hr />
                                <BootstrapTable
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                  wrapperClasses="table-responsive"
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    purchaseInvoices: state.purchaseInvoice.invoices,
    products: state.product.products,
    requestSend: state.server.requestSend,
    suppliers: state.supplier.suppliers
  };
};

export default connect(mapStateToProps)(PurchaseProduct);

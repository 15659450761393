import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const Table = ({ children, data, columns, caption, customInput }) => {
  const CustomComponent = ({ data, search }) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className={`${!customInput && "flex-grow-1"} w-75 `}>
          <div className="d-flex flex-wrap">
            {data &&
              data.map((item, index) => (
                <div
                  key={index}
                  style={{ fontSize: "1.1rem", fontWeight: "700" }}
                >
                  {item.title}: {item.value} {index !== data.length - 1 && "|"}
                  &nbsp;
                </div>
              ))}
          </div>
        </div>
        <div className="flex-grow-2">{search}</div>
      </div>
    );
  };

  return (
    <PaginationProvider
      pagination={paginationFactory({
        sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
      })}
    >
      {({ paginationTableProps }) => (
        <ToolkitProvider
          bootstrap4
          keyField="_id"
          data={data}
          columns={columns}
          search
        >
          {props => (
            <div>
              {children}
              {customInput && customInput}

              <BootstrapTable
                {...props.baseProps}
                {...paginationTableProps}
                wrapperClasses="table-responsive"
                caption={
                  <CustomComponent
                    search={
                      !customInput && <SearchBar {...props.searchProps} />
                    }
                    data={caption}
                  />
                }
              />
            </div>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
};

export default Table;

import {
  CREATE_SITE_INFO,
  GET_SITE_INFO,
  UPDATE_SITE_INFO
} from "../actions/types";

const initialState = {
  siteInfo: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SITE_INFO:
      return {
        ...state,
        siteInfo: action.payload
      };
    case CREATE_SITE_INFO:
      return {
        ...state,
        siteInfo: action.payload
      };
    case UPDATE_SITE_INFO:
      const updatedData = state.siteInfo.map(value => {
        if (value._id === action.payload._id) {
          return {
            ...action.payload
          };
        }
        return value;
      });
      return {
        ...state,
        siteInfo: updatedData
      };

    default:
      return state;
  }
};

import axios from "axios";
import $ from "jquery";
import toastr from "toastr";
import {
  GET_EXPENDITURES,
  ADD_EXPENDITURE,
  UPDATE_EXPENDITURE,
  DELETE_EXPENDITURE,
  GET_ERRORS,
  DECREASE_CASH,
  ADD_LENDEN
} from "../actions/types";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

export const getExpenditures = storeID => dispatch => {
  axios
    .get(`${SERVER_URL}/api/expenditure/get/${storeID}`)
    .then(res => {
      dispatch({
        type: GET_EXPENDITURES,
        payload: res.data.data
      });
    })
    .catch(error => {
      toastr.error(error?.response?.data?.message);
    });
};
export const addExpenditure = data => dispatch => {
  serverLoading(dispatch, true);
  axios
    .post(`${SERVER_URL}/api/expenditure/add`, data)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#exampleModal").modal("hide");

        dispatch({
          type: ADD_EXPENDITURE,
          payload: data.data.expenditure
        });
        dispatch({
          type: ADD_LENDEN,
          payload: data.data.lenden
        });

        dispatch({
          type: DECREASE_CASH,
          payload: data.data.expenditure.amount
        });
        toastr.success(data.msg);
      } else {
        // if any error occured
        if (data.msg) {
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      toastr.error(error?.response?.data?.message);

      serverLoading(dispatch, false);
    });
};

export const updateExpenditure = ({
  expenditureID,
  storeID,
  proprietorID,
  staffID,
  staffName,
  purposeID,
  purposeName,
  amount,
  prevAmount,
  remarks
}) => dispatch => {
  serverLoading(dispatch, true);
  axios
    .put(`${SERVER_URL}/api/expenditure/edit/${expenditureID}`, {
      storeID,
      proprietorID,
      staffID,
      staffName,
      purposeID,
      purposeName,
      amount,
      remarks
    })
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#exampleModal").modal("hide");

        dispatch({
          type: UPDATE_EXPENDITURE,
          payload: data.data
        });

        dispatch({
          type: DECREASE_CASH,
          payload: amount - prevAmount
        });
        toastr.success("খরচ আপডেট সম্পন্ন হয়েছে।");
      } else {
        // if any error occured
        if (data.msg) {
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      toastr.error(error?.response?.data?.message);

      serverLoading(dispatch, false);
    });
};
export const deleteExpenditure = id => dispatch => {
  serverLoading(dispatch, true);
  axios
    .delete(`${SERVER_URL}/api/expenditure/delete/${id}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      dispatch({
        type: DELETE_EXPENDITURE,
        payload: data.data._id
      });

      toastr.success("খরচ ডিলিট সম্পন্ন হয়েছে।");
      return;
    })
    .catch(error => {
      toastr.error(error?.response?.data?.message);

      serverLoading(dispatch, false);
    });
};

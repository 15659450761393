import { t } from "../../HNLanguage/i18nextSetUp";

const proprietorStoreFormData = [
  {
    type: "select",
    name: "proprietorID",
    placeholder: t(" Please Select Proprietor"),
    label: t("proprietor"),
    optionLabel: "Proprietor",
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false,
    options: []
  },
  {
    type: "text",
    name: "storeName",
    placeholder: t("storeName"),
    label: t("storeName"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },

  {
    type: "text",
    name: "contactNumber",
    placeholder: t("mobile"),
    label: t("mobile"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "select",
    name: "storeType",
    placeholder: t("Select Store Type"),
    label: t("storeType"),
    optionLabel: "Store Type",
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false,
    options: []
  },
  {
    type: "text",
    name: "storeUserName",
    placeholder: t("storeUniqueName"),
    label: t("storeUniqueName"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "tokenHeader",
    placeholder: t("tokenHeader"),
    label: t("tokenHeader"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "tokenFooter",
    placeholder: t("tokenFooter"),
    label: t("tokenFooter"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },

  {
    type: "text",
    name: "remarks",
    placeholder: t("remarks"),
    label: t("remarks"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "checkbox",
    name: "hasEcommerce",
    placeholder: t(""),
    label: t("HasEcommerce"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  }
];

export default proprietorStoreFormData;

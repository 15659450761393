import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import PdfToPrint from "./../../src/pages/OutputComponents/PdfToPrint";
import { stop } from "../actions/redirectActions";
import { t } from "../../src/HNLanguage/i18nextSetUp";
import { FormatNumber } from "../utils/utils";
import { getKisiByInvoice } from "../actions/installmentAction";
import moment from "moment";
import stores from "../store";
// import image from "../Customer/hisab.png";
// import { Link } from "react-router-dom";

function EmiInvoice(props) {
  let history = useHistory();

  let printRef = useRef();
  let invId = props.match.params.invoiceId;

  const kistiNum = props.match.params.kistiId;

  const { store, invoices, customers, kisti } = props;

  let totalKistiPaid = 0;
  const unpaid = kisti?.filter(item => item.status === "unpaid");
  let products = props.products;
  let invoice;
  let customer;
  if (invoices) {
    let emiInvoice = invoices.filter(invoice => invoice.type == "installment");
    console.log(emiInvoice);

    invoice = [...emiInvoice].find(inv => inv._id === invId);
  }

  if (customers && invoice) {
    customer = customers.find(cus => cus._id === invoice.customerID);
  }

  if (props.redirect) {
    props.stop();
  }
  kisti.forEach(item => {
    if (item.kistiNum <= kistiNum && item.status === "paid") {
      totalKistiPaid += item.paidAmount;
    }
  });

  useEffect(() => {
    stores.dispatch(getKisiByInvoice(props.match.params.invoiceId));
  }, [stores]);
  let tableBorder = () => {
    return { border: " 1px solid lightGray" };
  };

  console.log(invoice);

  let a = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen "
  ];
  let b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety"
  ];
  let inWords = num => {
    num = Math.floor(num);
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore"
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand  "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred  "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "Taka only "
        : "";
    return str;
  };

  return (
    <>
      <div className="main-content">
        <section className="section">
          <div className="container pt-3 pb-3 mb-4 bg-white text-center border-rounded d-flex">
            <button
              className="btn btn-primary text-center"
              onClick={() => history.goBack()}
            >
              <i
                className="fas fa-arrow-alt-circle-left  mt-2 "
                style={{ fontSize: "1.13rem" }}
              ></i>
            </button>
            <h4 style={{ marginLeft: "23rem", marginTop: ".3rem" }}>
              {t("printInvoice")}
            </h4>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="card">
                  {invoice && products ? (
                    <>
                      <PdfToPrint ref={el => (printRef = el)}>
                        <div className="card-body p-3 pt-3">
                          <div className="my-4 row align-items-center">
                            {store.logo?.src && (
                              <div className="col-md-3 ml-1">
                                <div>
                                  <img
                                    src={store.logo?.src}
                                    height="100px"
                                    width="100PX"
                                    style={{ objectFit: "contain" }}
                                  />
                                </div>
                              </div>
                            )}
                            {store.logo ? (
                              <div className="col-md-5">
                                <div className="text-center">
                                  <h6>{store.storeName}</h6>
                                  <small>{store.address}</small>
                                </div>
                              </div>
                            ) : (
                              <div className="col-12">
                                <div className="text-center">
                                  <span className="h6">{store.storeName}</span>
                                  <br />
                                  <small>{store.address}</small>
                                  <br />
                                  <small>{store.contactNumber}</small>
                                  <br />
                                  <small>{store.tokenHeader}</small>
                                  <br />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="clearfix">
                            <table
                              style={{
                                width: "100%"
                              }}
                            >
                              <tr style={tableBorder()}>
                                <td
                                  className="p-1 ml-1"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  Memo
                                </td>
                                <td>{invoice.memo}</td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Name</td>
                                <td>
                                  {customer?.name ||
                                    invoice.quickSellCustomer?.name}
                                </td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Mobile</td>
                                <td>
                                  {customer?.mobile ||
                                    invoice.quickSellCustomer?.mobile}
                                </td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Due</td>
                                <td>
                                  {FormatNumber(customer?.due) || 0} {t("tk")}
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div className="table-responsive mt-4">
                            <div className="d-flex justify-content-between">
                              <h6>{t("productDetails")}</h6>
                              <div className=" ">
                                <strong>
                                  {t("date")} :
                                  {new Date(invoice.soldAt).getDate()} /
                                  {new Date(invoice.soldAt).getMonth() + 1}/
                                  {new Date(invoice.soldAt).getFullYear()}
                                </strong>
                              </div>
                            </div>
                            <table
                              style={{
                                width: "100%"
                              }}
                              className="text-center"
                            >
                              <tr className="p-2" style={tableBorder()}>
                                <th className="p-2">{t("name")}</th>

                                <th style={tableBorder()}>{t("model")}</th>
                                <th style={tableBorder()}>{t("quantity")}</th>
                                <th style={tableBorder()}>{t("unitPrice")}</th>
                                <th style={tableBorder()}>{t("discount")}</th>
                                <th style={tableBorder()}>{t("totalPrice")}</th>
                              </tr>

                              {invoice &&
                                invoice.soldItems.map((p, pI) => {
                                  const product = products.find(
                                    pr => pr._id === p.productID
                                  );

                                  return (
                                    <tr
                                      key={pI}
                                      className="p-2"
                                      style={tableBorder()}
                                    >
                                      <td className="p-2">
                                        <strong>{product?.name} </strong>
                                      </td>

                                      <td style={tableBorder()}>
                                        {product?.model}
                                      </td>
                                      <td style={tableBorder()}>{p.qty}</td>
                                      <td style={tableBorder()}>
                                        {FormatNumber(p.unitPrice)}
                                      </td>
                                      <td style={tableBorder()}>
                                        {p.discount
                                          ? FormatNumber(p.discount)
                                          : 0}
                                      </td>
                                      <td style={tableBorder()}>
                                        {FormatNumber(p.qty * p.unitPrice)}{" "}
                                        {t("tk")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={tableBorder()} className="p-2">
                                  <strong>{t("Total Price With EMI")}</strong>
                                </td>
                                <td style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(
                                      invoice.grandTotal -
                                        invoice.totalDue +
                                        invoice?.kisti.totalDueWithInterest
                                    )}{" "}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={tableBorder()} className="p-2">
                                  <strong>{t("discount")}</strong>
                                </td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(invoice.totalDiscount)}{" "}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>{t("DownPayment")}</strong>
                                </td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(invoice?.kisti?.downPayment)}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={tableBorder()} className="p-2">
                                  <strong>{t("Total Due With EMI")}</strong>
                                </td>
                                <td style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(
                                      invoice?.kisti.totalDueWithInterest
                                    )}{" "}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div className="mt-3">
                            <h6 className="mt-2">
                              {t("Installment Information")} :
                            </h6>
                            <table>
                              <tr>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>{t("Interest Rate")}</strong> :{" "}
                                  <strong>
                                    {FormatNumber(invoice?.kisti.interestRate)}{" "}
                                    %
                                  </strong>
                                </td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>{t("Number of EMI")}</strong> :{" "}
                                  <strong>
                                    {FormatNumber(invoice?.kisti.month)}
                                  </strong>
                                </td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>{t("Premium")}</strong> :{" "}
                                  <strong>
                                    {FormatNumber(
                                      invoice?.kisti.perMonthAmount
                                    )}{" "}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div>
                            <h6 className="mt-2">
                              {t("Payment Information")} :
                            </h6>
                            <table
                              style={{
                                width: "100%"
                              }}
                              className="text-center mt-2"
                            >
                              <thead>
                                <tr className="p-2" style={tableBorder()}>
                                  <th style={tableBorder()}>
                                    {t("installment")} {kistiNum} {t("amount")}
                                  </th>
                                  <th style={tableBorder()}>{t("paidDate")}</th>
                                  <th style={tableBorder()}>
                                    {t("totalPaid")}
                                  </th>

                                  <th style={tableBorder()}>
                                    {t("remainingDue")}
                                  </th>

                                  <th style={tableBorder()}>
                                    {t("nextInstallmentDate")}
                                  </th>
                                </tr>
                              </thead>

                              {kisti && (
                                <tbody>
                                  <tr>
                                    <td className="p-2" style={tableBorder()}>
                                      {FormatNumber(
                                        kisti[props.match.params.kistiId - 1]
                                          ?.paidAmount
                                      )}
                                    </td>
                                    <td className="p-2" style={tableBorder()}>
                                      {moment(
                                        kisti[kistiNum - 1]?.updatedAt
                                      ).format("DD-MM-YYYY")}
                                    </td>
                                    <td className="p-2" style={tableBorder()}>
                                      {FormatNumber(totalKistiPaid)}
                                    </td>
                                    <td className="p-2" style={tableBorder()}>
                                      {FormatNumber(
                                        kisti[0].totalDueWithInterest -
                                          totalKistiPaid
                                      )}
                                    </td>

                                    <td className="p-2" style={tableBorder()}>
                                      {unpaid.length
                                        ? moment(
                                            kisti[kistiNum]?.kistiDate
                                          ).format("DD-MM-YYYY")
                                        : "N/A"}
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>

                          <div className="mt-5 pt-3">
                            <div className="clearfix text-center">
                              <div
                                className="float-left"
                                style={{ width: 150 }}
                              >
                                <hr />
                                <span>{t("proprietorSign")}</span>
                              </div>
                              <div
                                className="float-right"
                                style={{ width: 150 }}
                              >
                                <hr />
                                <span>{t("customerSign")}</span>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="pt-3 text-center">
                            <p>{store.tokenFooter}</p>
                          </div>

                          <div className="pt-3">
                            <small className="mb-0">
                              <i>
                                Powered By:&nbsp; Shunno Software &nbsp;
                                <i
                                  style={{ fontSize: 10 }}
                                  className="fas fa-phone"
                                ></i>
                                01321141790
                              </i>
                            </small>
                          </div>
                        </div>
                      </PdfToPrint>
                      <div className="m-4 text-right">
                        <ReactToPrint
                          trigger={() => (
                            <button type="button" className="btn btn-primary">
                              {t("printInvoice")}
                            </button>
                          )}
                          content={() => printRef}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{ minHeight: 230 }}
                      className="text-center d-flex justify-content-center align-items-center"
                    >
                      <div className="fa-3x">
                        <i
                          style={{ fontSize: 40 }}
                          className="fas fa-sync fa-spin"
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    invoices: state.invoice.invoices,
    customers: state.customer.customers,
    products: state.product.products,
    redirect: state.redirect.redirect,
    kisti: state.kisti.kisti
  };
};

export default connect(mapStateToProps, { stop })(EmiInvoice);

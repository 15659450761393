import {
  GET_LENDENS_STORE,
  GET_LENDENS_PROPRIETOR,
  ADD_LENDEN,
  UPDATE_LENDEN,
  DELETE_LENDEN,
  ADD_EXPENDITURE
} from "../actions/types";

const initialState = {
  isloaded: false,
  storeLendens: [],
  proprietorLendens: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LENDENS_STORE:
      return {
        isloaded: true,
        storeLendens: action.payload
      };

    case ADD_LENDEN:
      return {
        ...state,
        storeLendens: [action.payload, ...state.storeLendens]
      };

    case ADD_EXPENDITURE:
      return {
        ...state,
        storeLendens: [action.payload, ...state.storeLendens]
      };
    case DELETE_LENDEN:
      return {
        ...state,
        storeLendens: state.storeLendens.filter(
          len => len._id !== action.payload
        )
      };

    default:
      return state;
  }
};

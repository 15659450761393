import { GET_PERMISSIONS } from "../actions/types";

export default (state = { permissions: {}, isLoaded: false }, action) => {
  switch (action.type) {
    case GET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
        isLoaded: true
      };
    default:
      return state;
  }
};

import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  PRODUCT_BULK_IMPORT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_STOCK,
  GET_PRODUCT_DETAILS,
  BULK_UPDATE_PRODUCT
} from "../actions/types";

const initialState = { isLoaded: false, products: [], productDetails: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        isLoaded: true,
        products: action.payload
      };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        isLoaded: true,
        productDetails: action.payload
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload]
      };
    case BULK_UPDATE_PRODUCT:
      const updatedProduct = [...state.products];
      action.payload?.forEach(item => {
        const productIndex = updatedProduct.findIndex(
          product => product._id === item._id
        );
        if (productIndex !== -1) {
          updatedProduct[productIndex] = item;
        }
      });
      return {
        ...state,
        products: updatedProduct
      };
    case PRODUCT_BULK_IMPORT:
      return {
        ...state,
        products: [...state.products, ...action.payload]
      };

    case UPDATE_PRODUCT:
      let index = state.products.findIndex(
        pro => pro._id === action.payload._id
      );
      let updated = [
        ...state.products.slice(0, index),
        action.payload,
        ...state.products.slice(index + 1)
      ];
      return {
        ...state,
        products: updated
      };
    case UPDATE_STOCK:
      const updatedProducts = state.products.reduce((acc, curr) => {
        const findProduct = action.payload.find(
          item => item.productID === curr._id
        );
        if (findProduct) {
          curr.stock = curr.stock - findProduct.qty;
        }
        acc.push(curr);
        return acc;
      }, []);
      return {
        ...state,
        products: updatedProducts
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(pro => pro._id != action.payload)
      };
    default:
      return state;
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { t } from "../../HNLanguage/i18nextSetUp";

class Modal extends Component {
  render() {
    const {
      server: { requestSend }
    } = this.props;

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" id="deleteModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.deleteModalTitle}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{t("areYouSure")} ?</p>
            </div>
            <div className="modal-footer bg-whitesmoke br">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                {t("no")}
              </button>
              <button
                disabled={requestSend}
                type="submit"
                onClick={this.props.agreeHandler}
                className="btn btn-danger"
              >
                {requestSend ? "Loading..." : t("yes")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    server: state.server
  };
};

export default connect(mapStateToProps)(Modal);

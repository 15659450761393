const dateFilter = (arr, startDate, endDate, field, isRangeFilter) => {
  let filteredArray;
  if (isRangeFilter) {
    startDate.setHours(0, 0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    filteredArray = arr.filter(item => {
      return (
        startDate.getTime() < new Date(item[field]).getTime() &&
        endDate.getTime() > new Date(item[field]).getTime()
      );
    });
  } else {
    filteredArray = arr.filter(item => {
      return startDate.getTime() === new Date(item[field]).setHours(0, 0, 0, 0);
    });
  }
  return filteredArray;
};

export default dateFilter;

import axios from "axios";
import toastr from "toastr";
import $ from "jquery";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { SERVER_URL } from "../config/config";

import {
  ADMIN_LOGIN,
  GET_ERRORS,
  ADD_PROPRIETOR,
  GET_ADMIN_STORES,
  UPDATE_PROPRIETOR
} from "./types";
import { serverLoading } from "../utils/utils";

export const adminLogin = adminData => dispatch => {
  serverLoading(dispatch, true);
  axios
    .post(`${SERVER_URL}/api/users/login`, adminData)
    .then(res => {
      serverLoading(dispatch, false);
      // Save to localstorage
      const { token, status, data } = res.data;

      // if any error occured
      if (!status) {
        dispatch({
          type: GET_ERRORS,
          payload: data
        });
        return;
      }

      // Set token to localstorage
      localStorage.setItem("adminToken", token);
      // Set token to Auth header
      setAuthToken(token);

      const decoded = jwt_decode(token);

      dispatch({
        type: ADMIN_LOGIN,
        payload: decoded
      });
    })
    .catch(error => {
      serverLoading(dispatch, false);
      dispatch({
        type: GET_ERRORS,
        payload:
          "Something went again. Please check your username and password. Then Try again."
      });
    });
};

export const addProprietor = propretorInfo => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/proprietor/add`,
      propretorInfo
    );
    if (data.status == true) {
      toastr.success("প্রোপাইটর যোগ সম্পন্ন হয়েছে।");
      dispatch({ type: ADD_PROPRIETOR, payload: data.data });

      return true;
    }
  } catch (error) {
    toastr.error(error.response.data.message);
    console.log(error);
    return false;
  } finally {
    serverLoading(dispatch, false);
  }
};
export const updateProprietor = propretorInfo => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.patch(
      `${SERVER_URL}/api/admin/proprietor`,
      propretorInfo
    );
    if (data.status == true) {
      toastr.success(data.msg);
      dispatch({ type: UPDATE_PROPRIETOR, payload: data.data });

      return true;
    }
  } catch (error) {
    toastr.error(error.response.data.message);
    console.log(error);
    return false;
  } finally {
    serverLoading(dispatch, false);
  }
};

export const getStoresBySuperAdmin = () => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.get(`${SERVER_URL}/api/store/admin`);
    dispatch({
      type: GET_ADMIN_STORES,
      payload: data.data
    });
  } catch (error) {
    serverLoading(dispatch, false);
    console.log(error);
  }
};

import {
  SEND_REQUEST,
  RESPONSE_SUCCESS,
  RESPONSE_FAILD,
  RESET
} from "../actions/types";

const initialState = {
  requestSend: false,
  responseSuccess: false,
  responseFaild: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_REQUEST:
      return {
        requestSend: true,
        responseSuccess: false,
        responseFaild: false
      };

    case RESPONSE_SUCCESS:
      return {
        requestSend: false,
        responseSuccess: true,
        responseFaild: false
      };
    case RESPONSE_FAILD:
      return {
        requestSend: false,
        responseSuccess: false,
        responseFaild: true
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

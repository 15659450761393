import React, { Component } from "react";
import {
  createSiteInfo,
  updateSiteInfo
} from "../../../actions/ECAction/siteInfoAction";
import { t } from "../../../HNLanguage/i18nextSetUp";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";

class SiteInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: true,
      siteInfo: {
        _id: "",
        about: "",
        address: "",
        deliveryNotice: "",
        purchaseGuide: "",
        specialOffer: "",
        terms: "",
        aboutCompany: "",
        ploicy: "",
        mobile: "",
        email: "",
        mapLink: "",
        appleStoreAppLink: "",
        playStoreAppLink: "",
        storeId: ""
      },
      formFiles: {},
      socialLink: {}
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSocialLinkChange = this.onSocialLinkChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }
  // siteInfoDetail = this.state?.siteInfoDetails?.siteInfo.find(value => value);

  componentDidMount() {
    if (this.props.siteInfoDetails[0] && this.props.siteInfoDetails[0]) {
      const {
        _id,
        mobile,
        email,
        about,
        address,
        deliveryNotice,
        purchaseGuide,
        specialOffer,
        terms,
        aboutCompany,
        ploicy,
        mapLink,
        appleStoreAppLink,
        playStoreAppLink,
        socialLink
      } = this.props.siteInfoDetails[0];
      const tempLinks = {};
      if (socialLink) {
        for (let links of socialLink) {
          tempLinks[links.label] = {
            ...links
          };
        }
      }

      this.setState({
        ...this.state,
        add: false,
        siteInfo: {
          _id,
          mobile,
          email,
          about,
          address,
          deliveryNotice,
          purchaseGuide,
          specialOffer,
          terms,
          aboutCompany,
          ploicy,
          mapLink,
          appleStoreAppLink,
          playStoreAppLink
        },
        socialLink: { ...tempLinks }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.siteInfoDetails[0] && nextProps.siteInfoDetails[0]) {
      const {
        _id,
        mobile,
        email,
        about,
        address,
        deliveryNotice,
        purchaseGuide,
        specialOffer,
        terms,
        aboutCompany,
        ploicy,
        mapLink,
        appleStoreAppLink,
        playStoreAppLink,
        socialLink
      } = nextProps.siteInfoDetails[0];

      const tempLinks = {};
      if (socialLink) {
        for (let links of socialLink) {
          tempLinks[links.label] = {
            ...links
          };
        }
      }

      this.setState({
        ...this.state,
        add: false,
        siteInfo: {
          _id,
          mobile,
          email,
          about,
          address,
          deliveryNotice,
          purchaseGuide,
          specialOffer,
          terms,
          aboutCompany,
          ploicy,
          mapLink,
          appleStoreAppLink,
          playStoreAppLink
        },
        socialLink: { ...tempLinks }
      });
    }
  }
  onChangeHandler = e => {
    const value = e.target.value;
    const name = e.target.name;

    const fileTypeFieldName = [
      "topBanner",
      "bottomBanner",
      "midBannerOne",
      "midBannerTwo"
    ];

    if (fileTypeFieldName.includes(name)) {
      const files = e.target.files[0];
      this.setState({
        ...this.state,
        formFiles: {
          ...this.state.formFiles,
          [name]: files
        }
      });
    } else {
      this.setState({
        ...this.state,
        siteInfo: {
          ...this.state.siteInfo,
          [name]: value
        }
      });
    }
  };
  onSocialLinkChange = ({ target: { name, value } }) => {
    const socialObj = { ...this.state.socialLink };
    const conditons = {
      facebook: "fb",
      twitter: "tw",
      linkedIn: "linkedin"
    };

    socialObj[name] = {
      label: name,
      icon: conditons[name],
      link: value
    };

    this.setState({
      ...this.state,
      socialLink: { ...this.state.socialLink, ...socialObj }
    });
  };
  onSubmitHandler = e => {
    e.preventDefault();
    const socialLink = [];

    for (let social in this.state.socialLink) {
      socialLink.push(this.state.socialLink[social]);
    }

    const obj = {
      socialLink: JSON.stringify(socialLink),
      ...this.state.siteInfo,
      ...this.state.formFiles,
      storeID: this.props.store._id,
      proprietorID: this.props.store.proprietorID,
      storeUserName: this.props.store.storeUserName,
      storeName: this.props.store.storeName
    };
    if (this.state.add) {
      const { add, _id, ...others } = obj;
      this.props.createSiteInfo(others);
    } else {
      const { add, ...others } = obj;
      this.props.updateSiteInfo(others);
    }
  };

  render() {
    const { siteInfo, socialLink } = this.state;

    const inputs = [
      {
        label: t("topBanner"),
        type: "file",
        name: "topBanner"
      },
      // {
      //   label: t("midBannerOne"),
      //   type: "file",
      //   name: "midBannerOne"
      // },
      // {
      //   label: t("midBannerTwo"),
      //   type: "file",
      //   name: "midBannerTwo"
      // },
      {
        label: t("bottomBanner"),
        type: "file",
        name: "bottomBanner"
      },
      {
        label: t("mobile"),
        type: "text",
        name: "mobile",
        value: siteInfo.mobile || ""
      },
      {
        label: t("email"),
        type: "text",
        name: "email",
        value: siteInfo.email || ""
      },
      {
        label: t("playStoreAppLink"),
        type: "text",
        name: "playStoreAppLink",
        value: siteInfo.playStoreAppLink || ""
      },
      {
        label: t("appleStoreAppLink"),
        type: "text",
        name: "appleStoreAppLink",
        value: siteInfo.appleStoreAppLink || ""
      },
      {
        label: t("mapLink"),
        type: "text",
        name: "mapLink",
        value: siteInfo.mapLink || ""
      }
    ];

    const socialLinksInputs = [
      {
        label: "facebook",
        name: socialLink["facebook"]?.label,
        link: socialLink["facebook"]?.link
      },
      {
        label: "twitter",
        name: socialLink["twitter"]?.label,
        link: socialLink["twitter"]?.link
      },
      {
        label: "linkedin",
        name: socialLink["linkedin"]?.label,
        link: socialLink["linkedin"]?.link
      }
    ];

    return (
      <div className="main-content">
        <section className="section">
          <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
            <h4>{t("siteInfo")}</h4>
          </div>
          <div className="container">
            <div className="card">
              <form>
                <div className="card-body">
                  <div className="row">
                    {inputs.map(input => {
                      return (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>{input.label}</label>
                            <div className="input-group">
                              <input
                                type={input.type}
                                className="form-control "
                                name={input.name}
                                value={input.value}
                                onChange={this.onChangeHandler}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {socialLinksInputs.map(inputLinks => {
                      return (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>{inputLinks?.label}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name={inputLinks?.label}
                                value={inputLinks.link || ""}
                                onChange={this.onSocialLinkChange}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>{t("address")}</label>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={siteInfo.address}
                            onChange={value => {
                              this.setState({
                                ...this.state,
                                siteInfo: {
                                  ...this.state.siteInfo,
                                  address: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>{t("deliveryNotice")}</label>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={siteInfo.deliveryNotice}
                            onChange={value => {
                              this.setState({
                                ...this.state,
                                siteInfo: {
                                  ...this.state.siteInfo,
                                  deliveryNotice: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>{t("purchaseGuide")} </label>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={siteInfo.purchaseGuide}
                            onChange={value => {
                              this.setState({
                                ...this.state,
                                siteInfo: {
                                  ...this.state.siteInfo,
                                  purchaseGuide: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>{t("specialOffer")} </label>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={siteInfo.specialOffer}
                            onChange={value => {
                              this.setState({
                                ...this.state,
                                siteInfo: {
                                  ...this.state.siteInfo,
                                  specialOffer: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>{t("terms")}</label>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={siteInfo.terms}
                            onChange={value => {
                              this.setState({
                                ...this.state,
                                siteInfo: {
                                  ...this.state.siteInfo,
                                  terms: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>{t("aboutCompany")}</label>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={siteInfo.aboutCompany}
                            onChange={value => {
                              this.setState({
                                ...this.state,
                                siteInfo: {
                                  ...this.state.siteInfo,
                                  aboutCompany: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>{t("policy")}</label>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={siteInfo.ploicy}
                            onChange={value => {
                              this.setState({
                                ...this.state,
                                siteInfo: {
                                  ...this.state.siteInfo,
                                  ploicy: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>About</label>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={siteInfo.about}
                            onChange={value => {
                              this.setState({
                                ...this.state,
                                siteInfo: {
                                  ...this.state.siteInfo,
                                  about: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "right", marginTop: "1rem" }}>
                    {this.state.add ? (
                      <button
                        className="btn btn-primary d-flex-justify-content-center align-items-center"
                        onClick={this.onSubmitHandler}
                        style={{ fontSize: "1rem" }}
                      >
                        {this.props.requestSend && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {this.props.requestSend ? "Loading..." : t("add")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={this.onSubmitHandler}
                      >
                        {this.props.requestSend && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {this.props.requestSend ? "Loading..." : t("update")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    siteInfoDetails: state.siteInfo.siteInfo,
    requestSend: state.server.requestSend
  };
};

export default connect(mapStateToProps, {
  createSiteInfo,
  updateSiteInfo
})(SiteInfo);

import React from "react";

const addClass = {
  paid: "success",
  unpaid: "warning text-dark",
  expired: "danger",
  active: "light text-dark",
  inactive: "secondary",
  new: "primary text-white",
  credit: "success text-white",
  debit: "warning text-dark",
  deposit: "primary text-white",
  withdraw: "info text-white",
  transfer: "danger text-white",
  sell: "success",
  purchase: "primary",
  update: "secondary",
  pending: "secondary text-dark"
};

export const badge = item => {
  return (
    <span className={`badge rounded-pill bg-${addClass[item]}`}>{item}</span>
  );
};

import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line no-undef
import { BrowserRouter } from "react-router-dom";

import App from "./App";

// // Template JS File
import "bootstrap/dist/css/bootstrap.min.css";

import * as jQuery from "jquery";
import "jqvmap/dist/jqvmap.min.css";

import "bootstrap-social/bootstrap-social.css";

// toastr css
import "toastr/build/toastr.min.css";
import "./assets/css/style.css";
import "@fortawesome/fontawesome-free/css/all.css";

// Template

import "@mdi/font/css/materialdesignicons.min.css";
import * as serviceWorker from "./serviceWorker";
import "flag-icon-css/css/flag-icon.css";
// import "bootstrap-timepicker/css/bootstrap-timepicker.min.css"
import "./assets/css/components.css";
import { Provider } from "react-redux";
import store from "./store";
import ApiLoader from "./components/common/ApiLoader";

window.jQuery = jQuery;
window.$ = jQuery;

require("sticky-kit/dist/sticky-kit.min.js");
require("popper.js/dist/popper.min");
require("jquery/dist/jquery.min");
require("jquery-ui-dist/jquery-ui.min.js");

var moment = require("moment/min/moment.min.js");

require("bootstrap/dist/js/bootstrap.min");
require("jquery.nicescroll/dist/jquery.nicescroll.js");

window.moment = moment;

require("pdfmake/build/pdfmake.min");
require("pdfmake/build/vfs_fonts");

//
// JS Libraies

require("jquery-sparkline/jquery.sparkline.min");
require("prismjs/prism.js");
require("./js/js/scripts");
require("./js/js/custom");
require("./js/js/stisla");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ApiLoader />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

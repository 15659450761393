import axios from "axios";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";
import {
  GET_PROPRIETORS,
  UPDATE_PROPRIETOR,
  DELETE_PROPROETOR,
  ADD_PROPRIETOR
} from "./types";

export const getProprietors = () => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.get(`${SERVER_URL}/api/proprietor/list/`);
    dispatch({
      type: GET_PROPRIETORS,
      payload: data.data
    });
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

import React, { Component } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as yup from "yup";
import { t } from "../../HNLanguage/i18nextSetUp";
import AsForm from "../../ui/AsForm";
import FormInput from "../../ui/FormInput";
import proprietorFormData from "./ProprietorFormData";

class ProprietorAddEditModal extends Component {
  render() {
    const validation = {
      name: yup.string().required(t("Name is required")),
      fatherName: yup.string().required(t("Father name is required")),
      email: yup.string().required(t("Email is required")),
      nid: yup
        .string()
        .max(
          15,
          t("Nid length must be less than or equal to 15 characters long")
        )
        .required(t("Nid number is required")),
      address: yup.string().required(t("Please Enter Proprietor address")),
      mobile: yup
        .string()
        .matches(
          "^(?:\\+88|88)?(01[3-9]\\d{8})$",
          t("Please enter valid number")
        )
        .required(t("Mobile number is required"))
    };

    const defaultValues = {
      mobile: "",
      name: "",
      email: "",
      fatherName: "",
      refMobile: "",
      nid: "",
      address: "",
      refName: "",
      refMobile: "",
      refNid: "",
      refAddress: "",
      refRelation: "",
      remarks: ""
    };

    if (this.props.showProprietorEditModal) {
      defaultValues.name = this.props.proprietor?.name;
      defaultValues.fatherName = this.props.proprietor?.fatherName;
      defaultValues.nid = this.props.proprietor?.nid;
      defaultValues.address = this.props.proprietor?.address;
      defaultValues.mobile = this.props.proprietor?.mobile;
      defaultValues.email = this.props.proprietor?.email;
      defaultValues.refName = this.props.proprietor?.reference?.name;
      defaultValues.refMobile = this.props.proprietor?.reference?.mobile;
      defaultValues.refNid = this.props.proprietor?.reference?.nid;
      defaultValues.refAddress = this.props.proprietor?.reference?.address;
      defaultValues.refRelation = this.props.proprietor?.reference?.relation;
      defaultValues.remarks = this.props.proprietor?.remarks;
    }

    return (
      <Modal
        show={
          this.props.showProprietorAddModal ||
          this.props.showProprietorEditModal
        }
        onHide={this.props.closeProprietorModalHandler}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Card>
              <Card.Body>
                <AsForm
                  defaultValues={defaultValues}
                  validationSchema={validation}
                  onSubmit={
                    this.props.showProprietorEditModal
                      ? this.props.updateProprietorHandler
                      : this.props.createProprietorHandler
                  }
                >
                  <Row>
                    {this.props.showProprietorEditModal
                      ? proprietorFormData?.map(
                          (formItem, index) =>
                            !formItem.isUpdateHide && (
                              <Col
                                md={formItem.col}
                                className="mb-3"
                                key={index}
                              >
                                <FormInput
                                  type={formItem.type}
                                  name={formItem.name}
                                  placeholder={formItem.placeholder}
                                  label={formItem.label}
                                  isAlter={formItem.isAlter}
                                  size={formItem.size}
                                  options={formItem.options}
                                  optionLabel={formItem.optionLabel}
                                  defaultValue={{
                                    label: formItem.role,
                                    value: formItem.role
                                  }}
                                />
                              </Col>
                            )
                        )
                      : proprietorFormData?.map((formItem, index) => (
                          <Col md={formItem.col} className="mb-3" key={index}>
                            <FormInput
                              type={formItem.type}
                              name={formItem.name}
                              placeholder={formItem.placeholder}
                              label={formItem.label}
                              isAlter={formItem.isAlter}
                              size={formItem.size}
                              options={formItem.options}
                              optionLabel={formItem.optionLabel}
                              defaultValue={formItem.options?.find(
                                i => i.value === defaultValues.role
                              )}
                            />
                          </Col>
                        ))}
                  </Row>
                  <Button
                    type="submit"
                    variant="danger"
                    onClick={this.props.closeProprietorModalHandler}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="success" className="ml-2">
                    {this.props.showProprietorEditModal ? "Update " : "Add"}
                  </Button>
                </AsForm>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    server: state.server
  };
};
export default connect(mapStateToProps)(ProprietorAddEditModal);

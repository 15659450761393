import axios from "axios";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import { GET_CUSTOMER_PAYMENTS, GET_SUPPLIER_PAYMENTS } from "./types";

export const getCustomerPayments = storeId => async dispatch => {
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/payments/customer/${storeId}`
    );
    dispatch({
      type: GET_CUSTOMER_PAYMENTS,
      payload: data.data
    });
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
};

export const getSupplierPayments = storeId => async dispatch => {
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/payments/supplier/${storeId}`
    );
    dispatch({
      type: GET_SUPPLIER_PAYMENTS,
      payload: data.data
    });
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
};

import { formatDate, FormatNumber } from "../../../utils/utils";
import { t } from "../../../HNLanguage/i18nextSetUp";

const Column = ({ store, showEditSalesManModal, showSalesManProfileModal, showDeleteSalesManModal, role }) => {
  return [
    {
      dataField: "name",
      text: t("name"),
      sort: true
    },
    {
      dataField: "storeID",
      text: t("store"),
      formatter: storeID => store.stores.find(s => s._id === storeID)?.storeName
    },
    {
      dataField: "address",
      text: t("address"),
      sort: true
    },
    {
      dataField: "mobile",
      text: t("mobile"),
      sort: true
    },
    // {
    //   dataField: "due",
    //   text: t("due"),
    //   sort: true,
    //   formatter: cell => <div>{FormatNumber(cell)}</div>
    // },
    {
      dataField: "createdAt",
      text: t("date"),
      formatter: cell => <div>{formatDate(cell)}</div>
    },
    {
      dataField: "_id",
      text: t("option"),
      formatter: data => (
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <a href="#" data-toggle="dropdown" className="nav-link nav-link-lg">
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                type="button"
                onClick={() => showSalesManProfileModal(data)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#showProfile"
              >
                <i className="fas fa-user" />
                {t("profile")}
              </a>
              <a
                type="button"
                onClick={() => showEditSalesManModal(data)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fas fa-edit" />
                {t("edit")}
              </a>
              <a
                type="button"
                onClick={() => showEditSalesManModal(data)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#permissionModal"
              >
                <i className="fas fa-edit" />
                {t("editPermission")}
              </a>

              {
                role && role === "PROPRIETOR" &&
                <a
                  href="#"
                  onClick={() => {
                    showDeleteSalesManModal(data);
                  }}
                  className="dropdown-item has-icon text-danger"
                  data-toggle="modal"
                  data-target="#deleteModal"
                >
                  <i className="fas fa-trash" /> {t("delete")}
                </a>
              }

              {/* <a href="#" className="dropdown-item has-icon">
                <i className="fas fa-file-invoice" />
                খরচ
              </a> */}
              {/* <Link
                to={`/employee/${cel}/paysalary`}
                className="dropdown-item has-icon"
              >
                <i className="fas fa-hand-holding-usd"></i>
                {t("paySalary")}
              </Link> */}
              {/* <a href="#" className="dropdown-item has-icon">
                <i className="fas fa-file-invoice-dollar"></i>
                পেমেন্ট তথ্য
              </a> */}
            </div>
          </li>
        </ul>
      )
    }
  ];
};

export default Column;

import axios from "axios";
import toastr from "toastr";
import $ from "jquery";

import { SERVER_URL } from "../../config/config";
import { serverLoading } from "../../utils/utils";
import {
  ADD_SLIDER_IMAGES,
  DELETE_SLIDER_IMAGES,
  GET_SLIDER_IMAGES,
  UPDATE_SLIDER_IMAGES
} from "../types";

export const getHomeSliders = ({ proprietorID, storeID }) => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/ecommerce/homeSlider/homeSliderList/${proprietorID}/${storeID}`
    );
    dispatch({
      type: GET_SLIDER_IMAGES,
      payload: data
    });
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const createHomeSliders = data => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const formData = new FormData();
    for (let i = 0; i < data.files.length; i++) {
      formData.append("sliderImage", data.files[i]);
    }
    formData.append("proprietorID", data.proprietorID);
    formData.append("storeID", data.storeID);
    formData.append("storeUserName", data.storeUserName);

    //axios config
    // const config = {
    //   onUploadProgress: progressEvent => {
    //     const percentage = (progressEvent.loaded * 100) / progressEvent.total;
    //     this.setState({ ...this.state, progress: +percentage.toFixed(2) });
    //   },
    //   headers: { "Content-Type": "multipart/form-data" }
    // };
    serverLoading(dispatch, true);
    const res = await axios({
      method: "post",
      url: `${SERVER_URL}/api/ecommerce/homeSlider/upload`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });
    dispatch({
      type: ADD_SLIDER_IMAGES,
      payload: res.data.data
    });
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const updateHomeSlider = data => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const res = await axios.patch(
      `${SERVER_URL}/api/ecommerce/homeSlider/homeSliderUpdate/${data._id}`,
      data
    );
    $("#exampleModal").modal("hide");
    dispatch({
      type: UPDATE_SLIDER_IMAGES,
      payload: res.data.data
    });
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const deleteHomeSlider = sliderID => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const res = await axios.delete(
      `${SERVER_URL}/api/ecommerce/homeSlider/homeSliderDelete/${sliderID}`
    );
    $("#deleteModal").modal("hide");
    dispatch({
      type: DELETE_SLIDER_IMAGES,
      payload: res.data.data
    });
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

import React, { Component } from "react";
import { connect } from "react-redux";
import toastr from "toastr";
import { t } from "../../HNLanguage/i18nextSetUp";
import { proprietorStoreUpdate } from "../../actions/storeActions";

class ProprietorUpdateStore extends Component {
  state = {
    storeName: "",
    tokenHeader: "",
    tokenFooter: "",
    contactNumber: "",
    remarks: "",
    storeUserName: "",
    storeId: "",
    address: "",
    hasEcommerce: false,
    logo: ""
  };

  storeNameRef = React.createRef();
  tokenHeaderRef = React.createRef();
  tokenFooterRef = React.createRef();
  contactNumberRef = React.createRef();
  remarksRef = React.createRef();
  storeUserNameRef = React.createRef();
  addressRef = React.createRef();

  storeFindHandler = e => {
    const id = e.target.value;
    const storeDetails = this.props.proprietorStore.find(
      store => store._id === id
    );
    this.setState({
      storeName: storeDetails?.storeName,
      contactNumber: storeDetails?.contactNumber,
      tokenHeader: storeDetails?.tokenHeader,
      tokenFooter: storeDetails?.tokenFooter,
      remarks: storeDetails?.remarks,
      storeUserName: storeDetails?.remarks,
      address: storeDetails?.address,
      storeId: storeDetails?._id,
      hasEcommerce: storeDetails?.HNSettings.hasEcommerce
    });
  };

  storeChangeHandler = e => {
    if (e.target.name === "logo") {
      this.setState({
        [e.target.name]: e.target.files[0]
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

  storeUpdateHandler = e => {
    e.preventDefault();

    let {
      storeName,
      tokenHeader,
      tokenFooter,
      contactNumber,
      storeUserName,
      remarks,
      address
    } = this.state;

    if (!storeName) {
      toastr.error("স্টোরের নাম পূরন করুন");
      this.storeNameRef.current.focus();
    } else if (!tokenHeader) {
      toastr.error("টোকেন হেডার পূরন করুন");
      this.tokenHeaderRef.current.focus();
    } else if (!tokenFooter) {
      toastr.error("টোকেন ফুটার পূরন করুন");
      this.tokenFooterRef.current.focus();
    } else if (!contactNumber) {
      toastr.error("কন্টাক্ট নাম্বার পূরন করুন করুন");
      this.contactNumberRef.current.focus();
    } else if (!storeUserName) {
      toastr.error("ইউনিক স্টোর নাম পূরন করুন");
      this.storeUserNameRef.current.focus();
    } else if (!remarks) {
      toastr.error("নোট পূরন করুন");
      this.remarksRef.current.focus();
    } else if (!address) {
      toastr.error("ঠিকানা পূরন করুন");
      this.addressRef.current.focus();
    } else {
      this.props.proprietorStoreUpdate(this.state);
    }
  };

  render() {
    const {
      server: { requestSend }
    } = this.props;

    return (
      <>
        <div
          className="modal fade"
          id="updateStoreModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.storeUpdateHandler}>
                <div className="modal-body">
                  <h4>স্টোর আপডেট করুন</h4>
                  <div className="px-4 d-flex flex-wrap">
                    <div className="form-group px-2 w-50 ">
                      <label>স্টোর নির্বাচন করুন</label>
                      <select
                        name="storeSelect"
                        className="form-control"
                        onChange={this.storeFindHandler}
                      >
                        <option>স্টোর নির্বাচন করুন</option>
                        {this.props.proprietorStore.map(store => (
                          <option value={store._id}>{store.storeName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group px-2 w-50 ">
                      <label>স্টোরের নাম</label>
                      <input
                        ref={this.storeNameRef}
                        onChange={this.storeChangeHandler}
                        type="text"
                        className="form-control"
                        name="storeName"
                        value={this.state.storeName}
                      />
                    </div>
                    <div className="form-group px-2 w-50 ">
                      <label>ইউনিক স্টোর নাম</label>
                      <input
                        ref={this.storeUserNameRef}
                        onChange={this.storeChangeHandler}
                        type="text"
                        className="form-control"
                        name="storeUserName"
                        value={this.state.storeUserName}
                      />
                    </div>
                    <div className="form-group px-2 w-50">
                      <label>কন্টাক্ট নাম্বার</label>
                      <input
                        ref={this.contactNumberRef}
                        onChange={this.storeChangeHandler}
                        type="text"
                        className="form-control"
                        name="contactNumber"
                        value={this.state.contactNumber}
                      />
                    </div>
                    {/* <div className="form-group px-2 w-50">
                      <label>স্টোর টাইপ</label>
                      <select name="" className="form-control">
                        <option value="">স্টোর টাইপ</option>
                      </select>
                    </div> */}
                    <div className="form-group px-2 w-50">
                      <label>ঠিকানা</label>
                      <input
                        ref={this.addressRef}
                        onChange={this.storeChangeHandler}
                        type="text"
                        className="form-control"
                        name="address"
                        value={this.state.address}
                      />
                    </div>
                    <div className="form-group px-2 w-50">
                      <label>টোকেন হেডার</label>
                      <input
                        ref={this.tokenHeaderRef}
                        onChange={this.storeChangeHandler}
                        type="text"
                        className="form-control"
                        name="tokenHeader"
                        value={this.state.tokenHeader}
                      />
                    </div>

                    <div className="form-group px-2 w-50">
                      <label>টোকেন ফুটার</label>
                      <input
                        ref={this.tokenFooterRef}
                        onChange={this.storeChangeHandler}
                        type="text"
                        className="form-control"
                        name="tokenFooter"
                        value={this.state.tokenFooter}
                      />
                    </div>
                    <div className="form-group px-2 w-50">
                      <label>নোট</label>
                      <input
                        ref={this.remarksRef}
                        onChange={this.storeChangeHandler}
                        type="text"
                        className="form-control"
                        name="remarks"
                        value={this.state.remarks}
                      />
                    </div>
                    {/* <div
                      className="custom-control custom-checkbox w-50 mt-2 "
                      style={{ marginBottom: "4rem" }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="hasEcommerce"
                        onChange={e =>
                          this.setState({
                            ...this.state,
                            hasEcommerce: e.target.checked
                          })
                        }
                        checked={this.state.hasEcommerce}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="hasEcommerce"
                      >
                        Has Ecommerce
                      </label>
                    </div>
                    {this.state.hasEcommerce && (
                      <div className="custom-control w-50 mt-2">
                        <input
                          type="file"
                          className="form-control"
                          accept="image/*"
                          id="logo"
                          name="logo"
                          onChange={this.storeChangeHandler}
                        />
                        <label className="form-control-label" htmlFor="logo">
                          Uplaod store Logo
                        </label>
                      </div>
                    )} */}
                  </div>
                  <div className="modal-footer bg-whitesmoke br">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                    >
                      {t("cancel")}
                    </button>
                    <button
                      disabled={requestSend}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {requestSend ? "Loading..." : t("submit")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    proprietors: state.proprietor.proprietors,
    server: state.server
  };
};

export default connect(mapStateToProps, { proprietorStoreUpdate })(
  ProprietorUpdateStore
);

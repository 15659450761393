import axios from "axios";
import toastr from "toastr";
import $ from "jquery";

import { SERVER_URL } from "../../config/config";
import { serverLoading } from "../../utils/utils";
import { GET_ORDERS, UPDATE_ORDER, DELETE_ORDER } from "../types";

export const getEcommerceOrders = ({
  proprietorID,
  storeID
}) => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/ecommerce/order/cartOrderList/${proprietorID}/${storeID}`
    );

    dispatch({
      type: GET_ORDERS,
      payload: data
    });
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const changeOrderStatus = order => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.put(
      `${SERVER_URL}/api/ecommerce/order/cartOrderChangeOrderStatus/` +
        order._id,
      order
    );

    dispatch({
      type: UPDATE_ORDER,
      payload: data.data
    });
    $("#exampleModal").modal("hide");
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

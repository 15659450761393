import { REDIRECT, STOP } from "./types";

export const redirect = (data) => (dispatch) => {
  dispatch({
    type: REDIRECT,
    payload: data,
  });
};

export const stop = () => (dispatch) => {
  dispatch({
    type: STOP,
  });
};

import {
  DELETE_SS_INVOICE,
  GET_ALL_SS_INVOICE,
  GET_SS_INVOICE,
  GET_SS_LIST_INVOICE,
  PURCHASE_SMS,
  UPDATE_ADMIN_INVOICE
} from "../actions/types";

const initialState = {
  ssAllInvoices: [],
  ssInvoices: [],
  ssInvoice: null,
  isLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SS_INVOICE:
      return {
        ...state,
        ssInvoice: action.payload
      };

    case GET_SS_LIST_INVOICE:
      return {
        ...state,
        isLoaded: true,
        ssInvoices: action.payload
      };

    case PURCHASE_SMS:
      return {
        ...state,
        ssInvoices: [...state.ssInvoices, action.payload]
      };
    case DELETE_SS_INVOICE:
      return {
        ...state,
        ssInvoices: state.ssInvoices.filter(
          invoice => invoice._id != action.payload._id
        )
      };
    case GET_ALL_SS_INVOICE:
      return {
        ...state,
        ssAllInvoices: action.payload
      };
    case UPDATE_ADMIN_INVOICE:
      const updateInvoice = state.ssAllInvoices.map(item => {
        if (item?._id === action.payload?._id) {
          item = action.payload;
        }
        return item;
      });
      return {
        ...state,
        ssAllInvoices: updateInvoice
      };
    default:
      return state;
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { t } from "../../HNLanguage/i18nextSetUp";
class Modal extends Component {
  render() {
    const {
      server: { requestSend }
    } = this.props;

    return (
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog ${this.props.modalSize || "modal-lg"} `}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.modalTitle}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.props.onSubmit}>
              <div className="modal-body">{this.props.children}</div>
              <div className="modal-footer bg-whitesmoke br">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  {t("cancel")}
                </button>
                <button
                  disabled={requestSend}
                  type="submit"
                  className="btn btn-primary"
                >
                  {requestSend ? "Loading..." : t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    server: state.server
  };
};

export default connect(mapStateToProps)(Modal);

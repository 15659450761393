import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import toastr from "toastr";
import {
  addToPurchaseCart,
  clearPurchaseCart,
  increasePurchaseUnit,
  decreasePurchaseUnit,
  setPurchaseUnit,
  removeFromPurchaseCart,
  selectSupplier,
  setPurchaseUnitPrice
} from "../../actions/purchaseCartActions";
import { addSupplier } from "../../../src/actions/supplierActions";

import { addSupplierInvoice } from "../../actions/purchaseInvoiceActions";
import { t } from "../../HNLanguage/i18nextSetUp";
import { getCurrentUser } from "../../utils/utils";
import SupplierAddEditModal from "../Services/Supplier/SupplierAddEditModal";
import moment from "moment";
import { validInput } from "../../helpers/formHelper";
import ReactDatePicker from "react-datepicker";

class PurchaseCart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditable: false,
      storeID: props.store._id,
      proprietorID: props.store.proprietorID,
      products: props.products,
      suppliers: props.suppliers,
      purchaseCart: props.purchaseCart,
      selectedPurchaseProducts: [],
      discount: 0,
      pay: 0,
      memo: "",
      dateMemo: "",
      modalTitle: t("supplier") + " " + t("add"),
      supplier: {
        reference: {}
      },
      addSupplier: true,
      dueDate: "",
      note: "",
      invoiceDate: new Date()
    };

    this.memoRef = React.createRef();
    this.payRef = React.createRef();
    this.supRef = React.createRef();
  }

  onChangeHandler = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  selectSupplierHandler = ({ value }) => {
    const supplier = this.state.suppliers.find(sup => sup._id === value);
    this.props.selectSupplier(supplier);
  };
  clearCartHandler = () => {
    this.props.clearPurchaseCart();
  };
  selectProductHandler = data => {
    this.setState({
      ...this.state,
      selectedPurchaseProducts: data
    });

    let foundProducts = data.reduce((acc, curr) => {
      let foundProduct = this.state.products.find(
        item => item._id === curr.value
      );
      if (foundProduct) {
        acc.push(foundProduct);
      }
      return acc;
    }, []);
    this.props.addToPurchaseCart(foundProducts);
    let localPurchaseCart = JSON.parse(localStorage.getItem("purchaseCart"));
    localPurchaseCart.totalPrice = localPurchaseCart.products.reduce(
      (acc, curr) => {
        acc += curr.amounts * curr.purchasePrice;
        return acc;
      },
      0
    );
    localStorage.setItem("purchaseCart", JSON.stringify(localPurchaseCart));
  };
  addOnChangeHandler = e => {
    const { name, value } = e.target;

    if (name === "refName" || name === "refMobile" || name === "refAddress") {
      this.setState({
        ...this.state,
        supplier: {
          ...this.state.supplier,
          reference: {
            ...this.state.supplier.reference,
            [name]: value
          }
        }
      });
    } else {
      this.setState({
        ...this.state,
        supplier: {
          ...this.state.supplier,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  addSupplierHandler = e => {
    e.preventDefault();
    const { reference, ...others } = this.state.supplier;

    this.props.addSupplier({
      reference: {
        name: reference?.refName,
        mobile: reference?.refMobile,
        address: reference?.refAddress,
        nid: reference?.refNid,
        relation: reference?.refRelation
      },
      ...others,
      storeID: this.props.store._id,
      proprietorID: this.props.store.proprietorID
    });
  };

  createInvoiceHandler = () => {
    let {
      purchaseCart,
      pay,
      memo,
      discount,
      dueDate,
      note,
      invoiceDate
    } = this.state;
    const { proprietorID, storeID } = getCurrentUser();
    let { cashBox } = this.props;
    let invoice;
    let products = "";
    if (!purchaseCart.supplier) {
      alert(t("selectSupplier"));
      this.supRef.current.focus();
    } else if (!memo) {
      alert(t("fillUpMemoField"));
      this.memoRef.current.focus();
    } else {
      if (cashBox.inCash < Number(pay)) {
        toastr.error(t("notEnoughBalanceCashbox"));
        return;
      }

      for (let i = 0; i < purchaseCart?.products?.length; i++) {
        let product = purchaseCart?.products[i];
        products =
          products +
          `${product._id}_${product.amounts}_${product.purchasePrice}_${
            product.units[0]?.unitID
          }_${product.units[0]?.name}_0_${product.purchasePrice}_${
            product.sellPrice
          }${i == purchaseCart.products.length - 1 ? "" : ","}`;
      }

      invoice = {
        storeID,
        supplierID: purchaseCart.supplier._id,
        proprietorID,
        memo: this.state.dateMemo + "-" + this.state.memo.toString(),
        paidAmount: Number(pay) || 0,
        due: purchaseCart.totalPrice - discount - pay,
        totalPrice: purchaseCart.totalPrice,
        totalDiscount: Number(discount),
        grandTotal: purchaseCart.totalPrice - Number(discount),
        purchasedAt: new Date(invoiceDate).toISOString(),
        products,
        note,
        dueDate: dueDate ? new Date(dueDate).toISOString() : ""
      };
      // console.log(invoice)
      this.props.addSupplierInvoice(invoice);
    }
  };

  componentDidMount() {
    let dateMemo;
    let selectedPurchaseProducts = [];
    if (this.props.purchaseInvoices.length > 0) {
      dateMemo = this.generateMemoNumber(this.props.purchaseInvoices);
    }
    if (this.props.purchaseCart.products.length) {
      selectedPurchaseProducts = this.props.purchaseCart.products.map(item => {
        return {
          label: item.name,
          value: item._id
        };
      });
    }

    this.setState({
      ...this.state,
      products: this.props.products,
      suppliers: this.props.suppliers,
      purchaseCart: this.props.purchaseCart,
      selectedPurchaseProducts,
      redirect: this.props.redirect,
      dateMemo
    });
  }

  componentWillReceiveProps = nextProps => {
    let dateMemo;
    let selectedPurchaseProducts = [];
    if (nextProps.purchaseCart.products.length) {
      selectedPurchaseProducts = nextProps.purchaseCart.products.map(item => {
        return {
          label: item.name,
          value: item._id
        };
      });
    }
    if (nextProps.purchaseInvoices) {
      dateMemo = this.generateMemoNumber(nextProps.purchaseInvoices);
    }
    this.setState({
      ...this.state,
      products: nextProps.products,
      suppliers: nextProps.suppliers,
      purchaseCart: nextProps.purchaseCart,
      selectedPurchaseProducts,
      redirect: nextProps.redirect,
      dateMemo
    });
  };

  //generate memo number
  generateMemoNumber = invoices => {
    const fullDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-");
    let date = new Date().getDate();
    const year = fullDate[0].slice(2);
    const month = fullDate[1];
    let totalMemo = invoices.filter(value => {
      return (
        moment(value.createdAt).format("DD/MM/YYYY") ===
        moment(new Date().toISOString()).format("DD/MM/YYYY")
      );
    }).length;

    totalMemo += 1;

    if (totalMemo.toString().length === 1) {
      totalMemo = "00" + totalMemo;
    } else if (totalMemo.toString().length === 2) {
      totalMemo = "0" + totalMemo;
    } else if (date.toString().length == 1) {
      date = "0" + date;
    }

    return `${year}${month}${date}${totalMemo}`;
  };

  render() {
    let { products, suppliers, addSupplier, purchaseCart } = this.state;
    // console.log(selectedPurchaseProducts)

    const productOptions = products.map(pr => ({
      label: pr.name,
      value: pr._id
    }));
    const supplierOptions = suppliers.map(sup => ({
      label: `${sup.name} (${sup.company})`,
      value: sup._id
    }));
    return (
      <>
        {!this.props.redirect.redirect ? (
          <div className="main-content">
            <section className="section">
              <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
                <h4>{t("purchaseProduct")}</h4>
              </div>

              <div className="container pt-4 pb-2 mb-4 bg-white border-rounded">
                <div className="row">
                  <div className="col-md-4">
                    <span>{t("selectSupplier")}</span>
                    <Select
                      ref={this.supRef}
                      defaultInputValue={
                        purchaseCart.supplier ? purchaseCart.supplier.name : ""
                      }
                      onChange={this.selectSupplierHandler}
                      options={supplierOptions}
                    />
                  </div>
                  <div className="col-md-4">
                    <button
                      // onClick={this.showAddSupplierModal}
                      className="btn btn-info "
                      style={{ marginTop: "1.5rem", marginLeft: "-1.3rem" }}
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      {t("addSupplier")}
                    </button>
                  </div>
                  <div className="col-md-4">
                    <span>{t("selectProduct")}</span>
                    <Select
                      id="selectProduct"
                      onChange={data => this.selectProductHandler(data)}
                      isMulti
                      // defaultValue={selectedPurchaseProducts}
                      value={this.state.selectedPurchaseProducts}
                      options={productOptions}
                    />
                    {/* <button onClick={this.clearCartHandler} className="btn btn-danger btn-md mt-2">{t("clearCart")}</button> */}
                  </div>
                </div>
                {purchaseCart.products.length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div
                        style={{ minHeight: 230 }}
                        className="table-responsive mt-5"
                      >
                        <table className="table table-bordered table-striped table-md">
                          <thead>
                            <tr>
                              <th>{t("name")}</th>
                              <th>{t("stock")}</th>
                              <th>{t("unitPrice")}</th>
                              <th>{t("totalPrice")}</th>
                              <th>{t("quantity")}</th>
                              <th>{t("delete")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {purchaseCart.products.map((product, iProduct) => {
                              return (
                                <tr key={iProduct}>
                                  <td>{product.name}</td>
                                  <td>{product.stock}</td>
                                  <td
                                    onDoubleClick={() => {
                                      this.setState({
                                        ...this.state,
                                        isEditable: true
                                      });
                                    }}
                                  >
                                    {this.state.isEditable ? (
                                      <input
                                        style={{ width: 150, height: 30 }}
                                        className="form-control d-inline mx-2 py-0 text-center"
                                        type="number"
                                        defaultValue={Number(
                                          product.purchasePrice
                                        )}
                                        disabled={!this.state.isEditable}
                                        onChange={e =>
                                          this.props.setPurchaseUnitPrice(
                                            product,
                                            Number(e.target.value)
                                          )
                                        }
                                      />
                                    ) : (
                                      Number(product.purchasePrice)
                                    )}
                                  </td>
                                  <td>
                                    {Math.round(
                                      product.purchasePrice *
                                        product.amounts *
                                        100
                                    ) / 100}{" "}
                                    {t("tk")}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-outline-secondary btn-sm"
                                      onClick={() => {
                                        this.props.decreasePurchaseUnit(
                                          product
                                        );
                                      }}
                                      disabled={product.amounts <= 0}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                    <input
                                      style={{ width: 100, height: 30 }}
                                      className="form-control d-inline mx-2 py-0 text-center"
                                      type="text"
                                      value={product.amounts}
                                      onChange={e => {
                                        this.props.setPurchaseUnit(
                                          product,
                                          Number(e.target.value)
                                        );
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-secondary btn-sm"
                                      onClick={() => {
                                        this.props.increasePurchaseUnit(
                                          product
                                        );
                                      }}
                                    >
                                      <i className="fas fa-plus"></i>
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      onClick={() =>
                                        this.props.removeFromPurchaseCart(
                                          product._id
                                        )
                                      }
                                      className="btn btn-sm"
                                    >
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        <div className="pb-3">
                          <div className="bg-light p-2">
                            <h6 className="pt-3">{t("paymentDetails")}</h6>
                          </div>
                          <table className="talbe table-bordered mt-3">
                            <tbody>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("totalPrice")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <strong>
                                    {purchaseCart.totalPrice} {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("discount")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <input
                                    type="number"
                                    style={{ width: 120, height: 30 }}
                                    className="form-control d-inline"
                                    name="discount"
                                    value={this.state.discount}
                                    onChange={e => {
                                      e.target.value = validInput(
                                        e.target.value,
                                        purchaseCart.totalPrice
                                      );
                                      this.onChangeHandler(e);
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("grandTotal")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <strong>
                                    {purchaseCart.totalPrice -
                                      this.state.discount}{" "}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("paid")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <input
                                    type="number"
                                    style={{ width: 120, height: 30 }}
                                    className="form-control d-inline"
                                    name="pay"
                                    value={this.state.pay}
                                    onChange={e => {
                                      e.target.value = validInput(
                                        e.target.value,
                                        purchaseCart.totalPrice -
                                          this.state.discount
                                      );
                                      this.onChangeHandler(e);
                                    }}
                                    ref={this.payRef}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("due")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <strong>
                                    {Math.round(
                                      (purchaseCart.totalPrice -
                                        this.state.discount -
                                        this.state.pay) *
                                        100
                                    ) / 100}{" "}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("dueDate")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <ReactDatePicker
                                    className="form-control w-100  mb-3"
                                    selected={this.state.dueDate}
                                    onChange={date =>
                                      this.setState({
                                        ...this.state,
                                        dueDate: date
                                      })
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select Date"
                                    height="1rem"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("memo")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="memo number"
                                        style={{ width: 120, height: 30 }}
                                      >
                                        {this.state.dateMemo}
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      style={{ width: 120, height: 30 }}
                                      className="form-control d-inline"
                                      name="memo"
                                      value={this.state.memo}
                                      onChange={this.onChangeHandler}
                                      ref={this.memoRef}
                                      aria-describedby="memo number"
                                      placeholder="Memo"
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("note")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <input
                                    type="text"
                                    // style={{ width: 120, height: 30 }}
                                    className="form-control d-inline"
                                    name="note"
                                    onChange={this.onChangeHandler}
                                    aria-describedby="note"
                                    placeholder="Note"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2">
                                  <strong>{t("invoiceDate")}</strong>
                                </td>
                                <td className="p-2">
                                  <span className="mx-3">:</span>
                                </td>
                                <td className="p-2">
                                  <ReactDatePicker
                                    className="form-control w-100  mb-3"
                                    selected={this.state.invoiceDate}
                                    onChange={date =>
                                      this.setState({
                                        ...this.state,
                                        invoiceDate: date
                                      })
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select Date"
                                    height="1rem"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <button
                          className="btn btn-primary btn-lg btn-block mb-3"
                          type="button"
                          disabled={this.props.requestSend}
                          onClick={this.createInvoiceHandler}
                        >
                          {this.props.requestSend ? (
                            <>
                              Loading...
                              <span
                                className="spinner-border spinner-border-sm mt-1"
                                role="status"
                                aria-hidden={true}
                              ></span>
                            </>
                          ) : (
                            t("invoice")
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        ) : (
          <Redirect
            push
            to={`/supplier/${this.props.redirect.data.supplierID}/invoices/${this.props.redirect.data.invoiceID}`}
          />
        )}

        <SupplierAddEditModal
          supplier={this.state.supplier}
          addSupplier={addSupplier}
          modalTitle={this.state.modalTitle}
          addSupplierHandler={this.addSupplierHandler}
          onChangeHandler={this.addOnChangeHandler}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
    suppliers: state.supplier.suppliers,
    purchaseCart: state.purchaseCart,
    purchaseInvoices: state.purchaseInvoice.invoices,
    store: state.store.store,
    redirect: state.redirect,
    cashBox: state.cashBox,
    requestSend: state.server.requestSend
  };
};

export default connect(mapStateToProps, {
  addToPurchaseCart,
  clearPurchaseCart,
  setPurchaseUnit,
  decreasePurchaseUnit,
  increasePurchaseUnit,
  removeFromPurchaseCart,
  selectSupplier,
  addSupplierInvoice,
  addSupplier,
  setPurchaseUnitPrice
})(PurchaseCart);

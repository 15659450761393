import React, { Component } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import PdfToPrint from "../OutputComponents/PdfToPrint";
import Select from "react-select";
import { formatDate, FormatNumber } from "../../utils/utils";
import { t } from "../../HNLanguage/i18nextSetUp";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import dateFilter from "../../utils/dateRangeFilter";
import CustomComponent from "../../components/common/TableCustomComponent";

const { SearchBar } = Search;

class AllSell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      quickSellInvoices: [],
      viewProductInfo: [],
      products: [],
      customers: [],
      selectedOption: localStorage.getItem("option")
        ? JSON.parse(localStorage.getItem("option"))
        : { value: "", label: "Select Status" },
      startDate: localStorage.getItem("startDate")
        ? new Date(localStorage.getItem("startDate"))
        : new Date(),
      endDate: localStorage.getItem("endDate")
        ? new Date(localStorage.getItem("endDate"))
        : new Date()
    };
  }

  componentDidMount = () => {
    this.setState({
      ...this.state,
      invoices: this.props.invoices,
      quickSellInvoices: this.props.quickSellInvoices,
      products: this.props.products,
      customers: this.props.customers
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      invoices: nextProps.invoices,
      quickSellInvoices: nextProps.quickSellInvoices,
      products: nextProps.products,
      customers: nextProps.customers
    });
  }
  viewProductModal = (productInfo, invoiceInfo) => {
    const mappedProduct = productInfo.reduce((acc, curr) => {
      const product = this.state.products.find(
        item => item._id === curr.productID
      );

      const currentProduct = { ...curr };
      if (curr.customerID) {
        const customer = this.props.customers.find(
          item => item._id === curr.customerID
        );
        currentProduct.customer = {
          name: customer.name,
          mobile: customer.mobile,
          address: customer.address
        };
      }
      delete currentProduct.customerID;
      delete currentProduct.productID;
      currentProduct.products = product;
      currentProduct.invoiceInfo = invoiceInfo;

      acc.push(currentProduct);
      return acc;
    }, []);

    this.setState({ ...this.state, viewProductInfo: mappedProduct });
  };

  handleOptionChange = option => {
    this.setState({
      ...this.state,
      selectedOption: option
    });
    localStorage.setItem("option", JSON.stringify(option));
  };

  render() {
    let { store } = this.props;
    let {
      invoices,
      quickSellInvoices,
      viewProductInfo,
      startDate,
      endDate,
      selectedOption,
      customers
    } = this.state;

    //declare invoice customer details and invoice amount details
    const invoiceCustomer = viewProductInfo[0]?.quickSellCustomer;
    const invoiceInfo = viewProductInfo[0]?.invoiceInfo;
    let allInvoices = [...invoices, ...quickSellInvoices];

    let totalSell = 0;
    let totalDue = 0;
    let totalPaid = 0;
    if (allInvoices?.length > 0) {
      if (startDate && endDate) {
        allInvoices = dateFilter(
          allInvoices,
          startDate,
          endDate,
          "soldAt",
          true
        );
      } else if (startDate) {
        allInvoices = dateFilter(
          allInvoices,
          startDate,
          endDate,
          "soldAt",
          false
        );
      }

      allInvoices.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      if (selectedOption.value === "quickSell") {
        allInvoices = allInvoices.filter(item => !item.customerID);
      }
      if (selectedOption.value === "normalSell") {
        allInvoices = allInvoices.filter(
          item => item.customerID && item.type !== "installment"
        );
      }
      if (selectedOption.value === "installment") {
        allInvoices = allInvoices.filter(item => item.type === "installment");
      }
    }

    for (let i = 0; i < allInvoices.length; i++) {
      totalSell = totalSell + allInvoices[i].grandTotal;
    }

    totalDue = allInvoices.reduce((acc, curr) => {
      return (acc += curr.totalDue);
    }, 0);
    totalPaid = allInvoices.reduce((acc, curr) => {
      return (acc += curr.grandTotal - curr.totalDue);
    }, 0);

    // monthly filter date here
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    const customData = [
      {
        title: t("totalInvoice"),
        value: allInvoices?.length
      },
      {
        title: t("totalSell"),
        value: FormatNumber(totalSell)
      },
      {
        title: t("totalPaid"),
        value: FormatNumber(totalPaid)
      },
      {
        title: t("totalDue"),
        value: FormatNumber(totalDue)
      }
    ];

    let columns = [
      {
        dataField: "memo",
        text: t("memo")
      },
      {
        dataField: "_id",
        text: t("name"),
        formatExtraData: customers,
        formatter: (id, row, _index, customers) => {
          let customer = customers.find(
            customer => customer?._id == row?.customerID
          );
          return customer ? customer.name : "N/A";
        }
      },
      {
        dataField: "totalPrice",
        text: t("price"),
        formatter: data => FormatNumber(data)
      },
      {
        dataField: "totalDiscount",
        text: t("discount"),
        formatter: data => FormatNumber(data)
      },
      {
        dataField: "grandTotal",
        text: t("totalPrice"),
        formatter: data => FormatNumber(data)
      },
      {
        dataField: "_id",
        text: t("totalPaid"),
        formatter: (row, cell) => FormatNumber(cell.grandTotal - cell.totalDue)
      },
      {
        dataField: "totalDue",
        text: t("totalDue"),
        formatter: data => FormatNumber(data)
      },
      {
        dataField: "soldAt",
        text: t("date"),
        formatter: cell => <div>{formatDate(cell)}</div>
      },
      {
        dataField: "soldItems",
        text: t("option"),
        formatter: (_cell, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown d-inline">
                <a href="#" data-toggle="dropdown" className="px-2">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  {row.type === "installment" ? (
                    <Link
                      to={`/customer/${row.customerID}/emi-invoice/${row.id}`}
                      className="dropdown-item has-icon"
                    >
                      <i className="fas fa-print"></i>
                      {t("printInvoice")}
                    </Link>
                  ) : (
                    <Link
                      to={`/customer/${row.customerID}/sellReport/${row.id}`}
                      className="dropdown-item has-icon"
                    >
                      <i className="fas fa-print"></i>
                      {t("printInvoice")}
                    </Link>
                  )}
                  <Link
                    to={`/customer/${row.customerID}/chalanSellReport/${row.id}`}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-print"></i>
                    {t("Chalan Invoice")}
                  </Link>
                </div>
              </div>
            </div>
          );
        }
      }
    ];
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="px-2 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>{t("allSellReport")}</h4>

              <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2"
                    selected={startDate}
                    onChange={date => {
                      this.setState({
                        ...this.state,
                        startDate: date
                      });
                      localStorage.setItem("startDate", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                  />
                </div>
                <div>-</div>
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2 "
                    selected={endDate}
                    onChange={date => {
                      this.setState({
                        ...this.state,
                        endDate: date
                      });
                      localStorage.setItem("endDate", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    maxDate={new Date()}
                  />
                </div>
                <button
                  className="btn  btn-info mt-2"
                  style={{ marginLeft: "-.4rem" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date()
                    });
                    localStorage.setItem("startDate", new Date());
                    localStorage.setItem("endDate", new Date());
                  }}
                >
                  {t("daily")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: firstDay,
                      endDate: new Date()
                    });
                    localStorage.setItem("startDate", firstDay);
                    localStorage.setItem("endDate", new Date());
                  }}
                >
                  {t("monthly")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: "",
                      endDate: "",
                      selectedOption: { value: "", label: "Select Status" }
                    });
                    localStorage.removeItem("option");
                  }}
                >
                  {t("all")}
                </button>
                <button
                  className="btn btn-warning ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date(),
                      selectedOption: { value: "", label: "Select Status" }
                    });
                    localStorage.setItem("startDate", new Date());
                    localStorage.setItem("endDate", new Date());
                    localStorage.removeItem("option");
                  }}
                >
                  {t("reset")}
                </button>
              </div>
            </div>
            <div className="px-2 bg-white">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="clear-fix py-3">
                    <div
                      className="btn-group btn-group-sm float-right mb-2"
                      role="group"
                      aria-label="Basic example"
                    >
                      <ReactToPrint
                        trigger={() => (
                          <button
                            title={t("exportPDF")}
                            type="button"
                            className="btn btn-info"
                          >
                            <i className="fas fa-file-pdf"></i>
                          </button>
                        )}
                        content={() => this.componentRef}
                      />
                      <div style={{ display: "none" }}>
                        <PdfToPrint ref={el => (this.componentRef = el)}>
                          <div style={{ minHeight: 842 }} className="bg-white">
                            <div className="p-3">
                              <h3 className="text-center">{store.storeName}</h3>
                              <div className="clearfix py-3">
                                <h5 className="float-left">
                                  {t("customerInvoice")}-{allInvoices?.length}
                                </h5>
                                <span className="float-right">
                                  {new Date().toDateString()}{" "}
                                  {new Date().toLocaleTimeString()}
                                </span>
                              </div>
                              <div>
                                <h5>
                                  {t("totalSale")}: {totalSell}
                                </h5>
                                <h5>
                                  {t("totalPaid")}: {totalPaid}
                                </h5>
                                <h5>
                                  {t("totalDue")}: {totalDue}
                                </h5>
                              </div>
                            </div>

                            <table className="table table-bordered table-striped">
                              <tr>
                                <th scope="col">{t("memo")}</th>
                                <th scope="col">{t("price")}</th>
                                <th scope="col">{t("discount")}</th>
                                <th scope="col">{t("totalPrice")}</th>
                                <th scope="col">{t("totalPaid")}</th>
                                <th scope="col">{t("totalDue")}</th>
                                <th scope="col">{t("date")}</th>
                              </tr>
                              <tbody>
                                {allInvoices?.length > 0 &&
                                  allInvoices.map((invoice, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{invoice.memo}</td>
                                        <td>
                                          {FormatNumber(invoice?.totalPrice)}
                                        </td>
                                        <td>
                                          {FormatNumber(invoice?.totalDiscount)}
                                        </td>
                                        <td>
                                          {FormatNumber(invoice?.grandTotal)}{" "}
                                          {t("tk")}.
                                        </td>
                                        <td>
                                          {FormatNumber(
                                            invoice?.grandTotal -
                                              invoice?.totalDue
                                          )}{" "}
                                          {t("tk")}.
                                        </td>
                                        <td>
                                          {FormatNumber(invoice?.totalDue)}{" "}
                                          {t("tk")}.
                                        </td>
                                        <td>
                                          {formatDate(invoice?.createdAt)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </PdfToPrint>
                      </div>
                      {allInvoices?.length > 0 && (
                        <CSVLink
                          filename={"invoices.csv"}
                          data={
                            ([
                              t("memo"),
                              t("price"),
                              t("discount"),
                              t("totalPrice")
                            ],
                            allInvoices.map(invoice => [
                              invoice.memo,
                              FormatNumber(invoice?.totalPrice),
                              FormatNumber(invoice?.totalDiscount),
                              FormatNumber(invoice?.grandTotal)
                            ]))
                          }
                        >
                          <button
                            title={t("exportCSV")}
                            type="button"
                            className="btn btn-primary mx-2"
                          >
                            <i className="fas fa-file-csv"></i>
                          </button>
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div style={{ minHeight: 230 }} className="py-3">
                    {this.props.requestSend ? (
                      <div className="fa-3x text-center">
                        <i
                          style={{ fontSize: 40 }}
                          className="fas fa-sync fa-spin"
                        ></i>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
                        })}
                      >
                        {({ paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={allInvoices}
                            columns={columns}
                            search
                          >
                            {props => (
                              <div>
                                <div className="d-flex justify-content-between mt-3">
                                  <CustomComponent data={customData} />
                                  <div
                                    className="col-md-3 mt-1"
                                    style={{ marginRight: "-20rem" }}
                                  >
                                    <Select
                                      value={selectedOption}
                                      onChange={this.handleOptionChange}
                                      options={[
                                        {
                                          value: "quickSell",
                                          label: t("quickSell")
                                        },
                                        {
                                          value: "normalSell",
                                          label: t("normalSell")
                                        },
                                        {
                                          value: "installment",
                                          label: t("Installment")
                                        }
                                      ]}
                                    />
                                  </div>
                                  <div
                                    className="col-md-3 mt-1"
                                    style={{ marginRight: "-25rem" }}
                                  >
                                    <button
                                      className="btn btn-warning "
                                      onClick={() => {
                                        this.setState({
                                          ...this.state,

                                          selectedOption: {
                                            value: "",
                                            label: "Select Status"
                                          }
                                        });

                                        localStorage.removeItem("option");
                                      }}
                                    >
                                      {t("reset")}
                                    </button>
                                  </div>

                                  <SearchBar {...props.searchProps} />
                                </div>
                                <hr />
                                <BootstrapTable
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                  wrapperClasses="table-responsive"
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    invoices: state.invoice.invoices,
    quickSellInvoices: state.invoice.quickSellInvoices,
    products: state.product.products,
    customers: state.customer.customers,
    requestSend: state.server.requestSend
  };
};

export default connect(mapStateToProps)(AllSell);

import {
  ADD_TO_PURCHASE_CART,
  REMOVE_FROM_PURCHASE_CART,
  INCREASE_PURCHASE_UNIT,
  DECREASE_PURCHASE_UNIT,
  CLEAR_PURCHASE_CART,
  LOAD_PURCHASE_CART_FROM_LOCALSTORAGE,
  SELECT_SUPPLIER,
  SET_PURCHASE_UNIT,
  SET_PURCHASE_UNIT_PRICE
} from "../actions/types";

const initialState = {
  supplier: null,
  numberOfProducts: 0,
  products: [],
  totalPrice: 0
};

export default (state = initialState, action) => {
  let purchaseCart;

  switch (action.type) {
    case LOAD_PURCHASE_CART_FROM_LOCALSTORAGE:
      return action.payload;

    case ADD_TO_PURCHASE_CART:

      const { products, totalPrice } = action.payload.reduce((acc, curr) => {
        const foundInLocalCart = JSON.parse(localStorage.getItem("purchaseCart"))?.products.find(
          item => item._id === curr._id
        )
        curr.amounts = foundInLocalCart ? foundInLocalCart?.amounts : 0;
        acc.totalPrice += Math.round((curr.amounts * curr.purchasePrice) * 100) / 100;
        acc.products.push(curr)
        return acc;
      }, { products: [], totalPrice: 0 })
      purchaseCart = {
        ...state,
        numberOfProducts: action.payload.length,
        products,
        totalPrice
      };

      localStorage.setItem("purchaseCart", JSON.stringify(purchaseCart));
      return purchaseCart;

    case REMOVE_FROM_PURCHASE_CART:
      let productToRemove = state.products.find(p => p?._id == action.payload);
      purchaseCart = {
        ...state,
        numberOfProducts: state.numberOfProducts - 1,
        products: [...state.products.filter(p => p?._id != productToRemove?._id)],
        totalPrice:
          Math.round((state.totalPrice -
            productToRemove?.purchasePrice * productToRemove?.amounts) * 100) / 100
      };
      if (purchaseCart.products.length <= 0) {
        localStorage.removeItem("purchaseCart");
        return initialState;
      } else {
        localStorage.setItem("purchaseCart", JSON.stringify(purchaseCart));
        return purchaseCart;
      }

    case INCREASE_PURCHASE_UNIT:
      let product = {
        ...action.payload,
        amounts: action.payload.amounts + 1
      };

      let index = state.products.findIndex(p => p._id === product._id);

      purchaseCart = {
        ...state,
        products: [
          ...state.products.slice(0, index),
          product,
          ...state.products.slice(index + 1)
        ],
        totalPrice: Math.round((state.totalPrice + product.purchasePrice) * 100) / 100
      };

      localStorage.setItem("purchaseCart", JSON.stringify(purchaseCart));
      return purchaseCart;

    case DECREASE_PURCHASE_UNIT:
      let deProduct = {
        ...action.payload,
        amounts: action.payload.amounts - 1
      };

      let dIndex = state.products.findIndex(p => p._id === deProduct._id);
      purchaseCart = {
        ...state,
        products: [
          ...state.products.slice(0, dIndex),
          deProduct,
          ...state.products.slice(dIndex + 1)
        ],
        totalPrice: Math.round((state.totalPrice - deProduct.purchasePrice) * 100) / 100
      };

      localStorage.setItem("purchaseCart", JSON.stringify(purchaseCart));
      return purchaseCart;

    case SET_PURCHASE_UNIT:
      let seProduct = {
        ...action.payload
      };

      let sIndex = state.products.findIndex(p => p._id === seProduct._id);
      let productToSetUnit = state.products.find(p => p._id === seProduct._id);

      purchaseCart = {
        ...state,
        products: [
          ...state.products.slice(0, sIndex),
          seProduct,
          ...state.products.slice(sIndex + 1)
        ],
        totalPrice:
          Math.round((state.totalPrice -
            productToSetUnit.purchasePrice * productToSetUnit.amounts +
            seProduct.purchasePrice * seProduct.amounts) * 100) / 100
      };

      localStorage.setItem("purchaseCart", JSON.stringify(purchaseCart));
      return purchaseCart;
    case SET_PURCHASE_UNIT_PRICE:
      let unitProduct = {
        ...action.payload
      };
      let pIndex = state.products.findIndex(p => p._id === unitProduct._id);
      let productToSetUnitPrice = state.products.find(p => p._id === unitProduct._id);
      purchaseCart = {
        ...state,
        products: [
          ...state.products.slice(0, pIndex),
          unitProduct,
          ...state.products.slice(pIndex + 1)
        ],
        totalPrice:
          Math.round((state.totalPrice -
            productToSetUnitPrice.purchasePrice * productToSetUnitPrice.amounts +
            unitProduct.purchasePrice * unitProduct.amounts) * 100) / 100
      };

      localStorage.setItem("purchaseCart", JSON.stringify(purchaseCart));
      return purchaseCart;
    case SELECT_SUPPLIER:
      purchaseCart = {
        ...state,
        supplier: action.payload
      };

      localStorage.setItem("purchaseCart", JSON.stringify(purchaseCart));
      return purchaseCart;

    case CLEAR_PURCHASE_CART:
      localStorage.removeItem("purchaseCart");
      return initialState;
    default:
      return state;
  }
};

import React from "react";
import { t } from "../../../HNLanguage/i18nextSetUp";
import { FormatNumber, formatDate } from "../../../utils/utils";
import "./profile.css";
export default function Profile({ profileData }) {
  return (
    <div
      className="modal fade"
      id="showProfile"
      tabIndex="-1"
      aria-labelledby="customerModalDetails"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg ">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              style={{ color: "#0abb7a" }}
              className="modal-title"
              id="customerModalDetails"
            >
              {profileData?.name} {t("profile")}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="profileImage">
              <img
                src="https://roottogether.net/wp-content/uploads/2020/04/img-avatar-blank.jpg"
                alt=""
              />
            </div>
            <h2 className="ProfileName">{profileData?.name}</h2>
            <table className="table">
              <thead>
                {profileData?.companyName && (
                  <tr>
                    <th scope="col">{t("companyName")}</th>
                    <th>:</th>
                    <th scope="col">{profileData?.companyName}</th>
                  </tr>
                )}
                {profileData?.accountNumber && (
                  <tr>
                    <th scope="col">{t("accountNumber")}</th>
                    <th>:</th>
                    <th scope="col">{profileData?.accountNumber}</th>
                  </tr>
                )}
                <tr>
                  <th scope="col">{t("mobile")}</th>
                  <th>:</th>
                  <th scope="col">{profileData?.mobile}</th>
                </tr>
                <tr>
                  <th scope="col">{t("address")}</th>
                  <th>:</th>
                  <th scope="col">{profileData?.address}</th>
                </tr>
                {profileData.customerType && (
                  <tr>
                    <th scope="col">{t("customerType")}</th>
                    <th>:</th>
                    <th scope="col">{profileData?.customerType}</th>
                  </tr>
                )}
                {profileData.status && (
                  <tr>
                    <th scope="col">{t("status")}</th>
                    <th>:</th>
                    <th scope="col">{profileData?.status}</th>
                  </tr>
                )}

                {"salary" in profileData && (
                  <tr>
                    <th scope="col">{t("salary")}</th>
                    <th>:</th>
                    <th scope="col">{FormatNumber(profileData?.salary)}</th>
                  </tr>
                )}

                <tr>
                  <th scope="col">{t("due")}</th>
                  <th>:</th>
                  <th scope="col">{FormatNumber(profileData?.due)}</th>
                </tr>
                {profileData.joiningDate && (
                  <tr>
                    <th scope="col">{t("joiningDate")}</th>
                    <th>:</th>
                    <th scope="col">{formatDate(profileData?.joiningDate)}</th>
                  </tr>
                )}

                {profileData?.nid && (
                  <tr>
                    <th scope="col">{t("nid")}</th>
                    <th>:</th>
                    <th scope="col">{profileData?.nid}</th>
                  </tr>
                )}

                {profileData.fatherName && (
                  <tr>
                    <th scope="col">{t("fatherName")}</th>
                    <th>:</th>
                    <th scope="col">{profileData?.fatherName}</th>
                  </tr>
                )}

                <tr>
                  <th scope="col">{t("refName")}</th>
                  <th>:</th>
                  <th scope="col">{profileData?.reference?.name || "N/A"}</th>
                </tr>
                <tr>
                  <th scope="col">{t("refMobile")}</th>
                  <th>:</th>
                  <th scope="col">{profileData?.reference?.mobile || "N/A"}</th>
                </tr>
                <tr>
                  <th scope="col">{t("refAddress")}</th>
                  <th>:</th>
                  <th scope="col">
                    {profileData?.reference?.address || "N/A"}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

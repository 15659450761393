import axios from "axios";
import { Component } from "react";
import classes from "./homeSlider.module.css";
import { getCurrentUser } from "../../../utils/utils";
import { connect } from "react-redux";
import {
  createHomeSliders,
  deleteHomeSlider
} from "../../../actions/ECAction/homeSliderAction";
import SliderEditModal from "./SliderEditModal";
import DeleteModal from "../../Settings/DeleteModal";

class HomeSlider extends Component {
  state = {
    files: [],
    imageURL: [],
    progress: "",
    loading: false,
    homeSlider: this.props.homeSlider || [],
    slider: {}
  };

  uploadFileHandler = event => {
    const files = event.target.files;
    const urls = [];
    for (let i = 0; i < files.length; i++) {
      urls.push(URL.createObjectURL(files[i]));
    }

    this.setState({
      ...this.state,
      files,
      imageURL: urls
    });
  };
  fileSubmitHandler = async event => {
    event.preventDefault();
    const files = this.state.files;
    const { proprietorID, storeID } = getCurrentUser();
    const data = {
      proprietorID,
      storeID,
      files,
      storeUserName: this.props.store.storeUserName
    };

    this.props.createHomeSliders(data);
  };

  deleteHomeSlider = () => {
    this.props.deleteHomeSlider(this.state.slider._id);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      homeSlider: nextProps.homeSlider
    });
  }

  render() {
    const { imageURL, homeSlider, slider } = this.state;
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>HOME SLIDER</h4>
            </div>

            <div className="row d-flex justify-content-center mt-4">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5>Image Upload</h5>
                  </div>
                  <div className="card-block">
                    <form action="#" className="dropzone dz-clickable">
                      <div className="dz-default dz-message">
                        <div>
                          <label for="fileName" style={{ cursor: "pointer" }}>
                            Upload Your Selected File
                            <div>
                              <i
                                className="fas fa-cloud-upload-alt "
                                style={{ fontSize: "2rem" }}
                              ></i>
                            </div>
                          </label>

                          <input
                            type="file"
                            id="fileName"
                            className="d-none"
                            onChange={this.uploadFileHandler}
                            accept="image/*"
                            multiple
                          />
                        </div>
                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                          {imageURL.map(item => (
                            <div className="border mx-2">
                              <img src={item} alt="" width="100px" />
                            </div>
                          ))}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <hr />
                <div className="text-center m-t-20">
                  <button
                    onClick={this.fileSubmitHandler}
                    className="btn btn-primary"
                    type="button"
                    disabled={this.props.requestSend}
                  >
                    {this.props.requestSend && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    {this.props.requestSend ? "Loading..." : "Upload"}
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              {homeSlider.map(item => (
                <div className="col-md-6 d-flex justify-content-beteween">
                  <div className="card bg-dark text-white">
                    <img
                      className="card-img"
                      src={item?.image.src}
                      alt="slider image"
                    />
                    <div
                      className={`card-img-overlay d-flex justify-content-center align-items-center ${classes.customImageOverlay}`}
                    >
                      <div>
                        <h5 className="card-title">{item.title}</h5>
                        <p className="card-text">
                          {item.description &&
                            item.description.substring(0, 50) + "..."}
                        </p>
                        <button
                          data-toggle="modal"
                          data-target="#exampleModal"
                          className={`btn btn-warning mr-2 shadow-none  ml-auto ${classes.imageOverlayButton}`}
                          onClick={() =>
                            this.setState({ ...this.state, slider: item })
                          }
                        >
                          Edit <i className="fas fa-edit" />
                        </button>
                        <button
                          data-toggle="modal"
                          data-target="#deleteModal"
                          className={`btn btn-danger shadow-none  ml-auto ${classes.imageOverlayButton}`}
                          onClick={() =>
                            this.setState({ ...this.state, slider: item })
                          }
                        >
                          Delete <i className="fas fa-trush" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
        <SliderEditModal slider={slider} />
        <DeleteModal
          deleteModalTitle="Delete Slider"
          agreeHandler={this.deleteHomeSlider}
        />
      </>
    );
  }
}

HomeSlider.defaultProps = {
  homeSlider: []
};

const mapStateToProps = state => {
  return {
    store: state.store.store,
    homeSlider: state.homeSlider.homeSlider,
    requestSend: state.server.requestSend
  };
};

export default connect(mapStateToProps, {
  createHomeSliders,
  deleteHomeSlider
})(HomeSlider);

import React, { Component } from "react";
import { connect } from "react-redux";

import PdfToPrint from "../../OutputComponents/PdfToPrint";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { t } from "../../../HNLanguage/i18nextSetUp";
import SalesManAddModal from "./SalesManAddModal";
import SalesManPermissionsModal from "./SalesManPermissionsModal";

import {
  getSalesMans,
  addSalesMan,
  updateSalesMan,
  deleteSalesMan
} from "../../../actions/salesmanAction";
import { FormatNumber, formatDate } from "../../../utils/utils";
import Column from "./Column";
import Profile from "../Profile/Profile";
import Table from "../../../components/common/Table";
import DeleteModal from "../../Settings/DeleteModal";

const { SearchBar } = Search;

class SalesMan extends Component {
  state = {
    salesMans: this.props.salesMans,
    addSalesMan: false,
    editSalesMan: false,
    modalTitle: "",
    deleteID: "",
    salesMan: {
      reference: {}
    },
    storeID: "",
    singleSalesMan: {}
  };

  onChangeHandler = e => {
    const { name, value } = e.target;
    if (e.target.name === "storeID") {
      return this.setState({ ...this.state, storeID: value });
    }

    if (
      name === "refName" ||
      name === "refMobile" ||
      name === "refAddress" ||
      name === "refNid" ||
      name === "refRelation"
    ) {
      this.setState({
        ...this.state,
        salesMan: {
          ...this.state.salesMan,
          reference: {
            ...this.state.salesMan.reference,
            [name]: value
          }
        }
      });
    } else {
      this.setState({
        ...this.state,
        salesMan: {
          ...this.state.salesMan,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  showAddSalesManModal = () => {
    this.setState({
      ...this.state,
      addSalesMan: true,
      editSalesMan: false,
      modalTitle: t("salesMan") + " " + t("add"),
      salesMan: {}
    });
  };
  showSalesManProfileModal = id => {
    let salesMan = this.state.salesMans.find(salesMan => salesMan._id === id);
    this.setState({
      ...this.state,
      singleSalesMan: salesMan
    });
  };

  showEditSalesManModal = id => {
    let salesMan = this.state.salesMans.find(salesMan => salesMan._id === id);
    this.setState({
      ...this.state,
      addSalesMan: false,
      editSalesMan: true,
      modalTitle: t("salesMan") + " " + t("edit"),
      salesMan: {
        ...salesMan,
        reference: {
          refName: salesMan.reference ? salesMan.reference.name : "",
          refMobile: salesMan.reference ? salesMan.reference.mobile : ""
        }
      },
      storeID: salesMan.storeID
    });
  };
  deleteSalesManHandler = e => {
    e.preventDefault();
    this.props.deleteSalesMan(this.state.deleteID);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      salesMans: nextProps.salesMans
    });
  }
  showDeleteSalesManModal = (id) => {
    this.setState({
      ...this.state,
      deleteID: id,
      modalTitle: t("salesMan") + " " + t("delete")
    });
  }
  render() {
    let { salesMans, singleSalesMan } = this.state;
    let { store, requestSend } = this.props;
    let columnData = {
      store,
      showEditSalesManModal: this.showEditSalesManModal,
      showSalesManProfileModal: this.showSalesManProfileModal,
      showDeleteSalesManModal: this.showDeleteSalesManModal,
      role: this.props.role,
    };
    const columns = Column(columnData);

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>{t("salesMan")}</h4>
            </div>
            <div className="px-2">
              <div className="row justify-content-center">
                <div className="col-12 p-0">
                  <div className="card p-3">
                    <div className="card-body p-0">
                      {requestSend ? (
                        <div
                          style={{ minHeight: 230 }}
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <div className="fa-3x">
                            <i
                              style={{ fontSize: 40 }}
                              className="fas fa-sync fa-spin"
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="clear-fix mt-3">
                            <div
                              className="btn-group btn-group-sm float-right"
                              role="group"
                              aria-label="Basic example"
                            >
                              <ReactToPrint
                                trigger={() => (
                                  <button
                                    title={t("exportPDF")}
                                    type="button"
                                    className="btn btn-info"
                                  >
                                    <i className="fas fa-file-pdf"></i>
                                  </button>
                                )}
                                content={() => this.componentRef}
                              />
                              <div style={{ display: "none" }}>
                                <PdfToPrint
                                  ref={el => (this.componentRef = el)}
                                >
                                  <div
                                    style={{ minHeight: 842 }}
                                    className="bg-white"
                                  >
                                    <div className="p-3">
                                      <h3 className="text-center">
                                        {store.store.storeName}
                                      </h3>
                                      <div className="clearfix py-3">
                                        <h5 className="float-left">
                                          {t("salesman")}
                                        </h5>
                                        <span className="float-right">
                                          {new Date().toDateString()}{" "}
                                          {new Date().toLocaleTimeString()}
                                        </span>
                                      </div>
                                    </div>
                                    <table className="table table-bordered table-striped">
                                      <tr>
                                        <th scope="col"> {t("name")}</th>
                                        <th scope="col">
                                          {t("store")}
                                        </th>
                                        <th scope="col"> {t("address")}</th>
                                        <th scope="col"> {t("mobile")}</th>
                                      </tr>
                                      <tbody>
                                        {salesMans.length > 0 &&
                                          salesMans.map(salesMan => {
                                            return (
                                              <tr key={salesMan._id}>
                                                <td>{salesMan.name}</td>
                                                <td>{store?.stores.find(st => st._id === salesMan.storeID)?.storeName} </td>
                                                <td>{salesMan.address}</td>
                                                <td>{salesMan.mobile}</td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </PdfToPrint>
                              </div>

                              <CSVLink
                                filename={"salesmans.csv"}
                                data={
                                  ([
                                    t("name"),
                                    t("address"),
                                    t("mobile"),
                                    t("previousDue")
                                  ],
                                    salesMans.map(salesMan => [
                                      salesMan.name,
                                      salesMan.address,
                                      salesMan.mobile,
                                      salesMan.due
                                    ]))
                                }
                              >
                                <button
                                  title={t("exportCSV")}
                                  type="button"
                                  className="btn btn-primary mx-2"
                                >
                                  <i className="fas fa-file-csv"></i>
                                </button>
                              </CSVLink>
                              <button
                                onClick={this.showAddSalesManModal}
                                className="btn btn-success"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <i className="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div
                            style={{ minHeight: 230, marginTop: 60 }}
                            className="p-2"
                          >
                            {salesMans.length > 0 && (
                              // <PaginationProvider
                              //   pagination={paginationFactory({
                              //     sizePerPageList: [
                              //       50,
                              //       100,
                              //       500,
                              //       1000,
                              //       2000,
                              //       5000
                              //     ]
                              //   })}
                              // >
                              //   {({ paginationTableProps }) => (
                              //     <ToolkitProvider
                              //       bootstrap5
                              //       keyField="_id"
                              //       data={salesMans}
                              //       columns={columns}
                              //       search
                              //     >
                              //       {props => (
                              //         <div>
                              //           <SearchBar {...props.searchProps} />
                              //           <hr />
                              //           <BootstrapTable
                              //             {...props.baseProps}
                              //             {...paginationTableProps}
                              //             wrapperClasses="table-responsive"
                              //           />
                              //         </div>
                              //       )}
                              //     </ToolkitProvider>
                              //   )}
                              // </PaginationProvider>
                              <Table data={salesMans} columns={columns}></Table>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <SalesManAddModal
          onChangeHandler={this.onChangeHandler}
          salesMan={this.state.salesMan}
          storeID={this.state.storeID}
          modalTitle={this.state.modalTitle}
          isAddSalesMan={this.state.addSalesMan}
        />
        <SalesManPermissionsModal
          salesMan={this.state.salesMan}
          modalTitle={this.state.modalTitle}
        />
        <Profile profileData={singleSalesMan} />
        <DeleteModal
          agreeHandler={this.deleteSalesManHandler}
          deleteModalTitle={this.state.modalTitle}

        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store,
    salesMans: state.salesMans.salesMans,
    requestSend: state.server.requestSend,
    role: state.profile?.profile?.userID?.role,
  };
};

export default connect(mapStateToProps, {
  getSalesMans,
  addSalesMan,
  updateSalesMan,
  deleteSalesMan
})(SalesMan);

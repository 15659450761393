import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { t } from "../../../HNLanguage/i18nextSetUp";
import { stop } from "../../../actions/redirectActions";
import { FormatNumber, formatDate } from "../../../utils/utils";
import PdfToPrint from "./../../OutputComponents/PdfToPrint";
// import image from "../Customer/hisab.png";

function PrintInvoice(props) {
  console.log(3);

  const [downloading, setDownloading] = useState(false);
  let printRef = useRef();
  let history = useHistory();
  let invId = props.match.params.inv_id;
  const { store } = props;
  let products = props.products;
  let invoice;
  let supplier;

  if (props.invoices) {
    invoice = props.invoices.find(inv => inv._id === invId);
  }

  if (props.suppliers && invoice) {
    supplier = props.suppliers.find(cus => cus._id === invoice.supplierID);
  }

  if (props.redirect) {
    props.stop();
  }

  let tableBorder = () => {
    return { border: " 1px solid lightGray" };
  };

  let a = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen "
  ];
  let b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety"
  ];
  let inWords = num => {
    num = Math.floor(num);

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore  "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh  "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand  "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred  "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          " "
        : "";
    return str + "Taka only ";
  };
  const downloadPdf = () => {
    setDownloading(true);
    const capture = document.querySelector(".invoiceToPrint");
    html2canvas(capture, { allowTaint: true, useCORS: true, scale: 5 }).then(
      canvas => {
        const imgData = canvas.toDataURL("img/png");
        const doc = new jsPDF("p", "em", "a4");
        const componentWidth = doc.internal.pageSize.getWidth();
        const componentHeight = doc.internal.pageSize.getHeight();
        doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
        doc.save("invoice.pdf");
        setDownloading(false);
      }
    );
  };
  return (
    <>
      <div className="main-content">
        <section className="section">
          <div className="container pt-3 pb-3 mb-4 bg-white text-center border-rounded d-flex">
            {/* <Link
              className="btn btn-primary text-center"
              to={`/supplier/${supplier?._id}/invoices`}
            >
              <i
                className="fas fa-arrow-alt-circle-left  mt-2 "
                style={{ fontSize: "1.13rem" }}
              ></i>
            </Link> */}

            <button
              className="btn btn-primary text-center"
              onClick={() => history.goBack()}
            >
              <i
                className="fas fa-arrow-alt-circle-left  mt-2 "
                style={{ fontSize: "1.13rem" }}
              ></i>
            </button>
            <h4 style={{ marginLeft: "23rem", marginTop: ".5rem" }}>
              {t("printInvoice")}
            </h4>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="card">
                  {invoice && supplier && products ? (
                    <>
                      <PdfToPrint ref={el => (printRef = el)}>
                        <div className="invoiceToPrint card-body p-3">
                          <div className="my-4 row align-items-center">
                            {store.logo?.src && (
                              <div className="col-md-3 ml-1">
                                <div>
                                  <img
                                    src={store.logo?.src}
                                    height="100px"
                                    width="100PX"
                                    style={{ objectFit: "contain" }}
                                  />
                                </div>
                              </div>
                            )}
                            {store.logo ? (
                              <div className="col-md-5">
                                <div className="text-center">
                                  <span className="h6">{store.storeName}</span>
                                  <br />
                                  <small>{store.address}</small>
                                  <br />
                                  <small>{store.contactNumber}</small>
                                  <br />
                                  <small>{store.tokenHeader}</small>
                                  <br />
                                </div>
                              </div>
                            ) : (
                              <div className="col-12">
                                <div className="text-center">
                                  <span className="h6">{store.storeName}</span>
                                  <br />
                                  <small>{store.address}</small>
                                  <br />
                                  <small>{store.contactNumber}</small>
                                  <br />
                                  <small>{store.tokenHeader}</small>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="clearfix">
                            <table
                              style={{
                                width: "100%"
                              }}
                            >
                              <tr style={tableBorder()}>
                                <td
                                  className="p-1 ml-1"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  Memo
                                </td>
                                <td>{invoice.memo}</td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Name</td>
                                <td>{`${supplier.name} (${supplier.company})`}</td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Mobile</td>
                                <td>{supplier.mobile}</td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Due</td>
                                <td>{FormatNumber(supplier?.due) || 0}</td>
                              </tr>
                            </table>
                          </div>

                          <div className="table-responsive mt-4">
                            <div className="d-flex justify-content-between">
                              <h6>{t("productDetails")}</h6>
                              <div className=" ">
                                <strong>
                                  তারিখ :
                                  {new Date(invoice.purchasedAt).getDate()} /
                                  {new Date(invoice.purchasedAt).getMonth() + 1}
                                  /{new Date(invoice.purchasedAt).getFullYear()}
                                </strong>
                              </div>
                            </div>
                            <table
                              style={{
                                width: "100%"
                              }}
                              className="text-center"
                            >
                              <tr className="p-2" style={tableBorder()}>
                                <th className="p-2">{t("name")}</th>

                                <th style={tableBorder()}>{t("model")}</th>
                                <th style={tableBorder()}>{t("quantity")}</th>
                                <th style={tableBorder()}>{t("Unit Price")}</th>
                                <th style={tableBorder()}>{t("totalPrice")}</th>
                              </tr>

                              {invoice &&
                                invoice.products.map((p, pI) => {
                                  const totalPrice =
                                    p.qty * (p.unitPrice ? p.unitPrice : 0);
                                  const product = props.products.find(
                                    product => product._id === p.productID
                                  );
                                  return (
                                    <tr key={pI} style={tableBorder()}>
                                      <td style={tableBorder()}>
                                        {p?.productID?.name || product?.name}
                                      </td>
                                      <td style={tableBorder()}>
                                        {p?.productID?.model || product?.model}
                                      </td>
                                      <td style={tableBorder()}>{p.qty}</td>

                                      <td style={tableBorder()}>
                                        {FormatNumber(p.unitPrice)}
                                        {t("tk")}
                                      </td>
                                      <td style={tableBorder()}>
                                        {FormatNumber(totalPrice)}
                                        {t("tk")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={tableBorder()}>
                                  <strong>{t("totalPrice")}</strong>
                                </td>
                                <td style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(invoice.totalPrice)} {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={tableBorder()} className="p-2">
                                  <strong>{t("discount")}</strong>
                                </td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(invoice.totalDiscount)}{" "}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>{t("grandTotal")}</strong>
                                </td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(invoice.grandTotal)} {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>{t("paid")}</strong>
                                </td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(
                                      invoice.grandTotal - invoice.totalDue
                                    )}
                                    {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>{t("due")}</strong>
                                </td>
                                <td className="p-2" style={tableBorder()}>
                                  <strong>
                                    {FormatNumber(invoice.totalDue)} {t("tk")}
                                  </strong>
                                </td>
                              </tr>
                              {invoice.dueDate && (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="p-2" style={tableBorder()}>
                                    <strong>{t("dueDate")}</strong>
                                  </td>
                                  <td className="p-2" style={tableBorder()}>
                                    <strong>
                                      {formatDate(invoice.dueDate)}
                                    </strong>
                                  </td>
                                </tr>
                              )}
                              {invoice.note && (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="p-2" style={tableBorder()}>
                                    <strong>{t("note")}</strong>
                                  </td>
                                  <td className="p-2" style={tableBorder()}>
                                    <strong>{invoice.note}</strong>
                                  </td>
                                </tr>
                              )}
                            </table>
                          </div>
                          <div className="font-weight-bold mt-4">
                            <span>
                              <strong>In Words:</strong>
                            </span>
                            <span style={{ marginLeft: ".3rem" }}>
                              {inWords(invoice?.grandTotal)}
                            </span>
                          </div>

                          <div className="mt-5 pt-3">
                            <div className="clearfix text-center">
                              <div
                                className="float-left"
                                style={{ width: 150 }}
                              >
                                <hr />
                                <span>{t("propriotorSignature")}</span>
                              </div>
                              <div
                                className="float-right"
                                style={{ width: 150 }}
                              >
                                <hr />
                                <span>{t("supplierSignature")}</span>
                              </div>
                            </div>
                            <hr />
                            <div className="pt-3 text-center">
                              <p>{props.store.tokenFooter}</p>
                            </div>
                            <div className="pt-3">
                              <small className="mb-0">
                                {" "}
                                <i>
                                  Powered By:&nbsp; Shunno Software &nbsp;
                                  <i
                                    style={{ fontSize: 10 }}
                                    className="fas fa-phone"
                                  ></i>{" "}
                                  01321141788
                                </i>
                              </small>
                            </div>
                          </div>
                        </div>
                      </PdfToPrint>
                      <div className="m-4 text-right">
                        <button
                          disabled={downloading}
                          onClick={downloadPdf}
                          className="btn btn-danger mr-2"
                        >
                          {t("downloadPdf")}
                        </button>
                        <ReactToPrint
                          trigger={() => (
                            <button type="button" className="btn btn-primary">
                              {t("printInvoice")}
                            </button>
                          )}
                          content={() => printRef}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{ minHeight: 230 }}
                      className="text-center d-flex justify-content-center align-items-center"
                    >
                      <div className="fa-3x">
                        <i
                          style={{ fontSize: 40 }}
                          className="fas fa-sync fa-spin"
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    invoices: state.purchaseInvoice.invoices,
    suppliers: state.supplier.suppliers,
    products: state.product.products,
    redirect: state.redirect.redirect
  };
};

export default connect(mapStateToProps, { stop })(PrintInvoice);

import axios from "axios";
import toastr from "toastr";
import $ from "jquery";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";
import store from "../store";
import {
  ADD_BANK,
  ADD_TRANSACTIONS,
  DELETE_TRANSACTIONS,
  GET_BANK,
  GET_TRANSACTIONS,
  UPDATE_BANK,
  UPDATE_TRANSACTIONS,
  DELETE_BANK
} from "./types";

export const getBanks = storeID => dispatch => {
  axios
    .get(`${SERVER_URL}/api/bank/get/` + storeID)
    .then(res => {
      dispatch({
        type: GET_BANK,
        payload: res.data.data
      });
    })
    .catch(error => {
      console.log(error);
      serverLoading(dispatch, false);
    });
};

export const addBank = data => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const res = await axios.post(`${SERVER_URL}/api/bank/add/`, data);
    dispatch({
      type: ADD_BANK,
      payload: res.data.data
    });

    $("#bankModal").modal("hide");
    toastr.success(res.data.msg);
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};
export const editBank = (data, bankID) => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const res = await axios.patch(`${SERVER_URL}/api/bank/` + bankID, data);
    dispatch({
      type: UPDATE_BANK,
      payload: res.data.data
    });

    $("#bankModal").modal("hide");
    toastr.success(res.data.msg);
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const deleteBank = bankID => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.delete(`${SERVER_URL}/api/bank/` + bankID);
    dispatch({
      type: DELETE_BANK,
      payload: data.data
    });
    $("#deleteModal").modal("hide");
    toastr.success(data.msg);
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const addTransaction = (data, bankID) => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const res = await axios.post(
      `${SERVER_URL}/api/bank/transactions/` + bankID,
      data
    );

    if (data.transactionType === "transfer") {
      store.dispatch(getBanks(data.proprietorID));
      store.dispatch(getTransactions(data.proprietorID));
    } else {
      dispatch({
        type: UPDATE_BANK,
        payload: res.data.data.bank
      });
      dispatch({
        type: ADD_TRANSACTIONS,
        payload: res.data.data.transaction
      });
    }

    $("#exampleModal").modal("hide");
    toastr.success(res.data.msg);
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};
export const editTransaction = (data, transactionID) => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const res = await axios.patch(
      `${SERVER_URL}/api/bank/transactions/` + transactionID,
      data
    );
    dispatch({
      type: UPDATE_TRANSACTIONS,
      payload: res.data.data.transaction
    });
    dispatch({
      type: UPDATE_BANK,
      payload: res.data.data.bank
    });
    $("#exampleModal").modal("hide");
    toastr.success(res.data.msg);
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};
export const deleteTransaction = transactionID => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.delete(
      `${SERVER_URL}/api/bank/transactions/` + transactionID
    );
    dispatch({
      type: DELETE_TRANSACTIONS,
      payload: data.data.transaction
    });
    dispatch({
      type: UPDATE_BANK,
      payload: data.data.bank
    });
    $("#deleteModal").modal("hide");
    toastr.success(data.msg);
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const getTransactions = storeID => dispatch => {
  axios
    .get(`${SERVER_URL}/api/bank/transactions/` + storeID)
    .then(res => {
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data.data
      });
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

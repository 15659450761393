import React, { Component } from "react";
import { connect } from "react-redux";
import { invoiceLogoAdd } from "../../../src/actions/invoiceActions";
import toastr from "toastr";
class InvoiceSettings extends Component {
  state = {
    store: {},
    files: "",
    img: "",
    imageURL: []
  };

  componentDidMount() {
    this.setState({
      store: this.props.store
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      store: nextProps.store
    });
  }

  uploadFileHandler = event => {
    const file = event.target.files[0];
    let files = event.target.files;
    const urls = [];
    for (let i = 0; i < files.length; i++) {
      urls.push(URL.createObjectURL(files[i]));
    }

    this.setState({
      ...this.state,
      files: file,
      imageURL: urls
    });
  };

  fileSubmitHandler = async event => {
    event.preventDefault();
    if (this.state.files) {
      const data = {
        storeId: this.state.store?._id,
        logo: this.state.files
      };

      this.props.invoiceLogoAdd(data);
    } else {
      // window.alert("Please Select Your Logo");
      toastr.error("Please Select Your Logo");
    }
  };

  render() {
    const { imageURL, store } = this.state;

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>Invoice Setting</h4>
            </div>
            <div className="px-2">
              <div className="row justify-content-center">
                <div className="col-12 p-0">
                  <div className="card p-3">
                    <div className="card-body p-0">
                      <>
                        <div className="clear-fix mt-3 p-3">
                          <h4>Invoice Logo</h4>
                          <hr />
                          <div className="mb-5 h5 font-weight-bolder">
                            <div className="row d-flex justify-content-center mt-4">
                              <div className="col-md-6">
                                <div className="card">
                                  <div className="card-block">
                                    <form
                                      onSubmit={e => e.preventDefault()}
                                      className="dropzone dz-clickable"
                                    >
                                      <div className="dz-default dz-message">
                                        <div>
                                          <label
                                            for="fileName"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Upload Your Selected File
                                            <div>
                                              <i
                                                className="fas fa-cloud-upload-alt "
                                                style={{ fontSize: "2rem" }}
                                              ></i>
                                            </div>
                                          </label>

                                          <input
                                            type="file"
                                            id="fileName"
                                            className="d-none"
                                            onChange={this.uploadFileHandler}
                                            accept="image/*"
                                            multiple
                                          />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                                          {imageURL.map(item => (
                                            <div className="border mx-2">
                                              <img
                                                src={item}
                                                alt=""
                                                width="100px"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <hr />
                                <div className="text-center m-t-20">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                    onClick={this.fileSubmitHandler}
                                    disabled={this.props.requestSend}
                                  >
                                    {this.props.requestSend && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                    {this.props.requestSend
                                      ? "Loading..."
                                      : "Upload"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              {store && (
                <div className="col-md-6 d-flex justify-content-beteween">
                  <div className="card bg-dark text-white">
                    <img
                      className="card-img"
                      src={store.logo?.src}
                      height="150px"
                      width="150px"
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    requestSend: state.server.requestSend
  };
};

export default connect(mapStateToProps, {
  invoiceLogoAdd
})(InvoiceSettings);

import { ADMIN_LOGIN } from "../actions/types";

const initialState = {
  isLoggedIn: false,
  admin: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        admin: action.payload
      };

    default:
      return state;
  }
};

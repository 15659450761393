import { REDIRECT, STOP } from "../actions/types";

const initialState = { redirect: false, data: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case REDIRECT:
      return {
        ...state,
        redirect: true,
        data: action.payload
      };
    case STOP:
      return initialState;
    default:
      return initialState;
  }
};

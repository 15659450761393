import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { t } from "../../../HNLanguage/i18nextSetUp";
import { formatDate, FormatNumber } from "../../../utils/utils";
import CustomComponent from "../../../components/common/TableCustomComponent";
import ReactDatePicker from "react-datepicker";
import dateFilter from "../../../utils/dateRangeFilter";
import ReactToPrint from "react-to-print";
import PdfToPrint from "../../OutputComponents/PdfToPrint";
import dateOutOfRangeFilter from "../../../utils/dateOutOfRangeFilter";

const { SearchBar } = Search;

class SupplierLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierID: this.props.match.params.id,
      supplierLedger: this.props.supplierLedger,
      supplier: {},
      singleSupplierLedger: [],
      purchaseInvoices: [],
      startDate: new Date(),
      endDate: new Date()
    };
    this.printSuppLedgerRef = React.createRef();
  }
  componentDidMount = () => {
    const { suppliers, supplierLedger, purchaseInvoices } = this.props;
    const singleSupplierLedger = supplierLedger.filter(
      item => item.supplier === this.state.supplierID
    );

    this.setState({
      ...this.state,
      supplier: suppliers.find(sup => sup._id == this.state.supplierID),
      singleSupplierLedger,
      purchaseInvoices
    });
  };

  componentWillReceiveProps(nextProps) {
    const supplierLedger = nextProps.supplierLedger;
    const purchaseInvoices = nextProps.purchaseInvoices;
    const suppliers = nextProps.suppliers;
    const supplierID = nextProps.match.params.id;
    const singleSupplierLedger = supplierLedger.filter(
      item => item.supplier === this.state.supplierID
    );

    this.setState({
      ...this.state,
      supplierLedger,
      supplier: suppliers.find(sup => sup._id == supplierID),
      singleSupplierLedger,
      purchaseInvoices
    });
  }

  render() {
    let {
      supplier,
      singleSupplierLedger,
      purchaseInvoices,
      startDate,
      endDate
    } = this.state;

    if (singleSupplierLedger.length > 0) {
      let day;
      let latestEntry;
      const wholeLedger = [...singleSupplierLedger]
      if (startDate) {
        day = new Date(startDate);
      }
      else {
        day = new Date();
      }
      if (startDate && endDate) {
        singleSupplierLedger = dateFilter(
          singleSupplierLedger,
          startDate,
          endDate,
          "ledgerAt",
          true
        );
      } else if (startDate) {
        singleSupplierLedger = dateFilter(
          singleSupplierLedger,
          startDate,
          endDate,
          "ledgerAt",
          false
        );
      }
      const firstDayOfMonth = new Date(day.getFullYear(), day.getMonth(), 1)
      const previousEntries = dateOutOfRangeFilter(wholeLedger, new Date(firstDayOfMonth), new Date(firstDayOfMonth), "ledgerAt", false)
      if (previousEntries.length > 0) {
        latestEntry = { ...previousEntries[previousEntries.length - 1] };
        latestEntry.particular = "prevMonthBalance"
        latestEntry.ledgerAt = new Date(firstDayOfMonth).toISOString()
        latestEntry.debit = 0;
        latestEntry.credit = 0;
        singleSupplierLedger = [latestEntry, ...singleSupplierLedger]
      }

    }

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    let columns = [
      {
        dataField: "createdAt",
        text: t("date"),
        formatter: data => formatDate(data)
      },
      {
        dataField: "memo",
        text: t("ref"),
        formatter: cell => {
          return cell ? cell : "N/A";
        }
      },

      {
        dataField: "particular",
        text: t("particulars"),
        formatter: (cell, row) => {
          if (
            row.particular === "dueDecrement" ||
            row.particular === "dueIncrement"
          ) {
            return `${t(cell)} (${row?.adjustmentAmount})`;
          } else {
            return t(cell);
          }
        }
      },
      {
        dataField: "debit",
        text: t("debit"),
        formatter: data => {
          return FormatNumber(data);
        }
      },
      {
        dataField: "credit",
        text: t("credit"),
        formatter: data => {
          return FormatNumber(data);
        }
      },
      {
        dataField: "balance",
        text: t("balance"),
        formatter: data => {
          return FormatNumber(data);
        }
      }
    ];

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-3 pb-3 mb-4 bg-white text-center border-rounded ">
              <div className="d-flex">
                <Link className="btn btn-primary text-center" to={`/supplier`}>
                  <i
                    className="fas fa-arrow-alt-circle-left  mt-2 "
                    style={{ fontSize: "1.13rem" }}
                  ></i>
                </Link>

                <h4 style={{ marginLeft: "20rem", marginTop: ".3rem" }}>
                  {t("ledger")}( {supplier && supplier.name} )
                </h4>
              </div>

              <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2"
                    selected={startDate}
                    onChange={date =>
                      this.setState({
                        ...this.state,
                        startDate: date
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                  />
                </div>
                <div>-</div>
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2 "
                    selected={endDate}
                    onChange={date =>
                      this.setState({
                        ...this.state,
                        endDate: date
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    maxDate={new Date()}
                  />
                </div>

                <button
                  className="btn  btn-info mt-2"
                  style={{ marginLeft: "-.4rem" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date()
                    });
                  }}
                >
                  {t("daily")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: firstDay,
                      endDate: new Date()
                    });
                  }}
                >
                  {t("monthly")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: "",
                      endDate: ""
                    });
                  }}
                >
                  {t("reset")}
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button
                      title={t("exportPDF")}
                      type="button"
                      className="btn btn-info ml-2 mt-2"
                    >
                      <i className="fas fa-file-pdf"></i>
                    </button>
                  )}
                  content={() => this.printSuppLedgerRef}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="card">
                  {/* <div className="card-header"></div> */}
                  <div className="card-body p-0">
                    <>
                      {/* pdf to print */}
                      <div style={{ display: "none" }}>
                        <PdfToPrint ref={el => (this.printSuppLedgerRef = el)}>
                          <div style={{ minHeight: 842 }} className="bg-white">
                            <div className="p-3">
                              <h3 className="text-center">
                                {this.props.store?.storeName}
                              </h3>
                              <div className="clearfix py-3">
                                <h5 className="float-left">
                                  {t("supplierLedger")} - {supplier?.name}
                                </h5>
                                <span className="float-right">
                                  {new Date().toDateString()}{" "}
                                  {new Date().toLocaleTimeString()}
                                </span>
                              </div>
                            </div>
                            <table className="table table-bordered table-striped">
                              <tr>
                                <th scope="col">{t("date")}</th>
                                <th scope="col">{t("ref")}</th>
                                <th scope="col">{t("particulars")}</th>
                                <th scope="col">{t("debit")}</th>
                                <th scope="col">{t("credit")}</th>
                                <th scope="col">{t("balance")}</th>
                              </tr>

                              <tbody>
                                {singleSupplierLedger &&
                                  singleSupplierLedger.map(
                                    (ledger, iLedger) => {
                                      return (
                                        <tr key={iLedger}>
                                          <td>
                                            {formatDate(ledger?.createdAt)}
                                          </td>
                                          <td>{ledger?.memo}</td>
                                          <td>{ledger?.particular}</td>
                                          <td>{ledger?.debit} {t("tk")}</td>
                                          <td>{ledger?.credit} {t("tk")}</td>
                                          {iLedger + 1 ===
                                            singleSupplierLedger?.length ? (
                                            <td>
                                              <strong>{ledger?.balance}</strong>
                                            </td>
                                          ) : (
                                            <td>{ledger?.balance} {t("tk")}</td>
                                          )}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </PdfToPrint>
                      </div>

                      <div
                        style={{ minHeight: 230 }}
                        className="table-responsive p-3"
                      >
                        <PaginationProvider
                          pagination={paginationFactory({
                            sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
                          })}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              bootstrap4
                              keyField="id"
                              data={singleSupplierLedger}
                              columns={columns}
                              search={{ searchFormatted: true }}
                            >
                              {props => (
                                <div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    {/* <CustomComponent
                                        data={customHeaderData}
                                      /> */}
                                    <SearchBar {...props.searchProps} />
                                  </div>
                                  <hr />
                                  <BootstrapTable
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                    {...paginationProps}
                                    filter={filterFactory()}
                                    filterPosition="top"
                                    wrapperClasses="table-responsive"
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    suppliers: state.supplier.suppliers,
    supplierLedger: state.ledger.supplierLedger,
    purchaseInvoices: state.purchaseInvoice.invoices
  };
};

export default connect(mapStateToProps)(SupplierLedger);

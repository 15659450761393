import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { t } from "../../../src/HNLanguage/i18nextSetUp";
import { formatDate, FormatNumber } from "../../../src/utils/utils";
// import CustomComponent from "../../../components/common/TableCustomComponent";
import ReactDatePicker from "react-datepicker";
// import dateFilter from "../../utils/dateRangeFilter";

const { SearchBar } = Search;

class EmiReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allKisti: [],
      startDate: "",
      endDate: "",
      customerId: this.props.match.params.id
    };
  }

  componentDidMount() {
    const customerId = this.props.match.params.id;

    const filteredSingleCustomerKisti = this.props.allKisti.filter(
      kisti => kisti?.customerID?._id === customerId
    );
    this.setState({
      ...this.state,
      allKisti: filteredSingleCustomerKisti
    });
  }

  componentWillReceiveProps(nextProps) {
    const customerID = nextProps.match.params.id;
    if (nextProps && customerID) {
      this.setState({
        ...this.state,
        allKisti: nextProps.allKisti.filter(
          kisti => kisti?.customerID?._id === customerID
        )
      });
    }
  }

  render() {
    let { allKisti, startDate, endDate, customerId } = this.state;
    let { customers, requestSend } = this.props;

    //calculation total

    // monthly filter date here
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    let columns = [
      {
        dataField: "memo",
        text: t("memo")
      },
      {
        dataField: "totalPrice",
        text: t("price"),
        sort: true,
        formatter: data => FormatNumber(data)
      },

      {
        dataField: "totalDiscount",
        text: t("discount"),
        sort: true,
        formatter: data => FormatNumber(data)
      },

      {
        dataField: "kisti.totalDueWithInterest",
        text: t("due"),
        sort: true,
        formatter: cell => FormatNumber(cell)
      },
      {
        dataField: "soldAt",
        text: t("date"),
        formatter: data => formatDate(data)
      },
      {
        dataField: "_id",
        text: t("exportPDF"),
        formatter: (cell, data) => {
          return (
            <div className="sell_report_action d-flex">
              <ul className="navbar-nav navbar-right">
                <li className="dropdown">
                  <a
                    href="#"
                    data-toggle="dropdown"
                    className="nav-link nav-link-lg"
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    {data.type == "installment" && (
                      <Link
                        to={`/customer/${data.customerID}/emi-invoice/${cell}`}
                        className="dropdown-item has-icon"
                      >
                        <i className="fas fa-print"></i>
                        {t("installment")}
                      </Link>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          );
        }
      }
    ];

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-3 pb-3 mb-4 bg-white text-center border-rounded ">
              <div className="d-flex">
                <Link className="btn btn-primary text-center" to={`/customer`}>
                  <i
                    className="fas fa-arrow-alt-circle-left  mt-2 "
                    style={{ fontSize: "1.13rem" }}
                  ></i>
                </Link>
                <h4 style={{ marginLeft: "18rem", marginTop: ".3rem" }}>
                  {t("Emi Report")} ({" "}
                  {customers.length > 0 &&
                    customers.find(c => c._id === customerId).name}{" "}
                  )
                </h4>
              </div>

              <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2"
                    selected={startDate}
                    onChange={date =>
                      this.setState({
                        ...this.state,
                        startDate: date
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                  />
                </div>
                <div>-</div>
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2 "
                    selected={endDate}
                    onChange={date =>
                      this.setState({
                        ...this.state,
                        endDate: date
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    maxDate={new Date()}
                  />
                </div>

                <button
                  className="btn  btn-info mt-2"
                  style={{ marginLeft: "-.4rem" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date()
                    });
                  }}
                >
                  {t("daily")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: firstDay,
                      endDate: new Date()
                    });
                  }}
                >
                  {t("monthly")}
                </button>
                <button
                  className="btn btn-warning ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: "",
                      endDate: ""
                    });
                  }}
                >
                  {t("reset")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="card">
                  {/* <div className="card-header"></div> */}
                  <div className="card-body p-0">
                    {requestSend ? (
                      <div
                        style={{ minHeight: 230 }}
                        className="text-center d-flex justify-content-center align-items-center"
                      >
                        <div className="fa-3x">
                          <i
                            style={{ fontSize: 40 }}
                            className="fas fa-sync fa-spin"
                          ></i>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{ minHeight: 230 }}
                          className="table-responsive p-3"
                        >
                          <PaginationProvider
                            pagination={paginationFactory({
                              sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
                            })}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                bootstrap4
                                keyField="id"
                                data={allKisti}
                                columns={columns}
                                search={{ searchFormatted: true }}
                              >
                                {props => (
                                  <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <SearchBar {...props.searchProps} />
                                    </div>
                                    <hr />
                                    <BootstrapTable
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                      {...paginationProps}
                                      filter={filterFactory()}
                                      filterPosition="top"
                                      wrapperClasses="table-responsive"
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    allKisti: state.allKisti.allKisti,
    customers: state.customer.customers,
    requestSend: state.server.requestSend
  };
};

export default connect(mapStateToProps)(EmiReport);

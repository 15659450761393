import React from "react";
import "./paymentResponse.css";

const PaymentSuccess = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 mx-auto mt-5">
          <div className="payment">
            <div className="payment_header bg-success">
              <div className="check">
                <i className="fa fa-check" aria-hidden="true"></i>
              </div>
            </div>
            <div className="content">
              <h1>Payment Success !</h1>
              <p>হিসাবনিকাশের সাথে থাকার জন্য আপনাকে ধন্যবাদ </p>
              <a className="bg-success" href="/">
                Go to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { logout } from "../../../actions/authActions";
import { GET_STORE } from "../../../actions/types";
import store from "../../../store";
import { getCurrentUser } from "../../../utils/utils";

export class UserDropdown extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    store.dispatch(logout());
  }
  onChangeStore = e => {
    const findStore = store.getState().store.stores.find(item => {
      return item._id === e.target.value;
    });
    if (findStore?._id) {
      store.dispatch({
        type: GET_STORE,
        payload: findStore
      });
    }
    window.location.reload();
  };

  render() {
    document.querySelector('.storeDropdown')?.addEventListener('click', function (event) {
      event.stopPropagation();
    });
    const stores = store.getState().store;
    const singleStore = store.getState().store.store;
    const isAdmin = store.getState().admin.isLoggedIn;
    const { userDetail } = this.props;
    const { role, storeID } = getCurrentUser();
    return (
      <li className="dropdown">
        <a
          href="#"
          data-toggle="dropdown"
          className="nav-link dropdown-toggle nav-link-lg nav-link-user"
        >
          <img
            alt="image"
            src={userDetail.userImg}
            className="rounded-circle mr-1"
          />
          <div className="d-sm-none d-lg-inline-block">
            {userDetail.userName}
          </div>
        </a>
        <div className="dropdown-menu dropdown-menu-right">

          {
            stores?.stores?.length > 1 &&
            <select
              className="storeDropdown dropdown-item font-weight-bold d-block d-md-none"
              style={{
                backgroundColor: "transparent",
                fontSize: "0.8rem",
              }}
              onChange={this.onChangeStore}
              disabled={role !== "proprietor"}
              value={singleStore?._id}
            >
              {stores.stores.map(item => (
                <option key={item._id} value={item._id}>
                  {item.storeName}
                </option>
              ))}
            </select>}

          {!isAdmin &&
            userDetail.data.map((item, i) => {
              if (item.access)
                return (
                  <NavLink
                    key={i}
                    to={item.link}
                    activeStyle={{
                      color: "#6777ef"
                    }}
                    exact
                    className="dropdown-item has-icon"
                  >
                    <i className={item.icon} /> {item.title}
                  </NavLink>
                );
            })

          }

          <div className="dropdown-divider" />
          <a
            href="#"
            className="dropdown-item has-icon text-danger"
            onClick={this.onLogoutClick.bind(this)}
          // onClick={() => {
          //   Auth.logout(() => {
          //     window.location.reload();
          //   });
          // }}
          >
            <i className={userDetail.logoutIcon} /> {userDetail.logoutTitle}
          </a>
        </div>
      </li>
    );
  }
}

export default UserDropdown;
import axios from "axios";
import toastr from "toastr";

import {
  ADD_INVOICE,
  GET_INVOICES_OF_A_STORE,
  CLEAR_CART,
  REDIRECT,
  UPDATE_CUSTOMER,
  UPDATE_LENDEN,
  INCREASE_CASH,
  GET_QUICK_SELL_INVOICE,
  UPDATE_STOCK,
  ADD_CUSTOMER_PAYMENTS,
  GET_CUSTOMER_LEDGER_INVOICE,
  INVOICE_LOGO_ADD,
  DELETE_INVOICE,
  ADD_CUSTOMER_CART_PAYMENTS
} from "./types";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

export const addInvoice = (invoiceData, agreeEMI) => async dispatch => {
  let soldItems = [];
  let item;
  for (let i = 0; i < invoiceData.soldItems.length; i++) {
    item = {
      qty: invoiceData.soldItems[i].amounts,
      productID: invoiceData.soldItems[i]._id,
      unitPrice: invoiceData.soldItems[i].sellPrice,
      totalPurchasePrice: invoiceData.soldItems[i].purchasePrice,
      discount: invoiceData.soldItems[i].discount
    };
    soldItems.push(item);
    item = "";
  }
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/invoice/create`,
      invoiceData
    );
    if (data.status) {
      let { invoice, customer, lenden, payment, ledger } = data.data;

      dispatch({
        type: ADD_INVOICE,
        payload: { ...invoice, soldItems: [...soldItems] }
      });
      if (agreeEMI === "yes") {
        document.getElementById("printKistiFrom").click();
      }

      dispatch({
        type: UPDATE_CUSTOMER,
        payload: customer
      });
      dispatch({
        type: UPDATE_LENDEN,
        payload: lenden
      });
      dispatch({
        type: INCREASE_CASH,
        payload: lenden.amount
      });
      dispatch({
        type: UPDATE_STOCK,
        payload: soldItems
      });
      dispatch({
        type: CLEAR_CART,
        payload: null
      });

      dispatch({
        type: REDIRECT,
        payload: {
          customerID: customer._id,
          invoiceID: invoice._id
        }
      });
      dispatch({
        type: ADD_CUSTOMER_CART_PAYMENTS,
        payload: payment
      });

      dispatch({
        type: GET_CUSTOMER_LEDGER_INVOICE,
        payload: ledger
      });

      toastr.success(data.msg);
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
  } finally {
    serverLoading(dispatch, false);
  }
};

export const getInvoices = storeId => dispatch => {
  serverLoading(dispatch, true);
  axios
    .get(`${SERVER_URL}/api/invoice/get/store/${storeId}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status) {
        dispatch({
          type: GET_INVOICES_OF_A_STORE,
          payload: data.data
        });
      } else {
        dispatch({
          type: GET_INVOICES_OF_A_STORE,
          payload: []
        });
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};
export const getQuickSellInvoices = storeID => dispatch => {
  serverLoading(dispatch, true);
  axios
    .get(`${SERVER_URL}/api/invoice/quickSell/${storeID}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status) {
        dispatch({
          type: GET_QUICK_SELL_INVOICE,
          payload: data.data
        });
      } else {
        dispatch({
          type: GET_INVOICES_OF_A_STORE,
          payload: []
        });
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

export const invoiceLogoAdd = data => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const formData = new FormData();
    // for (let i = 0; i < data.files?.length; i++) {
    //   formData.append("logo", data.files[i]);
    // }
    formData.append("logo", data.logo);

    const res = await axios({
      method: "put",
      url: `${SERVER_URL}/api/store/upload/logo/${data.storeId}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });

    serverLoading(dispatch, false);
    toastr.success("লোগো আপলোড সম্পন্ন হয়েছে।");
    dispatch({
      type: INVOICE_LOGO_ADD,
      payload: res.data.data
    });
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }

  serverLoading(dispatch, false);
};

// export const deleteInvoice = deleteData => dispatch => {
//   serverLoading(dispatch, true);
//   axios
//     .get(`${SERVER_URL}/api/invoice/delete`)
//     .then(({ data }) => {
//       serverLoading(dispatch, false);
//       toastr.success("ইনভোয়েস ডিলিট সম্পন্ন হয়েছে।");
//       if (data.status) {
//         dispatch({
//           type: DELETE_INVOICE,
//           payload: data.data
//         });
//       }
//     })
//     .catch(error => {
//       serverLoading(dispatch, false);
//       toastr.error(error?.response?.data?.message);
//     });
// };

import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restrictedRoute from "./routePermission";
import SSInvoiceModal from "../../pages/Dashboard/modals/invoiceModal";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  const [showModal, setShowModal] = useState(false);
  const [invoiceFlag, setInvoiceFlag] = useState("");
  const { routePermission } = restrictedRoute();
  const pathName = rest.path;
  const accessRoute = routePermission[auth.user.type];

  useEffect(() => {
    if (rest?.ssInvoice && Object.keys(rest?.ssInvoice).length > 0) {
      if (new Date(rest?.ssInvoice?.dueDate).getTime() < new Date().getTime()) {
        setInvoiceFlag("EXPIRED");
      } else {
        setInvoiceFlag("UNPAID");
      }
    }
  }, [rest?.ssInvoice]);

  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated === true) {
          if (!accessRoute.includes(pathName)) {
            return (
              <>
                {invoiceFlag === "EXPIRED" && (
                  <>
                    {/* <div className="main-sidebar"></div> */}
                    <div className="main-content">
                      <section class="section">
                        <div class="row mb-4 mx-1">
                          <div class="d-flex align-items-center flex-column p-3 bg-danger w-100 text-white">
                            <div class="mb-1 pt-1 pb-1 h4">
                              হিসাবনিকাশ মাসিক বিল {rest?.ssInvoice?.amount} Tk
                              পরিশোধের শেষ সময়{" "}
                              {moment(rest?.ssInvoice?.dueDate).format(
                                "DD-MM-YYYY"
                              )}
                            </div>
                            <button
                              type="button"
                              class="btn btn-success text"
                              onClick={() => setShowModal(true)}
                            >
                              Make Payment
                            </button>
                          </div>
                        </div>
                      </section>
                    </div>
                    {/* shunno it payment modal  */}
                    <SSInvoiceModal
                      ssInvoice={rest?.ssInvoice}
                      invoiceFlag={invoiceFlag}
                      isInvoiceModalOpen={showModal}
                    />
                  </>
                )}

                <Component {...props} />
              </>
            );
          }
          return <Redirect to="/not-authorized" />;
        } else {
          return <Redirect to="/auth/login" />;
        }
      }}
    />
  );
};
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  store: state.store,
  auth: state.auth,
  ssInvoice: state.ssInvoice.ssInvoice
});

export default connect(mapStateToProps)(PrivateRoute);

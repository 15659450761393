import {
  GET_CUSTOMER_LEDGER,
  GET_SUPPLIER_LEDGER,
  GET_CUSTOMER_LEDGER_INVOICE,
  GET_SUPPLIER_LEDGER_INVOICE,
  GET_CUSTOMER_PAYMENT_LEDGER,
  GET_SUPPLIER_PAYMENT_LEDGER,
  GET_CUSTOMER_EMI_LEDGER
} from "../actions/types";

const initialState = {
  supplierLedger: [],
  customerLedger: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_LEDGER:
      return {
        ...state,
        customerLedger: state.customerLedger.concat(action.payload)
      };
    case GET_CUSTOMER_LEDGER_INVOICE:
      return {
        ...state,
        customerLedger: state.customerLedger.concat(action.payload)
      };
    case GET_CUSTOMER_PAYMENT_LEDGER:
      return {
        ...state,
        customerLedger: state.customerLedger.concat(action.payload)
      };
    case GET_SUPPLIER_LEDGER:
      return {
        ...state,
        supplierLedger: state.supplierLedger.concat(action.payload)
      };
    case GET_SUPPLIER_LEDGER_INVOICE:
      return {
        ...state,
        supplierLedger: state.supplierLedger.concat(action.payload)
      };

    case GET_SUPPLIER_PAYMENT_LEDGER:
      return {
        ...state,
        supplierLedger: state.supplierLedger.concat(action.payload)
      };
    case GET_CUSTOMER_EMI_LEDGER:
      return {
        ...state,
        customerLedger: [...state.customerLedger, action.payload]
      };

    default:
      return state;
  }
};

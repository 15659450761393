import {
  GET_INSTALLMENT_BY_INVOICE,
  // DEPOSITE_KISTI,
  LOADING,
  GET_ERRORS
} from "../actions/types";

const initialState = {
  kisti: [],
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true
      };
    case GET_INSTALLMENT_BY_INVOICE:
      return {
        ...state,
        kisti: action.payload
      };
    // case DEPOSITE_KISTI:
    //   const index = state.kisti.findIndex(
    //     item => item._id === action.payload._id
    //   );
    //   let updated = [
    //     ...state.kisti.slice(0, index),
    //     action.payload,
    //     ...state.kisti.slice(index + 1)
    //   ];
    //   return {
    //     ...state,
    //     kisti: updated,
    //     isLoading: false
    //   };

    case GET_ERRORS:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

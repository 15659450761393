/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import UserDropdown from "./UserDropdown";
// import Auth from '../../../../config/auth';
// import { getStore } from "../../../actions/storeActions";
import avatar from "./avatar-1.png";

import store from "../../../store";
import i18n, { t } from "../../../HNLanguage/i18nextSetUp";
import { GET_STORE } from "../../../actions/types";
import { getCurrentUser, getPermissions } from "../../../utils/utils";
class Header extends Component {
  constructor() {
    super();
    this.state = {
      language: "bn",
      userDetail: {
        userName: "",
        userImg: avatar,
        logoutIcon: "fas fa-sign-out-alt",
        logoutTitle: t("logout")
      }
    };
  }

  componentDidMount() {
    const reduxStore = store.getState();
    if (!reduxStore.auth.isAuthenticated && !reduxStore.admin.isLoggedIn) {
      window.location.href = "/auth/login";
    }

    let ud = { ...this.state.userDetail };
    if (this.props.store) {
      ud.userName = this.props.store.storeName;
      this.setState({
        userDetail: ud
      });
    }
  }

  componentWillReceiveProps = nextProps => {
    let ud = { ...this.state.userDetail };
    if (nextProps.store) {
      ud.userName = nextProps.store.storeName;
      this.setState({
        userDetail: ud
      });
    }
  };

  languageController = e => {
    this.setState({ ...this.state, language: e.target.value });
    i18n.changeLanguage(e.target.value);
    window.location.reload();
  };

  componentDidMount() {
    const getLanguage = localStorage.getItem("i18nextLng") || "bn";
    this.setState({
      ...this.state,
      language: getLanguage
    });
  }

  onChangeStore = e => {
    const findStore = store.getState().store.stores.find(item => {
      return item._id === e.target.value;
    });
    if (findStore?._id) {
      store.dispatch({
        type: GET_STORE,
        payload: findStore
      });
    }
    window.location.reload();
  };

  render() {
    let cart = this.props.cart;
    const isAdmin = store.getState().admin.isLoggedIn;
    const stores = store.getState().store;
    const singleStore = store.getState().store.store;
    const { role, storeID } = getCurrentUser();
    const data = [
      {
        link: "/profile",
        icon: "far fa-user",
        title: t("profile") + "" + t("update"), //for bangla and english language,
        access: true
      },
      {
        link: "/store",
        icon: "fas fa-shopping-basket",
        title: t("store") + "" + t("update"),
        access:
          getCurrentUser().role === "proprietor" || getPermissions().manageStore
      },
      {
        link: "/purchase",
        icon: "fas fa-cart-plus",
        title: t("product") + "" + t("purchase"),
        access:
          getCurrentUser().role === "proprietor" ||
          getPermissions().purchaseProduct
      },

      {
        link: "/shunno-it-invoice",
        icon: "fas fa-file-invoice",
        title: t("invoice"),
        access:
          getCurrentUser().role === "proprietor" ||
          getPermissions().purchaseProduct
      },

      {
        link: "/password",
        icon: "fas fa-cog",
        title: t("changePassword"),
        access: true
      }
    ];

    return (
      <div className="w-100">
        <div className="navbar-bg" />
        <nav className="navbar navbar-expand-lg main-navbar p-0 overflow-x-hidden">
          <form className="form-inline mr-auto">
            <ul className="navbar-nav ml-3">
              <li>
                <a
                  href="#"
                  data-toggle="sidebar"
                  className="nav-link nav-link-lg"
                >
                  <i className="fas fa-bars" />
                </a>
              </li>

              {/* <li>
              <a
                href="#"
                data-toggle="search"
                className="nav-link nav-link-lg d-sm-none"
              >
                <i className="fas fa-search" />
              </a>
            </li> */}
            </ul>
            {/* <Search searchResultData={searchResultData} /> */}
          </form>
          {!isAdmin && (
            <div
              className="d-flex align-items-center"
              style={{ position: "relative", marginRight: "20px" }}
            >
              <select
                className="border-none p-1 d-none d-md-block"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "0.8rem"
                }}
                onChange={this.onChangeStore}
                disabled={role !== "proprietor"}
                value={singleStore?._id}
              >
                {stores.stores.map(item => (
                  <option key={item._id} value={item._id}>
                    {item.storeName}
                  </option>
                ))}
              </select>
              <select
                onChange={this.languageController}
                className="mr-2"
                style={{
                  border: "none",
                  fontSize: "15px",
                  backgroundColor: "transparent"
                }}
                value={this.state.language}
              >
                <option value="bn">বাং</option>
                <option value="en">EN</option>
              </select>
              <NavLink to="/cart">
                <i
                  style={{ fontSize: 25, color: "#fff" }}
                  className="fas fa-shopping-cart"
                ></i>
              </NavLink>
              {cart.numberOfProducts > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    padding: "1px",
                    background: "red",
                    color: "#fff",
                    borderRadius: "50%",
                    minWidth: "20px",
                    textAlign: "center",
                    fontSize: "12px"
                  }}
                >
                  {cart.numberOfProducts}
                </span>
              )}
            </div>
          )}

          <ul className="navbar-nav navbar-right">
            <UserDropdown userDetail={{ ...this.state.userDetail, data }} />
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    store: state.store.store
  };
};

export default connect(mapStateToProps)(Header);

import React, { Component } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { formatDate, FormatNumber } from "../../../utils/utils";
import { t } from "../../../HNLanguage/i18nextSetUp";
import dateFilter from "../../../utils/dateRangeFilter";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import PdfToPrint from "../../OutputComponents/PdfToPrint";
import CustomComponent from "../../../components/common/TableCustomComponent";

const { SearchBar } = Search;

class CustomerPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      customers: [],
      invoices: [],
      quickSellInvoices: [],
      startDate: new Date(),
      endDate: new Date()
    };
    this.printRef = React.createRef();
  }
  getCustomerName = id => {
    const { customers } = this.state;
    return customers?.find(cus => cus._id === id)?.name;
  };
  componentDidMount = () => {
    if (this.props.payments?.length > 0) {
      this.setState({
        ...this.state,
        payments: this.props.payments,
        customers: this.props.customers,
        invoices: this.props.invoices,
        quickSellInvoices: this.props.quickSellInvoices
      });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.payments?.length > 0) {
      this.setState({
        ...this.state,
        payments: nextProps.payments,
        customers: nextProps.customers,
        invoices: nextProps.invoices,
        quickSellInvoices: nextProps.quickSellInvoices
      });
    }
  }
  render() {
    let {
      startDate,
      endDate,
      customers,
      payments,
      invoices,
      quickSellInvoices
    } = this.state;
    let allInvoices = [...invoices, ...quickSellInvoices];
    let { store } = this.props;

    // console.log(payments.map(pay => allInvoices.find(inv => inv._id === pay.customerInvoice[0])))

    // monthly filter date here
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    // date filter
    if (payments.length > 0) {
      if (startDate && endDate) {
        payments = dateFilter(payments, startDate, endDate, "createdAt", true);
      } else if (startDate) {
        payments = dateFilter(payments, startDate, endDate, "createdAt", false);
      }
    }
    // table column
    let columns = [
      {
        dataField: "_id",
        text: t("memo"),
        formatExtraData: allInvoices,
        formatter: (row, cell) => {
          if (cell.customerInvoice?.length) {
            let singleInvoice = allInvoices?.find(
              inv => inv._id === cell.customerInvoice[0]
            );
            return singleInvoice?.memo;
          } else {
            return "N/A";
          }
        }
      },
      {
        dataField: "_id",
        text: t("name"),
        formatExtraData: allInvoices,
        formatter: (row, cell) => {
          console.log(cell);
          let singleInvoice = allInvoices?.find(
            inv => inv._id === cell.customerInvoice[0]
          );
          return singleInvoice?.quickSellCustomer
            ? singleInvoice?.quickSellCustomer?.name
            : this.getCustomerName(cell?.customer);
        }
      },

      {
        dataField: "_id",
        text: t("amount"),
        formatter: (row, cell) => {
          return cell?.amount;
        }
      },
      {
        dataField: "_id",
        text: t("due"),
        formatter: (row, cell) => {
          return cell?.due;
        }
      },
      {
        dataField: "_id",
        text: t("type"),
        formatter: (row, cell) => {
          return cell?.type;
        }
      },

      {
        dataField: "createdAt",
        text: t("date"),
        formatter: cell => <div>{formatDate(cell)}</div>
      },
      {
        dataField: "_id",
        text: t("option"),
        formatExtraData: payments,
        formatter: (row, cell, _, payments) => {
          const payInv = payments.find(payment => payment._id === row);
          console.log(payInv);

          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown d-inline">
                <a href="#" data-toggle="dropdown" className="px-2">
                  {payInv.type === "customerPayment" ? (
                    <i className="fas fa-ellipsis-v"></i>
                  ) : (
                    <i className="fas fa-ellipsis-v d-none"></i>
                  )}
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link
                    to={`/payment/${payInv?.customerInvoice[0]}/invoice/${row}`}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-print"></i>
                    {t("printInvoice")}
                  </Link>
                </div>
              </div>
            </div>
          );
        }
      }
    ];

    const totalAmount = payments.reduce((acc, curr) => {
      acc += curr.amount;
      return acc;
    }, 0);
    const totalDue = payments.reduce((acc, curr) => {
      acc += curr.due;
      return acc;
    }, 0);

    const customColumns = [
      {
        title: t("totalAmount"),
        value: FormatNumber(totalAmount)
      },
      {
        title: t("totalDue"),
        value: FormatNumber(totalDue)
      }
    ];

    payments?.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    return (
      <>
        <div style={{ display: "none" }}>
          <PdfToPrint ref={el => (this.printRef = el)}>
            <div style={{ minHeight: 842 }} className="bg-white">
              <div className="p-3">
                <h3 className="text-center">{store?.storeName}</h3>
                <div className="clearfix py-3">
                  <h5 className="float-left">{t("customerPayment")}</h5>
                  <span className="float-right">
                    {new Date().toDateString()}{" "}
                    {new Date().toLocaleTimeString()}
                  </span>
                </div>
                <br />
                <div>
                  <h6>
                    {t("totalAmount")} : {FormatNumber(totalAmount)}
                  </h6>
                  <h6>
                    {t("totalDue")} : {FormatNumber(totalDue)}
                  </h6>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <tr>
                  <th scope="col">{t("memo")}</th>
                  <th scope="col">{t("name")}</th>
                  <th scope="col">{t("amount")}</th>
                  <th scope="col">{t("due")}</th>
                  <th scope="col">{t("date")}</th>
                </tr>
                <tbody>
                  {payments &&
                    payments.map((pay, iPay) => {
                      return (
                        <tr key={iPay}>
                          <td>
                            {
                              allInvoices?.find(
                                inv => inv._id === pay?.customerInvoice[0]
                              )?.memo
                            }
                          </td>
                          <td>
                            {allInvoices?.find(
                              inv => inv._id === pay?.customerInvoice[0]
                            )?.quickSellCustomer
                              ? allInvoices?.find(
                                  inv => inv._id === pay?.customerInvoice[0]
                                )?.quickSellCustomer?.name
                              : this.getCustomerName(pay?.customer)}
                          </td>
                          <td>
                            {pay?.amount} {t("tk")}
                          </td>
                          <td>
                            {pay?.due} {t("tk")}
                          </td>
                          <td>{formatDate(pay?.createdAt)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </PdfToPrint>
        </div>
        <div className="main-content">
          <section className="section">
            <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>{t("customerPayment")}</h4>

              <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                  <ReactDatePicker
                    style={{ height: "2.4rem" }}
                    className="form-control w-140 mt-2"
                    selected={startDate}
                    onChange={date =>
                      this.setState({ ...this.state, startDate: date })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    height="1rem"
                  />
                </div>
                <div>-</div>
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2"
                    selected={endDate}
                    onChange={date =>
                      this.setState({ ...this.state, endDate: date })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    maxDate={new Date()}
                  />
                </div>

                <button
                  className="btn  btn-info mt-2"
                  style={{ marginLeft: "-.4rem" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date()
                    });
                  }}
                >
                  {t("daily")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: firstDay,
                      endDate: new Date()
                    });
                  }}
                >
                  {t("monthly")}
                </button>
                <button
                  className="btn btn-warning ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date()
                    });
                  }}
                >
                  {t("reset")}
                </button>
              </div>
            </div>
            <div className="px-2 bg-white">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div style={{ minHeight: 230 }} className="py-3">
                    {this.props.requestSend ? (
                      <div className="fa-3x text-center">
                        <i
                          style={{ fontSize: 40 }}
                          className="fas fa-sync fa-spin"
                        ></i>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
                        })}
                      >
                        {({ paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={payments}
                            columns={columns}
                            search
                          >
                            {props => (
                              <>
                                <div className="row">
                                  <div className="col-6">
                                    <CustomComponent data={customColumns} />
                                  </div>
                                  <div className="col-6 text-right">
                                    <SearchBar {...props.searchProps} />
                                    <ReactToPrint
                                      trigger={() => (
                                        <button
                                          type="button"
                                          className="btn btn-primary mt-2"
                                        >
                                          <i className="fas fa-file-pdf"></i>
                                        </button>
                                      )}
                                      content={() => this.printRef}
                                    />
                                  </div>
                                </div>
                                <hr />
                                <BootstrapTable
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                  wrapperClasses="table-responsive"
                                />
                              </>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    payments: state.payments.customerPayments,
    customers: state.customer.customers,
    invoices: state.invoice.invoices,
    quickSellInvoices: state.invoice.quickSellInvoices,
    store: state.store.store
  };
};

export default connect(mapStateToProps)(CustomerPayment);

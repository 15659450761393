import axios from "axios";
import $ from "jquery";
import toastr from "toastr";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

import {
  GET_LENDENS_STORE,
  ADD_LENDEN,
  DELETE_LENDEN,
  GET_ERRORS,
  GET_CASH,
  INCREASE_CASH,
  DECREASE_CASH
} from "./types";

export const getStoreLendens = storeID => dispatch => {
  axios
    .get(`${SERVER_URL}/api/lenden/get/store/${storeID}`)
    .then(({ data }) => {
      if (data.status == true) {
        dispatch({
          type: GET_LENDENS_STORE,
          payload: data.data
        });

        const lendens = data.data;

        const debits = lendens.filter(lenden => lenden.type === "debit");
        const credits = lendens.filter(lenden => lenden.type === "credit");
        let totalDebit = 0;
        let totalCredit = 0;

        debits.forEach(debit => {
          totalDebit += debit.amount;
        });

        credits.forEach(credit => {
          totalCredit += credit.amount;
        });

        dispatch({
          type: GET_CASH,
          payload: totalCredit - totalDebit
        });
      } else {
        // if any error occured
        if (data.msg) {
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      toastr.error(error?.response?.data?.message);
    });
};

export const getProprietorLendens = () => dispatch => {
  serverLoading(dispatch, true);
};

export const addLenden = ({ amount, type, ...rest }) => dispatch => {
  serverLoading(dispatch, true);
  axios
    .post(`${SERVER_URL}/api/lenden/create`, {
      amount: Number(amount),
      type,
      ...rest
    })
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#exampleModal").modal("hide");
        dispatch({
          type: ADD_LENDEN,
          payload: data.data
        });

        if (type === "credit") {
          dispatch({
            type: INCREASE_CASH,
            payload: Number(amount)
          });
        } else {
          dispatch({
            type: DECREASE_CASH,
            payload: Number(amount)
          });
        }
        toastr.success("লেনদেন যোগ সম্পন্ন হয়েছে।");
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

export const deleteLenden = id => dispatch => {
  serverLoading(dispatch, true);
  axios
    .delete(`${SERVER_URL}/api/lenden/delete/${id}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#deleteModal").modal("hide");
        dispatch({
          type: DELETE_LENDEN,
          payload: id
        });
        toastr.success("লেনদেন ডিলিট সম্পন্ন হয়েছে।");
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import { register } from "../../../actions/authActions";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      mobile: "",
      password: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // this.props.history.push("/payment") // stop showing payment warning
      this.props.history.push("/");

      $(".needs-validation").submit(function(event) {
        let form = $(this);
        if (form[0].checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.addClass("was-validated");
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      // this.props.history.push("/payment") // stop showing payment warning
      this.props.history.push("/");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      mobile: this.state.mobile,
      password: this.state.password
    };

    this.props.register(userData);
  }

  render() {
    const { errors } = this.state;
    return (
      <div id="app">
        <section className="section">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="register-brand">
                  <img
                    src="../assets/img/hn.png"
                    alt="logo"
                    width="100"
                    className="shadow-light rounded-circle"
                  />
                </div>

                <div className="card card-danger">
                  <div className="card-header">
                    <h3>হিসাব নিকাশ</h3>
                  </div>

                  <div className="card-body">
                    <form
                      // method="POST"
                      // action="#"
                      noValidate
                      className="needs-validation"
                      onSubmit={this.onSubmit}
                    >
                      <div className="form-group">
                        <label htmlFor="name">এডমিন / প্রোপ্রাইটরের নাম</label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.name)
                          })}
                          id="name"
                          type="text"
                          name="name"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.name}
                          onChange={this.onChange}
                        />
                        {errors.name && (
                          <div className="invalid-feedback text-right">
                            {errors.name}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="fatherName">
                          এডমিন / প্রোপ্রাইটরের পিতার নাম
                        </label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.fatherName)
                          })}
                          id="fatherName"
                          type="text"
                          name="fatherName"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.fatherName}
                          onChange={this.onChange}
                        />
                        {errors.fatherName && (
                          <div className="invalid-feedback text-right">
                            {errors.fatherName}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="mobile">
                          এডমিন / প্রোপ্রাইটরের মোবাইল নম্বর{" "}
                        </label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.mobile)
                          })}
                          id="mobile"
                          type="text"
                          name="mobile"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.mobile}
                          onChange={this.onChange}
                        />
                        {errors.mobile && (
                          <div className="invalid-feedback text-right">
                            {errors.mobile}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="address">ঠিকানা</label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.address)
                          })}
                          id="address"
                          type="text"
                          name="address"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.address}
                          onChange={this.onChange}
                        />
                        {errors.address && (
                          <div className="invalid-feedback text-right">
                            {errors.address}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="nid">জাতীয় পরিচয়পত্রের নম্বর</label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.nid)
                          })}
                          id="nid"
                          type="text"
                          name="nid"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.nid}
                          onChange={this.onChange}
                        />
                        {errors.nid && (
                          <div className="invalid-feedback text-right">
                            {errors.nid}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="email">ইমেল</label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.email)
                          })}
                          id="email"
                          type="email"
                          name="email"
                          tabIndex="1"
                          autoFocus
                          value={this.state.email}
                          onChange={this.onChange}
                        />
                        {errors.email && (
                          <div className="invalid-feedback text-right">
                            {errors.email}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="refName">রেফারেন্সের নাম</label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.refName)
                          })}
                          id="refName"
                          type="text"
                          name="refName"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.refName}
                          onChange={this.onChange}
                        />
                        {errors.refName && (
                          <div className="invalid-feedback text-right">
                            {errors.refName}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="refMobile">
                          রেফারেন্সের মোবাইল নম্বর
                        </label>
                        <input
                          className={classnames("form-control", {
                            "is-invalid": !isEmpty(errors.refMobile)
                          })}
                          id="refMobile"
                          type="text"
                          name="refMobile"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.refMobile}
                          onChange={this.onChange}
                        />
                        {errors.refMobile && (
                          <div className="invalid-feedback text-right">
                            {errors.refMobile}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-danger btn-lg btn-block"
                          tabIndex="4"
                        >
                          লগিন
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="mt-5 text-muted text-center">
                  Don't have an account?{" "}
                  <Link to={"/auth/register"}>Create One</Link>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { register })(Register);

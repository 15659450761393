//External Lib Import
import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
//Internal Lib Import
// import resizeFile from "../../utils/resizeFile";
import Icon from "../../src/icon/Icon";

const FormInput = ({
  name,
  type,
  label,
  placeholder,
  isAlter,
  size,
  children,
  onChange,
  defaultValue,
  options,
  optionLabel
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [defaultValueSelect, setDefaultValueSelect] = useState(defaultValue);
  const [file, setFileName] = useState(false);

  // const FileHandleChange = async (e, setFieldValue) => {
  //   setFieldValue(name, await resizeFile(e.target?.files?.[0]));
  //   onChange(await resizeFile(e.target?.files?.[0]));
  //   setFileName(e.target?.files?.[0].name);
  // };

  useEffect(() => {
    setDefaultValueSelect(defaultValue);
  }, [defaultValue]);

  if (type === "checkbox") {
    return (
      <FormGroup className={isAlter && "is-alter"}>
        <Field name={name}>
          {({ field, form: { touched, errors }, meta }) => (
            <div
              className={`custom-control custom-checkbox custom-control-${size}`}
            >
              <input
                className={
                  touched[name] && errors[name]
                    ? `custom-control-input error`
                    : `custom-control-input`
                }
                type={type}
                id={name}
                placeholder={placeholder}
                {...field}
                checked={field?.value}
              />

              {label ? (
                <Label htmlFor={name} className="custom-control-label">
                  {label}
                </Label>
              ) : null}

              <ErrorMessage name={name}>
                {msg => <span className="invalid">{msg}</span>}
              </ErrorMessage>
            </div>
          )}
        </Field>
      </FormGroup>
    );
  } else if (type === "textarea") {
    return (
      <FormGroup className={isAlter && "is-alter"}>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}

        <Field name={name}>
          {({ field, form: { touched, errors }, meta }) => (
            <div className="form-control-wrap">
              <textarea
                className={
                  touched[name] && errors[name]
                    ? `form-control error form-control-${size}`
                    : `form-control form-control-${size}`
                }
                type={type}
                id={name}
                placeholder={placeholder}
                {...field}
              />
              <ErrorMessage name={name}>
                {msg => <span className="invalid">{msg}</span>}
              </ErrorMessage>
            </div>
          )}
        </Field>
      </FormGroup>
    );
  } else if (type === "file") {
    return (
      <FormGroup className={isAlter && "is-alter"}>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}

        <Field name={name}>
          {({ field, form: { touched, errors, setFieldValue }, meta }) => (
            <div className="form-control-wrap">
              <div className="custom-file">
                <input
                  multiple
                  className={
                    touched[name] && errors[name]
                      ? `custom-file-input form-control error form-control-${size}`
                      : `custom-file-input form-control form-control-${size}`
                  }
                  id={name}
                  type={type}
                  placeholder={placeholder}
                  // onChange={e => FileHandleChange(e, setFieldValue)}
                />
                <Label className="custom-file-label" htmlFor="customFile">
                  {file === false ? "Choose file" : file}
                </Label>
                <ErrorMessage name={name}>
                  {msg => <span className="invalid">{msg}</span>}
                </ErrorMessage>
              </div>
            </div>
          )}
        </Field>
      </FormGroup>
    );
  } else if (type === "react-phone") {
    return (
      <FormGroup className={isAlter && "is-alter"}>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}

        <Field name={name}>
          {({ field, form: { touched, errors, setFieldValue }, meta }) => (
            <div className="form-control-wrap">
              <PhoneInput
                value={defaultValue}
                onChange={phone => setFieldValue(name, phone)}
                className={
                  touched[name] && errors[name] ? `error w-100` : `w-100`
                }
                type={type}
                id={name}
                placeholder={placeholder}
                // onlyCountries={['bd', 'in']}
                country="bd"
              />

              <ErrorMessage name={name}>
                {msg => (
                  <span className="invalid-feedback text-danger">{msg}</span>
                )}
              </ErrorMessage>
            </div>
          )}
        </Field>
      </FormGroup>
    );
  } else if (type === "simple-rich-edior") {
    return (
      <FormGroup className={isAlter && "is-alter"}>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}

        <Field name={name}>
          {({
            field,
            form: { touched, errors, setFieldValue, values },
            meta
          }) => (
            <div className="form-control-wrap">
              <ReactQuill
                value={values?.[name]}
                onChange={text => setFieldValue(name, text)}
              />

              <ErrorMessage name={name}>
                {msg => <span className="invalid-feedback">{msg}</span>}
              </ErrorMessage>
            </div>
          )}
        </Field>
      </FormGroup>
    );
  } else if (type === "react-single-select") {
    return (
      <FormGroup>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}
        <Field>
          {({ field, form: { touched, errors, setFieldValue, values } }) => (
            <>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={options}
                onChange={option => setFieldValue(name, option.value)}
                defaultValue={defaultValue}
              />

              <ErrorMessage name={name}>
                {msg => <span className="invalid">{msg}</span>}
              </ErrorMessage>
            </>
          )}
        </Field>
      </FormGroup>
    );
  } else if (type === "react-multiple-select") {
    return (
      <FormGroup>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}
        <Field>
          {({ field, form: { touched, errors, setFieldValue, values } }) => (
            <>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={[
                  { value: "chocolate", label: "Chocolate" },
                  { value: "strawberry", label: "Strawberry" },
                  { value: "vanilla", label: "Vanilla" }
                ]}
                onChange={option =>
                  setFieldValue(
                    name,
                    option.map(i => i.value)
                  )
                }
                isMulti={true}
              />

              <ErrorMessage name={name}>
                {msg => <span className="invalid">{msg}</span>}
              </ErrorMessage>
            </>
          )}
        </Field>
      </FormGroup>
    );
  } else if (type === "password") {
    return (
      <FormGroup className={isAlter && "is-alter"}>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}

        <Field name={name}>
          {({ field, form: { touched, errors }, meta }) => (
            <div className="form-control-wrap">
              <a
                href="#password"
                onClick={ev => {
                  ev.preventDefault();
                  setShowPassword(!showPassword);
                }}
                className={`${size} form-icon  form-icon-right passcode-switch ${
                  showPassword ? "is-hidden" : "is-shown"
                }`}
              >
                <Icon name="eye" className="passcode-icon icon-show"></Icon>
                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
              </a>
              <input
                type={showPassword ? "text" : "password"}
                id={name}
                placeholder={placeholder}
                className={
                  touched[name] && errors[name]
                    ? `form-control error form-control-${size}`
                    : `form-control form-control-${size}`
                }
                {...field}
              />

              <ErrorMessage name={name}>
                {msg => <span className="invalid">{msg}</span>}
              </ErrorMessage>
            </div>
          )}
        </Field>
      </FormGroup>
    );
  } else if (type === "select") {
    return (
      <FormGroup className={isAlter && "is-alter"}>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}

        <Field name={name} as="select">
          {({ field, form: { touched, errors }, meta }) => (
            <div className="form-control-wrap">
              <Field name={name} as="select" className="form-control">
                <option value="" label={optionLabel}>
                  {optionLabel}
                </option>
                {options.map((item, i) => (
                  <option key={i} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Field>

              <ErrorMessage name={name}>
                {msg => <span className="invalid text-danger">{msg}</span>}
              </ErrorMessage>
            </div>
          )}
        </Field>
      </FormGroup>
    );
  } else {
    return (
      <FormGroup className={isAlter && "is-alter"}>
        {label ? (
          <Label htmlFor={name} className="form-label">
            {label}
          </Label>
        ) : null}

        <Field name={name}>
          {({ field, form: { touched, errors }, meta }) => (
            <div className="form-control-wrap">
              <input
                className={
                  touched[name] && errors[name]
                    ? `form-control error form-control-${size}`
                    : `form-control form-control-${size}`
                }
                type={type}
                id={name}
                placeholder={placeholder}
                {...field}
              />
              <ErrorMessage name={name}>
                {msg => <span className="invalid text-danger">{msg}</span>}
              </ErrorMessage>
            </div>
          )}
        </Field>
      </FormGroup>
    );
  }
};

export default FormInput;

import axios from "axios";
import toastr from "toastr";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

import {
  GET_PROFILE,
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
  GET_ERRORS
} from "./types";

// Get current profile
export const getCurrentProfile = () => dispatch => {
  dispatch(setProfileLoading());
  axios
    .get(`${SERVER_URL}/api/profile`)
    .then(res => {
      dispatch({
        type: GET_PROFILE,
        payload: res.data.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PROFILE,
        payload: {}
      });
    });
};

export const updateProfile = data => dispatch => {
  axios
    .put(`${SERVER_URL}/api/profile/`, data)
    .then(res => {
      serverLoading(dispatch, false);
      if (res.data.status) {
        toastr.success("প্রোফাইল পরিবর্তন সফল হয়েছে");
        dispatch({
          type: GET_PROFILE,
          payload: res.data.data
        });
      } else {
        toastr.error("দুঃখিত, আবার চেষ্টা করুন।");
        dispatch({
          type: GET_ERRORS,
          payload: {}
        });
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);

      dispatch({
        type: GET_ERRORS,
        payload: {}
      });
    });
};

// Update password
export const updatePassword = passwordData => dispatch => {
  serverLoading(dispatch, true);
  // dispatch(setProfileLoading())
  axios
    .put(`${SERVER_URL}/api/profile/updatePassword`, passwordData)
    .then(res => {
      serverLoading(dispatch, false);
      if (res.data.status) {
        toastr.success("পাসওয়ার্ড পরিবর্তন সফল হয়েছে");
        // dispatch({
        //   type: GET_PROFILE,
        //   payload: res.data.data,
        // })
      } else {
        toastr.error("দুঃখিত, আবার চেষ্টা করুন।");
        dispatch({
          type: GET_ERRORS,
          payload: {}
        });
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);

      dispatch({
        type: GET_ERRORS,
        payload: {}
      });
    });
};

// Profile loading
export const setProfileLoading = () => {
  return {
    type: PROFILE_LOADING
  };
};

// Clear profile
export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};

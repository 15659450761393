import {
  ADD_BANK,
  GET_BANK,
  UPDATE_BANK,
  DELETE_BANK,
  GET_TRANSACTIONS,
  ADD_TRANSACTIONS,
  UPDATE_TRANSACTIONS,
  DELETE_TRANSACTIONS
} from "../actions/types";

const initialState = {
  banks: [],
  transactions: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_BANK:
      return {
        ...state,
        banks: action.payload
      };
    case ADD_BANK:
      return {
        ...state,
        banks: [...state.banks, action.payload]
      };
    case UPDATE_BANK:
      let index = state.banks.findIndex(
        bank => bank._id === action.payload._id
      );
      let updated = [
        ...state.banks.slice(0, index),
        action.payload,
        ...state.banks.slice(index + 1)
      ];
      return {
        ...state,
        banks: updated
      };
    case DELETE_BANK:
      return {
        ...state,
        banks: state.banks.filter(bank => bank._id != action.payload._id)
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload
      };
    case ADD_TRANSACTIONS:
      return {
        ...state,
        transactions: [...state.transactions, action.payload]
      };
    case UPDATE_TRANSACTIONS:
      let transactionIndex = state.transactions.findIndex(
        bank => bank._id === action.payload._id
      );
      let updatedTransactions = [
        ...state.transactions.slice(0, transactionIndex),
        action.payload,
        ...state.transactions.slice(transactionIndex + 1)
      ];
      return {
        ...state,
        transactions: updatedTransactions
      };
    case DELETE_TRANSACTIONS:
      return {
        ...state,
        transactions: state.transactions.filter(
          transaction => transaction._id !== action.payload._id
        )
      };
    default:
      return state;
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { formatDate } from "../../../utils/utils";
import { t } from "../../../HNLanguage/i18nextSetUp";
import { getMessageLog } from "../../../actions/smsAction";
import NoDataFound from "../../Pages/Errors/NoDataFound";

class NonMasking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageLogs: [],

      type: "",
      status: "",
      filteredMessageLogs: [],
      isLoaded: true
    };
  }
  componentDidMount = () => {
    let filteredMessageLogs = this.props.messageLogs.filter(
      log => log.sendBy == "non_masking"
    );
    this.setState({
      ...this.state,
      isLoaded: this.props.isLoaded,
      messageLogs: this.props.messageLogs,
      filteredMessageLogs
    });
  };
  componentWillReceiveProps(nextProps) {
    let filteredMessageLogs = nextProps.messageLogs.filter(
      log => log.sendBy == "non_masking"
    );
    this.setState({
      ...this.state,
      isLoaded: nextProps.isLoaded,
      messageLogs: nextProps.messageLogs,
      filteredMessageLogs
    });
  }

  resetFilter = () => {
    this.setState({
      ...this.state,
      type: "",
      status: ""
    });
  };

  render() {
    let { filteredMessageLogs, type, status, isLoaded } = this.state;

    // monthly filter date here
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    filteredMessageLogs = filteredMessageLogs?.filter(item => {
      let isPass = true;
      if (type) {
        isPass = item.type == type;
      }
      if (status) {
        isPass = item.status == status;
      }

      return isPass;
    });

    // table column
    let columns = [
      {
        dataField: "_id",
        text: t("#"),
        formatter: (row, cell, index) => {
          return index + 1;
        }
      },
      {
        dataField: "_id",
        text: t("mobile"),
        formatter: (row, cell) => {
          return cell?.mobile;
        }
      },
      {
        dataField: "_id",
        text: t("status"),
        formatter: (row, cell) => {
          return cell?.status;
        }
      },
      {
        dataField: "_id",
        text: t("type"),
        formatter: (row, cell) => {
          return cell?.type;
        }
      },
      {
        dataField: "_id",
        text: t("count"),
        formatter: (row, cell) => {
          return cell?.count;
        }
      },
      {
        dataField: "_id",
        text: t("sendingType"),
        formatter: (row, cell) => {
          return cell?.sendBy;
        }
      },
      {
        dataField: "_id",
        text: t("createAt"),
        formatter: (row, cell) => {
          return formatDate(cell?.createdAt);
        }
      },
      {
        dataField: "_id",
        text: t("message"),
        formatter: (row, cell) => {
          return cell?.message;
        }
      }
    ];

    return (
      <>
        <section className="section">
          <div className="form-group d-flex align-items-center mb-2 mt-2">
            <div className="row">
              <div className="col-12">
                <select
                  className="form-control  mr-1"
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      type: e.target.value
                    })
                  }
                  value={type}
                >
                  <option value="" selected>
                    {t("type")}
                  </option>

                  <option value="bill">{t("bill")}</option>
                  <option value="bulk">{t("bulk")}</option>
                  <option value="other">{t("other")}</option>
                </select>
              </div>
            </div>
            <div className="mx-2">
              <select
                className="form-control  mr-1"
                onChange={e =>
                  this.setState({
                    ...this.state,
                    status: e.target.value
                  })
                }
                value={status}
              >
                <option value="" selected>
                  {t("selectStatus")}
                </option>

                <option value="sent">{t("send")}</option>
                <option value="pending">{t("pending")}</option>
                <option value="failed">{t("failed")}</option>
              </select>
            </div>

            <div className="ml-2">
              <button
                onClick={this.resetFilter}
                className="btn btn-warning  mt-1 "
              >
                Reset
              </button>
            </div>
          </div>

          {isLoaded ? (
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {filteredMessageLogs.length > 0 ? (
                <>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div style={{ minHeight: 230 }} className="py-3">
                        {this.props.requestSend ? (
                          <div className="fa-3x text-center">
                            <i
                              style={{ fontSize: 40 }}
                              className="fas fa-sync fa-spin"
                            ></i>
                          </div>
                        ) : (
                          <PaginationProvider
                            pagination={paginationFactory({
                              sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
                            })}
                          >
                            {({ paginationTableProps }) => (
                              <ToolkitProvider
                                bootstrap4
                                keyField="id"
                                data={filteredMessageLogs}
                                columns={columns}
                                search
                              >
                                {props => (
                                  <>
                                    {/* <div>
                                  <SearchBar {...props.searchProps} />
                                </div>
                                <hr /> */}
                                    <BootstrapTable
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                      wrapperClasses="table-responsive"
                                    />
                                  </>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <NoDataFound />
              )}
            </>
          )}
        </section>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoaded: state.messages.isLoaded,
    messageLogs: state.messages.messageLogs
  };
};

export default connect(mapStateToProps, {
  getMessageLog
})(NonMasking);

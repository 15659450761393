import { t } from "../../HNLanguage/i18nextSetUp";
const proprietorFormData = [
  {
    type: "text",
    name: "name",
    placeholder: t("name"),
    label: t("name"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "fatherName",
    placeholder: t("fatherName"),
    label: t("fatherName"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "email",
    placeholder: t("email"),
    label: t("email"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "mobile",
    placeholder: t("mobile"),
    label: t("mobile"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "address",
    placeholder: t("address"),
    label: t("address"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "nid",
    placeholder: t("nid"),
    label: t("nid"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "refName",
    placeholder: t("refName"),
    label: t("refName"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "refMobile",
    placeholder: t("refMobile"),
    label: t("refMobile"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "refNid",
    placeholder: t("refNID"),
    label: t("refNID"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "refAddress",
    placeholder: t("refAddress"),
    label: t("refAddress"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "refRelation",
    placeholder: t("refRelation"),
    label: t("refRelation"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "remarks",
    placeholder: t("remarks"),
    label: t("remarks"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  }
];

export default proprietorFormData;

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import storeReducer from "./storeReducer";
import supplierReducer from "./supplierReducer";
import productReducer from "./productReducer";
import customerReducer from "./customerReducer";
import employeeReducer from "./employeeReducer";
import cartReducer from "./cartReducer";
import invoiceReducer from "./invoiceReducer";
import redirectReducer from "./redirectReducer";
import serverReducer from "./serverReducer";
import expenditureReducer from "./expenditureReducer";
import lendenReducer from "./lendenReducer";
import purchaseCartReducer from "./purchaseCartReducer";
import purchaseInvoiceReducer from "./purchaseInvoiceReducer";
import adminReducer from "./adminReducer";
import cashBoxReducer from "./cashBoxReducer";
import kistiReducers from "./kistiReducers";
import allKistiReducer from "./allKistiReducer";
import proprietorReducer from "./proprietorReducer";
import bankReducer from "./bankReducer";
import managerReducer from "./managerReducer";
import salesManReducer from "./salesManReducer";
import permissionsReducer from "./permissionsReducer";
import stockReducer from "./stockReducer";
import soldProductsReducer from "./soldProductsReducer";
import ssInvoiceReducer from "./ssInvoiceReducer";
import categorySubCategoryReducer from "./category-subCategoryReducer";
import orderReducer from "./orderReducer";
import homeSlider from "./homeSliderReducer";
import siteInfo from "./ecSiteInfoReducer";
import paymentsReducer from "./paymentsReducer";
import ledgerReducer from "./ledgerReducer";
import smsReducer from "./smsReducer";

export default combineReducers({
  admin: adminReducer,
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  store: storeReducer,
  supplier: supplierReducer,
  product: productReducer,
  customer: customerReducer,
  employee: employeeReducer,
  cart: cartReducer,
  invoice: invoiceReducer,
  redirect: redirectReducer,
  server: serverReducer,
  expenditure: expenditureReducer,
  lenden: lendenReducer,
  cashBox: cashBoxReducer,
  purchaseCart: purchaseCartReducer,
  purchaseInvoice: purchaseInvoiceReducer,
  kisti: kistiReducers,
  allKisti: allKistiReducer,
  proprietor: proprietorReducer,
  banks: bankReducer,
  managers: managerReducer,
  salesMans: salesManReducer,
  permissions: permissionsReducer,
  stock: stockReducer,
  soldProducts: soldProductsReducer,
  ssInvoice: ssInvoiceReducer,
  category: categorySubCategoryReducer,
  orders: orderReducer,
  homeSlider: homeSlider,
  siteInfo: siteInfo,
  payments: paymentsReducer,
  ledger: ledgerReducer,
  messages: smsReducer
});

export default function CustomComponent({ data }) {
  return (
    <div className="d-flex flex-column">
      {data &&
        data.map((item, index) => (
          <div key={index} style={{ fontSize: "1rem rem" }}>
            {item.title}: {item.value}
            &nbsp;
          </div>
        ))}
    </div>
  );
}

export const LOADING = "LOADING";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PERMISSIONS = "GET_PERMISSIONS";

export const GET_STORES = "GET_STORES";
export const GET_STORE = "GET_STORE";
export const UPDATE_STORE = "UPDATE_STORE";

// action types for suppliers
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const PRODUCT_BULK_IMPORT = "PRODUCT_BULK_IMPORT";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const SUPPLIER_PAYMENT = "SUPPLIER_PAYMENT";
export const EDIT_SUPPLIER_DUE = "EDIT_SUPPLIER_DUE";
export const ADD_SUPPLIER_QUICK_PAYMENTS = "ADD_SUPPLIER_QUICK_PAYMENTS";

// action types for customers
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const SYNC_CUSTOMER = "SYNC_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const CUSTOMER_PAYMENT = "CUSTOMER_PAYMENT";
export const EDIT_CUSTOMER_DUE = "EDIT_CUSTOMER_DUE";

// action types for products
export const GET_PRODUCTS = "GET_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const BULK_UPDATE_PRODUCT = "BULK_UPDATE_PRODUCT";
export const STOCK_BULK_UPDATE = "STOCK_BULK_UPDATE";
export const STOCK_HISTORY_BULK_UPDATE = "STOCK_HISTORY_BULK_UPDATE";

// action types for Staff
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const PAY_SALARY = "PAY_SALARY";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";

// action types for Manager
export const GET_MANAGERS = "GET_MANAGERS";
export const ADD_MANAGER = "ADD_MANAGER";
export const UPDATE_MANAGER = "UPDATE_MANAGER";
export const DELETE_MANAGER = "DELETE_MANAGER";

// action types for SalesMan
export const GET_SALESMANS = "GET_SALESMANS";
export const ADD_SALESMAN = "ADD_SALESMAN";
export const UPDATE_SALESMAN = "UPDATE_SALESMAN";
export const DELETE_SALESMAN = "DELETE_SALESMAN";

// action types for Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const INCREASE_UNIT = "INCREASE_UNIT";
export const DECREASE_UNIT = "DECREASE_UNIT";
export const SET_UNIT = "SET_UNIT";
export const CLEAR_CART = "CLEAR_CART";
export const LOAD_CART_FROM_LOCALSTORAGE = "LOAD_CART_FROM_LOCALSTORAGE";
export const SELL_PRICE_UPDATE = "SELL_PRICE_UPDATE";
export const INDIVIDUAL_PRODUCT_DISCOUNT = "INDIVIDUAL_PRODUCT_DISCOUNT";

// actions types for Invoice
export const ADD_INVOICE = "ADD_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const GET_INVOICES_OF_A_CUSTOMER = "GET_INVOICES_OF_CUSTOMER";
export const GET_INVOICES_OF_A_STORE = "GET_INVOICES_OF_STORE";
export const GET_QUICK_SELL_INVOICE = "GET_QUICK_SELL_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";

export const REDIRECT = "REDIRECT";
export const STOP = "STOP";

// action types for Category
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

// action types for subCategory
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY";
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";
export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY";

//  actions types for expenditurePurpose
export const ADD_EXPENDITURE_PURPOSE = "ADD_EXPENDITURE_PURPOSE";
export const UPDATE_EXPENDITURE_PURPOSE = "UPDATE_EXPENDITURE_PURPOSE";
export const DELETE_EXPENDITURE_PURPOSE = "DELTE_EXPENDITURE_PURPOSE";

// action Types for Units
export const ADD_UNIT = "ADD_UNIT";
export const UPDATE_UNIT = "UPDATE_UNIT";
export const DELETE_UNIT = "DELETE_UNIT";

// action Types for Manufacturings
export const ADD_MANUFACTURER = "ADD_MANUFACTURER";
export const UPDATE_MANUFACTURER = "UPDATE_MANUFACTURER";
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER";

// action types for server
export const SEND_REQUEST = "SEND_REQUEST";
export const RESPONSE_SUCCESS = "RESPONSE_SUCCESS";
export const RESPONSE_FAILD = "RESPONSE_FAILD";
export const RESET = "RESET";

// action types for Expenditures
export const GET_EXPENDITURES = "GET_EXPENDITURES";
export const ADD_EXPENDITURE = "ADD_EXPENDITURE";
export const UPDATE_EXPENDITURE = "UPDATE_EXPENDITURE";
export const DELETE_EXPENDITURE = "DELETE_EXPENDITURE";

// action types for Lendens
export const GET_LENDENS_STORE = "GET_LENDEN_STORE";
export const GET_LENDENS_PROPRIETOR = "GET_LENDENS_PROPRIETOR";
export const ADD_LENDEN = "ADD_LENDEN";
export const UPDATE_LENDEN = "UPDATE_LENDEN";
export const DELETE_LENDEN = "DELETE_LENDEN";

// action types for Purchase Cat
export const ADD_TO_PURCHASE_CART = "ADD_TO_PURCHASE_CART";
export const REMOVE_FROM_PURCHASE_CART = "REMOVE_FROM_PURCHASE_CART";
export const INCREASE_PURCHASE_UNIT = "INCREASE_PURCHASE_UNIT";
export const DECREASE_PURCHASE_UNIT = "DECREASE_PURCHASE_UNIT";
export const CLEAR_PURCHASE_CART = "CLEAR_PURCHASE_CART";
export const LOAD_PURCHASE_CART_FROM_LOCALSTORAGE =
  "LOAD_PURCHASE_CART_FROM_LOCALSTORAGE";
export const SELECT_SUPPLIER = "SELECT_SUPPLIER";
export const SET_PURCHASE_UNIT = "SET_PURCHASE_UNIT";
export const SET_PURCHASE_UNIT_PRICE = "SET_PURCHASE_UNIT_PRICE";
// actions types for Supplier Invoices
export const ADD_SUPPLIER_INVOICE = "ADD_SUPPLIER_INVOICE";
export const GET_INVOICES_OF_A_SUPPLIER = "GET_INVOICES_OF_SUPPLIER";
export const GET_PURCHASE_INVOICES = "GET_PURCHASE_INVOICES";
export const UPDATE_PURCHASE_INVOICE = "UPDATE_PURCHASE_INVOICE";
export const DELETE_PURCHASE_INVOICE = "DELETE_PURCHASE_INVOICE";

// actions types for Admin
export const ADMIN_LOGIN = "ADMIN_LOGIN";

// action types for cashBox
export const GET_CASH = "GET_CASH_BOX";
export const INCREASE_CASH = "INCREASE_CASH";
export const DECREASE_CASH = "DECREASE_CASH";

//kisti action type
export const GET_INSTALLMENT_BY_CUSTOMER = "GET_ALL_INSTALLMENT_BY_CUSTOMER";
export const DEPOSITE_INSTALLMENT = "DEPOSITE_INSTALLMENT";
export const GET_INSTALLMENT_BY_INVOICE = "GET_INSTALLMENT_BY_INVOICE";
export const GET_ALL_INSTALLMENT = "ALL_INSTALLMENT";

//warranty action
export const ADD_WARRANTY = "ADD_WARRANTY";
export const GET_WARRANTY = "GET_WARRANTY";
export const UPDATE_WARRANTY = "WARRANTY_UPDATE";
export const DELETE_WARRANTY = "DELETE_WARRANTY";

//admin proprietor type
export const ADD_PROPRIETOR = "ADD_PROPRIETOR";
export const GET_PROPRIETORS = "GET_PROPRIETORS";
export const UPDATE_PROPRIETOR = "UPDATE_PROPRIETOR";
export const DELETE_PROPROETOR = "DELETE_PROPROETOR";
export const GET_ADMIN_STORES = "GET_ADMIN_STORES";
export const PROPRIETOR_STORE_UPDATE = "PROPRIETOR_STORE_UPDATE";
//bank statement
export const GET_BANK = "GET_BANK";
export const ADD_BANK = "ADD_BANK";
export const UPDATE_BANK = "UPDATE_BANK";
export const DELETE_BANK = "DELETE_BANK";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const ADD_TRANSACTIONS = "ADD_TRANSACTIONS";
export const UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS";
export const DELETE_TRANSACTIONS = "DELETE_TRANSACTIONS";

//stock type

export const GET_STOCKS = "GET_STOCKS";
export const GET_STOCKS_HISTORY = "GET_STOCKS_HISTORY";

//get sold PRODUCTS

export const GET_SOLD_PRODUCTS = "GET_SOLD_PRODUCTS";

//shunno software invoices
export const GET_SS_INVOICE = "GET_SS_INVOICE";

export const GET_SS_LIST_INVOICE = "GET_SS_LIST_INVOICE";
export const ADD_SS_LIST_INVOICE = "ADD_SS_LIST_INVOICE";
export const GET_SS_DETAILS_INVOICE = "GET_SS_DETAILS_INVOICE";
export const UPDATE_SS_INVOICE = "UPDATE_SS_INVOICE";
export const DELETE_SS_INVOICE = "DELETE_SS_INVOICE";
export const INVOICE_LOGO_ADD = "INVOICE_LOGO_ADD";
export const GET_ALL_SS_INVOICE = "GET_ALL_SS_INVOICE";
export const UPDATE_ADMIN_INVOICE = "UPDATE_ADMIN_INVOICE";

//ecommerce orders type
export const GET_ORDERS = "GET_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";

//slider image

export const GET_SLIDER_IMAGES = "GET_SLIDER_IMAGES";
export const ADD_SLIDER_IMAGES = "ADD_SLIDER_IMAGES";
export const UPDATE_SLIDER_IMAGES = "UPDATE_SLIDER_IMAGES";
export const DELETE_SLIDER_IMAGES = "DELETE_SLIDER_IMAGES";

// site info types
export const GET_SITE_INFO = "GET_SITE_INFO";
export const CREATE_SITE_INFO = "CREATE_SITE_INFO";
export const UPDATE_SITE_INFO = "UPDATE_SITE_INFO";

// site info types
export const GET_CUSTOMER_PAYMENTS = "GET_CUSTOMER_PAYMENTS";
export const ADD_CUSTOMER_PAYMENTS = "ADD_CUSTOMER_PAYMENTS";
export const CUSTOMERS_QUICKPAYMENT_UPDATE = "CUSTOMERS_QUICKPAYMENT_UPDATE";
export const GET_SUPPLIER_PAYMENTS = "GET_SUPPLIER_PAYMENTS";
export const ADD_SUPPLIER_PAYMENTS = "ADD_SUPPLIER_PAYMENTS";
export const ADD_CUSTOMER_INSTALLMENT_PAYMENTS =
  "ADD_CUSTOMER_INSTALLMENT_PAYMENTS";
export const ADD_CUSTOMER_CART_PAYMENTS = "ADD_CUSTOMER_CART_PAYMENTS";
export const CUSTOMER_SINGLE_PAYMENT = "CUSTOMER_SINGLE_PAYMENT";

///// ledger  types
//customer ledger
export const GET_CUSTOMER_LEDGER = "GET_CUSTOMER_LEDGER";
export const GET_CUSTOMER_LEDGER_INVOICE = "GET_CUSTOMER_LEDGER_INVOICE";
export const GET_CUSTOMER_PAYMENT_LEDGER = "GET_CUSTOMER_PAYMENT_LEDGER";
export const GET_CUSTOMER_EMI_LEDGER = "GET_CUSTOMER_EMI_LEDGER";

//supplier ledger
export const GET_SUPPLIER_LEDGER = "GET_SUPPLIER_LEDGER";
export const GET_SUPPLIER_LEDGER_INVOICE = "GET_SUPPLIER_LEDGER_INVOICE";
export const GET_SUPPLIER_PAYMENT_LEDGER = "GET_SUPPLIER_PAYMENT_LEDGER";

//bulk sms
export const UPDATE_SMS_SETTING = "UPDATE_SMS_SETTING";
export const PURCHASE_SMS = "PURCHASE_SMS";
export const GET_BULK_SMS = "GET_BULK_SMS";
export const CREATE_BULK_SMS = "CREATE_BULK_SMS";
export const GET_MESSAGE_LOG = "GET_MESSAGE_LOG";

//shunnoIT Invoice
export const GET_SHUNNOIT_INVOICE = "GET_SHUNNOIT_INVOICE";

import {
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  SYNC_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  CUSTOMER_PAYMENT,
  CUSTOMERS_QUICKPAYMENT_UPDATE,
  EDIT_CUSTOMER_DUE,
  ADD_CUSTOMER_PAYMENTS
} from "../actions/types";

const initialState = { isLoaded: false, customers: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        isLoaded: true,
        customers: action.payload
      };
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.payload]
      };
    case SYNC_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, ...action.payload]
      };
    case UPDATE_CUSTOMER:
      let index = state.customers.findIndex(
        sup => sup._id === action.payload._id
      );
      let updated = [
        ...state.customers.slice(0, index),
        action.payload,
        ...state.customers.slice(index + 1)
      ];

      return {
        ...state,
        customers: updated
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(sup => sup._id != action.payload)
      };

    case CUSTOMER_PAYMENT:
      let pIndex = state.customers.findIndex(
        sup => sup._id === action.payload._id
      );
      let pUpdated = [
        ...state.customers.slice(0, pIndex),
        action.payload,
        ...state.customers.slice(pIndex + 1)
      ];

      return {
        ...state,
        customers: pUpdated
      };
    case CUSTOMERS_QUICKPAYMENT_UPDATE:
      let customers = state.customers.map(item => {
        if (item._id === action.payload._id) {
          item.due = action.payload.due;
        }
        return item;
      });
      return {
        ...state,
        customers
      };
    case EDIT_CUSTOMER_DUE:
      let cusIndex = state.customers.findIndex(
        sup => sup._id === action.payload._id
      );
      let updatedCus = [
        ...state.customers.slice(0, cusIndex),
        action.payload,
        ...state.customers.slice(cusIndex + 1)
      ];

      return {
        ...state,
        customers: updatedCus
      };

    default:
      return state;
  }
};

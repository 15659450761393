import { DECREASE_CASH, GET_CASH, INCREASE_CASH } from "../actions/types";

const initialState = {
  inCash: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CASH:
      return {
        inCash: action.payload
      };
    case INCREASE_CASH:
      return {
        inCash: state.inCash + action.payload
      };

    case DECREASE_CASH:
      return {
        inCash: state.inCash - action.payload
      };
    default:
      return state;
  }
};

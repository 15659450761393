import {
  ADD_TO_CART,
  SELECT_CUSTOMER,
  REMOVE_FROM_CART,
  INCREASE_UNIT,
  DECREASE_UNIT,
  SET_UNIT,
  CLEAR_CART,
  LOAD_CART_FROM_LOCALSTORAGE,
  SELL_PRICE_UPDATE,
  INDIVIDUAL_PRODUCT_DISCOUNT
} from "../actions/types";

const initialState = {
  customer: null,
  numberOfProducts: 0,
  products: [],
  totalPrice: 0,
  totalDiscount: 0
};

export default (state = initialState, action) => {
  let cart;

  switch (action.type) {
    case LOAD_CART_FROM_LOCALSTORAGE:
      return action.payload;

    case ADD_TO_CART:
      const found = state.products.find(
        item => item._id === action.payload._id
      );
      if (!found) {
        cart = {
          ...state,
          numberOfProducts: state.numberOfProducts + 1,
          products: [...state.products, action.payload],
          totalPrice: Math.round((state.totalPrice + action.payload.sellPrice) * 100) / 100
        };
      } else {
        let product = {
          ...action.payload,
          amounts: found.amounts + 1
        };

        let index = state.products.findIndex(p => p._id === product._id);

        cart = {
          ...state,
          products: [
            ...state.products.slice(0, index),
            product,
            ...state.products.slice(index + 1)
          ],
          totalPrice: state.totalPrice + product.sellPrice
        };
      }

      localStorage.setItem("cart", JSON.stringify(cart));
      return cart;

    case REMOVE_FROM_CART:
      let productToRemove = state.products.find(p => p._id == action.payload);
      cart = {
        ...state,
        numberOfProducts: state.numberOfProducts - 1,
        products: [...state.products.filter(p => p._id != productToRemove._id)],
        totalPrice:
          state.totalPrice - productToRemove.sellPrice * productToRemove.amounts
      };
      if (cart.products.length <= 0) {
        localStorage.removeItem("cart");
        return initialState;
      } else {
        localStorage.setItem("cart", JSON.stringify(cart));
        return cart;
      }

    case INCREASE_UNIT:
      let product = {
        ...action.payload,
        amounts: Number(action.payload.amounts) + 1
      };

      let index = state.products.findIndex(p => p._id === product._id);

      cart = {
        ...state,
        products: [
          ...state.products.slice(0, index),
          product,
          ...state.products.slice(index + 1)
        ],
        totalPrice: Math.round((state.totalPrice + product.sellPrice) * 100) / 100
      };

      localStorage.setItem("cart", JSON.stringify(cart));
      return cart;

    case DECREASE_UNIT:
      let deProduct = {
        ...action.payload,
        amounts: action.payload.amounts - 1
      };

      let dIndex = state.products.findIndex(p => p._id === deProduct._id);
      cart = {
        ...state,
        products: [
          ...state.products.slice(0, dIndex),
          deProduct,
          ...state.products.slice(dIndex + 1)
        ],
        totalPrice: Math.round((state.totalPrice - deProduct.sellPrice) * 100) / 100
      };

      localStorage.setItem("cart", JSON.stringify(cart));
      return cart;

    case SET_UNIT:
      let seProduct = {
        ...action.payload
      };

      let sIndex = state.products.findIndex(p => p._id === seProduct._id);
      let productToSetUnit = state.products.find(p => p._id === seProduct._id);

      cart = {
        ...state,
        products: [
          ...state.products.slice(0, sIndex),
          seProduct,
          ...state.products.slice(sIndex + 1)
        ],
        totalPrice:
          (Math.round(state.totalPrice -
            productToSetUnit.sellPrice * productToSetUnit.amounts +
            seProduct.sellPrice * seProduct.amounts) * 100) / 100
      };

      localStorage.setItem("cart", JSON.stringify(cart));
      return cart;

    case SELL_PRICE_UPDATE:
      const { productID, price } = action.payload;
      const getStateProducts = [...state.products];

      const productIndex = getStateProducts.findIndex(
        item => item._id === productID
      );
      if (productIndex !== -1) {
        getStateProducts[productIndex].sellPrice = Number(price);
      }

      const totalNewPrice = getStateProducts.reduce((acc, curr) => {
        acc += curr.sellPrice * curr.amounts;
        return acc;
      }, 0);

      cart = {
        ...state,
        products: getStateProducts,
        totalPrice: Math.round((totalNewPrice) * 100) / 100
      };

      localStorage.setItem("cart", JSON.stringify(cart));
      return cart;

    case INDIVIDUAL_PRODUCT_DISCOUNT:
      const { productID: ID, discount } = action.payload;
      const getProducts = [...state.products];

      const getProductIndex = getProducts.findIndex(item => item._id === ID);

      if (getProductIndex !== -1) {
        getProducts[getProductIndex].discount = Number(discount) || "";
      }

      const totalDiscount = getProducts.reduce((acc, curr) => {
        if (curr?.discount) {
          acc += curr.discount;
        }
        return acc;
      }, 0);

      cart = {
        ...state,
        products: getProducts,
        totalDiscount: totalDiscount
      };
      localStorage.setItem("cart", JSON.stringify(cart));
      return cart;

    case SELECT_CUSTOMER:
      cart = {
        ...state,
        customer: action.payload
      };

      localStorage.setItem("cart", JSON.stringify(cart));
      return cart;

    case CLEAR_CART:
      localStorage.removeItem("cart");
      return initialState;
    default:
      return state;
  }
};

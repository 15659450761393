import axios from "axios";
import { SERVER_URL } from "../config/config";
import {
  GET_INSTALLMENT_BY_CUSTOMER,
  GET_ALL_INSTALLMENT,
  LOADING,
  GET_INSTALLMENT_BY_INVOICE,
  GET_CUSTOMER_EMI_LEDGER,
  ADD_CUSTOMER_PAYMENTS,
  ADD_CUSTOMER_INSTALLMENT_PAYMENTS
} from "./types";
import toastr from "toastr";
import { serverLoading } from "../utils/utils";
import store from "../store";

export const getAllKistiByCustomer = customerId => async dispatch => {
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/installment/get/${customerId}`
    );
    if (data.status) {
      dispatch({
        type: GET_INSTALLMENT_BY_CUSTOMER,
        payload: data.kisti
      });
    }
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data.message);
  }
};

export const getKisiByInvoice = invoiceId => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/installment/invoice/get/${invoiceId}`
    );

    if (data.status) {
      dispatch({
        type: GET_INSTALLMENT_BY_INVOICE,
        payload: data.kisti
      });
    }
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data.message);
  }
  serverLoading(dispatch, false);
};

export const depositeKisti = (invoiceId, data) => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const res = await axios.patch(
      `${SERVER_URL}/api/installment/deposite/${invoiceId}`,
      data
    );

    document.getElementById("exampleModal").click();
    toastr.success("Payment success");
    store.dispatch(getKisiByInvoice(invoiceId));
    dispatch({
      type: GET_CUSTOMER_EMI_LEDGER,
      payload: res.data.ledger
    });
    dispatch({
      type: ADD_CUSTOMER_INSTALLMENT_PAYMENTS,
      payload: res.data.payment
    });
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.message);
  }
  serverLoading(dispatch, false);
};

export const getAllKistiByProprietor = proprietorID => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/installment/invoice/all-installment/${proprietorID}`
    );

    if (data.status) {
      dispatch({
        type: GET_ALL_INSTALLMENT,
        payload: data.allKisti
      });
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

import {
  ADD_TO_PURCHASE_CART,
  REMOVE_FROM_PURCHASE_CART,
  INCREASE_PURCHASE_UNIT,
  DECREASE_PURCHASE_UNIT,
  CLEAR_PURCHASE_CART,
  LOAD_PURCHASE_CART_FROM_LOCALSTORAGE,
  SET_PURCHASE_UNIT,
  SELECT_SUPPLIER,
  SET_PURCHASE_UNIT_PRICE,
} from "./types";

export const addToPurchaseCart = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_PURCHASE_CART,
    payload: product
  });
};

export const loadPurchaseCartFromLocalStorage = (purchaseCart) => dispatch => {
  dispatch({
    type: LOAD_PURCHASE_CART_FROM_LOCALSTORAGE,
    payload: JSON.parse(purchaseCart)
  })
}

export const removeFromPurchaseCart = (productId) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_PURCHASE_CART,
    payload: productId,
  });
};

export const increasePurchaseUnit = (product) => (dispatch) => {

  dispatch({
    type: INCREASE_PURCHASE_UNIT,
    payload: product,
  });
};

export const decreasePurchaseUnit = (product) => (dispatch) => {
  dispatch({
    type: DECREASE_PURCHASE_UNIT,
    payload: product,
  });
};

export const setPurchaseUnit = (product, amounts) => dispatch => {
  if (!amounts) {
    amounts = 0
  }

  dispatch({
    type: SET_PURCHASE_UNIT,
    payload: { ...product, amounts },
  });
}
export const setPurchaseUnitPrice = (product, purchasePrice) => (dispatch) => {
  dispatch({
    type: SET_PURCHASE_UNIT_PRICE,
    payload: { ...product, purchasePrice }
  })
}

export const selectSupplier = (supllier) => dispatch => {
  dispatch({
    type: SELECT_SUPPLIER,
    payload: supllier
  });
}

export const clearPurchaseCart = () => dispatch => {
  dispatch({
    type: CLEAR_PURCHASE_CART,
    payload: null
  })
}
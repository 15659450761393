import axios from "axios";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";
import { GET_CUSTOMER_LEDGER, GET_SUPPLIER_LEDGER } from "./types";
export const getSupplierLedger = storeId => dispatch => {
  serverLoading(dispatch, true);
  axios
    .get(`${SERVER_URL}/api/ledger/supplier/${storeId}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status) {
        dispatch({
          type: GET_SUPPLIER_LEDGER,
          payload: data.data
        });
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};
export const getCustomerLedger = storeId => dispatch => {
  serverLoading(dispatch, true);
  axios
    .get(`${SERVER_URL}/api/ledger/customer/${storeId}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status) {
        dispatch({
          type: GET_CUSTOMER_LEDGER,
          payload: data.data
        });
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { salesManPermission } from "../Manager/permissionData";
import { t } from "../../../HNLanguage/i18nextSetUp";
import { updateSalesMan } from "../../../actions/salesmanAction";

class SalesManPermissionModal extends Component {
  state = {
    permissions: [...salesManPermission]
  };

  componentWillReceiveProps(nextProps) {
    let tempPermissions = [...this.state.permissions];
    if (nextProps.salesMan?.permissions) {
      tempPermissions = tempPermissions.map(item => {
        item.isChecked = nextProps.salesMan.permissions[item.value];
        return item;
      });

      this.setState({
        permissions: tempPermissions
      });
    }
  }

  updateSalesManHandler = e => {
    e.preventDefault();
    const permissions = {};

    //prepare permission object
    for (let permission of this.state.permissions) {
      if (!permission.isHeader) {
        permissions[permission.value] = permission.isChecked;
      }
    }

    //call update api
    this.props.updateSalesMan({
      ...this.props.salesMan,
      permissions
    });
  };

  onPermissionChange = e => {
    const { name, checked } = e.target;
    const { permissions } = this.state;

    if (name === "selectAll" && checked === true) {
      const tempPermissions = permissions.map(item => {
        if (!item.isHeader) {
          item.isChecked = true;
        }

        return item;
      });
      return this.setState({ permissions: tempPermissions });
    }

    if (name === "selectAll" && checked === false) {
      const tempPermissions = permissions.map(item => {
        if (!item.isHeader) {
          item.isChecked = false;
        }

        return item;
      });
      return this.setState({ permissions: tempPermissions });
    }

    const tempPermissions = permissions.map(item => {
      if (!item.isHeader && item.value === name) {
        item.isChecked = checked;
      }

      return item;
    });

    this.setState({ permissions: tempPermissions });
  };

  render() {
    const { requestSend } = this.props;
    const { permissions } = this.state;
    const isAllSelect = permissions.filter(item => !item.isHeader);
    return (
      <div
        className="modal fade"
        id="permissionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="permissionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("editPermission")}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.updateSalesManHandler}>
              <div className="modal-body">
                <div className="custom-control custom-checkbox w-50">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={this.onPermissionChange}
                    id="checkedAll"
                    name="selectAll"
                    checked={isAllSelect.every(item => item.isChecked)}
                  />
                  <label className="custom-control-label" htmlFor="checkedAll">
                    {t("selectAll")}
                  </label>
                </div>
                <div className="d-flex flex-wrap">
                  {permissions &&
                    permissions.map(item => {
                      return (
                        <React.Fragment key={item.text}>
                          <div className="w-100">
                            {item.isHeader && <strong>{item.text}</strong>}
                          </div>
                          <div className="w-50 d-flex">
                            {!item.isHeader && (
                              <div
                                key={item.value}
                                className="custom-control custom-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name={item.value}
                                  id={item.value}
                                  checked={item.isChecked}
                                  onChange={this.onPermissionChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={item.value}
                                >
                                  {item.text}
                                </label>
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  {t("cancel")}
                </button>
                <button
                  disabled={requestSend}
                  type="submit"
                  className="btn btn-primary"
                >
                  {requestSend ? "Loading..." : t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store,
    requestSend: state.server.requestSend
  };
};

export default connect(mapStateToProps, { updateSalesMan })(
  SalesManPermissionModal
);

import axios from "axios";
import $ from "jquery";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

import {
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  ADD_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  GET_CATEGORY,
  GET_SUB_CATEGORY
} from "./types";

const makeFormData = data => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });
  return formData;
};

export const getCategory = storeID => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.get(`${SERVER_URL}/api/category/${storeID}`);

    if (data.status == true) {
      dispatch({
        type: GET_CATEGORY,
        payload: data.data
      });
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};
export const addCategory = body => async dispatch => {
  const formData = makeFormData(body);
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/category/${body.storeID}`,
      formData
    );
    if (data.status == true) {
      dispatch({
        type: ADD_CATEGORY,
        payload: data.data
      });
      $("#exampleModal").modal("hide");
      toastr.success(data.msg);
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const updateCategory = ({
  categoryID,
  ...categoryData
}) => async dispatch => {
  serverLoading(dispatch, true);
  const formData = makeFormData(categoryData);
  try {
    const { data } = await axios.put(
      `${SERVER_URL}/api/category/${categoryID}`,
      formData
    );

    if (data.status == true) {
      $("#exampleModal").modal("hide");
      // goes to store reducer
      dispatch({
        type: UPDATE_CATEGORY,
        payload: data.data
      });
      toastr.success(data.msg);
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const deleteCategory = categoryID => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.delete(
      `${SERVER_URL}/api/category/${categoryID}`
    );

    if (data.status == true) {
      $("#deleteModal").modal("hide");

      // goes to store reducer
      dispatch({
        type: DELETE_CATEGORY,
        payload: data.data._id
      });
      toastr.success(data.msg);
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
  }

  serverLoading(dispatch, false);
};

export const getSubCategory = storeID => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/category/subCategory/${storeID}`
    );
    if (data.status == true) {
      dispatch({
        type: GET_SUB_CATEGORY,
        payload: data.data
      });
    }
  } catch (error) {
    serverLoading(dispatch, false);
    toastr.error(error.response?.data?.message);
  }
};

export const addSubCategory = subCategoryData => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/category/subCategory/${subCategoryData.storeID}`,
      subCategoryData
    );

    serverLoading(dispatch, false);
    if (data.status) {
      // goes to store reducer
      dispatch({
        type: ADD_SUB_CATEGORY,
        payload: data.data
      });
      $("#exampleModal").modal("hide");
      toastr.success(data.msg);
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response.data.message);
  }
  serverLoading(dispatch, false);
};

export const updateSubCategory = ({
  subCategoryID,
  name,
  isEcom
}) => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const {
      data
    } = await axios.put(
      `${SERVER_URL}/api/category/subCategory/${subCategoryID}`,
      { name, isEcom }
    );

    if (data.status == true) {
      // goes to store reducer
      dispatch({
        type: UPDATE_SUB_CATEGORY,
        payload: data.data
      });
      $("#exampleModal").modal("hide");
      toastr.success(data.msg);
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const deleteSubCategory = subCategoryId => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.delete(
      `${SERVER_URL}/api/category/subCategory/${subCategoryId}`
    );
    serverLoading(dispatch, false);
    if (data.status == true) {
      $("#deleteModal").modal("hide");

      // goes to store reducer
      dispatch({
        type: DELETE_SUB_CATEGORY,
        payload: data.data._id
      });
      toastr.success(data.msg);
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import PdfToPrint from "../../OutputComponents/PdfToPrint";
import { stop } from "../../../actions/redirectActions";
import { t } from "../../../HNLanguage/i18nextSetUp";
import { FormatNumber } from "../../../utils/utils";
// import image from "../Customer/hisab.png";
// import { Link } from "react-router-dom";

function PrintReport(props) {
  let history = useHistory();

  let printRef = useRef();
  let invId = props.match.params.inv_id;
  const { store, invoices, quickSellInvoices, customers } = props;
  let products = props.products;
  let invoice;
  let customer;
  if (invoices && quickSellInvoices) {
    invoice = [...invoices, ...quickSellInvoices].find(
      inv => inv._id === invId
    );
  }

  if (customers && invoice) {
    customer = customers.find(cus => cus._id === invoice.customerID);
  }

  if (props.redirect) {
    props.stop();
  }

  let tableBorder = () => {
    return { border: " 1px solid lightGray" };
  };

  let a = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen "
  ];
  let b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety"
  ];
  let inWords = num => {
    num = Math.floor(num);
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore"
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand  "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred  "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "Taka only "
        : "";
    return str;
  };

  return (
    <>
      <div className="main-content">
        <section className="section">
          <div className="container pt-3 pb-3 mb-4 bg-white text-center border-rounded d-flex">
            <button
              className="btn btn-primary text-center"
              onClick={() => history.goBack()}
            >
              <i
                className="fas fa-arrow-alt-circle-left  mt-2 "
                style={{ fontSize: "1.13rem" }}
              ></i>
            </button>
            <h4 style={{ marginLeft: "23rem", marginTop: ".3rem" }}>
              {t("Chalan Invoice")}
            </h4>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="card">
                  {invoice && products ? (
                    <>
                      <PdfToPrint ref={el => (printRef = el)}>
                        <div className="card-body p-3 pt-3">
                          <div className="my-4 row align-items-center">
                            {store.logo?.src && (
                              <div className="col-md-3 ml-1">
                                <div>
                                  <img
                                    src={store.logo?.src}
                                    height="100px"
                                    width="100PX"
                                    style={{ objectFit: "contain" }}
                                  />
                                </div>
                              </div>
                            )}
                            {store.logo ? (
                              <div className="col-md-5">
                                <div className="text-center">
                                  <h6>{store.storeName}</h6>
                                  <small>{store.address}</small>
                                </div>
                              </div>
                            ) : (
                              <div className="col-12">
                                <div className="text-center">
                                  <h6>{store.storeName}</h6>
                                  <small>{store.address}</small>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="clearfix">
                            <table
                              style={{
                                width: "100%"
                              }}
                            >
                              <tr style={tableBorder()}>
                                <td
                                  className="p-1 ml-1"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  Memo
                                </td>
                                <td>{invoice.memo}</td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Name</td>
                                <td>
                                  {customer?.name ||
                                    invoice.quickSellCustomer?.name}
                                </td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Mobile</td>
                                <td>
                                  {customer?.mobile ||
                                    invoice.quickSellCustomer?.mobile}
                                </td>
                              </tr>
                              <tr style={tableBorder()}>
                                <td className="p-1">Due</td>
                                <td>{FormatNumber(customer?.due) || 0}</td>
                              </tr>
                            </table>
                          </div>

                          <div className="table-responsive mt-4">
                            <div className="d-flex justify-content-between">
                              <h6>{t("productDetails")}</h6>
                              <div className=" ">
                                <strong>
                                  {t("date")} :
                                  {new Date(invoice.soldAt).getDate()} /
                                  {new Date(invoice.soldAt).getMonth() + 1}/
                                  {new Date(invoice.soldAt).getFullYear()}
                                </strong>
                              </div>
                            </div>
                            <table
                              style={{
                                width: "100%"
                              }}
                              className="text-center"
                            >
                              <tr className="p-2" style={tableBorder()}>
                                <th className="p-2">{t("name")}</th>
                                <th className="p-2" style={tableBorder()}>
                                  {t("warranty")}
                                </th>

                                <th style={tableBorder()}>{t("model")}</th>
                                <th style={tableBorder()}>{t("quantity")}</th>
                                <th style={tableBorder()}>{t("unitPrice")}</th>
                                <th style={tableBorder()}>{t("discount")}</th>
                                <th style={tableBorder()}>{t("totalPrice")}</th>
                              </tr>

                              {invoice &&
                                invoice.soldItems.map((p, pI) => {
                                  const product = products.find(
                                    pr => pr._id === p.productID
                                  );
                                  const warranty =
                                    product?.warrantyID &&
                                    store.warranty.find(
                                      wrn => wrn._id === product.warrantyID
                                    );

                                  return (
                                    <tr
                                      key={pI}
                                      className="p-2"
                                      style={tableBorder()}
                                    >
                                      <td className="p-2">
                                        <strong>{product?.name} </strong>
                                      </td>
                                      <td style={tableBorder()}>
                                        {warranty && (
                                          <span>{warranty.warrantyName} </span>
                                        )}
                                      </td>
                                      <td style={tableBorder()}>
                                        {product?.model}
                                      </td>
                                      <td style={tableBorder()}>{p.qty}</td>
                                      <td style={tableBorder()}>
                                        {FormatNumber(p.unitPrice)}
                                      </td>
                                      <td style={tableBorder()}>
                                        {FormatNumber(p.discount)}
                                      </td>
                                      <td style={tableBorder()}>
                                        {FormatNumber(p.qty * p.unitPrice)} {t("tk")}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </table>
                          </div>
                          <div className="font-weight-bold mt-3">
                            <span>
                              <strong>In Words:</strong>
                            </span>
                            <span style={{ marginLeft: ".3rem" }}>
                              {inWords(invoice?.grandTotal)}
                            </span>
                          </div>

                          <div className="mt-5 pt-3">
                            <div className="clearfix text-center">
                              <div
                                className="float-left"
                                style={{ width: 150 }}
                              >
                                <hr />
                                <span>{t("proprietorSign")}</span>
                              </div>
                              <div
                                className="float-right"
                                style={{ width: 150 }}
                              >
                                <hr />
                                <span>{t("customerSign")}</span>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="pt-3 text-center">
                            <p>{store.tokenFooter}</p>
                          </div>

                          <div className="pt-3">
                            <small className="mb-0">
                              <i>
                                Powered By:&nbsp; Shunno Software &nbsp;
                                <i
                                  style={{ fontSize: 10 }}
                                  className="fas fa-phone"
                                ></i>
                                01321141790
                              </i>
                            </small>
                          </div>
                        </div>
                      </PdfToPrint>
                      <div className="m-4 text-right">
                        <ReactToPrint
                          trigger={() => (
                            <button type="button" className="btn btn-primary">
                              {t("printInvoice")}
                            </button>
                          )}
                          content={() => printRef}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{ minHeight: 230 }}
                      className="text-center d-flex justify-content-center align-items-center"
                    >
                      <div className="fa-3x">
                        <i
                          style={{ fontSize: 40 }}
                          className="fas fa-sync fa-spin"
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    invoices: state.invoice.invoices,
    quickSellInvoices: state.invoice.quickSellInvoices,
    customers: state.customer.customers,
    products: state.product.products,
    redirect: state.redirect.redirect
  };
};

export default connect(mapStateToProps, { stop })(PrintReport);

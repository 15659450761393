import React, { useEffect, lazy } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import toastr from "toastr";

//import css
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";

// redux store
import store from "./store";

//actions

import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logout } from "./actions/authActions";
import {
  clearCurrentProfile,
  getCurrentProfile
} from "./actions/profileActions";
import { loadCartFromLocalStorage, clearCart } from "./actions/cartActions";
import { getStore, getStores } from "./actions/storeActions";
import { getCustomers, getNetFeeCustomers } from "./actions/customerActions";
import { getSuppliers } from "./actions/supplierActions";
import { getProducts, getProductsDetails } from "./actions/productActions";
import { getEmployees } from "./actions/employeeActions";
import { getExpenditures } from "./actions/expenditureActions";
import { getStoreLendens } from "./actions/lendenActions";
import { loadPurchaseCartFromLocalStorage } from "./actions/purchaseCartActions";
import { getPurchaseInvoices } from "./actions/purchaseInvoiceActions";
import { getInvoices, getQuickSellInvoices } from "./actions/invoiceActions";
import { getAllKistiByProprietor } from "./actions/installmentAction";
import { getProprietors } from "./actions/proprietorAction";
import { getBanks, getTransactions } from "./actions/bankAction";
import { getManagers } from "./actions/managerActions";
import { getCategory, getSubCategory } from "./actions/categoryActions";

//public components
import Register from "./pages/Pages/Auth/Register";
import { Header, Footer, Sidebar } from "./components/admin";
import Login from "./pages/Pages/Auth/Login";

//admin components
import AdminRoute from "./components/common/AdminRoute";
import Proprietor from "./pages/Admin/Proprietor";

//private components
import PrintReport from "./pages/Services/Customer/PrintReport";
import PurchaseCart from "./pages/Header/PurchaseCart";
import PrintInvoice from "./pages/Services/Supplier/PrintInvoice";
import AddStore from "./pages/Header/AddStore";

import PrivateRoute from "./components/common/PrivateRoute";
import NoInternet from "./pages/Pages/Errors/NoInternet";
import Salesman from "./pages/Services/SalesMan/Salesman";
import { getSalesMans } from "./actions/salesmanAction";
import { getCurrentUser } from "./utils/utils";
import { getPermissions } from "./actions/permissionAction";
import { getStock, getStockHistory } from "./actions/stockAction";
import { getSoldProducts } from "./actions/soldProductsAction";
import {
  monthlyServicesCharge,
  getShunnoitListInvoice,
  getAllSSInvoices
} from "./actions/ssInvoiceAction";
import PaymentSuccess from "./components/paymentResponse/PaymentSuccess";
import PaymentFailed from "./components/paymentResponse/PaymentFailed";
import { getEcommerceOrders } from "./actions/ECAction/orderActions";
import HomeSlider from "./pages/Services/ESlider/HomeSlider";
import { getHomeSliders } from "./actions/ECAction/homeSliderAction";
import SiteInfo from "./pages/Services/ESiteInfo/SiteInfo";
import { getSiteInfo } from "./actions/ECAction/siteInfoAction";
import { getStoresBySuperAdmin } from "./actions/adminActions";
import {
  getCustomerPayments,
  getSupplierPayments
} from "./actions/paymentsActions";
import Purchase from "./pages/Reports/Purchase";

import Stores from "./pages/Admin/Stores";
import Profit from "./pages/Reports/Profit";
import ChalanInvoice from "./pages/Services/ChalanInvoice/ChalanInvoice";
import ChalanSellInvoice from "./pages/Services/Customer/ChalanSellInvoice";
import CustomerLedger from "./pages/Services/Customer/CustomerLedger";
import SupplierLedger from "./pages/Services/Supplier/SupplierLedger";
import { getCustomerLedger, getSupplierLedger } from "./actions/ledgerActions";
import PurchaseProduct from "./pages/Reports/PurchaseProduct";
import InvoiceSettings from "./pages/Settings/InvoiceSettings";
import EMIInvoice from "./pages/Services/EMIInvoice/EMIInvoice";

import { getMessageLog } from "./actions/smsAction";
import MessageLog from "./pages/Message/MessageLog/MessageLog";
// import PosInvoice from "./pages/Services/Customer/PosInvoice";
// import CustomerPayment from "./pages/Reports/Payments/Payments";
import AllInvoice from "./pages/Admin/AdminInvoice/AllInvoice";
import SaleReturn from "./pages/Services/Return/SaleReturn";
import EmiReport from "./pages/Reports/EmiReport";
import CustomerPayment from "./pages/Reports/Payments/CustomerPayment";
import SupplierPayment from "./pages/Reports/Payments/SupplierPayment";
import AllSell from "./pages/Reports/AllSell";
import EmiInvoice from "./Emi/EmiInvoice";
import CustomerPaymentInvoice from "./pages/Services/Customer/CustomerPaymentInvoice";
import SupplierPaymentInvoice from "./pages/Services/Supplier/SupplierPaymentInvoice";

//private components with lazy loads

// const Error500 = React.lazy(() => import("./pages/Pages/Errors/500"));

const Error403 = React.lazy(() => import("./pages/Pages/Errors/403"));
const Error404 = lazy(() => import("./pages/Pages/Errors/404"));
const EcommerceDashboard = lazy(() =>
  import("./pages/Dashboard/EcommerceDashboard")
);
const Employee = lazy(() => import("./pages/Services/Employee/"));
const PaySalary = lazy(() => import("./pages/Services/Employee/PaySalary"));
const Manager = lazy(() => import("./pages/Services/Manager/Manager"));

const Sell = lazy(() => import("./pages/Reports/Sell"));
const Expense = lazy(() => import("./pages/Reports/Expense"));
const IncomeExpense = lazy(() => import("./pages/Reports/IncomeExpense"));
const Cart = lazy(() => import("./pages/Header/Cart"));
const Lenden = lazy(() => import("./pages/Reports/Lenden"));
const Store = lazy(() => import("./pages/Header/Store"));
const Profile = lazy(() => import("./pages/Header/Profile"));
const Password = lazy(() => import("./pages/Header/Password"));
const Category = lazy(() => import("./pages/Settings/Category"));
const Unit = lazy(() => import("./pages/Settings/Unit"));
const Manufacturing = lazy(() => import("./pages/Settings/Manufacturing"));
const CostSection = lazy(() => import("./pages/Settings/CostSection"));

const Supplier = lazy(() => import("./pages/Services/Supplier/"));
const Invoice = lazy(() => import("./pages/Services/Supplier/Invoice"));
const Payments = lazy(() => import("./pages/Services/Supplier/Payments"));

const Customer = lazy(() => import("./pages/Services/Customer/"));
const SellReport = lazy(() => import("./pages/Services/Customer/SellReport"));
const Product = lazy(() => import("./pages/Services/Product/"));
const history = lazy(() => import("./history"));
const CustomerPayments = lazy(() =>
  import("./pages/Services/Customer/CustomerPayments")
);
const ProductSellReport = lazy(() =>
  import("./pages/Services/Product/ProductSellReport")
);
const EMI = lazy(() => import("./Emi/emi"));
const PrintEmi = lazy(() => import("./Emi/PrintEmi"));
const Installment = lazy(() => import("./pages/Reports/Installment"));
const QuickSell = lazy(() => import("./pages/Reports/QuickSell"));
const Warranty = lazy(() => import("./pages/Settings/Warranty"));
const SoldProduct = lazy(() => import("./pages/Reports/SoldProduct"));
const Bank = lazy(() => import("./pages/Reports/Bank"));
const UISettings = lazy(() => import("./pages/Settings/UISettings"));
const Stock = lazy(() => import("./pages/Reports/Stock"));
const Order = lazy(() => import("./pages/Services/Order/Order"));
const Message = lazy(() => import("./pages/Message/Message"));
const MessageTempSetting = lazy(() =>
  import("./pages/Settings/MessageTempSetting")
);
const ShunnoITInvoice = lazy(() =>
  import("./pages/ShunnoITInvoice/ShunnoITInvoice")
);

// Check for token
if (localStorage.SsHnCcToken) {
  // Set auth token to header auth
  setAuthToken(localStorage.SsHnCcToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.SsHnCcToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout());
    // Clear current profile
    store.dispatch(clearCurrentProfile());
    // clear Cart
    store.dispatch(clearCart());
    // Redirect to login
    window.localStorage.href = "/auth/login";
  }
} else if (localStorage.adminToken) {
  // Set auth token to header auth
  setAuthToken(localStorage.adminToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.adminToken);
  // Set user and isAuthenticated
  store.dispatch({
    type: "ADMIN_LOGIN",
    payload: decoded
  });
  // store.dispatch(getStores());

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout());
  }
}

if (localStorage.getItem("i18nextLng") === "en-US") {
  localStorage.setItem("i18nextLng", "bn");
}

if (localStorage.cart) {
  store.dispatch(loadCartFromLocalStorage(localStorage.cart));
}

if (localStorage.purchaseCart) {
  store.dispatch(loadPurchaseCartFromLocalStorage(localStorage.purchaseCart));
}

function App() {
  const storeID = useSelector(state => state.store.store?._id);

  let location = useLocation().pathname;
  let locationSplit = location.split("/");
  let locationParent = locationSplit[1];
  let WithoutRouter = [
    "auth",
    "error",
    "utilities",
    "admin",
    "payment-success",
    "payment-failed",
    "not-authorized"
  ];
  const isAdmin = store.getState().admin.isLoggedIn;
  const activeStore = localStorage.getItem("activeStore");

  const existingStore =
    activeStore && activeStore === !"undefined" && JSON.parse(activeStore);

  useEffect(() => {
    // Check for token
    if (localStorage.SsHnCcToken) {
      // Set auth token to header auth
      setAuthToken(localStorage.SsHnCcToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.SsHnCcToken);
      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(decoded));
      store.dispatch(getStores());

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        store.dispatch(logout());
        // Clear current profile
        store.dispatch(clearCurrentProfile());
        // clear Cart
        store.dispatch(clearCart());
        // Redirect to login
        window.localStorage.href = "/auth/login";
      }
    } else if (localStorage.adminToken) {
      // Set auth token to header auth
      setAuthToken(localStorage.adminToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.adminToken);
      // Set user and isAuthenticated
      store.dispatch({
        type: "ADMIN_LOGIN",
        payload: decoded
      });
      // store.dispatch(getStores());

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        store.dispatch(logout());
      }
    }

    if (localStorage.cart) {
      store.dispatch(loadCartFromLocalStorage(localStorage.cart));
    }

    if (localStorage.purchaseCart) {
      store.dispatch(
        loadPurchaseCartFromLocalStorage(localStorage.purchaseCart)
      );
    }

    const { proprietorID, role, storeType, mobile } = getCurrentUser();

    if (location !== "/auth/login") {
      if (!existingStore?._id && role !== "admin") {
        store.dispatch(getStores());
      }

      if (role !== "proprietor" && role !== "admin")
        store.dispatch(getPermissions());
    }

    if (storeID) {
      store.dispatch(getCurrentProfile());
      // if (storeType === "netFee") {
      //   store.dispatch(getNetFeeCustomers(mobile));
      // } else {
      //   store.dispatch(getCustomers(storeID));
      // }
      store.dispatch(getCustomers(storeID));
      store.dispatch(getSuppliers(storeID));
      store.dispatch(getProducts(storeID));
      store.dispatch(getProductsDetails(storeID));
      store.dispatch(getEmployees(storeID));
      store.dispatch(getInvoices(storeID));
      store.dispatch(getPurchaseInvoices(storeID));
      store.dispatch(getExpenditures(storeID));
      store.dispatch(getStoreLendens(storeID));
      store.dispatch(getAllKistiByProprietor(storeID));
      store.dispatch(getQuickSellInvoices(storeID));
      store.dispatch(getStore(storeID));
      store.dispatch(getStock(storeID));
      store.dispatch(getStockHistory(storeID));
      store.dispatch(getSoldProducts(storeID));
      store.dispatch(getCategory(storeID));
      store.dispatch(getSubCategory(storeID));
      store.dispatch(getEcommerceOrders({ proprietorID, storeID }));
      store.dispatch(getHomeSliders({ proprietorID, storeID }));
      store.dispatch(getSiteInfo({ proprietorID, storeID }));
      store.dispatch(getCustomerPayments(storeID));
      store.dispatch(getSupplierPayments(storeID));
      store.dispatch(getSupplierLedger(storeID));
      store.dispatch(getCustomerLedger(storeID));
      store.dispatch(getMessageLog({ storeID, proprietorID }));
      if (role === "proprietor") {
        store.dispatch(monthlyServicesCharge(storeID));
        store.dispatch(getShunnoitListInvoice(storeID));
        store.dispatch(getManagers(proprietorID));
        store.dispatch(getSalesMans(proprietorID));
        store.dispatch(getBanks(proprietorID));
        store.dispatch(getTransactions(proprietorID));
      }
      return;
    }
    if (isAdmin) {
      store.dispatch(getProprietors());
      store.dispatch(getAllSSInvoices());
      store.dispatch(getStoresBySuperAdmin());
      return;
    }
  }, [storeID]);
  //check internet connection
  const isOnline = window.navigator.onLine;
  // const isOnline = true;
  //no internet connection then render NoInternet connection page
  if (!isOnline) {
    return <NoInternet />;
  }

  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-center",
    preventDuplicates: false,
    onclick: null,
    showDuration: "200",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "slideDown",
    hideMethod: "slideUp"
  };

  const lang = localStorage.getItem("i18nextLng");
  if (!lang) localStorage.setItem("i18nextLng", "bn");
  return (
    <div className="App">
      {!WithoutRouter.includes(locationParent) && (
        <>
          <Header />
          <Sidebar />
        </>
      )}

      <React.Suspense
        fallback={
          <div
            style={{ height: "100vh", marginLeft: "15rem" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className="spinner-border text-dark"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            ></div>
          </div>
        }
      >
        <Switch history={history}>
          <AdminRoute exact path="/admin/proprietor" component={Proprietor} />
          <AdminRoute exact path="/admin/stores" component={Stores} />
          <AdminRoute exact path="/admin/invoices" component={AllInvoice} />

          <Route path="/auth/login" component={Login} />
          <Route path="/auth/register" component={Register} />

          <PrivateRoute path="/" exact component={EcommerceDashboard} />
          <PrivateRoute path="/customer/emi/:id/" component={EMI} />
          <PrivateRoute
            path="/print-emi-invoice/:invoiceId/:kistiId"
            component={PrintEmi}
          />
          <PrivateRoute
            path="/emi-invoice/:invoiceId/:kistiId"
            component={EmiInvoice}
          />
          <PrivateRoute
            path="/payment/:inv_id/invoice/:pay_id"
            component={CustomerPaymentInvoice}
          />
          <PrivateRoute
            path="/sup_payment/:inv_id/invoice/:pay_id"
            component={SupplierPaymentInvoice}
          />
          <PrivateRoute path="/addStore" component={AddStore} />
          <PrivateRoute path="/category" component={Category} />
          <PrivateRoute path="/unit" component={Unit} />
          <PrivateRoute path="/manufacturer" component={Manufacturing} />
          <PrivateRoute path="/costSection" component={CostSection} />
          <PrivateRoute path="/warranty" component={Warranty} />
          <PrivateRoute exact path="/supplier" component={Supplier} />
          <PrivateRoute
            exact
            path="/supplier/:id/invoices"
            component={Invoice}
          />
          <PrivateRoute
            exact
            path="/supplier/:id/supplierLedger"
            component={SupplierLedger}
          />

          <PrivateRoute
            exact
            path="/supplier/:sup_id/invoices/:inv_id"
            component={PrintInvoice}
          />
          <PrivateRoute
            exact
            path="/supplier/:sup_id/chalanInvoices/:inv_id"
            component={ChalanInvoice}
          />
          <PrivateRoute
            exact
            path="/create-sale-return"
            component={SaleReturn}
          />

          <PrivateRoute
            exact
            path="/supplier/:sup_id/invoices/:inv_id/payments"
            component={Payments}
          />

          <PrivateRoute exact path="/customer" component={Customer} />
          <PrivateRoute
            exact
            path="/customer/:id/sellReport"
            component={SellReport}
          />
          <PrivateRoute exact path="/customer/:id/emi" component={EmiReport} />
          <PrivateRoute
            exact
            path="/customer/:id/ledgerReport"
            component={CustomerLedger}
          />

          <PrivateRoute
            exact
            path="/customer/:cus_id/sellReport/:inv_id"
            component={PrintReport}
          />
          <PrivateRoute
            exact
            path="/customer/:cus_id/chalanSellReport/:inv_id"
            component={ChalanSellInvoice}
          />
          <PrivateRoute
            exact
            path="/customer/:cus_id/emi-invoice/:inv_id"
            component={EMIInvoice}
          />

          <PrivateRoute
            exact
            path="/customer/:cus_id/sellReport/:inv_id/payments"
            component={CustomerPayments}
          />

          <PrivateRoute exact path="/product" component={Product} />
          <PrivateRoute
            exact
            path="/product/:id"
            component={ProductSellReport}
          />
          <PrivateRoute exact path="/employee" component={Employee} />
          <PrivateRoute
            exact
            path="/employee/:id/paysalary"
            component={PaySalary}
          />
          <PrivateRoute
            exact
            path="/product/:id"
            component={ProductSellReport}
          />
          <PrivateRoute exact path="/employee" component={Employee} />
          <PrivateRoute
            exact
            path="/employee/:id/paysalary"
            component={PaySalary}
          />
          <PrivateRoute exact path="/manager" component={Manager} />
          <PrivateRoute exact path="/sales-man" component={Salesman} />

          <PrivateRoute path="/sell" component={Sell} />
          <PrivateRoute path="/allSell" component={AllSell} />
          <PrivateRoute path="/purchase-report" component={Purchase} />
          <PrivateRoute path="/customer-payment" component={CustomerPayment} />
          <PrivateRoute path="/supplier-payment" component={SupplierPayment} />

          {/* <PrivateRoute path="/purchase-product" component={PurchaseProduct} /> */}
          <PrivateRoute path="/profit" component={Profit} />
          <PrivateRoute path="/expense" component={Expense} />
          <PrivateRoute path="/income-expense" component={IncomeExpense} />
          <PrivateRoute path="/cash-box" component={Lenden} />

          <PrivateRoute path="/cart" component={Cart} />
          <PrivateRoute path="/store" component={Store} />
          <PrivateRoute path="/password" component={Password} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/purchase" component={PurchaseCart} />
          <PrivateRoute path="/installment" component={Installment} />
          <PrivateRoute path="/quickSell" component={QuickSell} />
          <PrivateRoute path="/sold-product" component={SoldProduct} />
          <PrivateRoute path="/bank-transaction" component={Bank} />
          <PrivateRoute path="/stock" component={Stock} />
          <PrivateRoute path="/ui-settings" component={UISettings} />
          <PrivateRoute path="/invoice-settings" component={InvoiceSettings} />

          {/* ecommerce  */}

          <PrivateRoute path="/ecommerce/orders" component={Order} />
          <PrivateRoute path="/ecommerce/home-slider" component={HomeSlider} />
          <PrivateRoute path="/ecommerce/site-info" component={SiteInfo} />

          <PrivateRoute path="/payment-success" component={PaymentSuccess} />
          <PrivateRoute path="/payment-failed" component={PaymentFailed} />

          {/* bulk message  */}
          <PrivateRoute path="/message/bulk" component={Message} />
          <PrivateRoute
            path="/message/template"
            component={MessageTempSetting}
          />
          <PrivateRoute path="/message/log" component={MessageLog} />
          {/* ShunnoIT Invoice */}
          <PrivateRoute path="/shunno-it-invoice" component={ShunnoITInvoice} />
          <Route path="/not-authorized" component={Error403} />
          <Route path="*" component={Error404} />
        </Switch>
      </React.Suspense>

      <Footer />
    </div>
  );
}

export default App;

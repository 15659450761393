import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INCREASE_UNIT,
  DECREASE_UNIT,
  SET_UNIT,
  SELECT_CUSTOMER,
  LOAD_CART_FROM_LOCALSTORAGE,
  CLEAR_CART,
  SELL_PRICE_UPDATE,
  INDIVIDUAL_PRODUCT_DISCOUNT
} from "./types";

export const loadCartFromLocalStorage = cart => {
  return {
    type: LOAD_CART_FROM_LOCALSTORAGE,
    payload: JSON.parse(cart)
  };
};

export const addToCart = product => dispatch => {
  dispatch({
    type: ADD_TO_CART,
    payload: { ...product, amounts: 1 }
  });
};

export const removeFromCart = productId => dispatch => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: productId
  });
};

export const increaseUnit = product => dispatch => {
  dispatch({
    type: INCREASE_UNIT,
    payload: product
  });
};

export const decreaseUnit = product => dispatch => {
  dispatch({
    type: DECREASE_UNIT,
    payload: product
  });
};

export const setUnit = (product, amounts) => dispatch => {
  if (!amounts) {
    amounts = 0;
  } else if (amounts >= product.stock) {
    amounts = product.stock;
  }

  dispatch({
    type: SET_UNIT,
    payload: { ...product, amounts }
  });
};

export const selectCustomer = customer => dispatch => {
  dispatch({
    type: SELECT_CUSTOMER,
    payload: customer
  });
};

export const clearCart = () => dispatch => {
  dispatch({
    type: CLEAR_CART,
    payload: null
  });
};

export const setSellPrice = (productID, price) => dispatch => {
  dispatch({
    type: SELL_PRICE_UPDATE,
    payload: { productID, price }
  });
};

export const setIndividualDiscount = (productID, discount) => dispatch => {
  dispatch({
    type: INDIVIDUAL_PRODUCT_DISCOUNT,
    payload: { productID, discount }
  });
};

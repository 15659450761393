import axios from "axios";
import $ from "jquery";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";
import {
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  GET_ERRORS,
  UPDATE_CUSTOMER,
  CUSTOMER_PAYMENT,
  ADD_LENDEN,
  UPDATE_INVOICE,
  RESPONSE_SUCCESS,
  RESPONSE_FAILD,
  INCREASE_CASH,
  GET_INVOICES_OF_A_STORE,
  ADD_CUSTOMER_PAYMENTS,
  GET_CUSTOMER_PAYMENT_LEDGER,
  GET_CUSTOMER_LEDGER,
  SYNC_CUSTOMER,
  CUSTOMERS_QUICKPAYMENT_UPDATE,
  EDIT_CUSTOMER_DUE,
  CUSTOMER_SINGLE_PAYMENT
} from "./types";

export const getCustomers = storeId => dispatch => {
  axios
    .get(`${SERVER_URL}/api/customer/get/${storeId}`)
    .then(res => {
      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data.data
      });
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

export const getNetFeeCustomers = ispOwnerMobile => async dispatch => {
  try {
    const { data } = await axios.get(
      `https://www.netfeebd.net/api/v1/admin/netfee/customer?mobile=${ispOwnerMobile}`,
      {
        headers: {
          apiKey: "b1bd575ef55600b23808974d8b93f166ab05eba4"
        }
      }
    );
    dispatch({
      type: GET_CUSTOMERS,
      payload: data.customers
    });
  } catch (error) {
    serverLoading(dispatch, false);
    toastr.error(error?.response?.data?.message);
  }
};

export const addCustomer = customerData => dispatch => {
  serverLoading(dispatch, true);
  axios
    .post(`${SERVER_URL}/api/customer/add`, customerData)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#exampleModal").modal("hide");
        dispatch({
          type: ADD_CUSTOMER,
          payload: data.data
        });
        dispatch({
          type: GET_CUSTOMER_LEDGER,
          payload: data.ledger
        });
        toastr.success("গ্রাহক সেভ সম্পন্ন হয়েছে।");
      } else {
        // if any error occured
        if (data.msg) {
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

export const syncCustomer = customerData => dispatch => {
  serverLoading(dispatch, true);
  axios
    .post(`${SERVER_URL}/api/customer/sync`, customerData)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        dispatch({
          type: SYNC_CUSTOMER,
          payload: data.customers
        });
        dispatch({
          type: GET_CUSTOMER_LEDGER,
          payload: data.ledgers
        });
        toastr.success("গ্রাহক সেভ সম্পন্ন হয়েছে।");
      } else {
        // if any error occured
        if (data.msg) {
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

export const updateCustomer = customer => dispatch => {
  serverLoading(dispatch, true);
  axios
    .put(`${SERVER_URL}/api/customer/edit/${customer._id}`, customer)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#exampleModal").modal("hide");
        dispatch({
          type: UPDATE_CUSTOMER,
          payload: data.data
        });
        toastr.success("গ্রাহক আপডেট সম্পন্ন হয়েছে।");
      } else {
        // if any error occured
        if (data.msg) {
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      $("#exampleModal").modal("hide");
      toastr.error(error?.response?.data?.message);
    });
};
export const updateCustomerDue = customer => dispatch => {
  serverLoading(dispatch, true);
  axios
    .put(`${SERVER_URL}/api/customer/editDue/${customer._id}`, customer)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        $("#editCustomerDueModal").modal("hide");
        dispatch({
          type: EDIT_CUSTOMER_DUE,
          payload: data.data
        });
        toastr.success("গ্রাহক বকেয়া আপডেট সম্পন্ন হয়েছে।");
      } else {
        // if any error occured
        if (data.msg) {
          toastr.error(data.msg);
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data.data
          });
        }
        return;
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      $("#editCustomerDueModal").modal("hide");
      toastr.error(error?.response?.data?.message);
    });
};

export const deleteCustomer = customerId => dispatch => {
  serverLoading(dispatch, true);

  axios
    .delete(`${SERVER_URL}/api/customer/delete/${customerId}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      dispatch({
        type: DELETE_CUSTOMER,
        payload: customerId
      });
      toastr.success("গ্রাহক ডিলিট হয়েছে।");
    })
    .catch(error => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      toastr.error("সার্ভার থেকে সাড়া পাওয়া যায়নি");
    });
};

export const customerPayment = (payment, soldItems) => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/customer/payment`,
      payment
    );
    console.log(data);

    if (data.status == true) {
      dispatch({
        type: CUSTOMER_PAYMENT,
        payload: data.data.customer
      });
      dispatch({
        type: UPDATE_INVOICE,
        payload: { ...data.data.invoice, soldItems }
      });

      dispatch({
        type: ADD_LENDEN,
        payload: data.data.lenden
      });
      dispatch({
        type: INCREASE_CASH,
        payload: data.data.lenden.amount
      });
      dispatch({
        type: CUSTOMER_SINGLE_PAYMENT,
        payload: data.data.payment
      });
      toastr.success("পেইমেন্ট সম্পন্ন হয়েছে।");
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const makePaymentCustomer = (postBody, clearState) => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/payments/customer?paymentOrder=${postBody.paymentOrder}`,
      postBody
    );

    dispatch({
      type: GET_INVOICES_OF_A_STORE,
      payload: data.data.payments
    });
    dispatch({
      type: ADD_CUSTOMER_PAYMENTS,
      payload: data.data.newPayment
    });
    dispatch({
      type: CUSTOMERS_QUICKPAYMENT_UPDATE,
      payload: data.data.customer
    });
    dispatch({
      type: GET_CUSTOMER_PAYMENT_LEDGER,
      payload: data.data.ledger
    });
    toastr.success("পেইমেন্ট সম্পন্ন হয়েছে।");
    $("#customerQuickPayment").modal("hide");
    clearState();
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

import { t } from "../../HNLanguage/i18nextSetUp";

const storeTypeData = [
  {
    label: t("electronics"),
    value: "electronics"
  },
  {
    label: t("electric"),
    value: "electric"
  },
  {
    label: t("mobileShop"),
    value: "mobileShop"
  },

  {
    label: t("motorcycleAndParts"),
    value: "motorcycleAndParts"
  },

  {
    label: t("clothStore"),
    value: "clothStore"
  },

  {
    label: t("cosmetics"),
    value: "cosmetics"
  },

  {
    label: t("furniture"),
    value: "furniture"
  },

  {
    label: t("hardware"),
    value: "hardware"
  },

  {
    label: t("ceramics"),
    value: "ceramics"
  },
  {
    label: t("materialStore"),
    value: "materialStore"
  },

  {
    label: t("pharmacy"),
    value: "pharmacy"
  },

  {
    label: t("crockeries"),
    value: "crockeries"
  },

  {
    label: t("shoeStore"),
    value: "shoeStore"
  },
  {
    label: t("optics"),
    value: "optics"
  },
  {
    label: t("bagShop"),
    value: "bagShop"
  },
  {
    label: t("stationary"),
    value: "stationary"
  },
  {
    label: t("library"),
    value: "library"
  },

  {
    label: t("grocery"),
    value: "grocery"
  },

  {
    label: t("surgicalShop"),
    value: "surgicalShop"
  }
];

export default storeTypeData;

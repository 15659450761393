import {
  GET_PURCHASE_INVOICES,
  ADD_SUPPLIER_INVOICE,
  UPDATE_PURCHASE_INVOICE,
  DELETE_PURCHASE_INVOICE
} from "../actions/types";

const initialState = { isLoaded: false, invoices: [], reDirect: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASE_INVOICES:
      return {
        isLoaded: true,
        invoices: [...action.payload]
      };
    case ADD_SUPPLIER_INVOICE:
      return {
        ...state,
        invoices: [...state.invoices, action.payload]
      };

    case UPDATE_PURCHASE_INVOICE:
      let index = state.invoices.findIndex(
        inv => inv._id === action.payload._id
      );
      let updated = [
        ...state.invoices.slice(0, index),
        action.payload,
        ...state.invoices.slice(index + 1)
      ];
      return {
        ...state,
        invoices: updated
      };
    case DELETE_PURCHASE_INVOICE:
      const purchaseInvoices = state.invoices.filter(
        invoice => invoice._id !== action.payload._id
      );
      return {
        ...state,
        invoices: purchaseInvoices
      };

    default:
      return state;
  }
};

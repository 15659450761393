import {
  ADD_CUSTOMER_PAYMENTS,
  ADD_SUPPLIER_PAYMENTS,
  GET_CUSTOMER_PAYMENTS,
  GET_SUPPLIER_PAYMENTS,
  ADD_CUSTOMER_INSTALLMENT_PAYMENTS,
  ADD_CUSTOMER_CART_PAYMENTS,
  CUSTOMER_SINGLE_PAYMENT,
  ADD_SUPPLIER_QUICK_PAYMENTS
} from "../actions/types";

const initialState = {
  customerPayments: null,
  supplierPayments: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PAYMENTS:
      return {
        ...state,
        customerPayments: action.payload
      };
    case GET_SUPPLIER_PAYMENTS:
      return {
        ...state,
        supplierPayments: action.payload
      };
    case ADD_CUSTOMER_PAYMENTS:
      return {
        ...state,
        customerPayments: [...state.customerPayments, ...action.payload]
      };
    case CUSTOMER_SINGLE_PAYMENT:
      return {
        ...state,
        customerPayments: [...state.customerPayments, action.payload]
      };
    case ADD_CUSTOMER_INSTALLMENT_PAYMENTS:
      return {
        ...state,
        customerPayments: [...state.customerPayments, action.payload]
      };
    case ADD_CUSTOMER_CART_PAYMENTS:
      return {
        ...state,
        customerPayments: [...state.customerPayments, action.payload]
      };
    case ADD_SUPPLIER_PAYMENTS:
      return {
        ...state,
        supplierPayments: [...state.supplierPayments, action.payload]
      };
    case ADD_SUPPLIER_QUICK_PAYMENTS:
      return {
        ...state,
        supplierPayments: [...state.supplierPayments, ...action.payload]
      };

    default:
      return state;
  }
};

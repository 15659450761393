import {
  GET_EMPLOYEES,
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  PAY_SALARY,
  DELETE_EMPLOYEE
} from "../actions/types";

const initialState = { isLoaded: false, employees: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        isLoaded: true,
        employees: action.payload,
      };
    case ADD_EMPLOYEE:
      return {
        ...state,
        employees: [...state.employees, action.payload],
      };
    case UPDATE_EMPLOYEE:
      let index = state.employees.findIndex(
        (emp) => emp._id === action.payload._id
      );
      let updated = [
        ...state.employees.slice(0, index),
        action.payload,
        ...state.employees.slice(index + 1),
      ];
      return {
        ...state,
        employees: updated,
      };

    case PAY_SALARY:
      let sIndex = state.employees.findIndex(
        (emp) => emp._id === action.payload._id
      );
      let sUpdated = [
        ...state.employees.slice(0, sIndex),
        action.payload,
        ...state.employees.slice(sIndex + 1),
      ];
      return {
        ...state,
        employees: sUpdated,
      };
    case DELETE_EMPLOYEE:
      return {
        ...state,
        employees: state.employees.filter(sup => sup._id != action.payload)
      };

    default:
      return state;
  }
};

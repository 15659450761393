import React from "react";
import "./paymentResponse.css";

const PaymentFailed = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 mx-auto mt-5">
          <div className="payment">
            <div className="payment_failed_header">
              <div className="check">
                <i className="fas fa-times" aria-hidden="true"></i>
              </div>
            </div>
            <div className="content content_failed">
              <h1>Payment Failed !</h1>
              <p>
                দুঃখিত আপনার পেমেন্ট সম্পূর্ণ হয় নি, অনুগহ করে আবার চেষ্টা করুন
              </p>
              <a href="/">Go to Home</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;

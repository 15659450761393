import { t } from "../../HNLanguage/i18nextSetUp";

const proprietorStoreUpdateData = [
  {
    type: "select",
    name: "storeId",
    placeholder: t("storeName"),
    label: t("selectStore"),
    optionLabel: t("selectStore"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "storeName",
    placeholder: t("storeName"),
    label: t("storeName"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },

  {
    type: "text",
    name: "contactNumber",
    placeholder: t("mobile"),
    label: t("mobile"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "storeUserName",
    placeholder: t("storeUniqueName"),
    label: t("storeUniqueName"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "tokenHeader",
    placeholder: t("tokenHeader"),
    label: t("tokenHeader"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "text",
    name: "tokenFooter",
    placeholder: t("tokenFooter"),
    label: t("tokenFooter"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },

  {
    type: "text",
    name: "remarks",
    placeholder: t("remarks"),
    label: t("remarks"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  },
  {
    type: "checkbox",
    name: "hasEcommerce",
    placeholder: t(""),
    label: t("hasEcommerce"),
    isAlter: true,
    size: "md",
    col: "6",
    isUpdateHide: false
  }
];

export default proprietorStoreUpdateData;

import React, { Component } from "react";

import { Link, NavLink } from "react-router-dom";
import { commonMenuData, AdminData } from "./data";
import "../../../js/js/scripts";
import "../../../js/js/stisla";
import logo from "./icons/hn.png";
import store from "../../../store";
// import SidebarGlobal from "../../../js/SidebarGlobal";
import { t } from "../../../HNLanguage/i18nextSetUp";
import { connect } from "react-redux";
import { getCurrentUser, getPermissions } from "../../../utils/utils";

const iconStyle = {
  width: "20px",
  marginRight: "5px"
};

export class SideBar extends Component {
  state = {
    store: {},
    Data: {
      ...commonMenuData
    }
  };

  componentWillReceiveProps(nextProps) {
    const { permissions, store } = nextProps;
    const { role } = getCurrentUser();
    const UISettings = store.HNSettings?.UISettings;
    const filterMenuByPermission = (item, permissionData, targetURL) => {
      if (
        role !== "proprietor" &&
        permissionData !== undefined &&
        !permissionData
      ) {
        if (item.url === targetURL) {
          item.role = item.role.filter(r => r !== role);
        }
      }
      return item;
    };
    const filterMenuByStoreSettings = (item, permissionData, targetURL) => {
      if (!permissionData) {
        if (item.url === targetURL) {
          item.role = [];
        }
      }
      return item;
    };

    if (role !== "admin") {
      const updatedMenu = this.state.Data.menus.map(item => {
        item = filterMenuByPermission(item, permissions.showDashboard, "/");
        // if (role !== "proprietor" && !permissions.showDashboard) {
        //   console.log(role, permissions.showDashboard);
        //   if (item.url === "/") {
        //     item.role = item.role.filter(r => r !== role);
        //   }
        // }

        item = filterMenuByPermission(
          item,
          permissions.showSellReport,
          "/sell"
        );

        // if (role !== "proprietor" && !permissions.showSellReport) {
        //   if (item.url === "/sell") {
        //     item.role = item.role.filter(r => r !== role);
        //   }
        // }

        item = filterMenuByPermission(
          item,
          permissions.showQuickSellReport,
          "/quickSell"
        );

        // if (role !== "proprietor" && !permissions.showQuickSellReport) {
        //   if (item.url === "/quickSell") {
        //     item.role = item.role.filter(r => r !== role);
        //   }
        // }

        item = filterMenuByPermission(
          item,
          permissions.showSoldProduct,
          "/sold-product"
        );

        // if (role !== "proprietor" && !permissions.showSoldProduct) {
        //   if (item.url === "/sold-product") {
        //     item.role = item.role.filter(r => r !== role);
        //   }
        // }

        item = filterMenuByPermission(
          item,
          permissions.showPurchaseReports,
          "/purchase-report"
        );
        item = filterMenuByPermission(
          item,
          permissions.showPurchaseReports,
          "/profit"
        );

        item = filterMenuByPermission(
          item,
          permissions.showIncomeExpense,
          "/income-expense"
        );

        // if (role !== "proprietor" && !permissions.showIncomeExpense) {
        //   if (item.url === "/income-expense") {
        //     item.role = item.role.filter(r => r !== role);
        //   }
        // }
        item = filterMenuByPermission(
          item,
          permissions.showCashbox,
          "/cash-box"
        );

        // if (role !== "proprietor" && !permissions.showCashbox) {
        //   if (item.url === "/cash-box") {
        //     item.role = item.role.filter(r => r !== role);
        //   }
        // }

        item = filterMenuByPermission(
          item,
          permissions.showInstallment,
          "/installment"
        );

        // if (role !== "proprietor" && !permissions.showInstallment) {
        //   if (item.url === "/installment") {
        //     item.role = item.role.filter(r => r !== role);
        //   }
        // }

        item = filterMenuByPermission(
          item,
          permissions.showExpenditure,
          "/expense"
        );

        item = filterMenuByPermission(item, permissions.showStock, "/stock");

        // if (role !== "proprietor" && !permissions.showExpenditure) {
        //   if (item.url === "/expense") {
        //     item.role = item.role.filter(r => r !== role);
        //   }
        // }
        if (Object.keys(store).length) {
          item = filterMenuByStoreSettings(
            item,
            store?.HNSettings?.hasEcommerce,
            "/ecommerce"
          );
        }

        if (UISettings) {
          item = filterMenuByStoreSettings(
            item,
            UISettings.warranty,
            "/warranty"
          );

          item = filterMenuByStoreSettings(
            item,
            UISettings.expenditures,
            "/costSection"
          );
          item = filterMenuByStoreSettings(
            item,
            UISettings.expenditures,
            "/income-expense"
          );

          item = filterMenuByStoreSettings(
            item,
            UISettings.manager,
            "/manager"
          );
          item = filterMenuByStoreSettings(
            item,
            UISettings.salesman,
            "/sales-man"
          );
          item = filterMenuByStoreSettings(
            item,
            UISettings.supplier,
            "/supplier"
          );
          item = filterMenuByStoreSettings(
            item,
            UISettings.bankTransaction,
            "/bank-transaction"
          );
          item = filterMenuByStoreSettings(
            item,
            UISettings.installment,
            "/installment"
          );
        }
        return item;
      });
      this.setState({
        ...this.state,
        Data: { ...this.state.Data, menus: updatedMenu }
      });
    }
  }
  render() {
    const isAdmin = store.getState().admin.isLoggedIn;
    const { Data } = this.state;
    let menuData = isAdmin ? AdminData : Data;
    const { role } = getCurrentUser();

    return (
      <div className="main-sidebar" style={{ overflow: "auto" }}>
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                width="40"
                className="rounded-circle mr-3"
              />
              {t("hisabnikash")}
            </Link>
          </div>
          <div className="sidebar-brand sidebar-brand-sm">
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                width="25"
                className="rounded-circle"
              />
            </Link>
          </div>
          <ul className="sidebar-menu mb-3">
            {menuData.menus.map((menu, iMenu) => {
              let comp;
              if (menu.header) {
                comp = this.props.store?.HNSettings?.hasEcommerce ? (
                  <li key={iMenu} className={`menu-header`}>
                    {menu.name}
                  </li>
                ) : (
                  <li
                    key={iMenu}
                    className={`menu-header ${menu?.hasEcommerce ? "d-none" : ""
                      }`}
                  >
                    {menu.name}
                  </li>
                );
              } else if (menu.dropdown) {
                if (menu.role.includes(role)) {
                  if (menu.active) {
                    comp = (
                      <li key={iMenu} className="nav-item dropdown active">
                        <a href="#" className="nav-link has-dropdown">
                          <img
                            style={iconStyle}
                            src={menu.icon}
                            alt={menu.name}
                          />
                          <span> {menu.name} </span>
                        </a>
                        <ul className="dropdown-menu">
                          {menu.children.map((submenu, iSubmenu) => {
                            let subComp;

                            if (submenu.active) {
                              if (submenu.beep) {
                                subComp = (
                                  <li key={iSubmenu} className="active">
                                    <NavLink
                                      activeStyle={{
                                        color: " #6777ef",
                                        fontWeight: "600"
                                      }}
                                      exact
                                      className="beep beep-sidebar"
                                      to={submenu.url}
                                    >
                                      {submenu.name}
                                    </NavLink>
                                  </li>
                                );
                              } else {
                                subComp = (
                                  <li key={iSubmenu}>
                                    <NavLink
                                      activeStyle={{
                                        color: " #6777ef",
                                        fontWeight: "600"
                                      }}
                                      exact
                                      to={submenu.url}
                                    >
                                      {submenu.name}
                                    </NavLink>
                                  </li>
                                );
                              }
                            } else if (submenu.beep) {
                              subComp = (
                                <li key={iSubmenu}>
                                  <NavLink
                                    activeStyle={{
                                      color: " #6777ef",
                                      fontWeight: "600"
                                    }}
                                    exact
                                    className="beep beep-sidebar"
                                    to={submenu.url}
                                  >
                                    {submenu.name}
                                  </NavLink>
                                </li>
                              );
                            } else {
                              subComp = (
                                <li key={iSubmenu}>
                                  <NavLink
                                    activeStyle={{
                                      color: " #6777ef",
                                      fontWeight: "600"
                                    }}
                                    exact
                                    to={submenu.url}
                                  >
                                    {submenu.name}
                                  </NavLink>
                                </li>
                              );
                            }

                            return subComp;
                          })}
                        </ul>
                      </li>
                    );
                  } else {
                    comp = (
                      <li key={iMenu} className="nav-item dropdown">
                        <a href="#" className="nav-link has-dropdown">
                          <img
                            style={iconStyle}
                            src={menu.icon}
                            alt={menu.name}
                          />
                          <span> {menu.name} </span>
                        </a>
                        <ul className="dropdown-menu">
                          {menu.children.map((submenu, iSubmenu) => {
                            let subComp;
                            if (submenu.active) {
                              if (submenu.beep) {
                                subComp = (
                                  <li key={iSubmenu} className="active">
                                    <NavLink
                                      activeStyle={{
                                        color: " #6777ef",
                                        fontWeight: "600"
                                      }}
                                      exact
                                      className="beep beep-sidebar"
                                      to={{ pathname: "https://infortts.com" }}
                                      target="_blank"
                                    >
                                      {submenu.name}
                                    </NavLink>
                                  </li>
                                );
                              } else {
                                subComp = (
                                  <li key={iSubmenu} className="active">
                                    <NavLink
                                      activeStyle={{
                                        color: " #6777ef",
                                        fontWeight: "600"
                                      }}
                                      exact
                                      to={submenu.url}
                                    >
                                      {submenu.name}
                                    </NavLink>
                                  </li>
                                );
                              }
                            } else if (submenu.beep) {
                              subComp = (
                                <li key={iSubmenu}>
                                  <NavLink
                                    activeStyle={{
                                      color: " #6777ef",
                                      fontWeight: "600"
                                    }}
                                    exact
                                    className="beep beep-sidebar"
                                    to={submenu.url}
                                  >
                                    {submenu.name}
                                  </NavLink>
                                </li>
                              );
                            } else {
                              subComp = (
                                <li key={iSubmenu}>
                                  <NavLink
                                    activeStyle={{
                                      color: " #6777ef",
                                      fontWeight: "600"
                                    }}
                                    exact
                                    // to={submenu.url}
                                    to={{
                                      pathname: submenu?.storeUse
                                        ? submenu.url +
                                        this.props.store?.storeUserName
                                        : submenu.url
                                    }}
                                    target={`${submenu?.anchor ? "_blank" : "_self"
                                      }`}
                                  >
                                    {submenu.name}
                                  </NavLink>
                                </li>
                              );
                            }

                            return subComp;
                          })}
                        </ul>
                      </li>
                    );
                  }
                }
              } else if (menu.active) {
                //
                comp = (
                  <li key={iMenu} className="s">
                    <NavLink
                      activeStyle={{
                        color: " #6777ef",
                        fontWeight: "600"
                      }}
                      exact
                      to={menu.url}
                    >
                      <img style={iconStyle} src={menu.icon} alt={menu.name} />
                      <span> {menu.name} </span>
                    </NavLink>
                  </li>
                );
              } else {
                //Single Component
                comp = menu.role.includes(role) && (
                  <li key={iMenu}>
                    <NavLink
                      activeStyle={{
                        color: " #6777ef",
                        fontWeight: "600"
                      }}
                      exact
                      to={menu.url}
                    >
                      {(() => {
                        let content;
                        if (
                          menu.name === t("dashboard") ||
                          menu.name === t("cashbox")
                        ) {
                          content = (
                            <i
                              style={{
                                backgroundColor: "#E12465",
                                padding: "5px 0px",
                                color: "#fff",
                                borderRadius: "2px",
                                fontSize: "10px"
                              }}
                              className={menu.icon + " m-0 mr-1"}
                            ></i>
                          );
                        } else {
                          content = (
                            <img
                              style={iconStyle}
                              src={menu.icon}
                              alt={menu.name}
                            />
                          );
                        }
                        return content;
                      })()}

                      <span> {menu.name}</span>
                    </NavLink>
                  </li>
                );
              }

              return comp;
            })}
          </ul>
        </aside>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    user: state.auth.user,
    permissions: state.permissions.permissions
  };
};

export default connect(mapStateToProps)(SideBar);

import {
  GET_CATEGORY,
  GET_SUB_CATEGORY,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  ADD_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
  DELETE_SUB_CATEGORY
} from "../actions/types";

const initialState = {
  categories: [],
  subCategories: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY:
      return {
        ...state,
        categories: action.payload
      };
    case GET_SUB_CATEGORY:
      return {
        ...state,
        subCategories: action.payload
      };

    case ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload]
      };
    case UPDATE_CATEGORY:
      let index = state.categories.findIndex(
        category => category._id === action.payload._id
      );
      let updated = [
        ...state.categories.slice(0, index),
        action.payload,
        ...state.categories.slice(index + 1)
      ];
      return {
        ...state,
        categories: updated
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          category => category._id != action.payload
        )
      };
    case ADD_SUB_CATEGORY:
      return {
        ...state,
        subCategories: [...state.subCategories, action.payload]
      };
    case UPDATE_SUB_CATEGORY:
      let findSub = state.subCategories.findIndex(
        subCategory => subCategory._id === action.payload._id
      );
      let updatedSub = [
        ...state.subCategories.slice(0, findSub),
        action.payload,
        ...state.subCategories.slice(findSub + 1)
      ];
      return {
        ...state,
        subCategories: updatedSub
      };
    case DELETE_SUB_CATEGORY:
      return {
        ...state,
        subCategories: state.subCategories.filter(
          subCategory => subCategory._id != action.payload
        )
      };
    default:
      return state;
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../../Settings/Modal";
import ReactSelect from "react-select";
import { t } from "../../../HNLanguage/i18nextSetUp";
import { updateHomeSlider } from "../../../actions/ECAction/homeSliderAction";

class SliderModal extends Component {
  state = {
    productLink: "",
    description: "",
    subTitle: "",
    title: "",
    slider: {}
  };

  onStatusChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  updateSliderHandler = e => {
    e.preventDefault();
    const { slider, ...rest } = this.state;

    const data = {
      ...slider,
      ...rest
    };
    this.props.updateHomeSlider(data);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.slider) {
      this.setState({
        productLink: nextProps.slider.productLink,
        description: nextProps.slider.description,
        subTitle: nextProps.slider.subTitle,
        title: nextProps.slider.title,
        slider: nextProps.slider
      });
    }
  }

  render() {
    const { description, subTitle, title, productLink } = this.state;

    const options = this.props.products.reduce((acc, curr) => {
      if (curr.isEcom) {
        acc.push({
          label: curr.name,
          value: curr.productCode
        });
      }
      return acc;
    }, []);

    const sliderInput = [
      {
        label: "Title",
        type: "text",
        value: title,
        name: "title",
        placeholder: "Slider Title"
      },
      {
        label: "Subtitle",
        type: "text",
        value: subTitle,
        name: "subTitle",
        placeholder: "Slider Subtitle"
      },
      {
        label: "Description",
        type: "textarea",
        value: description,
        name: "description",
        placeholder: "Slider Description"
      },
      {
        label: "Select Product",
        type: "select",
        value: {
          label: options.find(item => item.value === productLink)?.label
        }
      }
    ];

    return (
      <Modal
        onSubmit={this.updateSliderHandler}
        modalTitle="Update slider text"
        modalSize="modal-md"
      >
        {
          <>
            <div className="d-flex flex-wrap align-items-center justify-content-around">
              <div style={{ width: "40rem", marginBottom: "1rem" }}>
                {sliderInput.map(item => (
                  <div key={item.name} className="form-group">
                    <label htmlFor={item.name}>{item.label}</label>
                    {item.type === "select" && (
                      <ReactSelect
                        className="form-select"
                        onChange={data =>
                          this.setState({
                            ...this.state,
                            productLink: data.value
                          })
                        }
                        value={item.value}
                        options={options}
                      />
                    )}

                    {item.type === "textarea" && (
                      <textarea
                        type={item.type}
                        className="form-control"
                        id={item.name}
                        name={item.name}
                        placeholder={item.placeholder}
                        value={item.value}
                        onChange={this.onStatusChange}
                      />
                    )}

                    {item.type !== "textarea" && item.type !== "select" && (
                      <input
                        type={item.type}
                        className="form-control"
                        id={item.name}
                        name={item.name}
                        placeholder={item.placeholder}
                        value={item.value}
                        onChange={this.onStatusChange}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        }
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
    requestSend: state.server.requestSend
  };
};

SliderModal.defaultProps = {
  slider: {},
  products: []
};

export default connect(mapStateToProps, { updateHomeSlider })(SliderModal);

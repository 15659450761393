import axios from "axios";
import toastr from "toastr";
import $ from "jquery";
import { GET_SALESMANS, UPDATE_SALESMAN, ADD_SALESMAN, DELETE_SALESMAN } from "./types";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

export const getSalesMans = proprietorID => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/sales-man/list/${proprietorID}`
    );
    dispatch({
      type: GET_SALESMANS,
      payload: data.data
    });
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const addSalesMan = salesManData => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/sales-man/add`,
      salesManData
    );
    if (data.status == true) {
      dispatch({
        type: ADD_SALESMAN,
        payload: data.data
      });
      toastr.success(data.msg);
      $("#exampleModal").modal("hide");
    }
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const updateSalesMan = salesManData => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.put(
      `${SERVER_URL}/api/sales-man/edit/${salesManData._id}`,
      salesManData
    );

    if (data.status == true) {
      dispatch({
        type: UPDATE_SALESMAN,
        payload: data.data
      });
      toastr.success(data.msg);
    }
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  $("#exampleModal").modal("hide");
  $("#permissionModal").modal("hide");
  serverLoading(dispatch, false);
};
export const deleteSalesMan = salesManId => dispatch => {
  serverLoading(dispatch, true);

  axios
    .delete(`${SERVER_URL}/api/sales-man/delete/${salesManId}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      dispatch({
        type: DELETE_SALESMAN,
        payload: salesManId
      });
      toastr.success("সেলসম্যান ডিলিট হয়েছে।");
    })
    .catch(error => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      toastr.error("সার্ভার থেকে সাড়া পাওয়া যায়নি");
    });
};

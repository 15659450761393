// const allRoutes = [
//   "/",
//   "/category",
//   "/unit",
//   "/costSection",
//   "/warranty",
// "/supplier",
// "/supplier/:id/invoices",
// "/supplier/:sup_id/invoices/:inv_id",
//   "/supplier/:sup_id/invoices/:inv_id/payments",
//   "/customer",
//   "/customer/:id/sellReport",
//   "/customer/:cus_id/sellReport/:inv_id",
//   "/customer/:cus_id/sellReport/:inv_id/payments",
//   "/customer/emi/:id/",
// "/product",
// "/product/:id",
//   "/employee",
//   "/employee/:id/paysalary",
//   "/sell",
//   "/quickSell",
//   "/sold-product",
//   "/expense",
//   "/income-expense",
//   "/cash-box",
//   "/bank-transaction",
//   "/installment"
// ];

import store from "../../store";
import { getCurrentUser, getPermissions } from "../../utils/utils";

const restrictedRoute = () => {
  const { role, isAuthenticated } = getCurrentUser();

  //restrictedRoute
  const routePermission = {
    proprietor: [],
    manager: ["/manager", "/sales-man"],
    salesman: ["/manager", "/sales-man", "/store"]
  };
  if (role !== "proprietor" && isAuthenticated) {
    const {
      supplierAdd,
      supplierDelete,
      supplierEdit,
      showSupplierInvoice,
      addProduct,
      sellProduct,
      payEmployeeSalary,
      showQuickSellReport,
      showSellReport,
      showSoldProduct,
      addExpenditure,
      editExpenditure,
      deleteExpenditure,
      showIncomeExpense,
      showCashbox,
      showInstallment,
      manageStore,
      productDelete,
      showStock
    } = getPermissions();
    if (store.getState().permissions.isLoaded) {
      if (
        !supplierAdd &&
        !supplierEdit &&
        !supplierDelete &&
        !showSupplierInvoice
      ) {
        routePermission[role].push(
          "/supplier",
          "/supplier/:id/invoices",
          "/supplier/:sup_id/invoices/:inv_id"
        );
      }
      if (!addProduct && !sellProduct && !productDelete) {
        routePermission[role].push("/product");
      }

      if (!showSellReport) {
        routePermission[role].push("/sell");
      }
      if (!showQuickSellReport) {
        routePermission[role].push("/quickSell");
      }
      if (!showSoldProduct) {
        routePermission[role].push("/sold-product");
      }
      if (!addExpenditure && !editExpenditure && !deleteExpenditure) {
        routePermission[role].push("/expense");
      }
      if (!showIncomeExpense) {
        routePermission[role].push("/income-expense");
      }
      if (!showCashbox) {
        routePermission[role].push("/cash-box");
      }

      if (!showInstallment) {
        routePermission[role].push("/installment");
      }
      if (!manageStore) {
        routePermission[role].push("/store");
      }
      if (!showStock) {
        routePermission[role].push("/stock");
      }
    }
  }

  return { routePermission };
};

export default restrictedRoute;

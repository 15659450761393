import { RESPONSE_SUCCESS, SEND_REQUEST } from "../actions/types";

import { NumericFormat } from "react-number-format";
import moment from "moment";
import store from "../store";

export const FormatNumber = number => {
  return (
    <NumericFormat
      value={number}
      displayType="text"
      thousandSeparator={true}
      thousandsGroupStyle="lakh"
      decimalScale={2}
      // prefix={"৳ "}
    />
  );
};

export const formatDate = date => {
  return date && moment(date).format("YYYY-MM-DD HH:mm A");
};

export const serverLoading = (dispatch, isLoading) => {
  if (isLoading) {
    return dispatch({
      type: SEND_REQUEST
    });
  } else {
    return dispatch({
      type: RESPONSE_SUCCESS
    });
  }
};

export const getCurrentUser = () => {
  const auth = store.getState().auth;
  const storeData = store.getState().store?.store;
  const adminAuth = store.getState().admin;
  if (auth.isAuthenticated && auth.user.type === "proprietor") {
    return {
      userID: auth.user.id,
      proprietorID: auth.user.profile._id,
      role: auth.user.type,
      storeID: storeData?._id,
      isAuthenticated: auth.isAuthenticated,
      storeType: storeData?.storeType,
      mobile: auth.user.mobile
    };
  }
  if (auth.isAuthenticated) {
    return {
      userID: auth.user.id,
      proprietorID: auth.user.profile?.proprietorID,
      role: auth.user.type,
      storeID: auth?.user.store._id,
      isAuthenticated: auth.isAuthenticated,
      storeType: auth?.user?.store.storeType,
      mobile: auth.user.mobile
    };
  }

  if (adminAuth && adminAuth.isLoggedIn) {
    return {
      proprietorID: "",
      role: adminAuth.admin.type,
      storeID: "",
      isAuthenticated: adminAuth.isLoggedIn
    };
  }

  return {
    proprietorID: "",
    role: "",
    storeID: "",
    isAuthenticated: auth.isAuthenticated
  };
};

export const getPermissions = () => {
  const auth = store.getState().auth;
  const permissions = store.getState().permissions.permissions;
  if (
    auth.isAuthenticated &&
    auth.user.type !== "proprietor" &&
    auth.user.type !== "admin"
  ) {
    return permissions;
  } else {
    return {};
  }
};

import React, { Component } from "react";
import AsForm from "../../ui/AsForm";
import { Row, Col, Container, Button, Card, Modal } from "react-bootstrap";
import proprietorStoreFormData from "./ProprietorStoreFormData";
import FormInput from "../../ui/FormInput";
import { t } from "../../HNLanguage/i18nextSetUp";
import * as yup from "yup";
import { connect } from "react-redux";
import storeTypeData from "../Header/storeTypeData";

class ProprietorAddEditModal extends Component {
  render() {
    const options = this.props.proprietors?.map(item => {
      return { value: item._id, label: item.name };
    });
    let proprietorStoreData = proprietorStoreFormData.map(item => {
      if (item.name === "proprietorID") {
        item.options = options;
      } else if (item.name === "storeType") {
        item.options = storeTypeData;
      }
      return item;
    });

    const validation = {
      proprietorID: yup.string().required(t("Proprietor is required")),
      storeName: yup.string().required(t("Store name is required")),
      storeType: yup.string().required(t("Store Type is required")),
      contactNumber: yup.string().required(t("Mobile number is required"))
    };

    const defaultValues = {
      proprietorID: "",
      storeName: "",
      tokenHeader: "",
      tokenFooter: "",
      contactNumber: "",
      remarks: "",
      storeType: "",
      storeUserName: "",
      hasEcommerce: false,
      logo: ""
    };

    if (this.props.showProprietorStoreEditModal) {
      defaultValues.proprietorID = this.props.proprietorStore?.proprietorID;
      defaultValues.storeName = this.props.proprietorStore?.storeName;
      defaultValues.tokenHeader = this.props.proprietorStore?.tokenHeader;
      defaultValues.tokenFooter = this.props.proprietorStore?.tokenFooter;
      defaultValues.contactNumber = this.props.proprietorStore?.contactNumber;
      defaultValues.remarks = this.props.proprietorStore?.remarks;
      defaultValues.storeType = this.props.proprietorStore?.reference?.storeType;
      defaultValues.storeUserName = this.props.proprietorStore?.reference?.storeUserName;
      defaultValues.hasEcommerce = this.props.proprietorStore?.reference?.hasEcommerce;
    }

    return (
      <Modal
        show={
          this.props.showProprietorStoreAddModal ||
          this.props.showProprietorStoreEditModal
        }
        onHide={this.props.closeProprietorStoreModalHandler}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Card>
              <Card.Body>
                <AsForm
                  defaultValues={defaultValues}
                  validationSchema={validation}
                  onSubmit={
                    this.props.showProprietorStoreEditModal
                      ? this.props.updateProprietorStoreHandler
                      : this.props.createProprietorStoreHandler
                  }
                >
                  <Row>
                    {this.props.showProprietorEditModal
                      ? proprietorStoreData?.map(
                          (formItem, index) =>
                            !formItem.isUpdateHide && (
                              <Col
                                md={formItem.col}
                                className="mb-3"
                                key={index}
                              >
                                <FormInput
                                  type={formItem.type}
                                  name={formItem.name}
                                  placeholder={formItem.placeholder}
                                  label={formItem.label}
                                  isAlter={formItem.isAlter}
                                  size={formItem.size}
                                  options={formItem.options}
                                  optionLabel={formItem.optionLabel}
                                  defaultValue={{
                                    label: formItem.role,
                                    value: formItem.role
                                  }}
                                />
                              </Col>
                            )
                        )
                      : proprietorStoreData?.map((formItem, index) => (
                          <Col md={formItem.col} className="mb-3" key={index}>
                            <FormInput
                              type={formItem.type}
                              name={formItem.name}
                              placeholder={formItem.placeholder}
                              label={formItem.label}
                              isAlter={formItem.isAlter}
                              size={formItem.size}
                              options={formItem.options}
                              optionLabel={formItem.optionLabel}
                              defaultValue={formItem.options?.find(
                                i => i.value === defaultValues.role
                              )}
                            />
                          </Col>
                        ))}
                  </Row>
                  <Button
                    type="submit"
                    variant="danger"
                    onClick={this.props.closeProprietorStoreModalHandler}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="success" className="ml-2">
                    {this.props.showProprietorEditModal ? "Update " : "Add"}
                  </Button>
                </AsForm>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    server: state.server
  };
};
export default connect(mapStateToProps)(ProprietorAddEditModal);

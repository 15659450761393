//External Lib Import
import toastr from "toastr";

//Internal Lib Import
let EmailRegx = /\S+@\S+\.\S+/;
let MobileRegx = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
let validFileExtensions = [
  "image/jpg",
  "image/JPG",
  "image/jpeg",
  "image/JPEG",
  "image/PNG",
  "image/svg+xml",
  "image/png"
];

class FormHelper {
  IsEmpty(value) {
    return value.length === 0;
  }
  IsMobile(value) {
    return MobileRegx.test(value);
  }
  IsEmail(value) {
    return !EmailRegx.test(value);
  }
  ErrorToast(msg) {
    toastr.error(msg, { position: "bottom-center" });
  }

  SuccessToast(msg) {
    toastr.success(msg, { position: "bottom-center" });
  }
  InfoToast(msg) {
    toastr.info(msg, { position: "bottom-center" });
  }
  IsImageValid(imgExtension) {
    return validFileExtensions.includes(imgExtension);
  }
  IsImageValidSize(size) {
    return size < 10000;
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  validInput(currValue, checkValue) {
    if (currValue < 0) {
      return 0;
    } else if (currValue > checkValue) {
      return checkValue;
    } else {
      return currValue;
    }
  }
  validUnitPrice(currValue, checkValue) {
    if (currValue < checkValue) {
      return checkValue;
    } else {
      return currValue;
    }
  }
}
export const {
  validUnitPrice,
  validInput,
  IsEmpty,
  IsMobile,
  IsEmail,
  ErrorToast,
  getBase64,
  SuccessToast,
  IsImageValidSize,
  IsImageValid,
  InfoToast
} = new FormHelper();

import axios from "axios";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";
import { GET_STOCKS, GET_STOCKS_HISTORY } from "./types";

export const getStock = storeID => async dispatch => {
  serverLoading(dispatch, false);

  try {
    const { data } = await axios.get(`${SERVER_URL}/api/stock/get/` + storeID);
    dispatch({
      type: GET_STOCKS,
      payload: data.data
    });
  } catch (error) {
    console.log(error);
    serverLoading(dispatch, false);
    toastr.error(error?.response?.data?.message);
  }
};
export const getStockHistory = storeID => async dispatch => {
  serverLoading(dispatch, false);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/stock/history/` + storeID
    );
    dispatch({
      type: GET_STOCKS_HISTORY,
      payload: data.data
    });
  } catch (error) {
    console.log(error);
    serverLoading(dispatch, false);
    toastr.error(error?.response?.data?.message);
  }
};

import React, { Component } from "react";
import { Button, Card, Container, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { connect } from "react-redux";
import { updateAdminInvoice } from "../../../actions/ssInvoiceAction";

class InvoiceEditModal extends Component {
  state = {
    singleInvoice: {},
    dueDate: "",
    showModal: "",
    status: ""
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      singleInvoice: this.props.singleInvoice,
      status: this.props.singleInvoice.status,
      showModal: this.props.showModal
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      dueDate: nextProps.singleInvoice?.dueDate,
      singleInvoice: nextProps.singleInvoice,
      status: nextProps.singleInvoice.status,
      showModal: nextProps.showModal
    });
  }

  invoiceUpdateHandler = () => {
    const invoiceData = {
      _id: this.state.singleInvoice._id,
      dueDate: this.state.dueDate,
      status: this.state.status
    };
    this.props.updateAdminInvoice(invoiceData).then(result => {
      if (result) {
        this.setState({
          ...this.state,
          showModal: false
        });
      }
    });
  };

  paymentStatusHandler = event => {
    this.setState({
      ...this.state,
      status: event.target.value
    });
  };
  render() {
    const { singleInvoice } = this.props;

    return (
      <>
        <Modal
          show={this.state.showModal}
          onHide={this.props.closeModal}
          centered
        >
          <Modal.Header>
            <Modal.Title>{"Edit Invoice"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div>
                <span>Date</span>
                <ReactDatePicker
                  className="form-control w-100  mb-3"
                  minDate={new Date(singleInvoice.dueDate)}
                  selected={new Date(this.state.dueDate)}
                  onChange={date =>
                    this.setState({ ...this.state, dueDate: date })
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date"
                  height="1rem"
                />
              </div>
              <div className="mb-2">
                <select
                  className="form-control"
                  aria-label="Default select example"
                  onChange={this.paymentStatusHandler}
                  value={this.state.status}
                >
                  <option value="paid">Paid</option>
                  <option value="unpaid">Unpaid</option>
                </select>
              </div>

              <div className="float-right">
                <Button
                  type="submit"
                  variant="success"
                  onClick={this.invoiceUpdateHandler}
                >
                  {"Update "}
                </Button>
                <Button
                  type="submit"
                  variant="danger"
                  onClick={this.props.closeModal}
                  className="ml-2"
                >
                  Cancel
                </Button>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    proprietor: state.proprietor.proprietors,
    server: state.server
  };
};

export default connect(mapStateToProps, {
  updateAdminInvoice
})(InvoiceEditModal);

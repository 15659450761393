import React, { Component } from "react";
import { Link } from "react-router-dom";

export class NoInternet extends Component {
  render() {
    return (
      <div id="app">
        <section className="section">
          <div className="container mt-5">
            <div className="page-error">
              <div className="page-inner">
                <h1>Opps</h1>
                <div className="page-description">No internet connection</div>
                <div className="page-search">
                  <div className="mt-3">
                    <Link to="/">Back to Home</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="simple-footer mt-5">
              Copyright &copy; Shunno Software 2021
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NoInternet;

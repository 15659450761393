import {
  ADD_INVOICE,
  GET_INVOICES_OF_A_STORE,
  GET_QUICK_SELL_INVOICE,
  INVOICE_LOGO_ADD,
  UPDATE_INVOICE
} from "../actions/types";

const initialState = {
  isLoaded: false,
  invoices: [],
  quickSellInvoices: [],
  invoiceLogo: "",
  reDirect: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICES_OF_A_STORE:
      return {
        ...state,
        isLoaded: true,
        invoices: action.payload
      };
    case GET_QUICK_SELL_INVOICE:
      return {
        ...state,
        isLoaded: true,
        quickSellInvoices: action.payload
      };
    case ADD_INVOICE:
      if ("quickSellCustomer" in action.payload) {
        return {
          ...state,
          quickSellInvoices: [...state.quickSellInvoices, action.payload]
        };
      }
      return {
        ...state,
        invoices: [...state.invoices, action.payload]
      };

    case UPDATE_INVOICE:
      let index = state.invoices.findIndex(
        inv => inv._id === action.payload._id
      );
      let updated = [
        ...state.invoices.slice(0, index),
        action.payload,
        ...state.invoices.slice(index + 1)
      ];
      return {
        ...state,
        invoices: updated
      };

    case INVOICE_LOGO_ADD:
      return {
        ...state,
        invoiceLogo: action.payload.logo?.src
      };

    default:
      return state;
  }
};

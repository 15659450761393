import axios from "axios";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";
import { GET_SOLD_PRODUCTS } from "./types";
import toastr from "toastr";

export const getSoldProducts = storeID => async dispatch => {
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/invoice/get/sold-items/store/` + storeID
    );
    if (data.status) {
      dispatch({
        type: GET_SOLD_PRODUCTS,
        payload: data.data
      });
    }
  } catch (error) {
    serverLoading(dispatch, false);
    toastr.error(error?.response?.data?.message);
  }
};

import axios from "axios";
import toastr from "toastr";

import { SERVER_URL } from "../../config/config";
import { serverLoading } from "../../utils/utils";
import { CREATE_SITE_INFO, GET_SITE_INFO, UPDATE_SITE_INFO } from "../types";

export const getSiteInfo = ({ proprietorID, storeID }) => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/ecommerce/siteInfo/siteInfoList/${proprietorID}/${storeID}`
    );
    dispatch({
      type: GET_SITE_INFO,
      payload: data
    });
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const createSiteInfo = data => async dispatch => {
  serverLoading(dispatch, true);
  const filesData = Object.keys(data);
  try {
    const formData = new FormData();
    for (let i = 0; i < filesData.length; i++) {
      formData.append(filesData[i], data[filesData[i]]);
    }
    serverLoading(dispatch, true);
    const res = await axios({
      method: "post",
      url: `${SERVER_URL}/api/ecommerce/siteInfo/siteInfoCreate`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });
    dispatch({
      type: CREATE_SITE_INFO,
      payload: res.data.data
    });
    toastr.success("সাইট ইনফো সেভ সম্পন্ন হয়েছে।");
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const updateSiteInfo = data => async dispatch => {
  serverLoading(dispatch, true);
  const filesData = Object.keys(data);
  try {
    const formData = new FormData();
    for (let i = 0; i < filesData.length; i++) {
      formData.append(filesData[i], data[filesData[i]]);
    }
    serverLoading(dispatch, true);
    const res = await axios({
      method: "patch",
      url: `${SERVER_URL}/api/ecommerce/siteInfo/siteInfoUpdate/${data._id}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });
    dispatch({
      type: UPDATE_SITE_INFO,
      payload: res.data.data
    });
    toastr.success("সাইট ইনফো আপডেট সম্পন্ন হয়েছে।");
  } catch (error) {
    console.log(error);
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

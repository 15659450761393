import axios from "axios";
import $ from "jquery";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";
import {
  GET_SS_INVOICE,
  GET_SS_LIST_INVOICE,
  DELETE_SS_INVOICE,
  GET_ALL_SS_INVOICE,
  UPDATE_ADMIN_INVOICE
} from "./types";

export const monthlyServicesCharge = storeId => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/shunno-it/monthlyServicesCharge/${storeId}`
    );
    if (data.status) {
      dispatch({
        type: GET_SS_INVOICE,
        payload: data?.data
      });
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const getShunnoitListInvoice = storeId => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/shunno-it/listInvoice/${storeId}`
    );
    if (data.status) {
      dispatch({
        type: GET_SS_LIST_INVOICE,
        payload: data.data
      });
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const getAllSSInvoices = () => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/admin/shunno-it/invoice/`
    );
    if (data.status) {
      dispatch({
        type: GET_ALL_SS_INVOICE,
        payload: data.data
      });
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};
export const updateAdminInvoice = invoiceData => async dispatch => {
  serverLoading(dispatch, true);
  console.log(invoiceData);
  try {
    const { data } = await axios.put(
      `${SERVER_URL}/api/admin/shunno-it/invoice/${invoiceData?._id}`,
      invoiceData
    );
    if (data?.status) {
      toastr.success("Update Successfull");
      dispatch({
        type: UPDATE_ADMIN_INVOICE,
        payload: data?.data
      });
      return true;
    }
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  } finally {
    serverLoading(dispatch, false);
  }
};
export const deleteShunnoitInvoice = (storeID, invoiceID) => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.delete(
      `${SERVER_URL}/api/shunno-it/invoiceDelete/${storeID}/${invoiceID}`
    );
    if (data.status) {
      dispatch({
        type: DELETE_SS_INVOICE,
        payload: data.data
      });
    }

    $("#deleteModal").modal("hide");
  } catch (error) {
    console.log(error);
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

import React, { Component } from "react";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { formatDate, FormatNumber } from "../../utils/utils";
import { t } from "../../HNLanguage/i18nextSetUp";
import dateFilter from "../../utils/dateRangeFilter";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import PdfToPrint from "../OutputComponents/PdfToPrint";
import ReactToPrint from "react-to-print";

const { SearchBar } = Search;

class Profit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      startDate: new Date(),
      endDate: new Date(),
      viewProductInfo: [],
      products: [],
      soldProducts: [],
      quickSellInvoices: [],
      customers: []
    };
    this.componentRef = React.createRef();
  }
  componentDidMount = () => {
    this.setState({
      ...this.state,
      invoices: this.props.invoices,
      products: this.props.products,
      quickSellInvoices: this.props.quickSellInvoices,
      customers: this.props.customers
    });
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      invoices: nextProps.invoices,
      products: nextProps.products,
      quickSellInvoices: nextProps.quickSellInvoices,
      customers: this.props.customers
    });
  }
  render() {
    let {
      startDate,
      endDate,
      products,
      invoices,
      quickSellInvoices
    } = this.state;
    const { store } = this.props;
    const allInvoices = [...quickSellInvoices, ...invoices];

    // monthly filter date here
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    // table column
    let columns = [
      {
        dataField: "memo",
        text: t("memo")
      },
      {
        dataField: "_id",
        text: t("name"),
        formatter: (row, cell) => {
          return cell.soldItems.map((item, index) => {
            const found = products.find(
              product => product._id == item.productID
            );
            if (found) {
              return cell.soldItems.length - 1 === index
                ? `${found?.name}`
                : `${found?.name}, `;
            }
          });
        }
      },
      {
        dataField: "_id",
        text: t("quantity"),
        formatter: (row, cell) => {
          const totalQuantity = cell.soldItems.reduce((acc, curr) => {
            return (acc += curr.qty);
          }, 0);
          return totalQuantity;
        }
      },
      {
        dataField: "_id",
        text: t("totalPurchasePrice"),
        formatter: (row, cell) => {
          const totalPurchase = cell.soldItems.reduce((acc, curr) => {
            return (acc += curr.totalPurchasePrice);
          }, 0);
          return FormatNumber(totalPurchase);
        }
      },
      {
        dataField: "_id",
        text: t("totalSellPrice"),
        formatter: (row, cell) => {
          return FormatNumber(cell?.grandTotal);
        }
      },
      {
        dataField: "_id",
        text: t("discount"),
        formatter: (row, cell) => {
          return FormatNumber(cell?.totalDiscount);
        }
      },
      {
        dataField: "_id",
        text: t("profit"),
        formatter: (row, cell) => {
          const totalPurchase = cell.soldItems.reduce((acc, curr) => {
            return (acc += curr.totalPurchasePrice);
          }, 0);
          let profit = cell.grandTotal - Number(totalPurchase);
          return FormatNumber(profit);
        }
      },
      {
        dataField: "createdAt",
        text: t("date"),
        formatter: cell => <div>{formatDate(cell)}</div>
      },
      {
        dataField: "_id",
        text: t("option"),
        formatter: (row, cell) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown d-inline">
                <a href="#" data-toggle="dropdown" className="px-2">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link
                    to={`/customer/invoice/sellReport/${row}`}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-print"></i>
                    {t("printInvoice")}
                  </Link>
                </div>
              </div>
            </div>
          );
        }
      }
    ];

    let totalProfit = 0;
    let allFilterInvoices = [];
    if (allInvoices.length > 0) {
      if (startDate && endDate) {
        allFilterInvoices = dateFilter(
          allInvoices,
          startDate,
          endDate,
          "createdAt",
          true
        );
      }
      totalProfit = allFilterInvoices?.reduce((acc, curr) => {
        return (acc +=
          curr.grandTotal -
          curr.soldItems.reduce(
            (acc, curr) => (acc += curr.totalPurchasePrice),
            0
          ));
      }, 0);
    }
    const getProducts = (invoice) => {
      let foundProducts = [];
      invoice.soldItems.forEach(item => {
        const found = products.find(prod => prod._id === item.productID)?.name;
        if (found) {
          foundProducts.push(found);
        }
      })
      return foundProducts
    }
    const getQuantity = (invoice) => {
      const totalQuantity = invoice.soldItems.reduce((acc, curr) => {
        return (acc += curr.qty);
      }, 0);
      return totalQuantity;
    }
    const getTotalPurchasePrice = invoice => {
      const totalPurchase = invoice.soldItems.reduce((acc, curr) => {
        return (acc += curr.totalPurchasePrice);
      }, 0);
      return FormatNumber(totalPurchase);
    }
    const getProfit = invoice => {
      const totalPurchase = invoice.soldItems.reduce((acc, curr) => {
        return (acc += curr.totalPurchasePrice);
      }, 0);
      let profit = invoice.grandTotal - Number(totalPurchase);
      return FormatNumber(profit);
    }
    allFilterInvoices.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    return (
      <>
        <div style={{ display: "none" }}>
          <PdfToPrint ref={el => (this.componentRef = el)}>
            <div style={{ minHeight: 842 }} className="bg-white">
              <div className="p-3">
                <h3 className="text-center">{store.storeName}</h3>
                <div className="clearfix py-3">
                  <h5 className="float-left">{t("profit")}</h5>

                  <span className="float-right">
                    {new Date().toDateString()}{" "}
                    {new Date().toLocaleTimeString()}
                  </span>
                </div>
                <h6>{t("totalProfit")} : {FormatNumber(totalProfit)}</h6>
              </div>
              <table className="table table-bordered table-striped">
                <tr>
                  <th scope="col">{t("memo")}</th>
                  <th scope="col">{t("name")}</th>
                  <th scope="col">{t("quantity")}</th>
                  <th scope="col">{t("totalPurchasePrice")}</th>
                  <th scope="col">{t("totalSellPrice")}</th>
                  <th scope="col">{t("discount")}</th>
                  <th scope="col">{t("profit")}</th>
                  <th scope="col">{t("date")}</th>
                </tr>
                <tbody>
                  {allFilterInvoices &&
                    allFilterInvoices.map((invoice, index) => {
                      return (
                        <tr key={index}>
                          <td>{invoice?.memo}</td>
                          <td>{
                            getProducts(invoice) &&
                            getProducts(invoice).map((item, i) => {
                              return i + 1 !== getProducts(invoice).length ? (
                                <span>{item}, </span>
                              ) :
                                (
                                  <span>{item}</span>
                                )
                            })

                          }</td>
                          <td>
                            {getQuantity(invoice)}
                          </td>
                          <td>
                            {getTotalPurchasePrice(invoice)}
                          </td>
                          <td>{invoice?.grandTotal}</td>
                          <td>
                            {invoice?.totalDiscount}
                          </td>
                          <td>{getProfit(invoice)}</td>
                          <td>{formatDate(invoice.createdAt)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </PdfToPrint>
        </div>
        <div className="main-content">
          <section className="section">
            <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>{t("profit")}</h4>

              <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                  <ReactDatePicker
                    style={{ height: "2.4rem" }}
                    className="form-control w-140 mt-2"
                    selected={startDate}
                    onChange={date =>
                      this.setState({ ...this.state, startDate: date })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    height="1rem"
                  />
                </div>
                <div>-</div>
                <div className="col-md-3">
                  <ReactDatePicker
                    className="form-control w-140 mt-2"
                    selected={endDate}
                    onChange={date =>
                      this.setState({ ...this.state, endDate: date })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    maxDate={new Date()}
                  />
                </div>

                <button
                  className="btn  btn-info mt-2"
                  style={{ marginLeft: "-.4rem" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: new Date(),
                      endDate: new Date()
                    });
                  }}
                >
                  {t("daily")}
                </button>
                <button
                  className="btn btn-primary ml-2 mt-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      startDate: firstDay,
                      endDate: new Date()
                    });
                  }}
                >
                  {t("monthly")}
                </button>
              </div>
            </div>
            <div className="px-2 bg-white">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="clear-fix py-3">
                    <div className="float-left">
                      <p>
                        <strong>
                          {t("totalProfit")}: {FormatNumber(totalProfit)}
                          {t("taka")}
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div style={{ minHeight: 230 }} className="py-3">
                    {this.props.requestSend ? (
                      <div className="fa-3x text-center">
                        <i
                          style={{ fontSize: 40 }}
                          className="fas fa-sync fa-spin"
                        ></i>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [50, 100, 500, 1000, 2000, 5000]
                        })}
                      >
                        {({ paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={allFilterInvoices}
                            columns={columns}
                            search
                          >
                            {props => (
                              <>
                                <div>
                                  <SearchBar {...props.searchProps} />
                                  <ReactToPrint
                                    trigger={() => (
                                      <button
                                        title={t("exportPDF")}
                                        type="button"
                                        className="btn btn-info mx-2 my-3 float-right"
                                      >
                                        <i className="fas fa-file-pdf"></i>
                                      </button>
                                    )}
                                    content={() => this.componentRef}
                                  />
                                </div>
                                <hr />
                                <BootstrapTable
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                  wrapperClasses="table-responsive"
                                />
                              </>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    invoices: state.invoice.invoices,
    quickSellInvoices: state.invoice.quickSellInvoices,
    products: state.product.products,
    customers: state.customer.customers,
    store: state.store.store,
  };
};

export default connect(mapStateToProps)(Profit);

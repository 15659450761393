import { ADD_SALESMAN, GET_SALESMANS, UPDATE_SALESMAN, DELETE_SALESMAN } from "../actions/types";

const initialState = { salesMans: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SALESMANS:
      return {
        ...state,
        salesMans: action.payload
      };
    case ADD_SALESMAN:
      return {
        ...state,
        salesMans: [...state.salesMans, action.payload]
      };
    case UPDATE_SALESMAN:
      let index = state.salesMans.findIndex(
        manager => manager._id === action.payload._id
      );
      let updated = [
        ...state.salesMans.slice(0, index),
        action.payload,
        ...state.salesMans.slice(index + 1)
      ];
      return {
        ...state,
        salesMans: updated
      };
    case DELETE_SALESMAN:
      return {
        ...state,
        salesMans: state.salesMans.filter(sup => sup._id != action.payload)
      };

    // case PAY_SALARY:
    //   let sIndex = state.salesMans.findIndex(
    //     manager => manager._id === action.payload._id
    //   );
    //   let sUpdated = [
    //     ...state.salesMans.slice(0, sIndex),
    //     action.payload,
    //     ...state.salesMans.slice(sIndex + 1)
    //   ];
    //   return {
    //     ...state,
    //     salesMans: sUpdated
    //   };

    default:
      return state;
  }
};

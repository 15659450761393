import axios from "axios";
import toastr from "toastr";
import $ from "jquery";
import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

import {
  GET_PURCHASE_INVOICES,
  ADD_SUPPLIER_INVOICE,
  CLEAR_PURCHASE_CART,
  REDIRECT,
  UPDATE_SUPPLIER,
  ADD_LENDEN,
  DECREASE_CASH,
  ADD_SUPPLIER_PAYMENTS,
  GET_SUPPLIER_LEDGER_INVOICE,
  DELETE_PURCHASE_INVOICE,
  BULK_UPDATE_PRODUCT,
  STOCK_BULK_UPDATE,
  STOCK_HISTORY_BULK_UPDATE
} from "./types";

export const addSupplierInvoice = invoice => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/supplier/invoice/create`,
      invoice
    );

    serverLoading(dispatch, false);
    if (data.status == true) {
      // add supplier Invoice in redux store
      dispatch({
        type: ADD_SUPPLIER_INVOICE,
        payload: data.data.invoice
      });
      dispatch({
        type: BULK_UPDATE_PRODUCT,
        payload: data.data.products
      });
      dispatch({
        type: STOCK_BULK_UPDATE,
        payload: data.data.stocks
      });
      dispatch({
        type: STOCK_HISTORY_BULK_UPDATE,
        payload: data.data.stockHistories
      });

      // update supplier in redux store
      dispatch({
        type: UPDATE_SUPPLIER,
        payload: data.data.supplier
      });

      // update Lenden in redux store
      dispatch({
        type: ADD_LENDEN,
        payload: data.data.lenden
      });

      // Decrease Cache
      dispatch({
        type: DECREASE_CASH,
        payload: data.data.lenden.amount
      });
      dispatch({
        type: ADD_SUPPLIER_PAYMENTS,
        payload: data.data.payment
      });
      dispatch({
        type: CLEAR_PURCHASE_CART,
        payload: null
      });
      dispatch({
        type: REDIRECT,
        payload: {
          supplierID: data.data.supplier._id,
          invoiceID: data.data.invoice._id
        }
      });

      dispatch({
        type: GET_SUPPLIER_LEDGER_INVOICE,
        payload: data.data.ledger
      });
      toastr.success(data.msg);
    }
  } catch (error) {
    console.log(error);
    serverLoading(dispatch, false);
    toastr.error(error.response?.data?.message);
  }
};

export const getPurchaseInvoices = storeId => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/supplier/invoice/get?storeID=${storeId}`
    );

    if (data.status) {
      dispatch({
        type: GET_PURCHASE_INVOICES,
        payload: data.data
      });
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
  }
  serverLoading(dispatch, false);
};
export const deletePurchaseInvoice = deleteData => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios({
      method: "POST",
      url: `${SERVER_URL}/api/supplier/invoice/delete`,
      data: deleteData
    });
    if (data.status) {
      dispatch({
        type: DELETE_PURCHASE_INVOICE,
        payload: data.data
      });
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
    $("#deleteModal").modal("hide");
  }
  serverLoading(dispatch, false);
  $("#deleteModal").modal("hide");
  toastr.success("ইনভোয়েস ডিলিট সম্পন্ন হয়েছে।");
};

import {
  GET_EXPENDITURES,
  ADD_EXPENDITURE,
  UPDATE_EXPENDITURE,
  DELETE_EXPENDITURE,
} from "../actions/types";

const initialState = { isLoaded: false, expenditures: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPENDITURES:
      return {
        ...state,
        isLoaded: true,
        expenditures: action.payload,
      };
    case ADD_EXPENDITURE:
      return {
        ...state,
        expenditures: [...state.expenditures, action.payload],
      };
    case UPDATE_EXPENDITURE:
      let index = state.expenditures.findIndex(
        (exp) => exp._id === action.payload._id
      );
      let updated = [
        ...state.expenditures.slice(0, index),
        action.payload,
        ...state.expenditures.slice(index + 1),
      ];
      return {
        ...state,
        expenditures: updated,
      };
    case DELETE_EXPENDITURE:
      return {
        ...state,
        expenditures: state.expenditures.filter(
          (exp) => exp._id != action.payload
        ),
      };
    default:
      return state;
  }
};

import { t } from "../../../HNLanguage/i18nextSetUp";

const managerPermissions = [
  {
    isHeader: true,
    text: t("store")
  },
  {
    isChecked: false,
    text: t("showDashboard"),
    value: "showDashboard"
  },
  {
    isChecked: false,
    text: t("dashTotalInvoice"),
    value: "dashTotalInvoice"
  },
  {
    isChecked: false,
    text: t("dashTotalProfit"),
    value: "dashTotalProfit"
  },
  {
    isChecked: false,
    text: t("dashTotalPaidSale"),
    value: "dashTotalPaidSale"
  },
  {
    isChecked: false,
    text: t("dashInvoiceDue"),
    value: "dashInvoiceDue"
  },
  {
    isChecked: false,
    text: t("dashNewProducts"),
    value: "dashNewProducts"
  },
  {
    isChecked: false,
    text: t("dashTotalExpense"),
    value: "dashTotalExpense"
  },
  {
    isChecked: false,
    text: t("dashSupplierPayment"),
    value: "dashSupplierPayment"
  },
  {
    isChecked: false,
    text: t("dashPurchaseInvoiceDue"),
    value: "dashPurchaseInvoiceDue"
  },
  {
    isChecked: false,
    value: "manageStore",
    text: t("manageStore")
  },
  {
    isHeader: true,
    text: t("supplier")
  },
  {
    isChecked: false,
    value: "supplierAdd",
    text: t("supplierAdd"),
    group: "supplier"
  },
  {
    isChecked: false,
    value: "supplierEdit",
    text: t("supplierEdit")
  },
  {
    isChecked: false,
    value: "supplierDelete",
    text: t("supplierDelete")
  },
  {
    isChecked: false,
    value: "showSupplierInvoice",
    text: t("showSupplierInvoice"),
    group: "supplier"
  },
  {
    isChecked: false,
    value: "receivePaymentFromSupplier",
    text: t("receivePaymentFromSupplier"),
    group: "supplier"
  },
  {
    isChecked: false,
    value: "purchaseProduct",
    text: t("purchaseProduct"),
    group: "supplier"
  },
  {
    isHeader: true,
    text: t("product")
  },
  {
    isChecked: false,
    value: "addProduct",
    text: t("addProduct"),
    group: "product"
  },
  {
    isChecked: false,
    value: "productEdit",
    text: t("productEdit"),
    group: "product"
  },
  {
    isChecked: false,
    value: "productDelete",
    text: t("productDelete"),
    group: "product"
  },
  {
    isChecked: false,
    value: "sellProduct",
    text: t("sellProduct"),
    group: "product"
  },
  {
    isChecked: false,
    value: "discount",
    text: t("discount"),
    group: "product"
  },
  {
    isHeader: true,
    text: t("employee")
  },
  {
    isChecked: false,
    value: "editEmployee",
    text: t("editEmployee"),
    group: "employee"
  },
  {
    isChecked: false,
    value: "payEmployeeSalary",
    text: t("payEmployeeSalary"),
    group: "employee"
  },
  {
    isHeader: true,
    text: t("report")
  },
  {
    isChecked: false,
    value: "showSellReport",
    text: t("showSellReport"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showQuickSellReport",
    text: t("showQuickSellReport"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showSoldProduct",
    text: t("showSoldProduct"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showExpenditure",
    text: t("showExpenditure"),
    group: "report"
  },
  {
    isChecked: false,
    value: "addExpenditure",
    text: t("addExpenditure"),
    group: "report"
  },
  {
    isChecked: false,
    value: "deleteExpenditure",
    text: t("deleteExpenditure"),
    group: "report"
  },
  {
    isChecked: false,
    value: "editExpenditure",
    text: t("editExpenditure"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showIncomeExpense",
    text: t("showIncomeExpense"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showCashbox",
    text: t("showCashbox"),
    group: "report"
  },
  {
    isChecked: false,
    value: "useCashBox",
    text: t("useCashBox"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showStock",
    text: t("showStock"),
    group: "report"
  },
  // showBankTransaction{
  //   isChecked: false,
  // value:
  //   text: t("showBankTransaction")
  // },
  // manageBankTransaction{
  //   isChecked: false,
  // value:
  //   text: t("manageBankTransaction")
  // },
  {
    isHeader: true,
    text: t("installment")
  },
  {
    isChecked: false,
    value: "showInstallment",
    text: t("showInstallment"),
    group: "installment"
  },
  {
    isChecked: false,
    value: "receiveInstallment",
    text: t("receiveInstallment"),
    group: "installment"
  },
  {
    isChecked: false,
    value: "saleOnInstallment",
    text: t("saleOnInstallment"),
    group: "installment"
  }
];
const salesManPermission = [
  {
    isHeader: true,
    text: t("store")
  },
  {
    isChecked: false,
    text: t("showDashboard"),
    value: "showDashboard"
  },
  // {
  //   isChecked: false,
  //   value: "manageStore",
  //   text: t("manageStore")
  // },
  // {
  //   isHeader: true,
  //   text: t("supplier")
  // },
  // {
  //   isChecked: false,
  //   value: "supplierAdd",
  //   text: t("supplierAdd"),
  //   group: "supplier"
  // },
  // {
  //   isChecked: false,
  //   value: "supplierEdit",
  //   text: t("supplierEdit")
  // },
  // {
  //   isChecked: false,
  //   value: "supplierDelete",
  //   text: t("supplierDelete")
  // },
  // {
  //   isChecked: false,
  //   value: "showSupplierInvoice",
  //   text: t("showSupplierInvoice"),
  //   group: "supplier"
  // },
  // {
  //   isChecked: false,
  //   value: "receivePaymentFromSupplier",
  //   text: t("receivePaymentFromSupplier"),
  //   group: "supplier"
  // },
  // {
  //   isChecked: false,
  //   value: "purchaseProduct",
  //   text: t("purchaseProduct"),
  //   group: "supplier"
  // },
  {
    isHeader: true,
    text: t("product")
  },
  {
    isChecked: false,
    value: "addProduct",
    text: t("addProduct"),
    group: "product"
  },
  {
    isChecked: false,
    value: "productEdit",
    text: t("productEdit"),
    group: "product"
  },
  {
    isChecked: false,
    value: "productDelete",
    text: t("productDelete"),
    group: "product"
  },
  {
    isChecked: false,
    value: "sellProduct",
    text: t("sellProduct"),
    group: "product"
  },
  {
    isChecked: false,
    value: "discount",
    text: t("discount"),
    group: "product"
  },
  // {
  //   isHeader: true,
  //   text: t("employee")
  // },
  // {
  //   isChecked: false,
  //   value: "editEmployee",
  //   text: t("editEmployee"),
  //   group: "employee"
  // },
  // {
  //   isChecked: false,
  //   value: "payEmployeeSalary",
  //   text: t("payEmployeeSalary"),
  //   group: "employee"
  // },
  {
    isHeader: true,
    text: t("report")
  },
  {
    isChecked: false,
    value: "showSellReport",
    text: t("showSellReport"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showQuickSellReport",
    text: t("showQuickSellReport"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showSoldProduct",
    text: t("showSoldProduct"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showExpenditure",
    text: t("showExpenditure"),
    group: "report"
  },
  {
    isChecked: false,
    value: "addExpenditure",
    text: t("addExpenditure"),
    group: "report"
  },
  {
    isChecked: false,
    value: "deleteExpenditure",
    text: t("deleteExpenditure"),
    group: "report"
  },
  {
    isChecked: false,
    value: "editExpenditure",
    text: t("editExpenditure"),
    group: "report"
  },
  {
    isChecked: false,
    value: "showIncomeExpense",
    text: t("showIncomeExpense"),
    group: "report"
  },
  // {
  //   isChecked: false,
  //   value: "showCashbox",
  //   text: t("showCashbox"),
  //   group: "report"
  // },
  // {
  //   isChecked: false,
  //   value: "useCashBox",
  //   text: t("useCashBox"),
  //   group: "report"
  // },
  // {
  //   isChecked: false,
  //   value: "showStock",
  //   text: t("showStock"),
  //   group: "report"
  // },
  // showBankTransaction{
  //   isChecked: false,
  // value:
  //   text: t("showBankTransaction")
  // },
  // manageBankTransaction{
  //   isChecked: false,
  // value:
  //   text: t("manageBankTransaction")
  // },
  {
    isHeader: true,
    text: t("installment")
  },
  {
    isChecked: false,
    value: "showInstallment",
    text: t("showInstallment"),
    group: "installment"
  },
  {
    isChecked: false,
    value: "receiveInstallment",
    text: t("receiveInstallment"),
    group: "installment"
  },
  {
    isChecked: false,
    value: "saleOnInstallment",
    text: t("saleOnInstallment"),
    group: "installment"
  }
];

export { salesManPermission, managerPermissions };

import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { SERVER_URL } from "../config/config";

import { ADMIN_LOGIN, GET_ERRORS, SET_CURRENT_USER } from "./types";
import { serverLoading } from "../utils/utils";

export const register = userData => dispatch => {};

// Login - Get User Token
export const login = userData => dispatch => {
  serverLoading(dispatch, true);
  axios
    .post(`${SERVER_URL}/api/users/login`, userData)
    .then(res => {
      serverLoading(dispatch, false);
      // Save to localStorage
      const { token, status, data } = res.data;

      // if any error occured
      if (!status) {
        dispatch({
          type: GET_ERRORS,
          payload: data
        });

        return;
      }
      const user = jwt_decode(token);

      // Set token to localStorage

      if (user.type !== "admin") {
        localStorage.setItem("SsHnCcToken", token);

        // Redirect to Dashboard when user is proprietor
        window.location.href = "/";
      }
      if (user.type === "admin") {
        dispatch({
          type: ADMIN_LOGIN,
          payload: user
        });
        // Set token to localStorage
        localStorage.setItem("adminToken", token);
        window.location.href = "/admin/proprietor";
      }

      // Set token to Auth header
      // setAuthToken(token)
      // // Decode token to get user data
      // const decoded = jwt_decode(token)
      // // Set current user
      // dispatch(setCurrentUser(decoded))
      // dispatch({
      //   type: GET_STORE,
      //   payload: decoded.store
      // })
    })
    .catch(error => {
      serverLoading(dispatch, false);
      dispatch({
        type: GET_ERRORS,
        payload:
          "Something went again. Please check your username and password. Then Try again."
      });
    });
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logout = () => dispatch => {
  // Remove the token from localStorage
  localStorage.removeItem("cart");
  localStorage.removeItem("SsHnCcToken");
  localStorage.removeItem("adminToken");
  localStorage.removeItem("activeStore");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} and set isAuthenticated to false
  dispatch(setCurrentUser({}));

  window.location.href = "/auth/login";
};

import {
  ADD_SLIDER_IMAGES,
  DELETE_SLIDER_IMAGES,
  GET_SLIDER_IMAGES,
  UPDATE_SLIDER_IMAGES
} from "../actions/types";

const initialState = {
  homeSlider: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SLIDER_IMAGES:
      return {
        ...state,
        homeSlider: action.payload
      };
    case ADD_SLIDER_IMAGES:
      return {
        ...state,
        homeSlider: [...state.homeSlider, ...action.payload]
      };
    case UPDATE_SLIDER_IMAGES:
      const updatedData = state.homeSlider.map(slider => {
        if (slider._id === action.payload._id) {
          return {
            ...action.payload
          };
        }
        return slider;
      });
      return {
        ...state,
        homeSlider: updatedData
      };
    case DELETE_SLIDER_IMAGES:
      return {
        ...state,
        homeSlider: state.homeSlider.filter(
          slider => slider._id != action.payload._id
        )
      };
    default:
      return state;
  }
};

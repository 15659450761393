// PRODUCTION
export const SERVER_URL = "https://hisabnikash.top";
// export const SERVER_URL = "https://hisabnikash.net";

// DEVELOPMENT;
// export const SERVER_URL = "http://localhost:6100";
// export const SERVER_URL = "http://45.32.105.213:6100";
// export const SERVER_URL = "http://192.168.1.2:6100";
// export const SERVER_URL = "https://hisabnikash.net";
// export const SERVER_URL = "http://49.28.137.217:6100";
// export const SERVER_URL = "http://192.168.1.27:6100";
// export const SERVER_URL = "http://192.168.1.10:6100";

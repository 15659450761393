import React, { Component } from "react";

import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import SideBar from "../../../components/admin/sidebar";
import { Header } from "../../../components/admin";

import { resetServerState } from "../../../actions/serverActions";
import { badge } from "../../../utils/badge";
import { getAllSSInvoices } from "../../../actions/ssInvoiceAction";

import { t } from "../../../HNLanguage/i18nextSetUp";
import { Button } from "react-bootstrap";
import { formatDate } from "../../../utils/utils";
import InvoiceEditModal from "./InvoiceEditModal";

const { SearchBar } = Search;

class AllInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proprietors: props.proprietor || [],
      ssAllInovices: [],
      stores: props.stores || [],
      showModal: false,
      singleInvoice: {},
      paymentStatus: "",
      paymentMethod: "",
      smsPurchaseType: ""
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      ssAllInovices: this.props.ssAllInovices,
      proprietors: this.props.proprietors
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      ssAllInovices: nextProps.ssAllInovices,
      proprietors: nextProps.proprietors
    });
  }

  invoiceEditHandler = id => {
    const singleInvoice = this.state.ssAllInovices.find(
      invoice => invoice._id === id
    );
    this.setState({
      ...this.state,
      showModal: true,
      singleInvoice
    });
  };
  /// edit modal
  closeModal = () => {
    this.setState({
      ...this.state,
      showModal: false
    });
  };
  paymentStatusHandler = e => {
    this.setState({ ...this.state, paymentStatus: e.target.value });
  };
  paymentMethodHandler = e => {
    this.setState({ ...this.state, paymentMethod: e.target.value });
  };
  smsTypeHandler = e => {
    this.setState({ ...this.state, smsPurchaseType: e.target.value });
  };

  render() {
    const isLoaded = true;
    const { ssAllInovices, proprietors } = this.state;

    let filteredSSInvoices = [...this.state.ssAllInovices];
    if (this.state.paymentStatus) {
      filteredSSInvoices = filteredSSInvoices.filter(
        invoice => invoice.status === this.state.paymentStatus
      );
    }
    if (this.state.paymentMethod) {
      filteredSSInvoices = filteredSSInvoices.filter(
        invoice => invoice.paymentMethod === this.state.paymentMethod
      );
    }
    if (this.state.smsPurchaseType) {
      filteredSSInvoices = filteredSSInvoices.filter(
        invoice => invoice.smsPurchaseType === this.state.smsPurchaseType
      );
    }
    let columns = [
      {
        dataField: "_id",
        text: "Proprietor",
        sort: true,
        formatExtraData: proprietors,
        formatter: (invoiceId, row, _index, proprietors) => {
          let proprietor = proprietors.find(
            proprietor => proprietor._id == row.proprietor
          );
          return proprietor ? proprietor.name : "";
        }
      },
      {
        dataField: "amount",
        text: "Amount"
      },
      {
        dataField: "status",
        text: "Status",
        formatter: data => {
          return <div> {badge(data)}</div>;
        }
      },
      {
        dataField: "paymentMethod",
        text: "Payment Method"
      },
      {
        dataField: "numberOfSms",
        text: "SMS"
      },
      {
        dataField: "smsPurchaseType",
        text: "SMS Type"
      },
      {
        dataField: "type",
        text: "Type"
      },
      {
        dataField: "createdAt",
        text: "Created At",
        formatter: (row, cell) => formatDate(row)
      },
      {
        dataField: "dueDate",
        text: "Last Date",
        formatter: (row, cell) => formatDate(row)
      },
      {
        dataField: "paidAt",
        text: "Paid At",
        formatter: (row, cell) => formatDate(row)
      },
      {
        dataField: "_id",
        text: "অপশন",

        formatter: (cell, _row) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown d-inline">
                <a href="#" data-toggle="dropdown" className="px-2">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    type="button"
                    className="dropdown-item has-icon"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => this.invoiceEditHandler(cell)}
                  >
                    <i className="fas fa-edit" />
                    Edit
                  </a>

                  {/* <div className="dropdown-divider" /> */}
                </div>
              </div>
            </div>
          );
        }
      }
    ];

    return (
      <>
        <Header />
        <SideBar />
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>All Invoice</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 p-0">
                  <div className="card p-3">
                    <div className="card-body px-0">
                      {!isLoaded ? (
                        <div
                          style={{ minHeight: 230 }}
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <div className="fa-3x">
                            <i
                              style={{ fontSize: 40 }}
                              className="fas fa-sync fa-spin"
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className=" clear-fix px-3">
                            <div className="d-flex justify-content-start ">
                              <div>
                                <select
                                  className="form-control"
                                  aria-label="Default select example"
                                  onChange={this.paymentStatusHandler}
                                  value={this.state.paymentStatus}
                                >
                                  <option value="" selected>
                                    Payment Status
                                  </option>
                                  <option value="paid">Paid</option>
                                  <option value="unpaid">Unpaid</option>
                                </select>
                              </div>
                              <div className="ml-2">
                                <select
                                  className="form-control"
                                  aria-label="Default select example"
                                  onChange={this.paymentMethodHandler}
                                  value={this.state.paymentMethod}
                                >
                                  <option value="" selected>
                                    Payment method
                                  </option>
                                  <option value="bkash">Bkash</option>
                                  <option value="bKashPG">bKashPG</option>
                                  <option value="others">Others</option>
                                </select>
                              </div>
                              <div className="ml-2">
                                <select
                                  className="form-control"
                                  aria-label="Default select example"
                                  onChange={this.smsTypeHandler}
                                  value={this.state.smsPurchaseType}
                                >
                                  <option value="" selected>
                                    SMS Type
                                  </option>
                                  <option value="nonMasking">
                                    Non-Masking
                                  </option>
                                  <option value="masking">Masking</option>
                                  <option value="fixedNumber">
                                    Fixed Number
                                  </option>
                                </select>
                              </div>
                              <div className="ml-2">
                                <button
                                  className="btn btn-primary p-2 "
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      paymentMethod: "",
                                      paymentStatus: "",
                                      smsPurchaseType: ""
                                    });
                                  }}
                                >
                                  {t("Reset")}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div style={{ minHeight: 230 }} className="p-3">
                            <PaginationProvider
                              pagination={paginationFactory({
                                sizePerPageList: [
                                  50,
                                  100,
                                  500,
                                  1000,
                                  2000,
                                  5000
                                ]
                              })}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  bootstrap4
                                  keyField="_id"
                                  data={filteredSSInvoices}
                                  columns={columns}
                                  search
                                >
                                  {props => (
                                    <div>
                                      <div className="px-1">
                                        <SearchBar {...props.searchProps} />
                                      </div>
                                      <hr />
                                      <BootstrapTable
                                        {...props.baseProps}
                                        {...paginationTableProps}
                                        {...paginationProps}
                                        wrapperClasses="table-responsive"
                                      />
                                    </div>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <InvoiceEditModal
          singleInvoice={this.state.singleInvoice}
          showModal={this.state.showModal}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    server: state.server,
    ssAllInovices: state.ssInvoice.ssAllInvoices,
    proprietors: state.proprietor.proprietors
  };
};

export default connect(mapStateToProps, {
  resetServerState,
  getAllSSInvoices
})(AllInvoice);

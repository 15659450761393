import axios from "axios";
import toastr from "toastr";

import { SERVER_URL } from "../config/config";
import { getCurrentUser, serverLoading } from "../utils/utils";
import { logout } from "./authActions";

import {
  GET_ERRORS,
  GET_STORE,
  GET_STORES,
  PROPRIETOR_STORE_UPDATE
} from "./types";

const makeFormData = data => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });
  return formData;
};

// Get current store
export const getStores = () => dispatch => {
  serverLoading(dispatch, true);
  axios
    .get(`${SERVER_URL}/api/store/get`)
    .then(res => {
      serverLoading(dispatch, false);
      if (res.data.data.length > 0) {
        const { role, storeID } = getCurrentUser();
        const activeStore = localStorage.getItem("activeStore");
        const existingStore =
          activeStore && activeStore !== "undefined" && JSON.parse(activeStore);

        if (existingStore?._id) {
          dispatch({
            type: GET_STORES,
            payload: res.data.data
          });
          dispatch({
            type: GET_STORE,
            payload: existingStore
          });
        } else {
          dispatch({
            type: GET_STORES,
            payload: res.data.data
          });
          if (role === "proprietor") {
            dispatch({
              type: GET_STORE,
              payload: res.data.data[0]
            });
          } else {
            dispatch({
              type: GET_STORE,
              payload: res.data.data.find(item => item._id === storeID)
            });
          }
          window.location.reload();
        }
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      dispatch({
        type: GET_ERRORS,
        payload: {}
      });
    });
};
// Get current store
export const getStore = storeID => dispatch => {
  serverLoading(dispatch, true);
  axios
    .get(`${SERVER_URL}/api/store/get/` + storeID)
    .then(res => {
      serverLoading(dispatch, false);
      if (res.data.data) {
        dispatch({
          type: GET_STORE,
          payload: res.data.data
        });
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      console.log(error);
    });
};

export const updateStore = data => dispatch => {
  serverLoading(dispatch, true);
  axios
    .put(`${SERVER_URL}/api/store/edit/${data.storeId}`, data)
    .then(res => {
      serverLoading(dispatch, false);
      toastr.success("স্টোর আপডেট সম্পন্ন হয়েছে।");
      dispatch({
        type: GET_STORE,
        payload: res.data.data
      });
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error?.response?.data?.message);
    });
};

export const addStore = storeData => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(`${SERVER_URL}/api/store/add`, storeData);
    if (!data.status) {
      return toastr.error(data.msg);
    }
    toastr.success("স্টোর তৈরি সম্পন্ন হয়েছে।");
    dispatch(logout());
  } catch (error) {
    toastr.error(error?.response?.data?.message);
    console.log(error);
  }
  serverLoading(dispatch, false);
};

export const adminAddStore = storeData => async dispatch => {
  // const formData = makeFormData(storeData);
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/admin/store/add`,
      storeData
    );

    if (data.status) {
      toastr.success(data.msg);
      return true;
    }
  } catch (error) {
    toastr.error(error.response?.data?.message);
    console.log(error);
  } finally {
    serverLoading(dispatch, false);
  }
};

// export const proprietorStoreUpdate = storeUpdate => dispatch => {
//   serverLoading(dispatch, true);
//   axios
//     .put(`${SERVER_URL}/api/admin/store/${storeUpdate._id}`, storeUpdate)
//     .then(res => {
//       serverLoading(dispatch, false);
//       toastr.success("স্টোর আপডেট সম্পন্ন হয়েছে।");

//       dispatch({
//         type: PROPRIETOR_STORE_UPDATE,
//         payload: res.data.data
//       });
//     })
//     .catch(error => {
//       serverLoading(dispatch, false);
//       toastr.error(error.response?.data?.message);
//     });
// };
export const proprietorStoreUpdate = storeUpdate => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.put(
      `${SERVER_URL}/api/admin/store/${storeUpdate.storeId}`,
      storeUpdate
    );

    if (data.status) {
      dispatch({
        type: PROPRIETOR_STORE_UPDATE,
        payload: data.data
      });
      toastr.success("স্টোর আপডেট সম্পন্ন হয়েছে।");
      return true;
    }
  } catch (error) {
    serverLoading(dispatch, false);
    toastr.error(error.response?.data?.message);
  } finally {
    serverLoading(dispatch, false);
  }
};

import React from "react";
import Modal from "../../Settings/Modal";
import { t } from "../../../HNLanguage/i18nextSetUp";

const SupplierAddEditModal = ({
  supplier,
  modalTitle,
  addSupplier,
  addSupplierHandler,
  updateSupplierHandler,
  onChangeHandler,
  errors
}) => {
  const column = [
    {
      label: t("companyName"),
      type: "text",
      name: "company",
      value: supplier?.company || ""
    },
    {
      label: t("accountNumber"),
      type: "number",
      name: "accountNumber",
      value: supplier?.accountNumber || ""
    },
    {
      label: t("name"),
      type: "text",
      name: "name",
      value: supplier?.name || "",
      errors: errors?.name
    },
    {
      label: t("mobile"),
      type: "text",
      name: "mobile",
      value: supplier?.mobile || "",
      errors: errors?.mobile
    },
    {
      label: t("email"),
      type: "email",
      name: "email",
      value: supplier?.email || ""
    },
    {
      label: t("NID"),
      type: "text",
      name: "nid",
      value: supplier?.nid || ""
    },
    {
      label: t("address"),
      type: "text",
      name: "address",
      value: supplier?.address || ""
    },
    {
      label: t("previousDue"),
      type: "number",
      name: "due",
      value: supplier?.due || ""
    },
    {
      label: t("refName"),
      type: "text",
      name: "refName",
      value: supplier?.reference?.refName || ""
    },
    {
      label: t("refMobile"),
      type: "text",
      name: "refMobile",
      value: supplier?.reference?.refMobile || ""
    },
    {
      label: t("refAddress"),
      type: "text",
      name: "refAddress",
      value: supplier?.reference?.refAddress || ""
    }
  ];
  return (
    <Modal
      onSubmit={addSupplier ? addSupplierHandler : updateSupplierHandler}
      modalTitle={modalTitle}
    >
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        {column.map(item => {
          return (
            <div key={item.name} className="form-group">
              <label htmlFor={item.label}>{item.label}</label>
              <div className="input-group">
                {item.type === "number" ? (
                  <input
                    type={item.type}
                    className="form-control"
                    step="any"
                    min={0}
                    name={item.name}
                    value={item.value || ""}
                    onChange={onChangeHandler}
                  />
                ) : (
                  <input
                    type={item.type}
                    className="form-control"
                    name={item.name}
                    value={item.value || ""}
                    onChange={onChangeHandler}
                  />
                )}
                {item.errors && item.errors.name && (
                  <div className="invalid-feedback text-right">
                    {item.errors.name}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default SupplierAddEditModal;

//External Lib Import

import React, { Component } from "react";
import { connect } from "react-redux";

class ApiLoader extends Component {
  render() {
    const { requestSend } = this.props.server;

    return (
      <div className={requestSend ? "LoadingOverlay" : "d-none"}>
        <div className="loading__overlay">
          <div className="indeterminate"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state.store.store,
    server: state.server
  };
};

export default connect(mapStateToProps, {})(ApiLoader);

{
  /* 
   <div className={requestSend ? "LoadingOverlay" : "d-none"}>
      <div className="preloader">
        <div className="preloader__wrap">
          <svg className="spinner-container" viewBox="0 0 52 52">
            <circle
              className="path"
              cx="26px"
              cy="26px"
              r="20px"
              fill="none"
              strokeWidth="4px"
            />
          </svg>
          <div className="preloader__progress">
            <span style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </div> */
}

{
  /* <div className={true ? "LoadingOverlay" : "d-none"}>
      <div className="loading__overlay">
        <div className="indeterminate"></div>
      </div>
    </div> */
}

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
    getCustomers,

} from "../../../actions/customerActions";
import { getProducts } from "../../../actions/productActions";
import { FormatNumber } from "../../../utils/utils";
import { t } from "../../../HNLanguage/i18nextSetUp";

import { Button, Card, Col, Container, Form, FormControl, FormGroup, FormLabel, FormSelect, Row, Table } from 'react-bootstrap';


class SaleReturn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: props.customers,
            products: props.products,
            saleReturnCart: [],
            total: 0,
            deductables: 0
        };

        this.customerRef = React.createRef()
        this.productRef = React.createRef()
        this.vatRef = React.createRef()
        this.shippingRef = React.createRef()
        this.discountRef = React.createRef()
        this.otherCostRef = React.createRef()
    }

    addToCartHandler = () => {
        const prodId = this.productRef.current.value;
        if (prodId !== "") {
            let prod = this.state.products.find(item => item._id === prodId)
            prod = { ...prod, qty: 1, unitPrice: prod.sellPrice }
            this.setState({
                ...this.state,
                saleReturnCart: [...this.state.saleReturnCart, prod],
                total: this.state.total + prod.unitPrice * prod.qty,
                // grandTotal: this.state.total + prod.unitPrice * prod.qty,
            })

        }

    }

    qtyChangeHandler = (id, e) => {
        const updatedQty = this.state.saleReturnCart.map(item => {
            if (item._id === id) {
                item.qty = e.target.value
            }
            return item
        })
        this.setState({
            ...this.state,
            saleReturnCart: updatedQty,
            total: updatedQty.reduce((acc, curr) => {
                acc += curr.unitPrice * curr.qty
                return acc
            }, 0),

        })
    }
    unitPriceChangeHandler = (id, e) => {
        const updatedUnitPrice = this.state.saleReturnCart.map(item => {
            if (item._id === id) {
                item.unitPrice = e.target.value
            }
            return item
        })
        this.setState({
            ...this.state,
            saleReturnCart: updatedUnitPrice,
            total: updatedUnitPrice.reduce((acc, curr) => {
                acc += curr.unitPrice * curr.qty
                return acc
            }, 0),
        })
    }
    handleRemove = id => {
        const filteredCart = this.state.saleReturnCart.filter(item => item._id !== id)
        this.setState({
            ...this.state,
            saleReturnCart: filteredCart,
            total: filteredCart.reduce((acc, curr) => {
                acc += curr.unitPrice * curr.qty
                return acc
            }, 0),
        })
    }
    handleSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target),
            formDataObj = Object.fromEntries(formData.entries())
        const customer = this.state.customers?.find((item) => item._id === this.customerRef.current.value)
        const saleReturnFormData = { ...formDataObj, products: this.state.saleReturnCart, customer }
    }

    onChangeHandler = () => {
        this.setState({
            ...this.state,
            deductables: Number(this.vatRef?.current?.value) - Number(this.discountRef?.current?.value) + Number(this.otherCostRef?.current?.value) + Number(this.shippingRef?.current?.value)
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            customers: nextProps.customers,
            products: nextProps.products,
        });
    }

    render() {
        let {

            customers,
            products,
            saleReturnCart,
            total,
            deductables

        } = this.state;
        const grandTotal = total ? total + deductables : 0
        let { store } = this.props;

        const { server } = this.props;

        return (
            <div className="main-content">
                <Container fluid>
                    <Form onSubmit={this.handleSubmit}>
                        <Row>

                            <Col xs={12} md={3} className='mb-3'>
                                <Card className='h-100'>
                                    <Card.Body>
                                        <Row>
                                            <h5>Create Sale Return</h5>
                                            <hr className="bg-light" />

                                            <Col xs={12} className='p-1'>
                                                <FormGroup>
                                                    <FormLabel>
                                                        Customer
                                                    </FormLabel>
                                                    <br />
                                                    <FormSelect required className='form-control form-control-sm mr-1' ref={this.customerRef}>
                                                        <option key='blankChoice' hidden value=""> Select Customer</option>
                                                        {/* <option value="">
                                                        Select Customer
                                                    </option> */}
                                                        {
                                                            customers && customers.map((op, i) =>
                                                                <option key={i + 1} value={op._id}>{op.name}</option>)
                                                        }
                                                    </FormSelect>
                                                </FormGroup>

                                            </Col>
                                            <Col xs={12} className='p-1'>
                                                <FormGroup>
                                                    <FormLabel>
                                                        Vat/Tax
                                                    </FormLabel>
                                                    <FormControl name='vat' onChange={this.onChangeHandler} size="sm" min={0} type='number' defaultValue={0} ref={this.vatRef}>

                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} className='p-1'>
                                                <FormGroup>
                                                    <FormLabel>
                                                        Discount
                                                    </FormLabel>
                                                    <FormControl name='discount' onChange={this.onChangeHandler} size="sm" min={0} type='number' defaultValue={0} ref={this.discountRef}>

                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} className='p-1'>
                                                <FormGroup>
                                                    <FormLabel>
                                                        Other Cost
                                                    </FormLabel>
                                                    <FormControl name='otherCost' onChange={this.onChangeHandler} size="sm" min={0} type='number' defaultValue={0} ref={this.otherCostRef}>

                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} className='p-1'>
                                                <FormGroup>
                                                    <FormLabel>
                                                        Shipping Cost
                                                    </FormLabel>
                                                    <FormControl name='shippingCost' onChange={this.onChangeHandler} size="sm" min={0} type='number' defaultValue={0} ref={this.shippingRef}>

                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} className='p-1'>
                                                <FormGroup>
                                                    <FormLabel>
                                                        Grand Total
                                                    </FormLabel>
                                                    <FormControl name='grandTotal' readOnly size="sm" type='number' value={grandTotal}>

                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} className='p-1'>
                                                <FormGroup>
                                                    <FormLabel>
                                                        Note
                                                    </FormLabel>
                                                    <FormControl name='note' size="sm" type='text'>

                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} className='p-1'>
                                                <FormGroup>
                                                    <FormLabel>
                                                        Memo No
                                                    </FormLabel>
                                                    <FormControl required name='memo' size="sm" type='text'>

                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} className='p-1'>
                                                <Button type='submit'>Create</Button>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={9} className="mb-3">
                                <Card className="h-100">
                                    <Card.Body>
                                        <Row>
                                            <Col xs={8} className="p-1">
                                                <FormLabel>Select Product</FormLabel>
                                                <br />
                                                <FormSelect required className='form-control form-control-sm mr-1' ref={this.productRef}>
                                                    <option value="" hidden>Select Product</option>
                                                    {
                                                        products && products.map(product =>
                                                            <option key={product._id} value={product._id}>{product.name}</option>
                                                        )
                                                    }
                                                </FormSelect>
                                            </Col>
                                            {/* <Col xs={2} className="p-1">
                                            <FormLabel>Qty</FormLabel>
                                            <FormControl ref={qtyRef} size="sm" type="number" />
                                        </Col>
                                        <Col xs={2} className="p-1">
                                            <FormLabel>Unit Price</FormLabel>
                                            <FormControl ref={unitPriceRef} size="sm" type="number" />
                                        </Col> */}
                                            <Col xs={4} className="p-1 text-right">
                                                <FormLabel>Add to cart</FormLabel><br />
                                                <Button className='ml-2' onClick={this.addToCartHandler} variant="primary" size="sm">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"></path>
                                                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                                                    </svg>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="table-responsive table-section">
                                                    <Table className="table-sm text-center" hover>
                                                        <thead className="sticky-top bg-white">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th>Qty</th>
                                                                <th>Unit Price</th>
                                                                <th>Total</th>
                                                                <th>Remove</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                saleReturnCart &&
                                                                saleReturnCart.map((product, i) =>
                                                                    <tr key={product._id}>
                                                                        <td>{i + 1}</td>
                                                                        <td className='text-left pl-3'>{product.name}</td>
                                                                        <td><input onChange={(e) => this.qtyChangeHandler(product._id, e)} style={{ width: 100, height: 30 }}
                                                                            className="form-control d-inline mx-2 py-0 text-center" type="number" defaultValue={1} /></td>
                                                                        <td><input onChange={(e) => this.unitPriceChangeHandler(product._id, e)} style={{ width: 100, height: 30 }}
                                                                            className="form-control d-inline mx-2 py-0 text-center" type="number" defaultValue={product.sellPrice} /></td>
                                                                        <td>{product.qty * product.unitPrice}</td>
                                                                        <td><Button onClick={() => this.handleRemove(product._id)} variant='danger' size='sm' className='p-auto'>X</Button></td>
                                                                    </tr>
                                                                )

                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Form>
                </Container>
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        customers: state.customer.customers,
        products: state.product.products,
    };
};

export default connect(mapStateToProps, {
    getCustomers,
    getProducts

})(SaleReturn);

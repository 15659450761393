import { GET_ORDERS, UPDATE_ORDER, DELETE_ORDER } from "../actions/types";

const initialState = {
  orders: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload
      };
    case UPDATE_ORDER:
      const updatedOrder = state.orders.map(order => {
        if (order._id === action.payload._id) {
          return {
            products: order.products,
            customer: order.customer,
            orderItems: order.orderItems,
            ...action.payload
          };
        }
        return order;
      });
      return {
        ...state,
        orders: updatedOrder
      };
    case DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter(order => order._id != action.payload)
      };
    default:
      return state;
  }
};

import axios from "axios";
import toastr from "toastr";
import $ from "jquery";
import {
  GET_EMPLOYEES,
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  PAY_SALARY,
  SEND_REQUEST,
  RESPONSE_SUCCESS,
  DECREASE_CASH,
  DELETE_EMPLOYEE,
  ADD_LENDEN
} from "./types";

import { SERVER_URL } from "../config/config";
import { serverLoading } from "../utils/utils";

export const getEmployees = storeId => dispatch => {
  axios
    .get(`${SERVER_URL}/api/staff/list/${storeId}`)
    .then(res => {
      serverLoading(dispatch, false);
      dispatch({
        type: GET_EMPLOYEES,
        payload: res.data.data
      });
    })
    .catch(error => toastr.error(error?.response?.data?.message));
};

export const addEmployee = employeeData => dispatch => {
  serverLoading(dispatch, true);
  axios
    .post(`${SERVER_URL}/api/staff/add`, employeeData)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        dispatch({
          type: ADD_EMPLOYEE,
          payload: data.data
        });
        toastr.success("কর্মচারী সেভ সম্পন্ন হয়েছে।");
      }
      $("#exampleModal").modal("hide");
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error.response?.data?.message);
    });
};

export const updateEmployee = employee => dispatch => {
  serverLoading(dispatch, true);
  axios
    .put(`${SERVER_URL}/api/staff/edit/${employee._id}`, employee)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        dispatch({
          type: UPDATE_EMPLOYEE,
          payload: data.data
        });
        toastr.success("কর্মচারী আপডেট সম্পন্ন হয়েছে।");
      }
      $("#exampleModal").modal("hide");
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error.response?.data?.message);
    });
};

export const paySalary = ({ employeeID, amount, ...rest }) => dispatch => {
  serverLoading(dispatch, true);
  dispatch({ type: SEND_REQUEST });
  axios
    .put(`${SERVER_URL}/api/staff/salary/${employeeID}`, {
      ...rest,
      amount
    })
    .then(({ data }) => {
      serverLoading(dispatch, false);
      if (data.status == true) {
        dispatch({
          type: PAY_SALARY,
          payload: data.data
        });
        dispatch({
          type: ADD_LENDEN,
          payload: data.dataweb.lenden
        });
        dispatch({
          type: DECREASE_CASH,
          payload: amount
        });
        toastr.success("সেলারী প্রদান সম্পন্ন হয়েছে।");
        dispatch({ type: RESPONSE_SUCCESS });
      }
    })
    .catch(error => {
      serverLoading(dispatch, false);
      toastr.error(error.response?.data?.message);
    });
};
export const deleteEmployee = employeeId => dispatch => {
  serverLoading(dispatch, true);

  axios
    .delete(`${SERVER_URL}/api/staff/delete/${employeeId}`)
    .then(({ data }) => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      dispatch({
        type: DELETE_EMPLOYEE,
        payload: employeeId
      });
      toastr.success("কর্মচারী ডিলিট হয়েছে।");
    })
    .catch(error => {
      serverLoading(dispatch, false);
      $("#deleteModal").modal("hide");
      toastr.error("সার্ভার থেকে সাড়া পাওয়া যায়নি");
    });
};

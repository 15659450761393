import axios from "axios";
import $ from "jquery";
import toastr from "toastr";

import { SERVER_URL } from "../config/config";
import store from "../store";
import { serverLoading } from "../utils/utils";

import {
  ADD_PRODUCT,
  GET_PRODUCTS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT_DETAILS,
  PRODUCT_BULK_IMPORT
} from "./types";

const convertingToFormData = productData => {
  const formData = new FormData();

  Object.keys(productData).forEach(item => {
    if (item === "productImages") {
      productData[item].forEach(img => {
        formData.append("productImages", img);
      });
    } else if (item === "units") {
      formData.append("units", JSON.stringify(productData[item]));
    } else if (item === "productDetails") {
      formData.append("productDetails", JSON.stringify(productData[item]));
    } else if (item === "invoiceItems") {
      formData.append("invoiceItems", JSON.stringify(productData[item]));
    } else formData.append(item, productData[item]);
  });
  return formData;
};

export const getProducts = storeId => async dispatch => {
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/product/get/${storeId}`
    );
    dispatch({
      type: GET_PRODUCTS,
      payload: data.data
    });
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
};

export const getProductsDetails = storeId => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios.get(
      `${SERVER_URL}/api/product/productDetails/${storeId}`
    );

    dispatch({
      type: GET_PRODUCT_DETAILS,
      payload: data.data
    });
  } catch (error) {
    toastr.error(error?.response?.data?.message);
  }
  serverLoading(dispatch, false);
};

export const addProduct = (productData, clearForm) => async dispatch => {
  //make form data
  const formData = convertingToFormData(productData);

  serverLoading(dispatch, true);

  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/api/product/add`,
      data: formData,
      headers: {
        "Content-type": "multipart/form-data"
        // Accept: "application/jon"
      }
    });
    if (data.status == true) {
      $("#exampleModal").modal("hide");
      dispatch({
        type: ADD_PRODUCT,
        payload: data.data
      });
      toastr.success("প্রডাক্ট সেভ সম্পন্ন হয়েছে।");
    }
    clearForm();
  } catch (error) {
    toastr.error(error?.response?.data.message);
  }
  serverLoading(dispatch, false);
};

export const productBulkImport = productData => async dispatch => {
  serverLoading(dispatch, true);

  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/api/product/bulkImport`,
      data: productData
    });
    if (data.status == true) {
      $("#excelImportModal").modal("hide");
      dispatch({
        type: PRODUCT_BULK_IMPORT,
        payload: data.data
      });
      toastr.success("প্রডাক্ট সেভ সম্পন্ন হয়েছে।");
    }
  } catch (error) {
    toastr.error(error?.response?.data.message);
  }
  serverLoading(dispatch, false);
};

export const updateProduct = (productData, clearForm) => async dispatch => {
  serverLoading(dispatch, true);
  const formData = convertingToFormData(productData);

  try {
    const { data } = await axios({
      method: "put",
      url: `${SERVER_URL}/api/product/edit/${productData._id}`,
      data: formData,
      headers: {
        "Content-type": "multipart/form-data"
      }
    });
    if (data.status == true) {
      $("#exampleModal").modal("hide");
      dispatch({
        type: UPDATE_PRODUCT,
        payload: data.data
      });
      store.dispatch(getProductsDetails(productData.storeID));
      toastr.success(data.msg);
    }
    clearForm();
  } catch (error) {
    toastr.error(error.response.data.message);
  }
  serverLoading(dispatch, false);
};

export const deleteProduct = productId => async dispatch => {
  serverLoading(dispatch, true);
  try {
    const { data } = await axios.delete(
      `${SERVER_URL}/api/product/delete/${productId}`
    );

    serverLoading(dispatch, false);
    $("#deleteModal").modal("hide");
    dispatch({
      type: DELETE_PRODUCT,
      payload: productId
    });
    toastr.success(data.msg);
  } catch (error) {
    toastr.error(error.response.data.message);
  }
  serverLoading(dispatch, false);
};

import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { t } from "../../../HNLanguage/i18nextSetUp";
import Masking from "./Masking";
import NonMasking from "./NonMasking";
import FixedNumber from "./FixedNumber";

class MessageLog extends Component {
  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="px-3 pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>{t("Message Log")}</h4>
            </div>
            <div className="px-2 bg-white">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="clear-fix py-3">
                    <p>
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="true"
                          >
                            Non Masking
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link "
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="false"
                          >
                            Masking
                          </a>
                        </li>

                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="contact-tab"
                            data-toggle="tab"
                            href="#contact"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false"
                          >
                            Fixed Number
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade  show active"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <NonMasking />
                        </div>
                        <div
                          class="tab-pane fade"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <Masking />
                        </div>

                        <div
                          class="tab-pane fade"
                          id="contact"
                          role="tabpanel"
                          aria-labelledby="contact-tab"
                        >
                          <FixedNumber />
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default MessageLog;

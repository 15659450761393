import React, { Component } from "react";

import { connect } from "react-redux";

import toastr from "toastr";
import Modal from "../Settings/Modal";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { getProprietors } from "../../actions/proprietorAction";
import SideBar from "../../components/admin/sidebar";
import { Header } from "../../components/admin";

import { addProprietor, updateProprietor } from "../../actions/adminActions";
import { resetServerState } from "../../actions/serverActions";
import { badge } from "../../utils/badge";
import ProprietorUpdateStore from "./ProprietorUpdateStore";
import { t } from "../../HNLanguage/i18nextSetUp";
import { Button } from "react-bootstrap";
import ProprietorAddEditModal from "./ProprietorAddEditModal";
import ProprietorStoreAddEditModal from "./ProprietorStoreAddEditModal";
import { adminAddStore } from "../../actions/storeActions";
import ProprietorStoreUpdateModal from "./ProprietorStoreUpdateModal";

const { SearchBar } = Search;

class Proprietor extends Component {
  constructor(props) {
    super(props);
    this.nameRef = React.createRef();
    this.fatherNameRef = React.createRef();
    this.phoneRef = React.createRef();
    this.nidRef = React.createRef();
    this.addressRef = React.createRef();
    this.remarksRef = React.createRef();
    this.state = {
      proprietors: props.proprietor || [],
      stores: props.stores || [],
      modalTitle: "",
      addStoreOpenModal: false,
      editStoreOpenModal: false,
      proprietorStoreUpdateModal: false,
      store: [],
      proprietorStore: [],
      showModal: false,
      showProprietorAddModal: false,
      showProprietorEditModal: false,
      proprietor: {},
      showProprietorStoreAddModal: false,
      showProprietorStoreEditModal: false,
      proprietorId: ""
    };
  }

  showDeleteProductModal = id => {
    this.setState({ ...this.state, deleteId: id });
  };
  componentDidMount() {
    this.setState({
      ...this.state,
      proprietors: this.props.proprietor,
      stores: this.props.stores
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      proprietors: nextProps.proprietor,
      stores: nextProps.stores
    });
  }

  /// proprietor related work start here
  createProprietorHandler = values => {
    const postData = {
      mobile: values.mobile,
      name: values.name,
      fatherName: values.fatherName,
      email: values.email,
      nid: values.nid,
      address: values.address,
      reference: {
        name: values.refName,
        mobile: values.refMobile,
        address: values.refAddress,
        relation: values.refRelation,
        nid: values.refNid
      },
      remarks: values.remarks
    };
    this.props.addProprietor(postData).then(result => {
      if (result) {
        this.setState({
          ...this.state,
          showProprietorAddModal: false,
          showProprietorEditModal: false
        });
      }
    });
  };
  updateProprietorHandler = values => {
    const postData = {
      _id: this.state.proprietorId,
      mobile: values.mobile,
      name: values.name,
      fatherName: values.fatherName,
      email: values.email,
      nid: values.nid,
      address: values.address,
      reference: {
        name: values.refName,
        mobile: values.refMobile,
        address: values.refAddress,
        relation: values.refRelation,
        nid: values.refNid
      },
      remarks: values.remarks
    };

    this.props.updateProprietor(postData).then(result => {
      if (result) {
        this.setState({
          ...this.state,
          showProprietorAddModal: false,
          showProprietorEditModal: false
        });
      }
    });
  };

  showProprietorUpdateModal = id => {
    const getProprietor = this.state.proprietors.find(
      proprietor => proprietor._id === id
    );
    this.setState({
      modalTitle: "Edit Proprietor",
      showProprietorEditModal: true,
      proprietor: getProprietor,
      proprietorId: id
    });
  };

  closeProprietorModalHandler = () => {
    this.setState({
      ...this.state,
      showProprietorAddModal: false,
      showProprietorEditModal: false
    });
  };

  /// proprietor related work end here

  /// proprietor store related work start here

  createProprietorStoreHandler = values => {
    this.props.adminAddStore(values).then(result => {
      if (result) {
        this.setState({
          ...this.state,
          showProprietorStoreAddModal: false,
          showProprietorStoreEditModal: false
        });
      }
    });
  };
  updateProprietorStoreHandler = values => {

    // proprietorStoreUpdate(values);
  };

  updateProprietorStore = values => {

  };
  showProprietorStoreUpdateModal = id => {
    const getProprietorStore = this.state.stores.find(
      store => store.proprietorID === id
    );

    this.setState({
      modalTitle: "Edit Proprietor store",
      showProprietorStoreEditModal: true,
      proprietorStore: getProprietorStore
    });
  };

  closeProprietorStoreModalHandler = () => {
    this.setState({
      ...this.state,
      showProprietorStoreAddModal: false,
      showProprietorStoreEditModal: false
    });
  };
  closeProprietorStoreModal = () => {
    this.setState({
      ...this.state,

      proprietorStoreUpdateModal: false
    });
  };
  /// proprietor store related work end here

  updateStoreOpenModal = id => {
    const { stores } = this.state;
    const getStore = stores.filter(store => store.proprietorID === id);

    this.setState({
      modalTitle: "Edit store",
      addStoreOpenModal: false,
      editStoreOpenModal: true,
      store: getStore
    });
  };

  proprietorUpdateStoreModal = id => {
    const { stores } = this.state;
    const getProprietorStore = stores.filter(
      store => store.proprietorID === id
    );

    this.setState({
      addStoreOpenModal: false,
      editStoreOpenModal: false,
      proprietorStoreUpdateModal: true,
      proprietorStore: getProprietorStore
    });
  };

  render() {
    const isLoaded = true;
    let columns = [
      {
        dataField: "_id",
        text: "ID"
      },
      {
        dataField: "name",
        text: "Name",
        sort: true
      },
      {
        dataField: "mobile",
        text: "Mobile"
      },
      {
        dataField: "status",
        text: "Status",
        formatter: data => {
          return <div> {badge(data)}</div>;
        }
      },
      {
        dataField: "email",
        text: "Email"
      },
      {
        dataField: "address",
        text: "Address"
      },
      {
        dataField: "_id",
        text: "অপশন",

        formatter: (cell, _row) => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown d-inline">
                <a href="#" data-toggle="dropdown" className="px-2">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    type="button"
                    className="dropdown-item has-icon"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => this.showProprietorUpdateModal(cell)}
                  >
                    <i className="fas fa-edit" />
                    প্রোপ্রাইটর আপডেট করুন
                  </a>

                  <div className="dropdown-divider" />

                  {/* <a
                    type="button"
                    className="dropdown-item has-icon"
                    data-toggle="modal"
                    data-target="#updateStoreModal"
                    onClick={() => {
                      this.showProprietorStoreUpdateModal(cell);
                    }}
                  >
                    <i className="fas fa-edit" />
                    স্টোর আপডেট করুন
                  </a> */}
                  <a
                    type="button"
                    className="dropdown-item has-icon"
                    data-toggle="modal"
                    data-target="#updateStoreModal"
                    onClick={() => {
                      this.proprietorUpdateStoreModal(cell);
                    }}
                  >
                    <i className="fas fa-edit" />
                    স্টোর আপডেট করুন
                  </a>

                  <div className="dropdown-divider" />
                  <a
                    href="#"
                    className="dropdown-item has-icon text-danger"
                    data-toggle="modal"
                    data-target="#deleteModal"
                  >
                    <i className="fas fa-trash" /> ডিলিট করুন
                  </a>
                </div>
              </div>
            </div>
          );
        }
      }
    ];
    const { proprietors } = this.state;
    const { server } = this.props;
    return (
      <>
        <Header />
        <SideBar />
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>প্রোপ্রিয়েটোর</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 p-0">
                  <div className="card p-3">
                    <div className="card-body px-0">
                      {!isLoaded ? (
                        <div
                          style={{ minHeight: 230 }}
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <div className="fa-3x">
                            <i
                              style={{ fontSize: 40 }}
                              className="fas fa-sync fa-spin"
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="clear-fix px-3">
                            <div className="float-right">
                              {/* <button
                                title="এড প্রোপ্রিয়েটোর"
                                className="btn btn-success mx-2"
                                data-toggle="modal"
                                data-target="#addPropietorModal"
                              >
                                <i className="fas fa-plus"></i>
                              </button> */}
                              <Button
                                variant="primary"
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    showProprietorAddModal: true,
                                    modalTitle: "Add Proprietor"
                                  })
                                }
                                className="m-2"
                              >
                                <i className="fas fa-plus"></i>
                              </Button>
                              {/* <button
                                title="এড স্টোর"
                                className="btn btn-success"
                                data-toggle="modal"
                                data-target="#addStoreModal"
                              >
                                <i className="fas fa-plus"></i>
                              </button> */}
                              <Button
                                variant="primary"
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    showProprietorStoreAddModal: true,
                                    modalTitle: "Add Proprietor Store"
                                  })
                                }
                              >
                                <i className="fas fa-plus"></i>
                              </Button>
                            </div>
                            <div
                              className="btn-group btn-group-sm float-left ml-3"
                              role="group"
                              aria-label="Basic example"
                            ></div>
                          </div>
                          <div style={{ minHeight: 230 }} className="p-3">
                            <PaginationProvider
                              pagination={paginationFactory({
                                sizePerPageList: [
                                  50,
                                  100,
                                  500,
                                  1000,
                                  2000,
                                  5000
                                ]
                              })}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  bootstrap4
                                  keyField="_id"
                                  data={proprietors}
                                  columns={columns}
                                  search
                                >
                                  {props => (
                                    <div>
                                      <div className="px-1">
                                        <SearchBar {...props.searchProps} />
                                      </div>
                                      <hr />
                                      <BootstrapTable
                                        {...props.baseProps}
                                        {...paginationTableProps}
                                        {...paginationProps}
                                        wrapperClasses="table-responsive"
                                      />
                                    </div>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ProprietorAddEditModal
            showProprietorAddModal={this.state.showProprietorAddModal}
            showProprietorEditModal={this.state.showProprietorEditModal}
            createProprietorHandler={this.createProprietorHandler}
            updateProprietorHandler={this.updateProprietorHandler}
            modalTitle={this.state.modalTitle}
            proprietor={this.state.proprietor}
            closeProprietorModalHandler={this.closeProprietorModalHandler}
          />

          <ProprietorStoreAddEditModal
            showProprietorStoreAddModal={this.state.showProprietorStoreAddModal}
            closeProprietorStoreModalHandler={
              this.closeProprietorStoreModalHandler
            }
            createProprietorStoreHandler={this.createProprietorStoreHandler}
            modalTitle={this.state.modalTitle}
            proprietors={this.state.proprietors}
            proprietorStore={this.state.proprietorStore}
          />
          <ProprietorUpdateStore proprietorStore={this.state.proprietorStore} />
          {/* <ProprietorStoreUpdateModal
            proprietorStore={this.state.proprietorStore}
            updateProprietorStore={this.updateProprietorStore}
            closeProprietorStoreModal={this.closeProprietorStoreModal}
            showProprietorStoreEditModal={this.state.proprietorStoreUpdateModal}
          /> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    proprietor: state.proprietor.proprietors,
    stores: state.proprietor.stores,
    server: state.server
  };
};

export default connect(mapStateToProps, {
  resetServerState,
  addProprietor,
  getProprietors,
  adminAddStore,
  updateProprietor
})(Proprietor);
